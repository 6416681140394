import { AccountState } from '../../store/state';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import { AccountDetail } from './types';

export const useAccountState = (): Partial<AccountState> =>
  useSelector((state: RootState) => state.Account);

export const useIsLoadingAccountDetail = (): boolean =>
  useSelector((state: RootState) => state.Account.loading);

// export const useGetAccountListing = (): AccountDetail[] =>
//   useSelector((state: RootState) => state.Account.accounts);
// export const useGetErrorResponse = (): unknown =>
//   useSelector((state: RootState) => state.Account.error);

// export const useGetSelectedAccount = (): AccountDetail | undefined =>
//   useSelector((state: RootState) => state.Account.selectedAccount);

// export const useGetSelectedBankAccount = (): any[] | undefined =>
//   useSelector((state: RootState) => state.Account.bank_accounts[0]);

// export const useGetBankAccounts = (): any[] | undefined =>
//   useSelector((state: RootState) => state.Account.bank_accounts);
