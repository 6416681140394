/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.scss';

import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import useGetPaginationInformation, {
  MetadataConfig
} from '../../../hooks/utils/useGetPaginationInformation';

import { MetadataResponseType } from '../../../types/models/metadataResponseType';
import { SorterProps } from '../../../types/models/utils/table-models';
import TableFooter from '../../atoms/TableFooter';
import TableFormatterComponent from '../../atoms/TableFormatterComponent';
import { defaultTableFooterProps } from '../../../hooks/utils/tableConfig/defaultProps';
// import useTableFormatter from '../../../hooks/utils/tableConfig/useTableFormatter';
import { useGetScreenState } from '../../../store/screenSize/selector';

interface GeneralTableLayoutProps<T> {
  tableHeader?: JSX.Element;
  transactions: T[];
  fetching: boolean;
  sorter: SorterProps[];
  columnFormatter: (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => JSX.Element[];
  columnHeaders: any[];
  paginationDetails: {
    limit: number;
    paginationCallback: (offset: number) => void;
    metadata: MetadataResponseType;
  };
  fetchingText: {
    fetching: string | JSX.Element;
    noData: string;
  };
}

const GeneralTableLayout: React.FC<GeneralTableLayoutProps<any>> = ({
  sorter,
  paginationDetails,
  fetching,
  columnFormatter,
  columnHeaders,
  transactions,
  tableHeader,
  fetchingText
}) => {
  const [metadataTransactions, setMetadataTransactions] =
    useState<MetadataConfig>(defaultTableFooterProps);
  const [columnFormatterResults, setColumnFormatterResults] = useState<
    JSX.Element[]
  >([]);
  // const [headers, setHeaders] = useState<JSX.Element[]>([]);

  // const { createTableHeader } = useTableFormatter();
  const { calculatePagination } = useGetPaginationInformation();
  const { breakpoint } = useGetScreenState();

  useEffect(() => {
    const result = calculatePagination(paginationDetails.metadata);
    setMetadataTransactions(result);
  }, [paginationDetails.metadata]);

  useEffect(() => {
    const results = columnFormatter(transactions, columnHeaders, breakpoint);
    setColumnFormatterResults(results);
  }, [transactions, breakpoint]);

  const EmptyMessage = () => (
    <tr style={{ height: 300, textAlign: 'center' }}>
      <td colSpan={columnHeaders.length} className="table__empty-message">
        {fetching ? fetchingText.fetching : fetchingText.noData}
      </td>
    </tr>
  );

  const paginationHandler = (page: number) => {
    const factorOffset = (page - 1) * paginationDetails.limit;
    paginationDetails.paginationCallback &&
      paginationDetails.paginationCallback(factorOffset);
  };

  const tableFooterProps = {
    ...metadataTransactions,
    pagingHandler: paginationHandler
  };

  const DesktopLayout = (
    transactions: any[],
    columnFormatterResults: any[]
  ) => (
    <div className="table__section--container">
      <MDBTable responsive className="tableLayout">
        <MDBTableHead>
          <tr>
            <TableFormatterComponent
              columnHeaders={columnHeaders}
              sorter={sorter}
            />
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {transactions.length > 0 ? (
            <>{columnFormatterResults}</>
          ) : (
            EmptyMessage()
          )}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
  const MobileLayout = (trans: any[], columnFormatterResults: any[]) => (
    <div className="card__sectionContainer">
      {trans.length > 0 ? (
        <>{columnFormatterResults}</>
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300
          }}
        >
          {fetchingText.noData}
        </div>
      )}
    </div>
  );

  const TableLayout = (transactions: any[], columnFormatterResults: any[]) => {
    return breakpoint === 'smartphone'
      ? MobileLayout(transactions, columnFormatterResults)
      : DesktopLayout(transactions, columnFormatterResults);
  };

  return (
    <React.Fragment>
      <section
        className={`${
          breakpoint === 'smartphone' ? 'card__section' : 'table__section'
        }`}
      >
        {tableHeader || <></>}

        {fetching
          ? fetchingText.fetching
          : TableLayout(transactions, columnFormatterResults)}

        {/* {transactions.length > 0 && <TableFooter {...tableFooterProps} />} */}
      </section>
    </React.Fragment>
  );
};

export default GeneralTableLayout;
