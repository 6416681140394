/* eslint-disable @typescript-eslint/no-explicit-any */
import LynkModal from '../../../../atoms/LynkModal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../store/actions';
import { useModalState } from '../../../../../store/modals/modal/selector';
import { getIconMapper, IconKey } from '../../../../../utils/iconMap';
import './index.scss';

const TransactionStatusModal: React.FC = () => {
  const dispatch = useDispatch();

  const { data, modalKey } = useModalState();

  const closeAction = () => {
    //TODO: dashbboard
    dispatch(hideModal());
  };

  const [stateDetails, setStateDetails] = useState({
    icon: '',
    title: '',
    subtitle: ''
  });

  useEffect(() => {
    const currentStatus = data?.status || '';
    setStateDetails(
      transactionState[currentStatus as keyof typeof transactionState]
    );
  }, [data]);

  const transactionState = {
    success: {
      icon: 'transaction-success',
      title: 'Your transfer is successful',
      subtitle: 'You transfer has been submitted successfully.'
    },
    'success-pending': {
      icon: 'transaction-success-pending',
      title: 'Your transfer is being processed',
      subtitle:
        'You transfer has been submitted successfully. We\'ll let you know when it\'s ready.'
    },
    failure: {
      icon: 'transaction-failure',
      title: 'Your Transfer Failed',
      subtitle: 'We were unable to transfer your transaction. Please try again.'
    },
    'batch-failure': {
      icon: 'transaction-failure',
      title: 'Your Transfer Failed',
      subtitle:
        'We were unable to continue with your transaction. Please try again.'
    },
    confirm: {
      icon: 'are-you-sure',
      title: 'Are you sure?',
      subtitle: 'Are you sure you wish to do this?'
    },
    'notification-details': {
      icon: '',
      title: 'Are you sure?',
      subtitle: 'Are you sure you wish to do this?'
    },
    '': {
      icon: '',
      title: '',
      subtitle: ''
    }
  };

  return (
    <LynkModal
      classes={`customModal  ${
        stateDetails.icon ? 'transactionStatusModal' : 'notification'
      } `}
      titleClass={`transactionStatus-${data ? data.status : ''}`}
      modalBody={
        stateDetails.icon ? (
          <div>
            <div className="modalBody" style={{ height: 200 }}>
              {getIconMapper(stateDetails.icon as IconKey)}
            </div>
            {/* <ActionButton
            type={'primary'}
            block
            text={'Return to Dashboard'}
            onPress={closeAction}
          /> */}
          </div>
        ) : undefined
      }
      title={data?.title || stateDetails.title}
      subTitle={data?.subtitle || stateDetails.subtitle}
      loading={false}
      mainAction={data?.mainAction || undefined}
      closeAction={
        data?.closeAction || {
          testID: 'defaultCloseAction',
          onClickFn: closeAction,
          text: 'Return to Dashboard',
          type: 'primary'
        }
      }
      show={modalKey === 'transaction-status'}
    />
  );
};

export default TransactionStatusModal;
