/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import { TransactionStateKeyEnum } from '../../../../hooks/utils/tableConfig/transactionState';
import DataDisplayContainer from '../../../molecules/DataDisplayContainer';
import { useGetTransactionTableState } from '../../../../store/transactions/tableState/selector';
import { FilterRangeObject } from '../../../../types/models/dateTypes';
import FilterButton from '../../../molecules/FilterButton';
import {
  CustomerTransactionRow,
  NotificationsRow
} from '../../../../types/models/tableTransactions';
import NotificationItem from '../../../atoms/NotificationItem';
import TransactionColumnFormatter from '../../../molecules/TransactionColumnFormatter';
import { amountFormated } from '../../../../utils/currency';
import { hideModal, showModal } from '../../../../store/actions';
import useConfigTable from '../../../../hooks/utils/tableConfig/useConfigTable';
import {
  SorterProps,
  SortingAscDesc
} from '../../../../types/models/utils/table-models';
import { useGetScreenState } from '../../../../store/screenSize/selector';
import ButtonAsyncLoader from '../../../atoms/ButtonAsyncLoader';
import useGetPaginationInformation from '../../../../hooks/utils/useGetPaginationInformation';
import useRequestRefund from '../../../../hooks/merchant_portal/useRequestRefund';
import useOrderUtil from '../../Orders/useOrderUtil';
import { useDispatch } from 'react-redux';
import useUpdateNotification from '../../../../hooks/notifications/useUpdateNotification';
import { useLocation, useNavigate } from 'react-router-dom';

const NotificationsList: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const transactionTableState = useGetTransactionTableState();
  const dispatch = useDispatch();

  const [transTooltip, setTransToolitp] = useState<any>({});
  const { getTransactionDetails } = useConfigTable();
  const { dispatchMetadataUpdate } = useGetPaginationInformation();
  const { updateNotification } = useUpdateNotification();
  const [update, setUpdate] = useState(false);
  const breakpointDetails = useGetScreenState();
  const [pageLimit, setPageLimit] = useState(10);
  const location = useLocation();
  useEffect(() => {
    const tab = location.pathname.split('/')[3];
    const group = tab === 'all' ? '' : tab.replace('-', '_');
    filterRangeHandler(group);
  }, [location]);
  useEffect(() => {
    setPageLimit(breakpointDetails.breakpoint === 'smartphone' ? 10 : 10);
  }, [breakpointDetails]);

  useEffect(() => {
    setTransToolitp(
      getTransactionDetails(TransactionStateKeyEnum.NOTIFICATIONS)
    );
  }, []);

  const { startRefund } = useRequestRefund();

  const filterRangeHandler = (group: string) => {
    dispatchMetadataUpdate(
      {
        tableFilter: {
          dateRange: {
            startRange: undefined,
            endRange: undefined
          },
          group: group
        }
      },
      TransactionStateKeyEnum.NOTIFICATIONS
    );
  };

  const ColumnStructure = (
    transactions: CustomerTransactionRow[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: CustomerTransactionRow) => (
      <tr key={row.m_order_id}>
        {columnHeaders.map((col: any) => (
          <td key={`${row.m_order_id}-${col.field}`} className={`${col.label}`}>
            <TransactionColumnFormatter
              readOnlyMode={false}
              column={col}
              row={row}
              refundFn={startRefund}
              stateOptions={transTooltip}
              tooltipIdentifier={`tooltip-${col.field}-${row.m_order_id}`}
            />
          </td>
        ))}
      </tr>
    ));
  };
  const navigate = useNavigate();

  const moreInfoCallback = async (data: NotificationsRow) => {
    updateSort();
    if (!data.read) {
      try {
        const response = await updateNotification(data.id);
        setUpdate(true);
      } catch (error) {
        console.log(error);
      }
    }
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'notification-details',
          title: data.title,
          subtitle: (
            <>
              <div style={{ textAlign: 'center' }}>{data.body}</div>
            </>
          ),
          mainAction:
            data.code === 'BATCH_DISBURSE_FUNDS_TO_APPROVE'
              ? {
                  disabled: false,
                  testID: 'transaction-status-main',
                  onClickFn: () => {
                    navigate(
                      `../../lynk-wallet/payment-history/review/${data.data.lynkRouteRedirect.replace(
                        /\D/g,
                        ''
                      )}`
                    );
                    dispatch(hideModal());
                  },
                  text: 'Review Payment'
                }
              : null,
          closeAction:
            data.code === 'BATCH_DISBURSE_FUNDS_TO_APPROVE'
              ? {
                  disabled: false,
                  testID: 'transaction-status-main',
                  onClickFn: () => dispatch(dispatch(hideModal())),
                  text: 'Return to Dashboard'
                }
              : null
        }
      })
    );
  };
  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: NotificationsRow) => (
      <NotificationItem
        key={`${row.id}`}
        title={row.title || ''}
        mainTitle={row.created}
        subTitle={row.body}
        data={row.read}
        moreInfoCallback={() => moreInfoCallback(row)}
      />
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.NOTIFICATIONS].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;

    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.NOTIFICATIONS
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.NOTIFICATIONS]]
  );

  return (
    <DataDisplayContainer
      {...dataDisplayProps}
      // columnFilter={columnFilter}
      // endpointGenerator={endpointGenerator}
      dataKey={TransactionStateKeyEnum.NOTIFICATIONS}
      dataGenerator={generateDisplay}
      noDataMessage={'No transactions yet'}
      fetchingData={{
        fetchingText: (
          <ButtonAsyncLoader
            loadingText="Loading Data... Please wait. "
            showLoaderText={true}
          />
        )
      }}
    />
  );
};

export default NotificationsList;
