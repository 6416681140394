/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useMemo } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import TableFormatterComponent from '../TableFormatterComponent';
import './index.scss';
import { DataDisplayContext } from '../../../hooks/utils/tableConfig';
import { EmptyDisplayProp } from '../../../types/models/DashboardWidgetType';
import TableEmptyStatusView from '../TableEmptyStatusView';
interface DataProps {
  noHeader: boolean;
}
const TransactionTableLayoutEmptyDisplay: React.FC<EmptyDisplayProp> = ({
  isMobile,
  tableLength,
  noDataDetails = <TableEmptyStatusView />
}) => {
  const EmptyTableDisplay = (
    <tr className="emptyMobileDisplayContainer">
      <td colSpan={tableLength} className="table__empty-message">
        {noDataDetails}
      </td>
    </tr>
  );

  const EmptyMobileDisplay = (
    <div className="emptyMobileDisplay">{noDataDetails}</div>
  );

  return isMobile ? EmptyMobileDisplay : EmptyTableDisplay;
};

const DataDisplayBody: React.FC<DataProps> = ({ noHeader }) => {
  const { sortMap, columnHeaders, breakpoint, fetchingData, transactions } =
    useContext(DataDisplayContext);

  const isMobile = useMemo(
    () => breakpoint.breakpoint === 'smartphone',
    [breakpoint.breakpoint]
  );

  const DesktopLayout = (
    columnFormatterResults: JSX.Element | JSX.Element[]
  ) => (
    <div className="table__section--container">
      <MDBTable responsive className="tableLayout">
        <MDBTableHead>
          <tr className={`${noHeader ? 'noDisplay' : ''}`}>
            <TableFormatterComponent
              columnHeaders={columnHeaders}
              sorter={sortMap}
            />
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <>{columnFormatterResults}</>
        </MDBTableBody>
      </MDBTable>
    </div>
  );

  const MobileLayout = (
    columnFormatterResults: JSX.Element | JSX.Element[]
  ) => (
    <div className="card__sectionContainer">
      <>{columnFormatterResults}</>
    </div>
  );

  const TableLayout = (columnFormatterResults: JSX.Element | JSX.Element[]) => {
    return breakpoint.breakpoint === 'smartphone'
      ? MobileLayout(columnFormatterResults)
      : DesktopLayout(columnFormatterResults);
  };

  if (fetchingData.fetching) {
    return TableLayout(
      <TransactionTableLayoutEmptyDisplay
        isMobile={isMobile}
        tableLength={columnHeaders.length}
        noDataDetails={fetchingData.fetchingText}
      />
    );
  }

  if (transactions.length === 0) {
    return TableLayout(
      <TransactionTableLayoutEmptyDisplay
        isMobile={isMobile}
        tableLength={columnHeaders.length}
      />
    );
  }

  return TableLayout(transactions);
};

export default DataDisplayBody;
