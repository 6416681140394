import { ApiServiceType } from '../../types/enums/apiServiceType';
import usePutData from '../../hooks/api/usePutData';

const useUpdateNotification = (): {
  fetching: boolean;
  updateNotification(id: string): Promise<any>;
  readAllNotification(): Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, putData } = usePutData();
  const abortController = new AbortController();

  const updateNotification = async (id: string): Promise<any> => {
    const response = await putData({
      path: `notifications/${id}/read`,
      apiType: ApiServiceType.PROFILE
    });

    return response;
  };
  const readAllNotification = async (): Promise<any> => {
    const response = await putData({
      path: 'notifications/read-all',
      apiType: ApiServiceType.PROFILE
    });

    return response;
  };
  return {
    fetching: isFetching,
    updateNotification,
    readAllNotification,
    abortController
  };
};
export default useUpdateNotification;
