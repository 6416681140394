import { createSlice } from '@reduxjs/toolkit';
import { Breadcrumb } from '../types';

interface BreadcrumbState {
  breadcrumbs: Breadcrumb[];
}

const initialState: BreadcrumbState = {
  breadcrumbs: []
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    }
  }
});

export const { setBreadcrumbs } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
