export enum Role {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER ADMIN',
  MANAGER_SUPERVISOR = 'MANAGER/SUPERVISOR',
  CASHIER = 'CASHIER'
}

export enum RoleId {
  ADMIN = 1,
  SUPER_ADMIN = 4,
  MANAGER_SUPERVISOR = 2,
  CASHIER = 3
}
