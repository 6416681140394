/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiConfig } from '../../types/models/api/apiConfig';
import { getAxiosCall } from '../../services/api';
import { useState } from 'react';
import { getHeaders } from './getHeaders';
import { useGetProfileState } from '../../store/profile/selector';

/**
 * Helper function that exposes the state of the API call and the actual function
 * responsible for making the `API` call.
 *
 * @generic The object type the `Promise` should return.
 * @returns {boolean} `isFetching` -> a `boolean` indicating the status of the API call.
 * @returns {Promise<any>} `postData` -> the function making the `Promise`.
 */
const usePatchData = (): {
  isFetching: boolean;
  patchData: (config: ApiConfig) => Promise<any>;
} => {
  const [isFetching, setIsFetching] = useState(false);
  const { accessToken } = useGetProfileState();

  /**
   * Performs a `POST` request to the specified API, passing any objects specified
   * within the body of the request.
   *
   * @param {ApiConfig} config collection of settings for the request.
   * @param {ApiServiceType} apiType The type of API service needed
   * @returns
   */
  const patchData = async (config: ApiConfig): Promise<any> => {
    try {
      // Step 1: Update the state
      setIsFetching(true);
      const authHeaders = getHeaders(true, accessToken);
      const API_TYPE = config.apiType;
      const AXIOS = getAxiosCall(API_TYPE);
      setIsFetching(true);

      // Step 2: Make API call
      const response = await AXIOS.patch<any>(config.path, config.data, {
        ...config.params,
        headers: {
          ...authHeaders,
          ...config.headers
        }
      });
      // Step 3: Return data from API response
      return { ...response.data };
    } finally {
      setIsFetching(false);
    }
  };

  return { isFetching, patchData };
};

export default usePatchData;
