import moment from 'moment';

export function formaterTitleToltip(
  labelIndex: number,
  graphXAxisLabel: string,
  labels: string[]
): string {
  const labelDate = moment(new Date(labels[labelIndex])).utc();
  const startDateWeek = moment(new Date(labels[labelIndex]))
    .utc()
    .subtract(6, 'days');
  let titleTooltip = '';
  switch (graphXAxisLabel) {
    case 'Month':
      titleTooltip = `1 ${labelDate.format(
        'MMM'
      )} - ${labelDate.daysInMonth()}  ${labelDate.format('MMM')}`;
      break;
    case 'Week':
      titleTooltip = `${startDateWeek.format('MMM DD')} - ${labelDate.format(
        'MMM DD'
      )}`;
      break;
    default:
      titleTooltip = labelDate.format('MMM DD');
      break;
  }
  return titleTooltip;
}

export function formatLabelXaxies(
  label: string,
  graphXAxisLabel: string
): string {
  const formatPeriod = graphXAxisLabel === 'Month' ? 'MMM' : 'MMM DD';
  return label ? moment(new Date(label)).utc().format(formatPeriod) : '';
}

export function dateMonthDifferenceByOne(
  dateOne: Date,
  dateTwo: Date
): boolean {
  if (!dateOne || !dateTwo) return false;

  const start = moment(dateOne);
  const end = moment(dateTwo);
  const duration = moment.duration(end.diff(start));

  const monthDiff = duration.asMonths();

  return monthDiff <= 1;
}

export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0');
}

export function formatDate(
  date: Date | undefined,
  format = 'DD/MM/YYYY'
): string {
  if (!date) return '';

  const dateFormatSeparator = [...new Set(format.replace(/\w/g, '').split(''))];
  const dateFomatterSplit = format.toUpperCase().split(dateFormatSeparator[0]);

  const dayIndex = dateFomatterSplit.findIndex(value => value.startsWith('D'));
  const monthIndex = dateFomatterSplit.findIndex(value =>
    value.startsWith('M')
  );
  const yearIndex = dateFomatterSplit.findIndex(value => value.startsWith('Y'));

  const dateArr: string[] = [];
  dateArr[dayIndex] = padTo2Digits(date.getDate());
  dateArr[monthIndex] = padTo2Digits(date.getMonth() + 1);
  dateArr[yearIndex] = `${date.getFullYear()}`;

  return dateArr.join(dateFormatSeparator[0]);
}

export function createDate(
  dateVal: string,
  format = 'DD/MM/YYYY'
): Date | null {
  if (!validateDate(dateVal, format.toString())) return null;

  const dateFormatSeparator = [...new Set(format.replace(/\w/g, '').split(''))];
  const dateFomatterSplit = format.toUpperCase().split(dateFormatSeparator[0]);

  const dateValSplit = dateVal.split(dateFormatSeparator[0]);

  const dateObj = new Date();

  const dayIndex = dateFomatterSplit.findIndex(value => value.startsWith('D'));
  const monthIndex = dateFomatterSplit.findIndex(value =>
    value.startsWith('M')
  );
  const yearIndex = dateFomatterSplit.findIndex(value => value.startsWith('Y'));

  dateObj.setFullYear(
    Number.parseInt(dateValSplit[Number.parseInt(dateValSplit[yearIndex])]),
    Number.parseInt(dateValSplit[Number.parseInt(dateValSplit[monthIndex])]),
    Number.parseInt(dateValSplit[Number.parseInt(dateValSplit[dayIndex])])
  );

  return dateObj;
}

export function validateDate(dateVal: string, format = 'DD/MM/YYYY'): boolean {
  return moment(dateVal, format.toUpperCase(), true).isValid();
}

export const getTimeStamp = (timestmp: string): string => {
  const convertedMoment = moment(timestmp);
  return convertedMoment.format('MMM DD [at] hh:mm a');
};
