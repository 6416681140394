/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ApiServiceType } from '../../types/enums/apiServiceType';
import { AccountItemType } from '../../store/account/types';
import usePostData from '../../hooks/api/usePostData';
import {
  BalanceNotEnoughException,
  GenericTransferException,
  TransferLimitExceedException
} from '../../exceptions/TransferHistory.exceptions';

export type TransferBodyType = 'CASH-OUT' | 'CASH-IN';
export interface TransferBody {
  transfer_type: TransferBodyType;
  account_type: AccountItemType;
  account_id: number;
  amount: number;
}

const useTransferHook = (): {
  fetching: boolean;
  postTransaction: (
    transferBody: TransferBody,
    accountID: number
  ) => Promise<void>;
} => {
  const { postData } = usePostData();
  const [fetching, setFetching] = useState(false);
  const postTransaction = async (
    transferBody: TransferBody,
    sourceAccountID: number
  ) => {
    setFetching(true);
    try {
      await postData({
        path: `/v1/accounts/${sourceAccountID}/transfers`,
        apiType: ApiServiceType.ACCOUNT,
        data: transferBody
      });
    } catch (err: any) {
      const generalErrorList = err.response.data.error.general as string[];

      if (err.response.status === 500) {
        throw new GenericTransferException(
          err.response.data.error.details[0],
          err.response.status
        );
      }

      if (generalErrorList.includes('Insufficient Funds')) {
        throw new BalanceNotEnoughException(
          'Your account doesn’t have enough money. Try a different amount.',
          err.response.status
        );
      } else if (
        err.response.data.error.details.Reason === 'Transfer limit reached'
      ) {
        throw new TransferLimitExceedException(
          'Transfer limit reached',
          err.response.status
        );
      }
    } finally {
      setFetching(false);
    }
  };
  return {
    fetching,
    postTransaction
  };
};

export default useTransferHook;
