import React from 'react';
import './index.scss';
import { Label, Input } from 'reactstrap';
import { ReactComponent as Verified } from '../../../../src/assets/svg/verified.svg';

interface TransferAmount {
  amount: string;
  onFormChange: (e: React.FormEvent<HTMLInputElement>) => void;
  payeeDetails: any;
}
const TransferAmountForm: React.FC<TransferAmount> = ({
  amount,
  onFormChange,
  payeeDetails
}) => {
  return (
    <div className="extraFormPadding">
      <div className="payeeDetails">
        <Label className="textbox__description">
          Enter Recipient&apos;s Lynk.iD
        </Label>

        <div className="payeeDetails__container">
          <div className="payeeDetails__name">
            <div> {payeeDetails.display_name} </div>
            {payeeDetails.verified && (
              <Verified style={{ marginLeft: '3px' }} />
            )}
          </div>
          <div className="payeeDetails__subContainer">
            {payeeDetails.user_name} |{' '}
            {payeeDetails.profile_type === 'CONSUMER'
              ? 'Lynk User'
              : 'Lynkbiz Merchant'}
          </div>
        </div>
      </div>

      <div className="transferAmount">
        <Label className="transferAmount__amount"> Amount </Label>
        <div className="transferAmount__inputContainer">
          <Input
            className="transferAmount__input"
            value={amount}
            onChange={onFormChange}
            placeholder="0.00"
          />
        </div>
      </div>
    </div>
  );
};

export default TransferAmountForm;
