export interface SinglePaymentDetailType {
  account_id: string;
  account_type: number;
  reviewed_at: string;
  reviewed_by: string;
  completed_at: string;
  created_at: string;
  created_by: string;
  lynk_fee: number;
  lynk_fee_gct: number;
  merchant_id: string;
  payee_count: number;
  status: string;
  subtotal: number;
  total: number;
  id: number;
  action?: string;
  payees?: Array<any>;
}

export const initSinglePaymentDetail = {
  account_id: '',
  account_type: 1,
  reviewed_at: '',
  reviewed_by: '',
  completed_at: '',
  created_at: '',
  created_by: '',
  lynk_fee: 0,
  lynk_fee_gct: 0,
  merchant_id: '',
  name: '',
  payee_count: 0,
  status: '',
  subtotal: 0,
  total: 0,
  id: 0
};

export interface SinglePaymentPayee {
  id: number;
  user_id: string;
  display_name: string;
  user_name: string;
  name: string;
  last_name: string;
  email: string;
  phone_number: string;
  welcome_bonus_claimed: boolean;
  keep_default_username: boolean;
  verified: boolean;
  profile_type: string;
}

export const initSinglePaymentPayee = {
  id: 0,
  user_id: '',
  display_name: '',
  user_name: '',
  name: '',
  last_name: '',
  email: '',
  phone_number: '',
  welcome_bonus_claimed: false,
  keep_default_username: false,
  verified: false,
  profile_type: ''
};

export type SinglePaymentDetailTypeKey = keyof SinglePaymentDetailType;

export type SinglePaymentPayeeTypeKey = keyof SinglePaymentPayee;
