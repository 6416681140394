import './index.scss';

import React, { useMemo, useRef } from 'react';
import { addAlertMetadata, clearScope } from '../../../store/status';
import {
  useGetQRCodeDetail,
  useIsLoadingProfileDetail
} from '../../../store/organization/selector';

import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import { getToast } from './downloadToast';
import { useDispatch } from 'react-redux';
import useDownloadCompAsImage from '../../../hooks/utils/useDownloadCompAsImage';
import CBDCQRCodeDownload from '../../organisms/CBDCQRCodeDownload';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import { MerchantQRSectionProps } from '../../../types/models/MerchantCodeType';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { useAccountState } from '../../../store/account/selector';
import { WalletDetailBase } from '../../../store/account/types';
import { showModal } from '../../../store/modals/modal';

const DownloadMerchantJamDexQRWidget: React.FC<MerchantQRSectionProps> = ({
  classes = ''
}) => {
  const fetching = useIsLoadingProfileDetail();
  const qrDetails = useGetQRCodeDetail();
  //const profile = useGetProfileState();
  const walletManager = useGetWalletManager();
  const accounts = useAccountState();
  const { isFetching, downloadElementAsImage } = useDownloadCompAsImage();

  const ref: any = useRef();
  const dispatch = useDispatch();

  const renderResult = (errorType: 'success' | 'error') => {
    const toast = getToast(errorType);

    if (toast) {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'success',
            subtitle:
              'QR code for JAM-Dex generated successfully. Please check you machine for the file.',
            title: 'JAM-DEX QR code generated'
          }
        })
      );
    }
  };

  const downloadQRSnapshot = async () => {
    let status: 'success' | 'error' = 'success';
    dispatch(clearScope('general'));
    try {
      // dispatch(clearScope('general'));
      await downloadElementAsImage(
        `${qrDetails.name || 'merchant'}__qrCode`,
        ref
      );
    } catch (error) {
      status = 'error';
    } finally {
      renderResult(status);
    }
  };

  const generatedValue = isFetching ? 'Generating...' : 'Download QR Code';

  const jamDexURL = useMemo(() => {
    const cbdcAccountDetails = accounts.accounts?.find(
      (account: any) => account.type === 'CBDC'
    );
    if (!cbdcAccountDetails) return '';
    return `${
      (cbdcAccountDetails.details as WalletDetailBase).external_reference || ''
    }`;
  }, [accounts]);

  return (
    <React.Fragment>
      <section className={`downloadQRWidget ${classes || ''}`}>
        <DashboardIconWidgetBase
          testID="downloadQRWidgetBase"
          icon={
            isFetching ? (
              <ButtonAsyncLoader showLoaderText={false} />
            ) : (
              <QrCode2OutlinedIcon className="qrIconAtom default" />
            )
          }
          label={!fetching ? generatedValue : ''}
          callback={downloadQRSnapshot}
          disabled={false}
          id={'downloadQrWidget'}
        />
        <div className="downloadQRefSection">
          <div ref={ref} className="downloadQRefSection__qrCodeSection">
            <CBDCQRCodeDownload
              qrCode={jamDexURL}
              merchantName={qrDetails.name}
              merchantLynkID={qrDetails.username}
              loading={false}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default DownloadMerchantJamDexQRWidget;
