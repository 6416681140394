import React from 'react';
import './index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TableCardProps } from '../../../types/models/tableType';

const TableCard: React.FC<TableCardProps> = ({
  title,
  mainTitle,
  subTitle,
  data,
  moreInfoCallback
}) => {
  return (
    <>
      <div className="tableCardContainer">
        <div className="cardTitleContainer">
          <div className="title">{title}</div>
          <div className="moreDetailsIcon" onClick={moreInfoCallback}>
            <MoreVertIcon />
          </div>
        </div>
        <div className="cardDataContainer">
          <div>
            <div className="username">
              {!!mainTitle && mainTitle.length > 20
                ? `...${mainTitle.slice(6, mainTitle.length)}`
                : mainTitle}
            </div>
            <div className="transTimestamp">{subTitle}</div>
          </div>
          <div className="transAmt">{data}</div>
        </div>
      </div>
    </>
  );
};

export default TableCard;
