import React, { useEffect, useState } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { amountFormated } from '../../../utils/currency';
import './index.scss';
import CheckboxGroupItem from '../../atoms/CheckboxGroupItem';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as SpeakerPrompt } from '../../../assets/svg/speaker-prompt.svg';
import { ReactComponent as Verified } from '../../../assets/svg/verified.svg';
import { useGetProfileState } from '../../../store/profile/selector';

interface Props {
  isFetching: boolean;
  lynkAcctDetail: {
    lynkAcctCount: number;
    downloadText: string;
    onDownloadTrigger?: () => Promise<void>;
    isLynkAccountVerified?: boolean;
  };
  subTotal: number;
  processingFee: number;
  gctProcessingFee: number;
  total: number;
  showConfirm?: {
    confirmVal: boolean;
    changeConfirmCheck: () => void;
  };
  limitExceeded: boolean;
  setLimitedExceeded: any;
  transferBalance: number;
  isSinglePayment?: boolean;
}

const ConfirmPayment: React.FC<Props> = ({
  isFetching,
  lynkAcctDetail,
  subTotal,
  processingFee,
  gctProcessingFee,
  total,
  showConfirm,
  limitExceeded,
  setLimitedExceeded,
  transferBalance,
  isSinglePayment
}) => {
  const profileState = useGetProfileState();
  const [tierRoleError, setTierRoleError] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  setLimitedExceeded(total > transferBalance);
  useEffect(() => {
    const role = profileState.role.toLowerCase();
    setTierRoleError(role !== 'admin');
    // setLimitedExceeded(total > transferBalance);
  }, [profileState]);
  const adviceTextLimit = (): string => {
    let text = '';

    if (transferBalance < 0) {
      text =
        'We experienced technical difficulties checking your weekly limit.';
    } else if (limitExceeded) {
      text = tierRoleError
        ? `You currently have J$${transferBalance.toLocaleString()} remaining
        for this week, and the limit will reset at the end of the week.`
        : `Proceeding with this transaction will exceed your weekly limit.
        You have J$${transferBalance.toLocaleString()} remaining for this
        week.`;
    }
    return text;
  };

  const handleMouseOver = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseOut = () => {
    setIsTooltipOpen(false);
  };

  const checkChars = (name: string) => {
    const max = 25;
    return name.length > max ? name.substring(0, max) + '...' : name;
  };
  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <div className="detailRow">
          <div>
            {isFetching ? (
              <Skeleton width={200} />
            ) : (
              <>To: {lynkAcctDetail.lynkAcctCount} Lynk Account(s)</>
            )}{' '}
          </div>
          <div>
            {isFetching ? (
              <Skeleton width={150} />
            ) : isSinglePayment ? (
              <>
                <div
                  className="payeeNameContainer divRow"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  {isTooltipOpen &&
                    lynkAcctDetail.downloadText.length >= 25 && (
                      <div className="payeeNameTooltip">
                        {lynkAcctDetail.downloadText}
                      </div>
                    )}{' '}
                  {checkChars(lynkAcctDetail.downloadText)}
                  {lynkAcctDetail.isLynkAccountVerified && <Verified />}{' '}
                </div>
              </>
            ) : (
              <div
                className="downloadLink"
                onClick={lynkAcctDetail.onDownloadTrigger}
              >
                {lynkAcctDetail.downloadText} <SaveAltIcon />
              </div>
            )}
          </div>
        </div>
        <div className="detailRow">
          <div>{isFetching ? <Skeleton width={200} /> : <> Sub Total</>}</div>
          <div className="divRow">
            {isFetching ? <Skeleton width={150} /> : amountFormated(subTotal)}
          </div>
        </div>
        <div className="detailRow">
          <div>
            {isFetching ? <Skeleton width={200} /> : <>Processing Fee *</>}
          </div>
          <div className="divRow">
            {isFetching ? (
              <Skeleton width={150} />
            ) : (
              amountFormated(processingFee)
            )}
          </div>
        </div>
        <div className="detailRow">
          <div>
            {isFetching ? (
              <Skeleton width={200} />
            ) : (
              <>GCT on Processing Fee *</>
            )}
          </div>
          <div className="divRow">
            {isFetching ? (
              <Skeleton width={150} />
            ) : (
              amountFormated(gctProcessingFee)
            )}
          </div>
        </div>
        <div className="detailRow">
          <div>{isFetching ? <Skeleton width={200} /> : <>Total *</>}</div>
          <div className="divRow">
            {isFetching ? <Skeleton width={150} /> : amountFormated(total)}
          </div>
        </div>
      </div>
      {isFetching && (
        <>
          <Skeleton width={500} />
          <Skeleton width={200} />
        </>
      )}
      {showConfirm && !isFetching ? (
        <CheckboxGroupItem
          isActive={showConfirm.confirmVal}
          label={
            'I confirm the recipients and fees for this transfer. I agree that transfers are final once completed.'
          }
          onClick={showConfirm.changeConfirmCheck}
          classnames={limitExceeded ? 'disabled' : ''}
        />
      ) : undefined}
      {!isFetching && (
        <div className="estimatedFields">
          * Figures are estimated. Your recipients and fees will be double
          checked in real time when the transaction is being processed
        </div>
      )}

      {limitExceeded || transferBalance === -1 ? (
        <div className="exceedLimitContainer">
          <div className="exceedLimitContainer-icon">
            <SpeakerPrompt />
          </div>
          <p className="exceedLimitContainer-text">{adviceTextLimit()}</p>
        </div>
      ) : undefined}
    </>
  );
};

export default ConfirmPayment;
