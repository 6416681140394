import { ExportPage } from './model';

import ExportPreferenceWizardPage from '../../../../../molecules/ExportPreferenceWizardPage';
import React from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import {
  ModalStackDetails,
  ModalStack
} from '../../../../../../utils/modal/modalNavModal';

export const defaultPage: ModalStackDetails<ExportPage> = {
  title: 'Export Preferences',
  component: <ExportPreferenceWizardPage />,
  mainAction: {
    text: 'Download',
    action: 'preferences',
    loader: {
      text: 'Generating File',
      icon: <SyncIcon />
    }
  }
};

export const modalStack: ModalStack<ExportPage> = {
  preferences: {
    title: 'Export Preferences',
    component: <ExportPreferenceWizardPage />,
    mainAction: {
      text: 'Generate File',
      action: 'preferences',
      loader: {
        text: 'Generating File',
        icon: <SyncIcon />
      }
    }
  },
  downloading: {
    title: 'Export',
    component: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        Your report was generated successfully.
      </div>
    ),
    mainAction: {
      text: 'Download',
      action: 'downloading',
      loader: {
        text: 'Preparing',
        icon: <SyncIcon />
      }
    }
  }
};
