const preferencesTransactions = {
  PAYMENT_RECEIVED: 'Someone makes a payment to my Lynk Wallet'
};
const preferences = {
  BATCH_DISBURSE_FUNDS_REJECTED: 'A Payment Request was Rejected',
  BATCH_DISBURSE_FUNDS_TO_APPROVE: 'Someone sent a Payment to be Approved',
  BATCH_DISBURSE_FUNDS_PROCESSED_WITH_ERROR: 'A Payment Request was Processed',
  BATCH_DISBURSE_FUNDS_FAILURE: 'A Payment Request Fails',
  BATCH_DISBURSE_FUNDS_PROCESSED: 'A Payment Request was Completed',

  BATCH_DISBURSE_FUNDS_APPROVED: 'Someone Approved a Payment Request'
};
export type PreferenceKey = keyof typeof preferences;
export type PreferenceTransactionsKey = keyof typeof preferencesTransactions;
export type NotificationSettingsPreferenceKey =
  | PreferenceKey
  | PreferenceTransactionsKey;
export const transactionPreferences = {
  name: 'Someone makes a payment to my Lynk Wallet',
  group: 'TRANSACTION',
  enabled: false,
  code: 'PAYMENT_RECEIVED',
  id: 'TRANS_PAYMENT'
};
export const getPreferenceMapper = (preferenceKey: PreferenceKey): string => {
  const preference = preferences[preferenceKey];
  return preference;
};
export const getTransactionPreferenceMapper = (
  preferenceKey: PreferenceTransactionsKey
): string => {
  const preference = preferencesTransactions[preferenceKey];
  return preference;
};
