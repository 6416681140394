/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.scss';

import React, { useEffect, useState } from 'react';

import { ExportReportColumnHeaders } from '../../../../../hooks/utils/tableConfig/columnHeaders';
import GeneralTableLayout from '../../../../molecules/GeneralTableLayout';
import LynkModal from '../../../../atoms/LynkModal';
import { hideModal } from '../../../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import BaseColFormatter from '../../../../atoms/BaseColumnFormatter';

const ViewLastExportModal: React.FC = () => {
  const [lastDownloads, setLastDownloads] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setLastDownloads([
      // {
      //     date: '23/06/2022 to 23/09/2022',
      //     tables: 'Orders, Cash out, Refund',
      //     status: 'processing'
      //   },
      //   {
      //     date: '23/03/2022 to 23/06/2022',
      //     tables: 'Orders, Cash out, Refund',
      //     status: 'complete'
      //   },
      //   {
      //     date: '23/03/2022 to 23/06/2022',
      //     tables: 'Orders, Cash out, Refund',
      //     status: 'complete'
      //   },
      //   {
      //     date: '23/12/2021 to 23/03/2022',
      //     tables: 'Orders, Cash out',
      //     status: 'complete'
      //   }
    ]);
  }, []);
  //   const generated = () => {
  //     dispatch(addAlertMetadata({
  //       key: `download_success_${new Date().getMilliseconds()}`,
  //       alertType: 'success',
  //       scope: 'general',
  //       alertDetails : {
  //         title: 'Generating ',
  //         message: 'Your report was generated successfully. You can download it in the “last reports” section. Hi',
  //         timeMade: new Date(),
  //         alertTag: 'download'
  //       }
  //     }))
  //   };

  const close = () => {
    dispatch(hideModal());
  };

  const ColumnStructure = (
    transactions: any[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: any) => (
      <tr key={row.name}>
        {columnHeaders.map(col => (
          <td key={`${row.name}-${col.field}`} className={`${col.label}`}>
            <BaseColFormatter column={col} row={row} />
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <React.Fragment>
      <LynkModal
        classes="lastReportModal"
        modalBody={
          <>
            <h4 className="lastReportsH4"> Last Reports</h4>
            <div>
              Below you will find the list of your last 5 generated reports,
              sorted by date
            </div>
            <section>
              <GeneralTableLayout
                transactions={lastDownloads}
                fetching={false}
                sorter={[]}
                columnFormatter={ColumnStructure}
                columnHeaders={ExportReportColumnHeaders}
                paginationDetails={{
                  limit: 0,
                  paginationCallback: () => console.log(''),
                  metadata: {
                    count: lastDownloads.length,
                    limit: 0,
                    offset: lastDownloads.length,
                    total: lastDownloads.length
                  }
                }}
                fetchingText={{
                  fetching: 'Loading Last Five Reports',
                  noData: 'There are no previously generated reports'
                }}
              />
            </section>
          </>
        }
        title={''}
        loading={false}
        closeAction={{
          showButton: false,
          testID: 'viewLastExportModalCancel',
          onClickFn: close,
          text: 'Cancel'
        }}
        show={true}
      ></LynkModal>
    </React.Fragment>
  );
};

export default ViewLastExportModal;
