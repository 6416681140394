/* eslint-disable no-unused-vars */
import { useAccountState } from '../../store/account/selector';
import { ApiServiceType } from '../../types/enums/apiServiceType';
import useGetData from '../api/useGetData';

export type WalletType = 'jam-dex' | 'lynk' | 'corporate';

const useGetWalletBalance = (): {
  fetching: boolean;
  getWalletBalance(walletType: WalletType): Promise<number>;
  getAccountBalance(walletType: string): Promise<number>;
  abortController: AbortController;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();

  const accountDetail = useAccountState();

  const getWalletDetails = (walletType: WalletType) => {
    return accountDetail.accounts?.find(acct => {
      const map = {
        lynk: 'LYNK',
        'jam-dex': 'CBDC',
        corporate: ''
      };

      return acct.type == map[walletType];
    });
  };

  const getWalletBalance = async (walletType: WalletType): Promise<number> => {
    const accountDetail = getWalletDetails(walletType);
    const response = await getData({
      path: `/v2/accounts/${accountDetail?.id}/balance`,
      apiType: ApiServiceType.ACCOUNT,
      controller: abortController
    });
    const { balance } = response.data;

    return Number(balance);
  };
  const getAccountBalance = async (account: string): Promise<number> => {
    const response = await getData({
      path: `/v2/accounts/${account}/balance`,
      apiType: ApiServiceType.ACCOUNT,
      controller: abortController
    });
    const { balance } = response.data;

    return Number(balance);
  };
  return {
    fetching: isFetching,
    getWalletBalance,
    getAccountBalance,
    abortController
  };
};
export default useGetWalletBalance;
