import { useState } from 'react';

const useDateFilterButton = (): {
  date: Date | undefined;
  endDate: Date | undefined;
  clearDateRange: () => void;
  handleChange: (start: Date | undefined, endDate: Date | undefined) => void;
} => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const handleChange = (start: Date | undefined, endDate: Date | undefined) => {
    setDate(start);
    setEndDate(endDate);
  };

  const clearDateRange = () => {
    setDate(undefined);
    setEndDate(undefined);
  };

  return {
    date,
    endDate,
    handleChange,
    clearDateRange
  };
};

export default useDateFilterButton;
