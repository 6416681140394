import { ScreenState } from '.';
import { RootState } from '..';
import { useSelector } from 'react-redux';

export const useGetScreenState = (): ScreenState =>
  useSelector((state: RootState) => state.ScreenData);

// export const useIsMobile= (): boolean =>
// useSelector((state: RootState) => {
//   const breakpoint = state.ScreenData.breakpoint;

//   return breakpoint === 'smartphone';
// });

// export const useIsTablet= (): boolean =>
// useSelector((state: RootState) => {
//   return state.ScreenData.breakpoint === 'tablet';
// });

// export const useIsTable= (): boolean =>
// useSelector((state: RootState) => {
//   return state.ScreenData.breakpoint === 'tablet';
// });
