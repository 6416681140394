export interface BatchDetailType {
  account_id: string;
  account_type: number;
  reviewed_at: string;
  reviewed_by: string;
  completed_at: string;
  created_at: string;
  created_by: string;
  lynk_fee: number;
  lynk_fee_gct: number;
  merchant_id: string;
  name: string;
  payee_count: number;
  status: string;
  subtotal: number;
  total: number;
  id: number;
  action?: string;
  payees?: Array<any>;
}
export const initBatchDetail = {
  account_id: '',
  account_type: 1,
  reviewed_at: '',
  reviewed_by: '',
  completed_at: '',
  created_at: '',
  created_by: '',
  lynk_fee: 0,
  lynk_fee_gct: 0,
  merchant_id: '',
  name: '',
  payee_count: 0,
  status: '',
  subtotal: 0,
  total: 0,
  id: 0
};
export type BatchDetailTypeKey = keyof BatchDetailType;
