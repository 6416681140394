import { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';

const useAuth = (): {
  isLoading: boolean;
  isAuthenticated: boolean;
  auth0Token?: string;
  login: () => void;
  logout: () => void;
} => {
  const { isAuthenticated, isLoading, auth0Token, login, logout } =
    useContext(AuthContext);

  return {
    isLoading,
    isAuthenticated,
    auth0Token,
    login,
    logout
  };
};

export default useAuth;
