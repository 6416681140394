import { useModalState } from '../../../../store/modals/modal/selector';
import React, { useEffect, useState } from 'react';

import ExportTableModal from '../main/ExportTableModal';
import RefundModal from '../main/RefundModal';
import { hideModal } from '../../../../store/actions';
import { useGetScreenState } from '../../../../store/screenSize/selector';
import { useDispatch } from 'react-redux';
import DeleteUserModal from '../status/DeleteUserModal';
import { ModalMapper } from '../../../../types/models/transactionModalType';
import NewEditUserModal from '../main/NewEditUserModal';
import ChooseFundSourceModal from '../main/ChooseFundSourceModal';
import TransferModal from '../main/TransferModal';
import TransactionStatusModal from '../status/TransactionStatusModal';
import BatchPaymentModal from '../main/BatchPaymentModal';
import PaymentRequestModal from '../main/PaymentRequestModal';
import TransactionDetailsModal from '../main/TransactionDetailsModal';
import ViewLastExportModal from '../main/ViewLastExportModal';
import ReviewPaymentModal from '../main/ReviewPaymentModal';
import RejectPaymentModal from '../main/RejectPaymentModal';
import LogoutUserModal from '../main/LogoutUserModal';
import CreatePaymentsModal from '../main/CreatePaymentsModal';
import SinglePaymentModal from '../main/SinglePaymentModal';
import OrderDetailsModal from '../main/OrderDetailsModal';

const ModalHandler: React.FC = () => {
  const [currentModal, setCurrentModal] = useState(<></>);

  const { modalKey } = useModalState();

  const { breakpoint } = useGetScreenState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (breakpoint === 'desktop' && modalKey === 'more-trans-details') {
      dispatch(hideModal());
    }
  }, [breakpoint]);

  const modalMap: ModalMapper = {
    'register-user': <NewEditUserModal />,
    'exporting-table': <ExportTableModal />,
    'view-last-exports': <ViewLastExportModal />,
    'more-trans-details': <TransactionDetailsModal />,
    'more-order-details': <OrderDetailsModal />,
    'payment-request': <PaymentRequestModal />,
    'delete-staff': <DeleteUserModal />,
    refund: <RefundModal />,
    transfer: <TransferModal />,
    'transaction-status': <TransactionStatusModal />,
    'batch-payments': <BatchPaymentModal />,
    'choose-source-funds': <ChooseFundSourceModal />,
    'review-batch-payments': <ReviewPaymentModal />,
    'reject-batch-payments': <RejectPaymentModal />,
    'logout-user': <LogoutUserModal />,
    'create-payments': <CreatePaymentsModal />,
    'single-payment': <SinglePaymentModal />
  };

  useEffect(() => {
    const comp = modalMap[modalKey as string];
    setCurrentModal(comp || <></>);
  }, [modalKey]);

  return <React.Fragment>{currentModal}</React.Fragment>;
};

export default ModalHandler;
