import { ApiServiceType } from '../../types/enums/apiServiceType';
import { STAFF_URL } from '../../helpers/url_helper';
import { NewUserDetail } from './useCreateStaff';
import usePatchData from '../../hooks/api/usePatchData';

// interface EditUserDetail extends NewUserDetail {
//   auth_id: string;
// }

interface EditUserDetail {
  merchant_id: string;
  auth_id: string;
  email: string;
  display_name: string;
  role_id: number;
  show_qr_display_name: boolean;
  active: boolean;
}

const useUpdateStaff = (): {
  fetching: boolean;
  updateStaffDetails(id: string, data: EditUserDetail): Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, patchData } = usePatchData();
  const abortController = new AbortController();

  const updateStaffDetails = async (
    id: string,
    data: EditUserDetail
  ): Promise<any> => {
    const response = await patchData({
      path: `v3/staff/${id}`,
      apiType: ApiServiceType.PROFILE,
      controller: abortController,
      data
    });

    return response;
  };

  return { fetching: isFetching, updateStaffDetails, abortController };
};
export default useUpdateStaff;
