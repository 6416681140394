import { createSlice } from '@reduxjs/toolkit';

export interface BatchPaymentFormState {
  batchFile: File | null;
  batchName?: string;
}

const initialState: BatchPaymentFormState = {
  batchFile: null
};

export const batchPaymentCacheSlice = createSlice({
  name: 'batchPaymentCache',
  initialState,
  reducers: {
    setBatchFormDetails: (state, action) => {
      state.batchFile = action.payload.file;
      state.batchName = action.payload.batchName || '';
    },
    clearFormDetails: state => {
      state.batchFile = initialState.batchFile;
      state.batchName = initialState.batchName;
    }
  }
});

export const { setBatchFormDetails, clearFormDetails } =
  batchPaymentCacheSlice.actions;

export default batchPaymentCacheSlice.reducer;
