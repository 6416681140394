/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import {
  TransactionStateKeyEnum,
  transactionStateListing
} from '../../../hooks/utils/tableConfig/transactionState';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import { FilterRangeObject } from '../../../types/models/dateTypes';
import FilterButton from '../../molecules/FilterButton';
import { CustomerTransactionRow } from '../../../types/models/tableTransactions';
import TableCard from '../../atoms/TableCard';
import TransactionColumnFormatter from '../../molecules/TransactionColumnFormatter';
import { amountFormated } from '../../../utils/currency';
import { getTimeStamp } from '../../../utils/dateFormatter';
import useConfigTable from '../../../hooks/utils/tableConfig/useConfigTable';
import {
  SorterProps,
  SortingAscDesc
} from '../../../types/models/utils/table-models';
import { useGetScreenState } from '../../../store/screenSize/selector';
import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import useRequestRefund from '../../../hooks/merchant_portal/useRequestRefund';
import useOrderUtil from './useOrderUtil';
import useGetWalletBalance from '../../../hooks/merchant_portal/useGetWalletBalance';
import { useAccountState } from '../../../store/account/selector';

const Orders: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const transactionTableState = useGetTransactionTableState();

  const [transTooltip, setTransToolitp] = useState<any>({});
  const { getTransactionDetails } = useConfigTable();
  const { dispatchMetadataUpdate } = useGetPaginationInformation();
  const breakpointDetails = useGetScreenState();
  const [pageLimit, setPageLimit] = useState(25);

  const { getWalletBalance } = useGetWalletBalance();
  const [lynkWalletBalance, setLynkWalletBalance] = useState(0);

  const { accounts } = useAccountState();

  useEffect(() => {
    setPageLimit(breakpointDetails.breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpointDetails]);

  useEffect(() => {
    setTransToolitp(
      getTransactionDetails(TransactionStateKeyEnum.TRANSACTIONS)
    );
  }, []);

  useEffect(() => {
    if (accounts && accounts.length) {
      verifyLynkBalance();
    }
  }, [accounts]);

  const { startRefund } = useRequestRefund();
  const { moreInfoCallback } = useOrderUtil();

  const filterRangeHandler = (range: FilterRangeObject) => {
    dispatchMetadataUpdate(
      {
        tableFilter: {
          dateRange: range
        }
      },
      TransactionStateKeyEnum.TRANSACTIONS
    );
  };

  const updateStatusTextMobile = (status: string) => {
    if (status === Object.keys(transactionStateListing.transactions)[0]) {
      return 'Awaiting Payment';
    } else {
      return (
        status.toLowerCase().charAt(0).toUpperCase() +
        status.slice(1).toLowerCase()
      );
    }
  };

  const ColumnStructure = (
    transactions: CustomerTransactionRow[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: CustomerTransactionRow) => (
      <tr key={row.m_order_id}>
        {columnHeaders.map((col: any) => (
          <td key={`${row.m_order_id}-${col.field}`} className={`${col.label}`}>
            <TransactionColumnFormatter
              readOnlyMode={false}
              column={col}
              row={row}
              refundFn={startRefund}
              stateOptions={transTooltip}
              tooltipIdentifier={`tooltip-${col.field}-${row.m_order_id}`}
              isOrdersTable={true}
              moreInfoCallback={() =>
                moreInfoCallback(row, transTooltip, lynkWalletBalance)
              }
              lynkBalanceInsufficient={lynkWalletBalance < row.amount_to_pay}
            />
          </td>
        ))}
      </tr>
    ));
  };

  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: CustomerTransactionRow) => (
      <TableCard
        key={`${row.m_order_id}`}
        title={updateStatusTextMobile(row.status)}
        mainTitle={row.lynk_id}
        subTitle={getTimeStamp(row.datetime)}
        data={`${amountFormated(row.amount_to_pay)}`}
        moreInfoCallback={() =>
          moreInfoCallback(row, transTooltip, lynkWalletBalance)
        }
      />
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint === 'smartphone'
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const generateTableHeader = (isActive = true) => {
    return (
      <div>
        <FilterButton
          filterRange={
            transactionTableState[TransactionStateKeyEnum.TRANSACTIONS]
              .tableFilter.dateRange
          }
          setFilterRange={filterRangeHandler}
          isActive={isActive}
        />
      </div>
    );
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.TRANSACTIONS].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;

    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.TRANSACTIONS
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit,
      header: generateTableHeader
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.TRANSACTIONS]]
  );

  const verifyLynkBalance = async () => {
    const balance = await getWalletBalance('lynk');
    setLynkWalletBalance(balance);
  };

  return (
    <DataDisplayContainer
      {...dataDisplayProps}
      // columnFilter={columnFilter}
      // endpointGenerator={endpointGenerator}
      dataKey={TransactionStateKeyEnum.TRANSACTIONS}
      dataGenerator={generateDisplay}
      noDataMessage={'No transactions yet'}
      fetchingData={{
        fetchingText: (
          <ButtonAsyncLoader
            loadingText="Loading Data... Please wait. "
            showLoaderText={true}
          />
        )
      }}
    />
  );
};

export default Orders;
