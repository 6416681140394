import useGetData from '../../hooks/api/useGetData';
import { ApiServiceType } from '../../types/enums/apiServiceType';

const useGetNotifications = (): {
  fetching: boolean;
  getNotifications(): Promise<any>;
  getUnreadNotifCount(): Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();

  const getNotifications = async (): Promise<any> => {
    const response = await getData({
      path: '/notifications?offset=0&limit=5',
      apiType: ApiServiceType.PROFILE
    });

    return response;
  };

  const getUnreadNotifCount = async (): Promise<any> => {
    const response = await getData({
      path: '/notifications/unread-count',
      apiType: ApiServiceType.PROFILE
    });

    return response?.data.unread_count;
  };
  return {
    fetching: isFetching,
    getNotifications,
    getUnreadNotifCount,
    abortController
  };
};

export default useGetNotifications;
