import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAccountState } from '../../store/account/selector';

interface protectedProps {
  isSignedIn: boolean;
  children: JSX.Element;
}
const ProtectedRoute: React.FC<protectedProps> = ({ isSignedIn, children }) => {
  const accounts = useAccountState();

  const showJAMDEXContent = useMemo(() => {
    const cbdcAccountDetails = accounts.accounts?.find(
      (account: any) => account.type === 'CBDC'
    );
    return !!cbdcAccountDetails;
  }, [accounts]);
  if (isSignedIn && !showJAMDEXContent) {
    return <Navigate to={'/'} />;
  }
  return children;
};

export default ProtectedRoute;
