import { ApiServiceType } from '../../types/enums/apiServiceType';
import { STAFF_URL } from '../../helpers/url_helper';
import useDeleteData from '../../hooks/api/useDeleteData';

const useUpdateStaff = (): {
  isDeleting: boolean;
  deleteStaffDetails(userId: string): Promise<void>;
  abortController: AbortController;
} => {
  const { isFetching, deleteData } = useDeleteData();
  const abortController = new AbortController();

  const deleteStaffDetails = async (userId: string): Promise<void> => {
    return await deleteData({
      path: `v3/staff/${userId}`,
      apiType: ApiServiceType.PROFILE,
      controller: abortController
    });
  };

  return { isDeleting: isFetching, deleteStaffDetails, abortController };
};
export default useUpdateStaff;
