import {
  checkboxExportItems,
  getAllAttribute
} from '../../../components/molecules/ExportPreferenceWizardPage/hooks/checkboxItems';

import { ExportTableAction } from '../../../store/state';
import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../../utils/dateFormatter';

export interface ExportPreference {
  dateRange: {
    start: string;
    end: string;
  };
  types: string[];
}

export interface ExportPreferenceForm {
  preferences: ExportPreference;
  isFormValid: boolean;
}

export const exportDateRangeRegex = 'YYYY-MM-DD';

const initialState: ExportTableAction = {
  isValidForm: false,
  preferences: {
    dateRange: {
      start: formatDate(new Date(), exportDateRangeRegex) || '',
      end: formatDate(new Date(), exportDateRangeRegex) || ''
    },
    types: getAllAttribute(checkboxExportItems, 'type') as string[]
  },
  exportStatus: {
    inProgress: false,
    success: false,
    stage: 'generate'
  }
};

export const exportReportSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    resetPreferences: state => {
      state.isValidForm = initialState.isValidForm;
      state.preferences = {
        types: initialState.preferences.types,
        dateRange: initialState.preferences.dateRange
      };
      state.exportStatus = {
        inProgress: false,
        success: false,
        stage: 'generate'
      };
    },
    updatePreferences: (
      state,
      action: { payload: Partial<ExportPreferenceForm>; type: string }
    ) => {
      state.isValidForm = action.payload.isFormValid ?? state.isValidForm;
      state.preferences = {
        types: action.payload.preferences?.types || state.preferences.types,
        dateRange:
          action.payload.preferences?.dateRange || state.preferences.dateRange
      };
    },
    updateProgress: (
      state,
      action: {
        payload: {
          stage: 'generate' | 'download';
          inProgress: boolean;
          success?: boolean;
          error?: any;
        };
        type: string;
      }
    ) => {
      state.exportStatus = { ...state.exportStatus, ...action.payload };
    }
  }
});

export const { resetPreferences, updatePreferences, updateProgress } =
  exportReportSlice.actions;

export default exportReportSlice.reducer;
