/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useDownloadCompAsImage from '../../../hooks/utils/useDownloadCompAsImage';
import { showModal } from '../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import { useGetQRCodeDetail } from '../../../store/organization/selector';
import { clearScope } from '../../../store/status';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';
import CBDCQRCodeDownload from '../../organisms/CBDCQRCodeDownload';
import { useAccountState } from '../../../store/account/selector';
import { WalletDetailBase } from '../../../store/account/types';
import { getToast } from '../DownloadMerchantJamDexQRWidget/downloadToast';

const JAMDEXNavbar = (props: {
  exportTable: () => void;
  transferModal: () => void;
}) => {
  const ref: any = useRef();
  const { downloadElementAsImage } = useDownloadCompAsImage();
  const qrDetails = useGetQRCodeDetail();
  const dispatch = useDispatch();
  const accounts = useAccountState();

  const jamDexURL = useMemo(() => {
    const cbdcAccountDetails = accounts.accounts?.find(
      (account: any) => account.type === 'CBDC'
    );
    if (!cbdcAccountDetails) return '';
    return `${
      (cbdcAccountDetails.details as WalletDetailBase).external_reference || ''
    }`;
  }, [accounts]);

  const renderResult = (errorType: 'success' | 'error') => {
    const toast = getToast(errorType);
    if (toast) {
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: toast.status,
          title: toast.message
        }
      });
    }
  };

  const downloadQRSnapshotJAMDEX = async () => {
    let status: 'success' | 'error' = 'success';
    dispatch(clearScope('general'));
    try {
      // dispatch(clearScope('general'));
      await downloadElementAsImage(
        `${qrDetails.name || 'merchant'}__qrCode`,
        ref
      );
    } catch (error) {
      status = 'error';
    } finally {
      renderResult(status);
    }
  };
  const dispatchMap = {
    exportTables: props.exportTable,
    transfer: props.transferModal,
    downloadQRJAMDEX: downloadQRSnapshotJAMDEX
  };

  const { getValidSidebarComponentsJamdex } = useDashboardSetup();

  const [sidebarItemsJamdex, setSidebarItemsJamdex] = useState<any[]>([]);

  useEffect(() => {
    const sidebarJamdex = getValidSidebarComponentsJamdex();

    setSidebarItemsJamdex(
      sidebarJamdex.map(item => {
        const fn = dispatchMap[item.key as keyof typeof dispatchMap];
        return {
          ...item,
          onClickFn: fn
        };
      })
    );
  }, []);

  return (
    <React.Fragment>
      <div className="quickAccess">JAM-DEX - Quick Access</div>
      <ul className="navbar-nav">
        {sidebarItemsJamdex.map(sideItem => {
          return (
            <li
              key={sideItem.key}
              className="nav-item-sidebar"
              onClick={sideItem.onClickFn}
            >
              <div className="nav-link">
                <span>{sideItem.title}</span>
              </div>
            </li>
          );
        })}
      </ul>

      <div className="downloadQRefSection">
        <div ref={ref} className="downloadQRefSection__qrCodeSection">
          <CBDCQRCodeDownload
            qrCode={jamDexURL}
            merchantName={qrDetails.name}
            merchantLynkID={qrDetails.username}
            loading={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default JAMDEXNavbar;
