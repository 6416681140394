import React from 'react';
import './index.scss';
import { AmountDisplayProps } from '../../../types/models/BalanceDataType';

const AmountDisplay: React.FC<AmountDisplayProps> = ({
  amount,
  fontSizeMain = 32,
  fontSizeSub = 25
}) => {
  return (
    <div
      className="amountDisplay"
      style={{ fontSize: fontSizeMain }}
      data-testid="amountDisplayContaienr"
    >
      {/* Order Total Dollars */}
      {amount.toString().split('.')[0].concat('.')}
      <div className="amountDisplay__cents" style={{ fontSize: fontSizeSub }}>
        {/* Order Total Cents */}
        {amount.toString().split('.')[1]}
      </div>
    </div>
  );
};

export default AmountDisplay;
