import React, { useEffect, useState } from 'react';
import { amountFormated, currencyFormat } from '../../../utils/currency';
import AmountDisplay from '../../atoms/AmountDisplay';
import './index.scss';
import VisibleEyeToggle from '../../atoms/VisibleEyeToggle';
import Skeleton from 'react-loading-skeleton';
import { BalanceDataProps } from '../../../types/models/BalanceDataType';
import { BalanceValueProps } from '../../../types/models/DashboardWidgetType';

const BalanceDataView: React.FC<BalanceDataProps> = ({
  currency,
  amount,
  seeBalance,
  onClick
}) => (
  <>
    <div className="balanceValueContent" data-testid="amountLoaded">
      <div className="amountToDisplay amountDisplayContainer">
        {seeBalance ? (
          <>
            <AmountDisplay
              amount={currencyFormat(amount)}
              fontSizeMain={25}
              fontSizeSub={15}
            />
            <span style={{ fontSize: 16 }}>{currency}</span>
          </>
        ) : (
          <div>
            **** <span style={{ fontSize: 16 }}>{currency}</span>
          </div>
        )}
      </div>
    </div>
    <div className="showIcon">
      <VisibleEyeToggle makeVisible={!seeBalance} onClickFn={onClick} />
    </div>
  </>
);

const BalanceValue: React.FC<BalanceValueProps> = ({
  amount = 0,
  loading,
  loadBalance,
  currency
}) => {
  const [showBalance, setShowBalance] = useState(false);

  const handlePresShowBalance = (): void => {
    setShowBalance(!showBalance);
  };

  useEffect(() => {
    if (showBalance) {
      loadBalance && loadBalance();
    }
  }, [showBalance]);

  return (
    <div
      className="balanceValueContainer"
      onClick={handlePresShowBalance}
      data-testid="balanceValueContainer"
    >
      {loading ? (
        <div
          className="amountDisplayContainer skeleton"
          data-testid={'amountSkeleton'}
        >
          <Skeleton height={20} />
        </div>
      ) : (
        <BalanceDataView
          currency={currency}
          amount={amount}
          seeBalance={showBalance}
          onClick={handlePresShowBalance}
        />
      )}
    </div>
  );
};

export default BalanceValue;
