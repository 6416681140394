import { DateOrEmpty } from '../../../types/models/dateTypes';
import moment from 'moment';

export type ValidatorIndexType =
  | 'startGreaterThanEnd'
  | 'bothDatesMustNotBeEqual'
  | 'bothDatesMustNotBeBlank';

export const ValidatorIndex = {
  startGreaterThanEnd: (
    start: Date | undefined,
    end: Date | undefined
  ): boolean => {
    const startObj = moment(start);
    const endObj = moment(end);

    return startObj.isAfter(endObj);
  },
  bothDatesMustNotBeEqual: (
    start: Date | undefined,
    end: Date | undefined
  ): boolean => {
    const startObj = moment(start);
    const endObj = moment(end);

    return startObj.diff(endObj) === 0;
  },
  bothDatesMustNotBeBlank: (
    start: Date | undefined,
    end: Date | undefined
  ): boolean => {
    return !start || !end;
  }
};

export const isValidators = (
  index: string[],
  start: Date | undefined,
  end: Date | undefined
): string[] => {
  const validatorsToApply = Object.keys(ValidatorIndex).filter(key =>
    index.includes(key)
  );

  if (validatorsToApply.includes('bothDatesMustNotBeBlank')) {
    const result = ValidatorIndex['bothDatesMustNotBeBlank'](start, end);
    if (result) return ['bothDatesMustNotBeBlank'];
  }

  return validatorsToApply.filter(validator => {
    const validatorFn =
      ValidatorIndex[validator as keyof typeof ValidatorIndex];
    return validatorFn(start, end);
  });
};
