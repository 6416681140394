import './index.scss';

import { Input, Label } from 'reactstrap';
import React, { useState } from 'react';

import { TextInputProps } from '../../../types/models/formType';

const TextInput: React.FC<TextInputProps> = ({
  label,
  labelDescription,
  testID = 'textInput',
  readOnly = false,
  className,
  feedback,
  rightIcon,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      data-testid={`${testID}__container`}
      className={`text-input ${className ? className : ''}`}
    >
      <Label
        data-testid={`${testID}__label`}
        className={`text-input__label ${
          isActive && !props.invalid && 'active'
        } ${(readOnly || props.disabled) && 'disabled'} ${
          props.invalid && 'is-invalid'
        }`}
        for={props?.id}
      >
        {label}
      </Label>
      <div className="text-input__description">{labelDescription}</div>
      <div style={{ position: 'relative' }}>
        <Input
          data-testid={testID}
          className={`text-input__input ${
            isActive && !props.invalid && 'active'
          } ${readOnly && 'disabled'} ${props.invalid && 'is-invalid'}`}
          onFocus={() => !readOnly && setIsActive(true)}
          onBlur={() => !readOnly && setIsActive(false)}
          {...props}
        />
        {rightIcon}
      </div>

      <div
        data-testid={`${testID}__feedback`}
        className={`text-input__feedback ${props.invalid && 'is-invalid'}`}
      >
        {feedback}
      </div>
    </div>
  );
};

export default TextInput;
