import useGetData from '../api/useGetData';
import { ApiServiceType } from '../../types/enums/apiServiceType';

const useGetPayeeInfo = (): {
  fetching: boolean;
  getPayeeDetails(username: any): Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();

  const getPayeeDetails = async (username: any): Promise<any> => {
    const response = await getData({
      path: `/payee-lookup?lynk_id=%40${username}`,
      apiType: ApiServiceType.PAYMENT
    });
    return response;
  };
  return {
    fetching: isFetching,
    getPayeeDetails,
    abortController
  };
};

export default useGetPayeeInfo;
