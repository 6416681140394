import React from 'react';
import './index.scss';

const CashierRoleDetails: React.FC = () => {
  return (
    <>
      <div className="roleDetail--success">
        <div className="roleDetail__title">What this role can do:</div>
        <div className="roleDetail__list">
          <ul>
            <li>Download QR Code</li>
            <li>View Transaction History</li>
            <li>Share Payment Request Link</li>
          </ul>
        </div>
      </div>
      <div className="roleDetail--error">
        <div className="roleDetail__title">What this role can&apos;t do:</div>
        <div className="roleDetail__list">
          <ul>
            <li>Export transaction history</li>
            <li>Cash Out</li>
            <li>View Lynk Wallet Balance</li>
            <li>Issue Refunds</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CashierRoleDetails;
