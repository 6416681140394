import { ApiServiceType } from '../../types/enums/apiServiceType';
import { STAFF_URL } from '../../helpers/url_helper';
import usePostData from '../../hooks/api/usePostData';
import {
  GenericCreateUserMessage,
  SuperAdminLimitExceeded
} from '../../exceptions/CreateUserValidation.exception';

export interface NewMerchantUserDetails {
  merchant_id: string;
  role_id: number;
  show_qr_display_name: boolean;
  active: boolean;
}
export interface NewUserDetail {
  email: string;
  display_name: string;
  merchants: NewMerchantUserDetails[];
}

const useCreateStaff = (): {
  fetching: boolean;
  createStaffDetails(data: NewUserDetail): Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, postData } = usePostData();
  const abortController = new AbortController();

  const createStaffDetails = async (data: NewUserDetail): Promise<any> => {
    try {
      const response = await postData({
        path: STAFF_URL,
        apiType: ApiServiceType.PROFILE,
        controller: abortController,
        data
      });
      return response;
    } catch (err: any) {
      if (err.response.status === 403) {
        throw new SuperAdminLimitExceeded();
      } else {
        throw new GenericCreateUserMessage();
      }
    }
  };

  return { fetching: isFetching, createStaffDetails, abortController };
};
export default useCreateStaff;
