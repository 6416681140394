/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import {
  BatchDetailType,
  BatchDetailTypeKey
} from '../../../types/models/BatchPaymentsType';
import Env from '../../../helpers/Env';
import saveAs from 'file-saver';

import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import ButtonTooltip from '../../atoms/ButtonTooltip';
import {
  BatchColumHeaderInfo,
  RefundColumHeaderInfo
} from '../../../types/models/tableTransactions';
import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import TableCard from '../../atoms/TableCard';
import { getTimeStamp } from '../../../utils/dateFormatter';
import { amountFormated } from '../../../utils/currency';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/actions';
import moment from 'moment';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import { useGetScreenState } from '../../../store/screenSize/selector';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import { FilterRangeObject } from '../../../types/models/dateTypes';
import FilterButton from '../../molecules/FilterButton';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import {
  SorterProps,
  SortingAscDesc
} from '../../../types/models/utils/table-models';
import useConfigTable from '../../../hooks/utils/tableConfig/useConfigTable';
import { useGetProfileState } from '../../../store/profile/selector';
import useRequestBatch from '../../../hooks/batchPayments/useGetBatchPaymentProcess';

import { ButtonColFormatter } from '../../atoms/BatchButtons';
import { Outlet, useNavigate } from 'react-router-dom';
import './index.scss';
import { usePermissions } from '../../../features/permissions';

const BatchTransfers: React.FC = () => {
  const dispatch = useDispatch();

  const { breakpoint } = useGetScreenState();
  const transactionTableState = useGetTransactionTableState();
  const [batchTooltip, setBatchToolitp] = useState<any>({});
  const { getBatchById, receiptBatch } = useRequestBatch();

  const { hasPermissions } = usePermissions();
  const { getTransactionDetails } = useConfigTable();

  const approver = hasPermissions([
    {
      resource: 'batch-payments',
      actions: new Set(['approve'])
    }
  ]);
  const profile = useGetProfileState();

  const [pageLimit, setPageLimit] = useState(25);
  const { dispatchMetadataUpdate } = useGetPaginationInformation();

  useEffect(() => {
    setPageLimit(breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpoint]);
  useEffect(() => {
    setBatchToolitp(getTransactionDetails(TransactionStateKeyEnum.BATCH));
  }, []);
  const filterRangeHandler = (range: FilterRangeObject) => {
    dispatchMetadataUpdate(
      {
        tableFilter: {
          dateRange: range,
          group: ''
        }
      },
      TransactionStateKeyEnum.BATCH
    );
  };

  const generateTableHeader = (isActive = true) => {
    return (
      <div>
        <FilterButton
          filterRange={
            transactionTableState[TransactionStateKeyEnum.BATCH].tableFilter
              .dateRange
          }
          setFilterRange={filterRangeHandler}
          isActive={isActive}
        />
      </div>
    );
  };

  const updateStatusNames = (status: string) => {
    switch (status) {
      case 'COMPLETED_WITH_ERRORS':
        return 'Processed';
      case 'COMPLETED':
        return 'Processed';
      case 'PROCESSING':
        return 'Pending';
      case 'APPROVED':
        return 'Pending';
      default:
        return (
          status.toLowerCase().charAt(0).toUpperCase() +
          status.slice(1).toLowerCase()
        ).replace('_', ' ');
    }
  };

  const navigate = useNavigate();
  const reviewBatch = async (row: BatchDetailType) => {
    navigate(`./review/${row.id}`); //TODO: read documentation
  };
  const handleReciept = async (row: BatchDetailType) => {
    await receiptBatch(row, 'receipt.csv');
  };
  const actionDisplayed = (row: BatchDetailType) => {
    if (
      approver &&
      profile.role.toLowerCase().includes('admin') &&
      (row.status === 'VALIDATED' || row.status === 'PENDING_APPROVAL')
    ) {
      return (
        <ButtonColFormatter
          classname="bp-review-batch"
          text={'Review Batch'}
          row={row}
          review={true}
          actionFn={reviewBatch}
        />
      );
    } else {
      if (row.status.includes('COMPLETED') || row.status === 'FAILED') {
        return (
          <ButtonColFormatter
            text={'Receipt'}
            row={row}
            review={false}
            actionFn={handleReciept}
          />
        );
      }
    }
  };
  const cellContent = (row: BatchDetailType, col: BatchColumHeaderInfo) => {
    switch (col.field) {
      case 'name':
        return row.name && row.name.length > 15 ? (
          <ButtonTooltip
            tooltipIdentifier={`tooltip-${col.field}-${row.id}`}
            title={`${row.name.slice(0, 15)}`}
            feedback={row.name}
            ellipsis={true}
          />
        ) : (
          row.name
        );

      case 'status':
        return (
          <ButtonTooltip
            tooltipIdentifier={`tooltip-${row.id}`}
            title={updateStatusNames(row.status)}
            feedback={batchTooltip[row[col.field] || '']}
            classnames={'batch-payments-stat'}
            withErrors={row.status === 'COMPLETED_WITH_ERRORS'}
          />
        );
      case 'action':
        return actionDisplayed(row);
      default:
        return col.format
          ? col.format(`${row[col?.field as BatchDetailTypeKey]}`)
          : row[col.field as BatchDetailTypeKey];
    }
  };

  const ColumnStructure = (
    transactions: any[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: BatchDetailType, index: number) => (
      <tr key={`${row.id}_${index}`}>
        {columnHeaders.map((col: any) => (
          <td key={`${row.id}-${col.field}`}>{cellContent(row, col)}</td>
        ))}
      </tr>
    ));
  };

  const moreInfoCallback = (data: BatchDetailType) => {
    const dataMap = {
      details: [
        {
          label: 'Payment ID',
          value: data.id
        },
        {
          label: 'Payment Name',
          value: data.name
        },
        {
          label: 'Created by',
          value: data.created_by
        },
        {
          label: 'DATE',
          value: moment(data.created_at).isValid()
            ? moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')
            : ''
        },
        {
          label: 'Total',
          value: amountFormated(data.total)
        }
      ]
    };

    dispatch(
      showModal({
        modalKey: 'more-trans-details',
        data: dataMap,
        title: 'Payment'
      })
    );
  };

  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: BatchDetailType) => (
      <TableCard
        key={row.id}
        title={
          (
            <ButtonTooltip
              tooltipIdentifier={`tooltip-${row.id}`}
              title={updateStatusNames(row.status)}
              feedback={batchTooltip[row.status]}
              classnames={'batch-payments-stat'}
              withErrors={row.status === 'COMPLETED_WITH_ERRORS'}
            />
          ) || ''
        }
        mainTitle={`Payment ID: ${row.id}`}
        subTitle={
          moment(row.created_at).isValid() ? getTimeStamp(row.created_at) : ''
        }
        data={actionDisplayed(row) || ''}
        moreInfoCallback={() => moreInfoCallback(row)}
      />
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint === 'smartphone'
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.BATCH].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;

    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.BATCH
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime', 'created_at'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit,
      header: generateTableHeader
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.BATCH]]
  );

  return (
    <>
      <DataDisplayContainer
        {...dataDisplayProps}
        dataKey={TransactionStateKeyEnum.BATCH}
        dataGenerator={generateDisplay}
        noDataMessage={'No transactions yet'}
        fetchingData={{
          fetchingText: (
            <ButtonAsyncLoader
              loadingText="Loading Data... Please wait. "
              showLoaderText={true}
            />
          )
        }}
      />
      <Outlet />
    </>
  );
};

export default BatchTransfers;
