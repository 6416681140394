/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  loadProfile,
  loadProfileFailure,
  loadProfileSuccess
} from '../../store/organization';

import { ApiServiceType } from '../../types/enums/apiServiceType';
import { formatPhoneNumber } from '../../utils/phoneNumberFormatter';
import { useDispatch } from 'react-redux';
import useGetData from '../api/useGetData';

const useGetMerchantDetails = (): {
  fetching: boolean;
  getMerchantDetails(): Promise<any>;
  getMerchantQRCode(): Promise<any>;
  loadFullMerchantDetails(): Promise<any>;
  getMerchantStaffQR(userid: string, display_name: string): Promise<any>;
} => {
  const { isFetching, getData } = useGetData();
  const dispatch = useDispatch();
  const loadFullMerchantDetails = async (): Promise<any> => {
    try {
      dispatch(loadProfile());
      const [merchant, merchantQRDetails] = await Promise.all([
        getMerchantDetails(),
        getMerchantDets2()
      ]);

      const { user_name, ...rest } = merchantQRDetails;
      const body = await getMerchantQRCode();
      dispatch(
        loadProfileSuccess({
          ...merchant,
          ...{ username: user_name },
          ...{ qr_image: `data:image/png;base64,${body.data}` },
          ...rest
        })
      );
    } catch (err) {
      dispatch(loadProfileFailure(err));
    }
  };

  const getMerchantDetails = async (): Promise<any> => {
    const response = await getData({
      path: '',
      apiType: ApiServiceType.PROFILE,
      config: { responseType: 'blob' }
    });

    const { address, logo, description, name, phone_number, email, ...data } =
      response.data;

    const address_formatted =
      address && address.address ? `${address.address} ` : '';
    const address2_formatted =
      address && address.address_2 ? `${address.address_2} ` : '';
    const city_formatted = address && address.city ? `${address.city} ` : '';
    const province_formatted =
      address && address.province ? `${address.province}, ` : '';

    const addressString = address
      ? `${address_formatted}${address2_formatted}${city_formatted}${province_formatted}${address.country}`
      : 'N/A';

    return {
      ...data,
      ...{
        merchant: name,
        address: addressString,
        about: description || 'N/A',
        phone: formatPhoneNumber(phone_number) || 'N/A',
        email: email || 'N/A',
        logo: logo || undefined
      }
    };
  };

  const getMerchantDets2 = async (): Promise<any> => {
    const response = await getData({
      path: '/qr-code',
      apiType: ApiServiceType.PROFILE
    });

    return {
      ...response.data
    };
  };

  const getMerchantStaffQR = async (
    userid: string,
    display_name: string
  ): Promise<any> => {
    const response = await getData({
      path: `/v2/qr-code/${String(display_name).replace('#', ' ')}/${userid}`,
      apiType: ApiServiceType.PROFILE,
      headers: { 'Content-Type': 'image/png' }
    });

    return response;
  };

  const getMerchantQRCode = async (): Promise<any> => {
    const response = await getData({
      path: '/v2/qr-code',
      apiType: ApiServiceType.PROFILE,
      headers: { 'Content-Type': 'image/png' }
    });

    return response;
  };

  return {
    fetching: isFetching,
    getMerchantDetails,
    getMerchantQRCode,
    loadFullMerchantDetails,
    getMerchantStaffQR
  };
};
export default useGetMerchantDetails;
