import { domToJpeg } from 'modern-screenshot';
import saveAs from 'file-saver';
import { useState } from 'react';

const useDownloadCompAsImage = (): {
  isFetching: boolean;
  downloadElementAsImage: (filename: string, ref: any) => Promise<void>;
} => {
  const [isFetching, setIsFetching] = useState(false);

  const downloadElementAsImage = async (
    filename: string,
    ref: any
  ): Promise<void> => {
    try {
      setIsFetching(true);
      const qrImage = await domToJpeg(ref.current, { quality: 0.98 });
      saveAs(qrImage, `${filename}.jpeg`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  return { isFetching, downloadElementAsImage };
};

export default useDownloadCompAsImage;
