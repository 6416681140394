/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExportPage } from './model';
import {
  resetPreferences,
  updateProgress
} from '../../../../../../store/modals/exportAndDownload';
import { useEffect, useState } from 'react';

import { hideModal, showModal } from '../../../../../../store/actions';
import saveAs from 'file-saver';
import { useDispatch } from 'react-redux';
import { useExportReportMetadata } from '../../../../../../store/modals/exportAndDownload/selector';
import usePostGenerateReportForExport from '../../../../../../hooks/exportAndDownload/usePostGenerateReport';
import { defaultPage, modalStack } from './modalPage';
import useModalNav from '../../../../../../utils/modal/useModalNav';
import {
  ModalStackDetails,
  ModalStackDispatch
} from '../../../../../../utils/modal/modalNavModal';
import { useGetWalletManager } from '../../../../../../store/walletManagement/selector';
import useGetGenerateReportForExport from '../../../../../../hooks/exportAndDownload/useGetGenerateReports';

const useExportTableNav = (): {
  isInProgress: boolean;
  close: () => void;
  currentPageDetails: ModalStackDetails<ExportPage>;
  currentPage: ExportPage;
  executeDispatchMap: (key: ExportPage | undefined) => Promise<void>;
} => {
  const [isInProgress, setInProgress] = useState(false);
  const walletManager = useGetWalletManager();

  const [currentPage, setCurrentPage] = useState<ExportPage>('preferences');
  const [currentFile, setCurrentFile] = useState<string>('');
  const [currentPageDetails, setCurrentPageDetails] =
    useState<ModalStackDetails<ExportPage>>(defaultPage);

  const dispatch = useDispatch();
  const metadata = useExportReportMetadata();
  const { attemptGenerateNewReport } = usePostGenerateReportForExport();
  const { attemptGetNewReport } = useGetGenerateReportForExport();

  useEffect(() => {
    const page = getPageByKey(currentPage);
    setCurrentPageDetails(page);
  }, [currentPage]);

  useEffect(() => {
    if (
      metadata.exportStatus.stage === 'generate' &&
      metadata.exportStatus.success
    ) {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'success',
            subtitle:
              'File generated successfully. Please check you machine for the file.',
            title: 'Export tables successfully'
          }
        })
      );
      downloadFileFn();
    }
  }, [metadata.exportStatus]);

  const generateFile = async () => {
    if (!metadata.isValidForm) return;
    let errorObj: any = undefined;

    try {
      dispatch(
        updateProgress({
          stage: 'generate',
          inProgress: true,
          success: false
        })
      );
      let file;
      if (walletManager.currentWallet === 'lynk') {
        file = await attemptGenerateNewReport({
          types: metadata.preferences.types,
          dateRange: {
            startRange: metadata.preferences.dateRange.start,
            endRange: metadata.preferences.dateRange.end
          }
        });
      } else {
        file = await attemptGetNewReport({
          startRange: metadata.preferences.dateRange.start,
          endRange: metadata.preferences.dateRange.end,
          walletType: 'jam-dex'
        });
      }

      setCurrentFile(file);
    } catch (err) {
      errorObj = err;
    } finally {
      dispatch(
        updateProgress({
          stage: 'generate',
          inProgress: false,
          success: errorObj ? false : true,
          error: errorObj
        })
      );
    }
  };

  const downloadFileFn = async () => {
    const { start, end } = metadata.preferences.dateRange;
    const fileName = `${walletManager.currentWallet}_${start}_to_${end}.csv`;

    const csvContent = Buffer.from(currentFile, 'base64');
    const blob = new Blob([csvContent]);

    try {
      dispatch(
        updateProgress({
          stage: 'download',
          inProgress: true
        })
      );

      await Promise.resolve(saveAs(blob, fileName));
      // close();
    } finally {
      dispatch(
        updateProgress({
          stage: 'download',
          inProgress: false,
          success: true
        })
      );
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'success',
            subtitle:
              'Records Excel was generated successfully. Please check you machine for the file.',
            title: 'Excel generation'
          }
        })
      );
    }
  };

  const close = () => {
    dispatch(resetPreferences());
    dispatch(hideModal());
  };

  const getPageByKey = (page: ExportPage) => {
    return modalStack[page];
  };

  const dispatchMap: ModalStackDispatch<ExportPage> = {
    preferences: {
      functionToCall: generateFile,
      isAsync: true
    },
    downloading: {
      functionToCall: downloadFileFn,
      isAsync: true
    }
  };

  const { triggerOnclick } = useModalNav<ExportPage>(dispatchMap);

  const executeDispatchMap = async (action?: ExportPage) => {
    await triggerOnclick(action);
  };

  return {
    isInProgress,
    close,
    currentPage,
    currentPageDetails,
    executeDispatchMap
  };
};

export default useExportTableNav;
