import React, { useEffect, useRef } from 'react';
import './index.scss';
import Skeleton from 'react-loading-skeleton';
import QRCodeStyling from 'qr-code-styling';
import { ReactComponent as RotatedQRCode } from '../../../assets/svg/RotatedQRCode.svg';

interface Props {
  loading: boolean;
  classnames?: string;
  qrCodeURL?: string;
  alias?: string;
}
const CBDCQRIcon: React.FC<Props> = ({
  alias,
  qrCodeURL = '',
  loading,
  classnames
}) => {
  const qrCode = new QRCodeStyling({
    image: '../../assets/jamdex-logo.png',
    cornersSquareOptions: {
      color: 'black',
      type: 'square'
    },
    dotsOptions: {
      color: 'black',
      type: 'rounded'
    },
    imageOptions: {
      imageSize: 0.2,
      margin: 0
    },
    backgroundOptions: {
      color: 'transparent'
    }
  });

  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current as unknown as HTMLElement);
  }, [qrCode]);

  // const getAccountDetails = () => {
  //   const account = accountDetail.accounts?.find(acct => {
  //     return acct.type == 'CBDC';
  //   });

  //   return (
  //     'jmcbdc:V:1;S:' +
  //     account?.details.external_reference +
  //     ';E:rei_ecdd;P:PartitionID1279905956;'
  //   );
  // };

  useEffect(() => {
    if (qrCodeURL) {
      qrCode.update({
        data: `jmcbdc:V:1;S:${qrCodeURL};E:jm_ecdb;P:PartitionID1279905956;A_L:${alias}`
      });
    }
  }, [qrCodeURL, alias]);

  return (
    <div
      className={`${classnames || ''} rotateImg`}
      data-testid="qrIconContainer"
    >
      {loading ? (
        <Skeleton
          className={`${classnames || ''} rotateImg skeleton`}
          containerClassName="qrIconAtom"
          data-testid="qrIconSkeleton"
        />
      ) : (
        <>
          {qrCodeURL ? (
            <div className="qrIcon" ref={ref}></div>
          ) : (
            <RotatedQRCode className="qrIconAtom default" />
          )}
        </>
      )}
    </div>
  );
};

export default CBDCQRIcon;
