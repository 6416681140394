import useGetData from '../api/useGetData';
import { ApiServiceType } from '../../types/enums/apiServiceType';

const useGetSinglePaymentProcess = (): {
  isSinglePaymentValidating: boolean;
  abortController: AbortController;
  validateSinglePayment(id: number): Promise<any>;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();

  const validateSinglePayment = async (id: number) => {
    //https://dev-krakend.core-nautilus.net/bff-mcp-pay/batch-payments/1
    try {
      const response = await getData({
        path: `batch-payments/${id}`,
        apiType: ApiServiceType.PAYMENT
      });
      return response;
    } catch (err: any) {
      // throw new GenericBatchUploadException();
      console.log(err);
    }
  };

  return {
    isSinglePaymentValidating: isFetching,
    validateSinglePayment,
    abortController
  };
};

export default useGetSinglePaymentProcess;
