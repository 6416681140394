import React, { useEffect, useState } from 'react';
import './index.scss';
import { ReactComponent as ScanToPay } from '../../../assets/svg/scan2pay.svg';
import { ReactComponent as PlayStore } from '../../../assets/svg/playStore.svg';

import { ReactComponent as LynkLogo } from '../../../assets/svg/dark/lynklogo.svg';
import AppleIcon from '@mui/icons-material/Apple';
import MerchantTitle from '../../atoms/MerchantTitle';
import { QRCodeDownloadProps } from '../../../types/models/MerchantCodeType';
import { getIconMapper } from '../../../utils/iconMap';

const QRCodeDownload: React.FC<QRCodeDownloadProps> = ({
  merchantName,
  merchantLynkID,
  qrCode,
  name
}) => {
  return (
    <div className="qrCodeDownloadContainer">
      <header>
        <div>
          <div className="lynkLogo">{getIconMapper('lynk-only')}</div>
          <div className="merchantDetailGroup">
            <MerchantTitle
              merchantName={merchantName}
              loading={false}
              name={name}
              username={`Lynk ID: ${merchantLynkID}`}
            />
          </div>
        </div>
      </header>
      <section className="qrSection">
        <div>
          <img src={qrCode} title="QR" />
        </div>
      </section>
      <section className="footerDetails">
        <div>
          <div className="scanToPay">{getIconMapper('scan-to-pay')}</div>
          <div className="lynkLogo">
            <LynkLogo />
          </div>
        </div>
        <div className="downloadGrp">
          <div className="storeOptions">
            <AppleIcon className="appleIcon" />
            <div className="playStore">
              <PlayStore />
            </div>
          </div>
          <div className="downloadNow">Download now</div>
        </div>
      </section>
    </div>
  );
};

export default QRCodeDownload;
