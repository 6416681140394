import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import './index.scss';
import React from 'react';
import { showModal } from '../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import { ReactComponent as BatchPaymentsIcon } from '../../../assets/svg/batch-payments.svg';

interface WidgetProps {
  disableClick?: boolean;
  isActive?: boolean;
}
const BatchPaymentsWidget: React.FC<WidgetProps> = ({
  disableClick,
  isActive
}) => {
  const dispatch = useDispatch();

  const triggerCallback = () => {
    if (!disableClick) {
      dispatch(showModal({ modalKey: 'batch-payments' }));
    }
  };
  return (
    <div className={'viewBatchPayments'}>
      <DashboardIconWidgetBase
        testID="viewBatchPayments"
        disabled={false}
        icon={<BatchPaymentsIcon className="icon" />}
        label={'Create Batch Payments'}
        callback={triggerCallback}
        id={'viewBatchPaymentsWidget'}
        classes={isActive ? 'activeWidget' : ''}
      />
    </div>
  );
};

export default BatchPaymentsWidget;
