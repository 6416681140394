/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiServiceType } from '../../types/enums/apiServiceType';
import { FilterRangeObject } from '../../types/models/dateTypes';
import { SortingAscDesc } from '../../types/models/utils/table-models';
import { formatDate } from '../../utils/dateFormatter';
import useGetData from '../api/useGetData';
import { useState } from 'react';
import { STAFF_URL } from '../../helpers/url_helper';
import { useGetProfileState } from '../../store/profile/selector';

const useGetTransactions = (): {
  loadTransactions: (
    sort: SortingAscDesc,
    limit: number,
    endpoint: string,
    apiType: ApiServiceType,
    newOffset: number,
    dateFilterRange?: FilterRangeObject,
    group?: string
  ) => Promise<any>;
  fetching: boolean;
} => {
  const { merchant_id } = useGetProfileState();
  const [fetching, setFetching] = useState(false);

  const { getData } = useGetData();

  const loadTransactions = async (
    sort = SortingAscDesc.DESC,
    limit: number,
    endpoint: string,
    apiType = ApiServiceType.PAYMENT,
    newOffset: number,
    dateFilterRange?: FilterRangeObject,
    group?: string
  ) => {
    setFetching(true);
    try {
      const paramStarter = endpoint.split('?').length > 1 ? '&' : '?';
      let url = `/${endpoint}${paramStarter}offset=${newOffset}&limit=${limit}`;

      const addParamSorter = url.split('?').length > 1 ? '&' : '?';
      url =
        endpoint !== STAFF_URL ? `${url}${addParamSorter}sort=${sort}` : url;

      if (
        dateFilterRange &&
        dateFilterRange.startRange &&
        dateFilterRange.endRange
      ) {
        url = `${url}&from_utc=${formatDate(
          dateFilterRange.startRange,
          'YYYY-MM-DD'
        )}&to_utc=${formatDate(dateFilterRange.endRange, 'YYYY-MM-DD')}`;
      }
      if (group) {
        url = `${url}&group=${group.toUpperCase()}`;
      }
      const response = await getData({
        path: url,
        apiType
      });
      let results = [];
      let metadata = {
        count: 0,
        limit: 0,
        offset: 0,
        total: 0
      };
      console.log(response.data.merchants);
      const altMetadata = {
        total: 0,
        count: 0,
        offset: newOffset,
        limit: limit
      };
      if (Array.isArray(response.result)) {
        results = response.result;
      } else if (Array.isArray(response.data.result)) {
        results = response.data.result;
      } else if (Array.isArray(response.data.results)) {
        results = response.data.results;
      } else if (Array.isArray(response.data.merchants)) {
        results = response.data.merchants.filter(
          (item: any) => item.merchant_id !== merchant_id
        );
        altMetadata.total = results.length;
        altMetadata.count = results.length;
      } else {
        results =
          response.data.result.results || response.data.result.result || [];
      }

      const newMetadata =
        response.meta_data ||
        response.data.meta_data ||
        response.data.metadata ||
        response.data.result?.metadata ||
        response.data.result?.meta_data ||
        altMetadata;

      metadata = {
        ...metadata,
        ...newMetadata,
        total:
          newMetadata.total === 0 && results.length > 0
            ? newMetadata.count
            : newMetadata.total
      };

      return { results, metadata };
    } catch (error) {
      return {
        results: [],
        metadata: {
          total: 0,
          count: 0,
          offset: 0,
          limit: 0
        }
      };
    } finally {
      setFetching(false);
    }
  };

  return {
    loadTransactions,
    fetching
  };
};

export default useGetTransactions;
