import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import PostAddIcon from '@mui/icons-material/PostAdd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/modals/modal';

const ExportTableWidget: React.FC = () => {
  const dispatch = useDispatch();

  const generated = () => {
    dispatch(showModal({ modalKey: 'exporting-table' }));
  };

  return (
    <div className="exportTable">
      <DashboardIconWidgetBase
        id="exportTableWidget"
        testID="exportTableWidget"
        disabled={false}
        icon={<PostAddIcon className="icon" />}
        label={'Export Tables'}
        callback={generated}
      />
    </div>
  );
};

export default ExportTableWidget;
