/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

export interface AppModalState {
  previousModal?: {
    key: string;
    data?: any;
    title?: string;
  };
  modalKey: string;
  title?: string;
  data?: any | any[];
  isSPayment?: boolean;
}

const initialState: AppModalState = {
  modalKey: '',
  title: ''
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setPreviousModal: (state, action) => {
      state.previousModal = action.payload.modalToReturnTo;
    },
    showPreviousModal: state => {
      state.modalKey = state.previousModal?.key || state.modalKey;
      state.data = state.previousModal?.data || state.data;
      state.title = state.previousModal?.title || state.title;

      state.previousModal = initialState.previousModal;
    },
    showModal: (state, action: { payload: AppModalState; type: string }) => {
      state.modalKey = action.payload.modalKey;
      state.data = action.payload.data || state.data;
      state.title = action.payload.title || state.title;
      state.isSPayment = action.payload.isSPayment || state.isSPayment;
    },
    updateModal: (state, action: { payload: any; type: string }) => {
      state.data = action.payload.data || state.data;
      state.title = action.payload.title || state.title;
    },
    hideModal: state => {
      state.modalKey = '';
      state.title = '';
      state.data = initialState.data;
      state.previousModal = initialState.previousModal;
    }
  }
});

export const { showModal, hideModal, setPreviousModal, showPreviousModal } =
  modalSlice.actions;

export default modalSlice.reducer;
