const useGetReportsForDownload = (): {
  loadAvailiableReportsForDownload: (dateRange: {
    start: Date;
    end: Date;
  }) => Promise<any>;
} => {
  const loadAvailiableReportsForDownload = (dateRange: {
    start: Date;
    end: Date;
  }) => {
    return Promise.resolve(['TRANSFERS', 'REFUNDS', 'TRANSACTIONS']);
  };
  return { loadAvailiableReportsForDownload };
};
export default useGetReportsForDownload;
