import React, { useEffect, useMemo, useState } from 'react';
import useRequestRefund, {
  RequestValuesType
} from '../../../../../hooks/merchant_portal/useRequestRefund';
import RefundDetailsForm from '../../../../molecules/RefundDetailsForm';
import SelectRefundForm from '../../../../molecules/SelectRefundForm';
import LynkModal from '../../../../atoms/LynkModal';
import { useModalState } from '../../../../../store/modals/modal/selector';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { FooterDetail } from '../../../../../types/models/refundType';
import useGetWalletBalance from '../../../../../hooks/merchant_portal/useGetWalletBalance';

const RefundModal: React.FC = () => {
  const { data, modalKey } = useModalState();
  const {
    closeRefundModal,
    refundTypeHandler,
    isFullRefund,
    isFetching,
    submitRefundHandler
  } = useRequestRefund();

  const [isValid, setValid] = useState(true);
  const [showRefundDetailsForm, setShowRefundDetailsForm] = useState(false);
  const [refundValues, setRefundValues] = useState<RequestValuesType>({
    amount: 0,
    reason: '',
    notes: ''
  });

  useEffect(() => {
    if (data && data.transaction) {
      const dataVal = `${data.transaction.amount_to_pay}`;
      setRefundValues({
        ...refundValues,
        amount: Number(dataVal)
      });
    }
  }, [data]);

  const cancelClick = () => {
    setShowRefundDetailsForm(false);
    closeRefundModal();
  };

  const modalBody = showRefundDetailsForm ? (
    <RefundDetailsForm
      amount={data ? data.transaction.amount_to_pay : '0'}
      isFullRefund={isFullRefund}
      onApplyClick={(values, isValid) => {
        setRefundValues(values);
        setValid(isValid);
      }}
      refundRecipient={data ? data.transaction?.lynk_id : ''}
    />
  ) : (
    <SelectRefundForm
      amount={data ? data.transaction.amount_to_pay : '0'}
      onChangeFn={refundTypeHandler}
    />
  );

  const footerDetails = useMemo<FooterDetail>(() => {
    const btn: FooterDetail = {
      mainAction: {
        loader: {
          text: 'Please wait',
          icon: <AutorenewIcon />
        },
        testID: 'refundModalButtonPrimary',
        onClickFn: () => {
          setShowRefundDetailsForm(true), setValid(false);
        },
        text: 'Next',
        disabled: false
      },
      closeAction: {
        testID: 'refundModalButtonCancel',
        onClickFn: cancelClick,
        text: 'Cancel',
        showButton: true
      }
    };

    if (showRefundDetailsForm) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { onClickFn, ...rest } = btn.mainAction;
      btn.mainAction = {
        ...rest,
        onClickAsyncFn: () => {
          return submitRefundHandler(
            {
              amount: refundValues.amount,
              reason: refundValues.reason,
              notes: refundValues.notes
            },
            data.transaction
          );
        },
        text: isFetching ? 'Loading....' : 'Issue Refund',
        disabled: !isValid || isFetching //|| !formik.touched
      };
    }

    return btn;
  }, [showRefundDetailsForm, isFetching, isValid, refundValues]);

  return (
    <LynkModal
      classes="customModal"
      modalBody={<>{modalBody}</>}
      title={`Transaction ID #${data ? data.transaction.m_order_id : '0'}`}
      loading={false}
      show={modalKey === 'refund'}
      closeAction={{
        ...footerDetails.closeAction,
        testID: 'mainAction'
      }}
      mainAction={{
        ...footerDetails.mainAction,
        testID: 'mainAction'
      }}
    />
  );
};

export default RefundModal;
