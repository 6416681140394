import {
  BatchColumnHeaders,
  BranchesColumnHeaders,
  NotificationsHeaders,
  PaymentDetailColumnHeaders,
  RefundColumnHeaders,
  StaffColumnHeaders,
  TransactionColumnHeaders,
  TransferColumnHeaders
} from './columnHeaders';

import { FilterRangeObject } from '../../../types/models/dateTypes';
import { MetadataResponseType } from '../../../types/models/metadataResponseType';
import { TableFooterProps } from '../../../types/models/tableType';
import { ApiServiceType } from '../../../types/enums/apiServiceType';

export const defaultFilterRange: FilterRangeObject = {
  startRange: undefined,
  endRange: undefined
};

export const defaultMetadata: MetadataResponseType = {
  count: 0,
  limit: 0,
  offset: 0,
  total: 0
};

export const defaultTableFooterProps: TableFooterProps = {
  totalPageCount: 0,
  totalItems: 0,
  currentPage: 0,
  elementRange: {
    initial: 0,
    final: 0
  },
  pagingHandler: (targetPage: number) => {
    console.log(targetPage);
  }
};

export const columnHeaderDetails = {
  transfers: TransferColumnHeaders,
  transactions: TransactionColumnHeaders,
  refund: RefundColumnHeaders,
  staff: StaffColumnHeaders,
  payment: PaymentDetailColumnHeaders,
  batch: BatchColumnHeaders,
  notifications: NotificationsHeaders,
  branches: BranchesColumnHeaders
};

export const endpointType = {
  transfers: {
    endpoint: 'v2/connected-account-transactions',
    apiType: ApiServiceType.PAYMENT
  },
  transactions: {
    endpoint: 'customer-transactions',
    apiType: ApiServiceType.PAYMENT
  },
  refund: {
    endpoint: 'refund-transactions',
    apiType: ApiServiceType.PAYMENT
  },
  staff: {
    endpoint: 'v3/staff',
    apiType: ApiServiceType.PROFILE
  },
  payment: {
    endpoint: 'v1/accounts/{accountID}/payments',
    apiType: ApiServiceType.ACCOUNT
  },
  batch: {
    endpoint: 'batch-payments',
    apiType: ApiServiceType.PAYMENT
  },
  notifications: {
    endpoint: 'notifications',
    apiType: ApiServiceType.PROFILE
  },
  branches: {
    endpoint: 'v3/staff/{accountID}',
    apiType: ApiServiceType.PROFILE
  }
};
