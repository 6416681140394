import { LoaderProps } from '../../../types/models/loaderType';
import React from 'react';
import { ReactComponent as LynkLogoOnly } from '../../../assets/svg/dark/LogoLink.svg';
import './index.scss';

const Loader: React.FC<LoaderProps> = ({ loadingText, testID = 'loader' }) => {
  return (
    <div id="preloader" data-testid={testID}>
      <div id="status">
        <div className="status__status">{loadingText}</div>
        <div className="status_spinner">
          <LynkLogoOnly />
        </div>
      </div>
    </div>
  );
};

export default Loader;
