/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortingAscDesc } from '../../../types/models/utils/table-models';
import {
  TransactionStateKey,
  TransactionStateKeyEnum,
  transactionStateListing
} from './transactionState';
import { columnHeaderDetails, endpointType } from './defaultProps';

import { FilterRangeObject } from '../../../types/models/dateTypes';
import useGetTransactions from '../../transactions/useGetTransactions';
import { usePermissions } from '../../../features/permissions';

const useConfigTable = (): {
  fetching: boolean;
  getTransactionDetails: (key: TransactionStateKeyEnum) => any;
  getColumnHeaders: (key: TransactionStateKeyEnum) => any;
  getEndpointByKey: (key: TransactionStateKeyEnum) => any;

  loadTrans: (
    sort: SortingAscDesc,
    limit: number,
    offset: number,
    key: TransactionStateKeyEnum,
    range: FilterRangeObject,
    group: string | undefined,
    endpointGenerator?: (endpoint: string) => string
  ) => Promise<any>;
} => {
  const { loadTransactions, fetching } = useGetTransactions();
  const { hasPermissions } = usePermissions();

  const loadTrans = async (
    sort: SortingAscDesc,
    limit: number,
    offset: number,
    key: TransactionStateKey,
    range: FilterRangeObject,
    group: string | undefined,
    endpointGenerator?: (endpoint: string) => string
  ) => {
    const { endpoint, apiType } = getEndpointByKey(key);

    // eslint-disable-next-line no-extra-boolean-cast
    const generatedEndpoint = !!endpointGenerator
      ? endpointGenerator(endpoint)
      : endpoint;

    return await loadTransactions(
      sort,
      limit,
      generatedEndpoint,
      apiType,
      offset,
      range,
      group
    );
  };

  const getTransactionDetails = (key: TransactionStateKey) => {
    return transactionStateListing[key];
  };

  const getColumnHeaders = (key: TransactionStateKey) => {
    const results: any[] = columnHeaderDetails[key];
    return results.filter(result => {
      if (!result.permissions) return true;
      return hasPermissions(result.permissions);
    });
  };

  const getEndpointByKey = (key: TransactionStateKey) => {
    return endpointType[key];
  };

  return {
    fetching,
    getTransactionDetails,
    getColumnHeaders,
    getEndpointByKey,
    loadTrans
  };
};

export default useConfigTable;
