const useFileHandler = (): {
  convertBase64: (file: any) => Promise<any>;
  confirmFileType: (file: File | null, fileTypeCheck: string) => boolean;
} => {
  const convertBase64 = async (file: any) => {
    return new Promise(resolve => {
      let baseURL = '';
      // Make new FileReader
      const reader: any = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result.split(',')[1];
        resolve(baseURL);
      };
    });
  };

  const confirmFileType = (
    file: File | null,
    fileTypeCheck: string
  ): boolean => {
    // if (file === null) return false;

    // const fileName = file.name;

    // const fileExt = fileName.substring(fileName.lastIndexOf('.'));

    // let isValid = true;
    // if (fileExt !== fileTypeCheck) {
    //     throw new
    //   // setFileUploadFeedback(
    //   //   'The wrong file type was uploaded. Please upload a CSV file'
    //   // );
    // //   setFileUploadException(new InvalidFileTypeException())
    //   isValid = false;
    // } else {
    // //   setFileUploadException(null);
    // }

    return false;
  };

  return {
    convertBase64,
    confirmFileType
  };
};
export default useFileHandler;
