import './index.scss';

import { Card, CardBody, Tooltip, UncontrolledTooltip } from 'reactstrap';

import React, { useEffect, useState } from 'react';
import { DashboardIconWidgetBaseProps } from '../../../types/models/DashboardWidgetType';

const DashboardIconWidgetBase: React.FC<DashboardIconWidgetBaseProps> = ({
  id,
  tooltip,
  testID = 'dashboardIconWidget',
  icon,
  label,
  disabled,
  callback,
  classes
}) => {
  const triggerCallback = () => {
    if (!disabled) callback();
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Card
        id={id}
        data-testid={testID}
        className={`iconWidget ${classes} ${disabled && 'disabled'}`}
        onClick={triggerCallback}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <CardBody>
          <div className={`iconWidget__icon ${disabled && 'disabled'}`}>
            {icon}
          </div>
          <div className="iconWidget__text">{label}</div>
        </CardBody>
      </Card>
      {!!tooltip && (
        <Tooltip placement={'bottom'} target={id} isOpen={isHovered}>
          {tooltip?.text}
        </Tooltip>
      )}
    </>
  );
};

export default DashboardIconWidgetBase;
