import './index.scss';

import React, { useState } from 'react';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { SectionViewProps } from '../../../types/models/sectionType';

const SectionView: React.FC<SectionViewProps> = ({
  title,
  defaultVisible = true,
  body
}) => {
  const [showSection, setShowSection] = useState(defaultVisible);

  const toggleSectionVisibility = () => {
    setShowSection(!showSection);
  };

  return (
    <>
      <section className="toggleSection">
        <div
          className="toggleSection-title"
          data-testid="toggleSection-title"
          onClick={toggleSectionVisibility}
        >
          {title}
          <span>
            {showSection ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </span>
        </div>
        <div
          className={`toggleSection-body ${showSection ? 'visible' : 'hide'}`}
          data-testid="toggleSectionBody"
        >
          {body}
        </div>
      </section>
    </>
  );
};

export default SectionView;
