import { updateTableMetadata } from '../../store/actions';
import { TransacFilter } from '../../store/transactions/tableState/innerState';
import { useDispatch } from 'react-redux';
import { ElementRangeType } from '../../types/models/elementRangeType';
import { MetadataResponseType } from '../../types/models/metadataResponseType';
import { TransactionStateKeyEnum } from './tableConfig/transactionState';

export interface MetadataConfig {
  currentPage: number;
  elementRange: ElementRangeType;
  totalItems: number;
  totalPageCount: number;
}

const useGetPaginationInformation = (): {
  calculatePagination(responseMetadata: MetadataResponseType): MetadataConfig;
  dispatchMetadataUpdate(
    filter: Partial<TransacFilter>,
    key: TransactionStateKeyEnum
  ): void;
} => {
  const calculatePageCount = (total: number, limit: number) => {
    const oneMorePage = total % limit;
    let pageCount = Math.trunc(total / limit);

    if (oneMorePage) pageCount++;

    return pageCount;
  };

  const calculatePagination = (
    responseMetadata: MetadataResponseType
  ): MetadataConfig => {
    let currentPage = 1;
    let totalPageCount = 1;
    let initialRange = { initial: 1, final: 25 };

    if (responseMetadata.offset > 0)
      currentPage = Math.round(
        responseMetadata.offset / responseMetadata.limit + 1
      );
    if (responseMetadata.total) {
      totalPageCount = calculatePageCount(
        responseMetadata.total,
        responseMetadata.limit
      );

      const initial = responseMetadata.limit * (currentPage - 1) + 1;
      let final = responseMetadata.limit * currentPage;
      if (
        responseMetadata.count < responseMetadata.limit ||
        final > responseMetadata.total
      )
        final = responseMetadata.total;

      initialRange = {
        initial,
        final
      };
    }

    return {
      currentPage,
      elementRange: initialRange,
      totalItems: responseMetadata.total,
      totalPageCount
    };
  };

  const dispatch = useDispatch();
  const dispatchMetadataUpdate = (
    filter: Partial<TransacFilter>,
    key: TransactionStateKeyEnum
  ) => {
    dispatch(
      updateTableMetadata({
        key,
        filter: { ...filter }
      })
    );
  };

  return { calculatePagination, dispatchMetadataUpdate };
};

export default useGetPaginationInformation;
