import { CheckboxGroupItem } from '../../../../types/models/formType';

export const checkboxExportItems: CheckboxGroupItem[] = [
  {
    label: 'Orders',
    key: 'last-transactions',
    isActive: true,
    type: 'TRANSACTIONS'
  },
  {
    label: 'Transfers',
    key: 'transfers',
    isActive: true,
    type: 'TRANSFERS'
  },
  {
    label: 'Refunds',
    key: 'refunds',
    isActive: true,
    type: 'REFUNDS'
  }
];

export const getAllAttribute = (
  checkboxExportItems: CheckboxGroupItem[],
  keyVal: keyof CheckboxGroupItem
): (string | boolean)[] => {
  return checkboxExportItems.map(items => items[keyVal]);
};
