import { createSlice } from '@reduxjs/toolkit';
import { getBreakpoint } from '../../utils/useAppBreakpoint';

export type ScreenStateType = 'smartphone' | 'table' | 'desktop';
export interface ScreenState {
  breakpoint: ScreenStateType;
  windowWidth: number;
  windowHeight: number;
}

const initialState: ScreenState = {
  breakpoint: getBreakpoint() as ScreenStateType,
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth
};

export const screenSizeSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCurrentBreakpoint: (state, action) => {
      state.breakpoint = action.payload.breakpoint || state.breakpoint;
      state.windowHeight = action.payload.windowHeight || state.windowHeight;
      state.windowWidth = action.payload.windowWidth || state.windowWidth;
    },
    updateBreakpoint: (state, action) => {
      return state;
    }
  }
});

export const { updateBreakpoint, setCurrentBreakpoint } =
  screenSizeSlice.actions;

export default screenSizeSlice.reducer;
