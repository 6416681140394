import * as serviceWorker from './serviceWorker';

import App from './App';
import AuthProvider from './providers/AuthProvider';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './store';
import { PermissionsProvider } from './features/permissions';

const app = (
  <Provider store={store}>
    <PermissionsProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </PermissionsProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
