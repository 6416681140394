/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiServiceType } from '../../types/enums/apiServiceType';
import useGetData from '../../hooks/api/useGetData';
import { useDispatch } from 'react-redux';
import {
  loadAccountDetails,
  loadAccountDetailsFailure,
  loadAccountDetailsSuccess
} from '../../store/account';
import { BalanceNotEnoughException } from '../../exceptions/TransferHistory.exceptions';
import { useState } from 'react';

const useGetAccountDetails = (): {
  fetch: boolean;
  isFetching: boolean;
  getAccountDetail: (id: number) => Promise<any>;
  getAccountListing: () => Promise<void>;
  getChildrenAccount: () => Promise<any>;
} => {
  const { isFetching, getData } = useGetData();
  const dispatch = useDispatch();
  const [fetch, setFetch] = useState(false);

  const getAccountDetail = async (accountID: number) => {
    setFetch(true);
    if (accountID === 230) {
      return Promise.reject(
        setTimeout(() => {
          setFetch(false);
          return '';
        }, 2000)
      );
    } else
      return Promise.resolve(
        setTimeout(() => {
          setFetch(false);
          return '';
        }, 2000)
      );
  };

  const getAccountListing = async (): Promise<void> => {
    try {
      dispatch(loadAccountDetails());
      const response = await getData({
        path: '/v1/accounts',
        apiType: ApiServiceType.ACCOUNT
      });

      dispatch(loadAccountDetailsSuccess({ ...response.data }));
    } catch (err: unknown) {
      const error = new BalanceNotEnoughException(
        (err as any).message,
        (err as any).code
      );
      dispatch(loadAccountDetailsFailure(error));
    }
  };

  ///v1/accounts
  const getChildrenAccount = async () => {
    try {
      dispatch(loadAccountDetails());
      const response = await getData({
        path: '/v1/accounts/children',
        apiType: ApiServiceType.ACCOUNT
      });

      // dispatch(loadAccountDetailsSuccess({ ...response.data }));
      return response.data;
    } catch (err: unknown) {
      return {
        children: []
      };
    }
  };
  return {
    isFetching,
    getAccountListing,
    getAccountDetail,
    getChildrenAccount,
    fetch
  };
};

export default useGetAccountDetails;
