/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from 'redux';
import { screenSizeSlice, setCurrentBreakpoint } from '.';
import { all, fork, put, takeLatest } from 'redux-saga/effects';

function* updateBreakpoint({ payload }: AnyAction) {
  try {
    yield put(setCurrentBreakpoint(payload)); //call(setCurrentBreakpoint,payload);
  } catch (error) {
    console.log(error);
  }
}

export function* watchWindowSize() {
  yield takeLatest(
    screenSizeSlice.actions.updateBreakpoint.toString(),
    updateBreakpoint
  );
}

function* ScreenDetailsSaga() {
  yield all([fork(watchWindowSize)]);
}

export default ScreenDetailsSaga;
