export class CreateUserException extends Error {
  errorMessage: string = '';
  cancelAction: boolean;

  constructor(msg: string, code: string, cancelAction = false) {
    super(msg);
    this.cancelAction = cancelAction;
  }

  public isCancelable(): boolean {
    return this.cancelAction;
  }
}

export class GenericCreateUserMessage extends CreateUserException {
  constructor() {
    super(
      'Oops! Something went wrong. Your transfer couldn’t be initiated, please try again.',
      '',
      true
    );
    Object.setPrototypeOf(this, GenericCreateUserMessage.prototype);
  }
}

export class SuperAdminLimitExceeded extends CreateUserException {
  constructor() {
    super(
      'User limit exceeded. No more Super Admins can be stored for this merchant.',
      '',
      true
    );
    Object.setPrototypeOf(this, SuperAdminLimitExceeded.prototype);
  }
}
