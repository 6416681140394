import { ModalStackDispatch } from './modalNavModal';

const useModalNavigation = <T extends string>(
  dispatchMap: ModalStackDispatch<T>
): {
  triggerOnclick: (action: T | undefined) => Promise<void>;
} => {
  const executeDispatchMap = async (key: T | undefined) => {
    if (!key) return Promise.resolve(undefined);
    const dispatch = dispatchMap[key];
    await dispatch.functionToCall();
  };

  const triggerOnclick = async (action?: T) => {
    if (!action) return;
    return await executeDispatchMap(action);
  };

  return { triggerOnclick };
};

export default useModalNavigation;
