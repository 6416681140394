// export type TransactionStateKey = 'transfers' | 'transactions' | 'refund';

export enum TransactionStateKeyEnum {
  TRANSFERS = 'transfers',
  TRANSACTIONS = 'transactions',
  REFUND = 'refund',
  STAFF = 'staff',
  PAYMENT = 'payment',
  BATCH = 'batch',
  NOTIFICATIONS = 'notifications',
  BRANCHES = 'branches'
}

export type TransactionStateKey =
  typeof TransactionStateKeyEnum[keyof typeof TransactionStateKeyEnum];

export const transactionStateListing = {
  transfers: {
    SCHEDULED: 'It has been accepted by NCB and queued for transmission',
    TRANSMITTED:
      'The recipient bank has received the transfer but might not have posted to the customer',
    COMPLETED: 'Transaction successful',
    FAILED:
      'The recipient was not able to get the funds and the monies has been returned to the sender',
    PENDING: 'It has been accepted by NCB and queued for transmission'
  },
  transactions: {
    RECEIVED: 'This order was received but the customer has not yet submitted payment.',
    PAID: 'Order completed and payment received',
    REJECTED: 'User rejected this order in the lynk app',
    FAILED: 'An error occurred when trying to complete transaction',
    PENDING_REFUND: 'Refund has been initiated',
    EXPIRED: 'User did not accept transaction within the allotted time',
    REFUNDED: 'Refund completed'
  },
  refund: {},
  staff: {},
  notifications: {},
  branches: {},
  payment: {
    Success: 'Transaction was successful',
    Failure: 'Transaction was a failure'
  },
  batch: {
    NEW: 'It has been accepted but not yet processed',
    PENDING_APPROVAL: 'It has been created but is pending approval',
    // APPROVED: 'Payment sent for approval.',
    APPROVED: 'The Payment is being processed. The status will update soon.',
    INVALID_PAYEE: 'Invalid Payee',
    VALIDATED: 'It has been validated',
    PROCESSING: 'The Payment is being processed. The status will update soon.',
    REJECTED: 'Payment request was rejected.',
    COMPLETED: 'The payment was successful. All accounts received payment.',
    FAILED: 'There were errors found with some Lynk accounts.',
    COMPLETED_WITH_ERRORS:
      'There were some errors in processing. Please check the receipt.'
  }
};
