import Layout from './layout/reducer';
import Organization from './organization';
import accountSlice from './account';
import appStatusSlice from './status';
import { combineReducers } from 'redux';
import exportReportSlice from './modals/exportAndDownload';
import modalSlice from './modals/modal';
import profileSlice from './profile';
import tableStateSlice from './transactions/tableState';
import screenSizeSlice from './screenSize';
import walletManagementSlice from './walletManagement';
import dataCacheSlice from './data';
import batchPaymentCacheSlice from './batchPaymentProcess';
import breadcrumbSlice from '../features/breadcrumbs';

const rootReducer = combineReducers({
  ModalAction: modalSlice,
  Account: accountSlice,
  Layout,
  Profile: profileSlice,
  Organization,
  ScreenData: screenSizeSlice,
  AppStatus: appStatusSlice,
  ExportReport: exportReportSlice,
  TransactionFilter: tableStateSlice,
  WalletManager: walletManagementSlice,
  DataCache: dataCacheSlice,
  BatchPayment: batchPaymentCacheSlice,
  Breadcrumb: breadcrumbSlice
});

export default rootReducer;
