import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';

import ButtonTooltip from '../../atoms/ButtonTooltip';
import MiniFileUploadInput from '../../atoms/MiniFileUploadInput';
import TextInput from '../../atoms/TextInput';
import { clearFormDetails } from '../../../store/batchPaymentProcess';
import { useDispatch } from 'react-redux';
export interface FormChange {
  file: File | null;
  batchName?: string;
}

const DefaultFileFeedback: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        color: 'gray'
      }}
    >
      <div>Max file size: 20MB | Allowed file types: csv</div>
    </div>
  );
};

const DefaultBatchNameFeedback: React.FC<{ batchNameLength: number }> = ({
  batchNameLength
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        color: 'gray'
      }}
    >
      <div>{batchNameLength}/100 Characters</div>
      <div className="noMobile">This field is optional.</div>
    </div>
  );
};

interface Props {
  file: File | null;
  batchName?: string;
  onFormChange: (formVal: FormChange, isValid: boolean) => void;
  downloadTemplate: (batch: string, name: string) => void;
  technicalError: string;
  downloadReceipt: () => void;
}

const SubmitBatchPaymentForm: React.FC<Props> = ({
  file,
  batchName = '',
  onFormChange,
  technicalError,
  downloadTemplate,
  downloadReceipt
}) => {
  const dispatch = useDispatch();

  const fileSizeInMB = 20;
  const [isFileValid, setFileValid] = useState<boolean>(false);
  const [fileUploadFeedback, setFileUploadFeedback] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (technicalError && technicalError.length > 0) {
      setFileUploadFeedback(technicalError);
      setFileValid(false);
    }
  }, [technicalError]);

  const confirmFileType = (file: File | null): boolean => {
    if (file === null) return false;

    const fileName = file.name;

    const fileExt = fileName.substring(fileName.lastIndexOf('.'));

    let isValid = true;
    if (fileExt !== '.csv') {
      setFileUploadFeedback(
        'The wrong file type was uploaded. Please upload a CSV file'
      );
      isValid = false;
    } else {
      setFileUploadFeedback(null);
    }

    return isValid;
  };

  const clearFile = (): void => {
    dispatch(clearFormDetails());
    setFileValid(true);
    setFileUploadFeedback(null);
  };

  const fileUploadFeedbackRender = useMemo(
    () => fileUploadFeedback || <DefaultFileFeedback />,
    [fileUploadFeedback]
  );

  const batchNameFeedbackRender = useMemo(
    () => <DefaultBatchNameFeedback batchNameLength={batchName.length} />,
    [batchName]
  );

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <MiniFileUploadInput
          id="batchPaymentUploadID"
          type={[]}
          feedback={fileUploadFeedbackRender}
          label={'Bulk payment file'}
          testID={'batchPaymentFormTestID'}
          readOnly={false}
          valid={isFileValid}
          clearFile={() => clearFile()}
          onUploadFile={(newFile: File | null, validFileSize: boolean) => {
            const isValidFileType = confirmFileType(newFile);

            const isFileCurrentValid = validFileSize;
            if (!isFileCurrentValid && isValidFileType)
              setFileUploadFeedback(
                'Your batch file exceeds the maximum file size limit.'
              );
            setFileValid(isValidFileType && isFileCurrentValid);
            onFormChange(
              { file: newFile, batchName },
              isValidFileType && isFileCurrentValid
            );
          }}
          uploadFileName={file?.name || undefined}
          fileSizeInMB={fileSizeInMB}
          downloadReceipt={downloadReceipt}
        />
      </div>
      <div>
        <TextInput
          maxLength={100}
          label="Batch name (Optional)"
          placeholder="Enter a name to identify this batch"
          onChange={event =>
            onFormChange({ file, batchName: event.target.value }, isFileValid)
          }
          feedback={batchNameFeedbackRender}
        />
      </div>
      <div
        style={{ display: 'flex', height: 60 }}
        className="downloadTemplateBatch"
      >
        <ButtonTooltip
          placement="right"
          tooltipIdentifier={'downloadTemplateFileID'}
          title={
            <div
              style={{
                color: 'blue',
                cursor: 'pointer',
                fontSize: 16,
                fontWeight: 400,
                letterSpacing: 0.005
              }}
              id="downloadTemplateFileID"
              onClick={() =>
                downloadTemplate(
                  'bHlua19pZCx0cm4sYW1vdW50DQpAbHlua2Jpei1wYXksMTIzLTQ1Ni03ODkvMSwxMjMuNDUNCg==',
                  'template'
                )
              }
            >
              Download template file
            </div>
          }
          feedback={
            'To ensure your upload is formatted correctly, use this template file.'
          }
        />
      </div>
    </>
  );
};

export default SubmitBatchPaymentForm;
