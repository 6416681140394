import DownloadQRWidget from '../../components/molecules/DownloadMerchantQRWidget';
import JamDexDownloadQRWidget from '../../components/molecules/DownloadMerchantJamDexQRWidget';
import ExportTableWidget from '../../components/molecules/ExportTableWidget';
import LynkBalanceWidget from '../../components/organisms/LynkBalanceWidget';
import PaymentRequestWidget from '../../components/molecules/PaymentRequestWidget';
import React from 'react';
import ViewLastExportsWidget from '../../components/molecules/ViewLastExportsWidget';
import { Permission } from '../../features/permissions';
import TransferWidget from '../../components/molecules/TransferWidget';
import JAMDEXWallet from '../../components/organisms/JAMDEXWallet';
import { AccountItem } from '@store/account/types';
import AccountsLimitWidget from '../../components/organisms/AccountsLimitWidget';
import CreatePaymentsWidget from '../../components/molecules/CreatePaymentsWidget';

interface WidgetGroup {
  comp: JSX.Element;
  key: string;
  permissions: Permission[];
  walletView: string[];
  additionalValidation?: (
    component: WidgetGroup,
    additionalParams: {
      additionalDetails: any[] | any;
      validator?: (permissions: Permission[]) => boolean;
    }
  ) => boolean;
}

export const widgets: WidgetGroup[] = [
  {
    comp: <LynkBalanceWidget />,
    key: 'balance',
    permissions: [{ resource: 'balance', actions: new Set(['read']) }],
    walletView: ['lynk', 'corporate']
  },
  {
    comp: <AccountsLimitWidget />,
    key: 'account-limits',
    permissions: [{ resource: 'balance', actions: new Set(['read']) }],
    walletView: ['lynk']
  },
  {
    comp: <JAMDEXWallet />,
    key: 'jam-dex',
    permissions: [{ resource: 'balance', actions: new Set(['read']) }],
    walletView: ['jam-dex', 'corporate']
  },
  {
    comp: <DownloadQRWidget />,
    key: 'downloadQR',
    permissions: [{ resource: 'profile', actions: new Set(['read']) }],
    walletView: ['lynk']
  },
  {
    comp: <JamDexDownloadQRWidget />,
    key: 'downloadJamDexQR',
    permissions: [{ resource: 'profile', actions: new Set(['read']) }],
    walletView: ['jam-dex']
  },
  {
    comp: <ExportTableWidget />,
    key: 'export-table',
    permissions: [{ resource: 'reports-detailed', actions: new Set(['read']) }],
    walletView: ['lynk', 'jam-dex']
  },
  {
    comp: <PaymentRequestWidget />,
    key: 'payment-request',
    permissions: [{ resource: 'orders', actions: new Set(['create']) }],
    walletView: ['lynk']
  },
  {
    comp: <ViewLastExportsWidget />,
    key: 'last-reports-downloaded',
    permissions: [{ resource: '*', actions: new Set() }],
    walletView: ['lynk']
  },
  {
    comp: <TransferWidget />,
    key: 'transfer',
    walletView: ['lynk', 'jam-dex'],
    additionalValidation: (
      component: WidgetGroup,
      additionalParams: {
        additionalDetails: any[] | any;
        validator?: (permissions: Permission[]) => boolean;
      }
    ): boolean => {
      if (!additionalParams || !additionalParams.additionalDetails)
        return false;

      const cBDCAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'CBDC'
        ) || [];
      const bankAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'BANK_ACCOUNT'
        ) || [];

      const beneficiaryAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'BENEFICIARY'
        ) || [];

      const hasCBDC = cBDCAccounts.length > 0;
      const hasBankAccount = bankAccounts.length > 0;
      const hasBeneficiary = beneficiaryAccounts.length > 0;

      const bankPermissions = [
        { resource: 'cashins', actions: new Set(['create']) }
      ];

      const cbdcPermissions = [
        { resource: 'cbdc-cashins', actions: new Set(['create']) },
        { resource: 'cbdc-cashout', actions: new Set(['create']) }
      ];

      const beneficaryPermission = [
        { resource: 'cashouts', actions: new Set(['create']) }
      ];

      return (
        (hasBankAccount &&
          (additionalParams.validator
            ? additionalParams.validator(bankPermissions)
            : true)) ||
        (hasCBDC &&
          (additionalParams.validator
            ? additionalParams.validator(cbdcPermissions)
            : true)) ||
        (hasBeneficiary &&
          (additionalParams.validator
            ? additionalParams.validator(beneficaryPermission)
            : true))
      );
    },
    permissions: [
      { resource: 'cashouts', actions: new Set(['create']) },
      { resource: 'cashins', actions: new Set(['create']) },
      { resource: 'cbdc-cashins', actions: new Set(['create']) },
      { resource: 'cbdc-cashout', actions: new Set(['create']) }
    ]
  },
  {
    comp: <CreatePaymentsWidget />,
    key: 'create-payments',
    permissions: [{ resource: 'batch-payments', actions: new Set(['create']) }],
    walletView: ['lynk']
  }
];
