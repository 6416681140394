import { BranchesRow } from '../../types/models/tableTransactions';
import { useGetProfileState } from '../../store/profile/selector';
import useGetWalletBalance from '../merchant_portal/useGetWalletBalance';

export interface Permission {
  actions: Set<string>;
  resource: string;
}

const useFormatBranchAccounts = (): {
  generateBranchAccounts(accounts: any[], rows: BranchesRow[]): any;
  generateBalanceAccount(account: string): any;
} => {
  const { getAccountBalance } = useGetWalletBalance();

  const generateBranchAccounts = (accounts: any[], rows: BranchesRow[]) => {
    const response: any[] = [];
    rows.forEach(element => {
      const newRow = element;
      response.push({
        ...newRow,
        accounts:
          accounts.find(acc => acc.id === element.merchant_id)?.accounts || []
      });
    });

    return response;
  };

  const generateBalanceAccount = async (account: string) => {
    const balance = await getAccountBalance(account);
    return balance;
  };

  return {
    generateBranchAccounts,
    generateBalanceAccount
  };
};

export default useFormatBranchAccounts;
