import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/actions';

const PaymentRequestWidget: React.FC = () => {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(showModal({ modalKey: 'payment-request' }));
  };
  return (
    <>
      <DashboardIconWidgetBase
        testID="paymentRequestBtn"
        disabled={false}
        icon={<LinkIcon className="icon" />}
        label={'Send Payment Request'}
        callback={openModal}
        id={'paymentRequestWidget'}
      />
    </>
  );
};

export default PaymentRequestWidget;
