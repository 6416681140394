import React from 'react';
import { useDispatch } from 'react-redux';
import LynkModal from '../../../../atoms/LynkModal';
import { hideModal } from '../../../../../store/modals/modal';
import useAuth from '../../../../../hooks/auth/useAuth';
import './index.scss';

const LogoutUserModal: React.FC = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth();

  const onCloseHandler = () => {
    dispatch(hideModal());
  };
  return (
    <LynkModal
      title={'Are you sure you want to log out?'}
      subTitle={
        'After logging out of your account you will not be able to access the Lynk Biz Dashboard.'
      }
      loading={false}
      show={true}
      closeAction={{
        testID: 'logoutModalButtonCancel',
        onClickFn: onCloseHandler,
        text: 'Cancel',
        showButton: true
      }}
      mainAction={{
        testID: 'logoutModalButtonCancel',
        onClickFn: logout,
        text: 'Logout',
        disabled: false
      }}
      titleClass={'title-width'}
    />
  );
};

export default LogoutUserModal;
