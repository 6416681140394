import React from 'react';
import { Row, Col } from 'reactstrap';
import ActionButton from '../ActionButton';
import { ButtonFooterProps } from '../../../types/models/ButtonsTypes';

const ButtonFooter: React.FC<ButtonFooterProps> = ({
  cancelBtnProps,
  actionBtnProps
}) => {
  return (
    <Row>
      {!!cancelBtnProps && (
        <Col
          sm={12}
          md={actionBtnProps ? 6 : 12}
          className="order-1 order-md-1 order-sm-2"
        >
          <ActionButton
            testId={cancelBtnProps.testId}
            block
            disabled={cancelBtnProps.disabled}
            type={
              cancelBtnProps.type ? cancelBtnProps.type : 'outline-secondary'
            }
            text={cancelBtnProps.title}
            onPress={cancelBtnProps.onPressFn}
          />
        </Col>
      )}
      {!!actionBtnProps && (
        <Col
          sm={12}
          md={cancelBtnProps ? 6 : 12}
          className="order-md-2 order-sm-1"
        >
          <ActionButton
            loading={actionBtnProps.loadingDetails.isLoading}
            loadingText={actionBtnProps.loadingDetails.text}
            loaderIcon={actionBtnProps.loadingDetails.loaderIcon}
            block
            testId={'submitting'}
            disabled={actionBtnProps.disabled}
            type={'primary'}
            text={actionBtnProps.title}
            onPress={actionBtnProps.onPressAsync || actionBtnProps.onPressFn}
          />
        </Col>
      )}
    </Row>
  );
};
export default ButtonFooter;
