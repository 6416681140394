import './index.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import LynkModal from '../../../../atoms/LynkModal';
import { hideModal, showModal } from '../../../../../store/modals/modal';
import { useModalState } from '../../../../../store/modals/modal/selector';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TransferAmountForm from '../../../../molecules/TransferAmountForm';
import ChooseRecipientForm from '../../../../molecules/ChooseRecipientForm';
import ConfirmPayment from '../../../../molecules/ConfirmPayment';
import useGetPayeeInfo from '../../../../../hooks/singlePayments/useGetPayeeInfo';
import usePostSinglePaymentProcess from '../../../../../hooks/singlePayments/usePostSinglePaymentProcess';
import useGetSinglePaymentProcess from '../../../../../hooks/singlePayments/useGetSinglePaymentProcess';
import useGetBatchTransferLimit from '../../../../../hooks/merchant_portal/useGetBatchTransferLimit';
import { useGetProfileState } from '../../../../../store/profile/selector';
import {
  SinglePaymentDetailType,
  initSinglePaymentDetail,
  initSinglePaymentPayee,
  SinglePaymentPayee
} from '../../../../../types/models/SinglePaymentsType';

const SinglePaymentModal: React.FC = () => {
  const dispatch = useDispatch();
  const modalDetails = useModalState();
  const { fetching, getPayeeDetails } = useGetPayeeInfo();
  const {
    isSinglePaymentFetching,
    createSinglePayment,
    requestApprovalSinglePayment
  } = usePostSinglePaymentProcess();
  const { isSinglePaymentValidating, validateSinglePayment } =
    useGetSinglePaymentProcess();
  const { getBatchTransferLimitBalance } = useGetBatchTransferLimit();
  const profileState = useGetProfileState();

  const [currentPageTitle, setCurrentPageTitle] = useState({
    title: '',
    subTitle: ''
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [lynkID, setLynkID] = useState('');
  const [transferAmount, setTransferAmount] = useState('');
  const [payeeData, setPayeeData] = useState<SinglePaymentPayee>(
    initSinglePaymentPayee
  );
  const [singlePaymentInfo, setSinglePaymentInfo] =
    useState<SinglePaymentDetailType>(initSinglePaymentDetail);
  const [confirmSinglePayment, setConfirmSinglePayment] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [limitedExceeded, setLimitedExceeded] = useState(true);
  const [transferBalance, setTransferBalance] = useState(100);
  const isSinglePayment = true;

  const pages = [
    {
      page: 0,
      title: 'Choose your recipient',
      subTitle:
        'Sending money to a Lynk user or a LynkBiz merchant? Verify the recipient\'s details to ensure accuracy.',
      pageComponent: ChooseRecipientForm
    },
    {
      page: 1,
      title: 'Enter transfer amount',
      subTitle:
        'Sending money to a Lynk user or a Lynkbiz merchant? Verify the recipient\'s details. All transactions are final.',
      pageComponent: TransferAmountForm
    },
    {
      page: 2,
      title: 'Confirm payment',
      subTitle: 'Please review this payment and choose an action',
      pageComponent: ConfirmPayment
    }
  ];

  const onCloseHandler = () => {
    if (currentPage === 0) {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'confirm',
            subtitle:
              'Please confirm that you want to cancel this payment. This process cannot be undone.',
            title: 'Are you sure?',
            mainAction: {
              disabled: false,
              testID: 'transaction-status-main',
              onClickFn: () => {
                dispatch(hideModal());
              },
              text: 'Yes'
            },
            closeAction: {
              disabled: false,
              testID: 'transaction-status-main',
              onClickFn: () =>
                dispatch(
                  showModal({
                    modalKey: modalDetails.modalKey || '',
                    data: modalDetails.data
                  })
                ),
              text: 'Nevermind'
            }
          }
        })
      );
    } else if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLynkIDInput = (e: React.FormEvent<HTMLInputElement>) => {
    setLynkID(e.currentTarget.value);
    setFeedbackText('');
  };

  const handleTransferAmountInput = (e: React.FormEvent<HTMLInputElement>) => {
    setTransferAmount(e.currentTarget.value);
  };

  const getLimitBalance = async () => {
    const balance = await getBatchTransferLimitBalance('lynk');
    setTransferBalance(balance);
  };

  const approvalLabelOptions = {
    admin: 'Approve & Continue',
    manager: 'Request approval'
  };

  const approvalLabel = useMemo(() => {
    const profileRole = profileState.role.toLowerCase();

    return approvalLabelOptions[
      profileRole as keyof typeof approvalLabelOptions
    ];
  }, [profileState]);

  useEffect(() => {
    getLimitBalance();
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  useEffect(() => {
    setCurrentPageTitle({
      title: pages[currentPage].title,
      subTitle: pages[currentPage].subTitle
    });
  }, [currentPage]);

  const handleErrorGetPayee = (err: any) => {
    let message = '';
    if (err.response && err.response.data) {
      if (err.response.data.status === 403) {
        message = 'Transaction not allowed';
      } else if (
        err.response.data.status === 404 ||
        err.response.data.code === 'US-01'
      ) {
        message =
          'I’m sorry, we could not find a matching or active account. Please verify the Lynk ID with the recipient and try again.';
      } else {
        message =
          'We are having some technical issues performing the ID check. Please try again';
      }
    } else {
      message =
        'We are having some technical issues performing the ID check. Please try again';
    }
    setFeedbackText(message);
  };

  const showConfirmProps = useMemo(() => {
    const showConfirm = profileState.role.toLowerCase().includes('admin')
      ? {
          confirmVal: confirmSinglePayment,
          isSinglePayment: isSinglePayment,
          changeConfirmCheck: () => {
            setConfirmSinglePayment(!confirmSinglePayment);
          }
        }
      : undefined;

    return {
      lynkAcctDetail: {
        lynkAcctCount: singlePaymentInfo?.payee_count || 0,
        downloadText: payeeData?.display_name,
        isLynkAccountVerified: payeeData?.verified
      },
      subTotal: singlePaymentInfo?.subtotal || 0,
      processingFee: singlePaymentInfo?.lynk_fee || 0,
      gctProcessingFee: singlePaymentInfo?.lynk_fee_gct || 0,
      total: singlePaymentInfo?.total || 0,
      showConfirm
    };
  }, [confirmSinglePayment, singlePaymentInfo, profileState, payeeData]);

  const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

  const successApproval = () => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          title: 'Payment Sent for Approval',
          status: 'success',
          subtitle:
            'Your payment was sent for approval. You will be notified when the approval is done.'
        }
      })
    );
  };

  const approveBatch = async () => {
    if (profileState.role.toLowerCase().includes('admin')) {
      dispatch(
        showModal({
          modalKey: 'choose-source-funds',
          data: singlePaymentInfo,
          isSPayment: true
        })
      );
    } else {
      try {
        await requestApprovalSinglePayment(singlePaymentInfo);
        successApproval();
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const spAction = async () => {
    if (currentPage === pages.length - 1) {
      approveBatch();
    } else {
      if (currentPage === 0) {
        try {
          const response = await getPayeeDetails(lynkID);
          console.log({ response });
          setPayeeData(response.data);
          setCurrentPage(currentPage + 1);
        } catch (err: any) {
          handleErrorGetPayee(err);
        }
      }
      if (currentPage === 1) {
        try {
          const body = {
            lynk_id: '@' + lynkID,
            amount: transferAmount
          };
          const response = await createSinglePayment(body);

          let currentStatus = '';
          while (
            currentStatus !== 'VALIDATED' &&
            currentStatus !== 'INVALID_PAYEE'
          ) {
            await timer(2000);
            const singleBatchValidated = await validateSinglePayment(
              response?.payees[0].batch_id
            );
            if (singleBatchValidated) {
              setSinglePaymentInfo(singleBatchValidated);
              currentStatus = singleBatchValidated.status;
            } else {
              currentStatus = 'INVALID_PAYEE';
            }
          }
          setCurrentPage(currentPage + 1);
        } catch (err: any) {
          console.log(err);
        }
      }
    }
  };

  const changeForm = () => {
    if (currentPage === 0) {
      return (
        <ChooseRecipientForm
          lynkID={lynkID}
          onFormChange={handleLynkIDInput}
          errorText={feedbackText}
        />
      );
    }
    if (currentPage === 1) {
      return (
        <TransferAmountForm
          amount={transferAmount}
          onFormChange={handleTransferAmountInput}
          payeeDetails={payeeData}
        />
      );
    }
    if (currentPage === 2) {
      return (
        <ConfirmPayment
          {...showConfirmProps}
          limitExceeded={limitedExceeded}
          setLimitedExceeded={setLimitedExceeded}
          transferBalance={transferBalance}
          isFetching={false}
          isSinglePayment={isSinglePayment}
        />
      );
    }
  };

  const handleDisable = () => {
    if (currentPage === 0 && lynkID.length < 3) {
      return true;
    }
    if (currentPage === 1 && transferAmount === '') {
      return true;
    }
    if (
      currentPage === 2 &&
      confirmSinglePayment === false &&
      profileState.role.toLowerCase().includes('admin')
    ) {
      return true;
    }
    return false;
  };

  return (
    <LynkModal
      title={currentPageTitle.title}
      subTitle={currentPageTitle.subTitle}
      loading={fetching || isSinglePaymentFetching || isSinglePaymentValidating}
      show={true}
      modalBody={<>{changeForm()}</>}
      closeAction={{
        //TODO: Add test
        testID: 'logoutModalButtonCancel',
        onClickFn: onCloseHandler,
        text: currentPage === 0 ? 'Cancel' : 'Back',
        showButton: true
      }}
      mainAction={{
        testID: 'logoutModalButtonCancel',
        onClickFn: spAction,
        text: currentPage !== 2 ? 'Next' : approvalLabel,
        loader: {
          text: 'Next',
          icon: <AutorenewIcon />
        },
        disabled: handleDisable()
      }}
    />
  );
};

export default SinglePaymentModal;
