import './index.scss';

import { FormGroup, Label } from 'reactstrap';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import React from 'react';
import { CheckboxGroupProps } from '../../../types/models/formType';

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  checkboxGroup,
  upgradeGroupState
}) => {
  const getCheckboxIcon = (isActive: boolean) => {
    return isActive ? (
      <CheckBoxOutlinedIcon className="batch-Checkbox--active" />
    ) : (
      <CheckBoxOutlineBlankIcon className="batch-Checkbox--not-active" />
    );
  };

  return (
    <React.Fragment>
      {checkboxGroup.map(checkboxGroupItem => (
        <FormGroup
          check
          data-testid="checkgroupItem"
          className="checkgroup-item"
          key={checkboxGroupItem.key}
          onClick={() => upgradeGroupState(checkboxGroupItem.key)}
        >
          <div className="batch-Checkbox">
            {getCheckboxIcon(checkboxGroupItem.isActive)}
          </div>
          <Label check for="exampleCheck">
            {checkboxGroupItem.label}
          </Label>
        </FormGroup>
      ))}
    </React.Fragment>
  );
};

export default CheckboxGroup;
