import React, { useEffect, useState } from 'react';
import { FormControl, RadioGroup } from '@mui/material';
import RadioButtonCustom from '../../atoms/RadioButtonCustom';
import { RefundType } from '../../../hooks/merchant_portal/useRequestRefund';
import './index.scss';
import { SelectRefundFormProps } from '../../../types/models/refundType';

const SelectRefundForm: React.FC<SelectRefundFormProps> = ({
  amount,
  selectedRefundType = 'full-refund',
  onChangeFn
}) => {
  const [value, setValue] = useState<RefundType>(selectedRefundType);

  const handleChange = (refundType: RefundType) => setValue(refundType);

  useEffect(() => {
    onChangeFn(value);
  }, [value]);

  return (
    <div className="form-type-refund">
      <FormControl className="form-type-refund__type">
        <RadioGroup
          data-testid="radio-buttons-group"
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={event => handleChange(event.target.value as RefundType)}
        >
          <RadioButtonCustom
            testId="radio-button-full-refund"
            title="Full Refund"
            description={`Refund the full J$${amount}`}
            isActive={value === 'full-refund'}
            value="full-refund"
            classNames="form-type-refund__type__radio-button"
          />
          {/* <RadioButtonCustom
            testId="radio-button-partial-refund"
            title="Partial Refund"
            description="Select how much you need to refund"
            isActive={value === 'partial-refund'}
            value="partial-refund"
            classNames="form-type-refund__type__radio-button"
          /> */}
        </RadioGroup>
        <div className="form-type-refund__type__feedback">
          Refunds are issued immediately to your customer for Lynk to Lynk
          Transfers.
        </div>
      </FormControl>
    </div>
  );
};

export default SelectRefundForm;
