import './index.scss';

import { IconKey, getIconMapper } from '../../../utils/iconMap';

import React from 'react';
import { TableHeaderIconProps } from '../../../types/models/tableType';

const TableHeaderIcon: React.FC<TableHeaderIconProps> = ({
  iconType,
  iconCallback
}) => {
  return (
    <>
      <div
        className="iconContainer"
        onClick={iconCallback}
        data-testid="tableIcon"
      >
        {getIconMapper(iconType)}
      </div>
    </>
  );
};

export default TableHeaderIcon;
