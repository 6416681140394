import React from 'react';
import './index.scss';
import { MerchantTitleProps } from '../../../types/models/MerchantCodeType';

const MerchantTitle: React.FC<MerchantTitleProps> = ({
  merchantName,
  loading,
  username,
  name
}): JSX.Element => {
  return (
    <div className="merchantTitle">
      <div className="merchantTitle__name">{merchantName}</div>
      {merchantName !== name && (
        <div className="merchantTitle__cashier">{name}</div>
      )}
      <div className="merchantTitle__id">{username}</div>
    </div>
  );
};

export default MerchantTitle;
