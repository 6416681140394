/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiServiceType } from '../../types/enums/apiServiceType';
import useGetData from '../../hooks/api/useGetData';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useAccountState } from '../../store/account/selector';
import { WalletType } from '../merchant_portal/useGetWalletBalance';
export type TransferType = 'TOP' | 'ACH_CASHOUT';

const useGetCashInOutLimits = (): {
  isFetching: boolean;
  getTransferLimit: (transfer_type: TransferType) => Promise<any>;
  getTransferLimitBalance: (transfer_type: TransferType) => Promise<any>;
  getMainLimitBalance: (
    account_id: string,
    transfer_type: string
  ) => Promise<any>;
} => {
  const accountDetail = useAccountState();

  const { isFetching, getData } = useGetData();
  const dispatch = useDispatch();
  const [fetch, setFetch] = useState(false);
  const PERIOD = 1;
  const getWalletDetails = (walletType: WalletType) => {
    return accountDetail.accounts?.find(acct => {
      const map = {
        lynk: 'LYNK',
        'jam-dex': 'CBDC',
        corporate: ''
      };

      return acct.type == map[walletType];
    });
  };
  const getTransferLimitBalance = async (transfer_type: TransferType) => {
    const accountDetail = getWalletDetails('lynk');
    const response = await getData({
      path: `/v1/accounts/${accountDetail?.id}/limits?period=${PERIOD}&transfer_type=${transfer_type}`,
      apiType: ApiServiceType.ACCOUNT
    });

    return response.data?.remaining;
  };

  const getTransferLimit = async (transfer_type: TransferType) => {
    const accountDetail = getWalletDetails('lynk');
    const response = await getData({
      path: `/v1/accounts/${accountDetail?.id}/limits?period=${PERIOD}&transfer_type=${transfer_type}`,
      apiType: ApiServiceType.ACCOUNT
    });

    return response.data?.limit;
  };
  const getMainLimitBalance = async (
    account_id: string,
    transfer_type: string
  ) => {
    const response = await getData({
      path: `/v1/accounts/${account_id}/limits?period=${
        transfer_type === 'MBATCH' ? 2 : PERIOD
      }&transfer_type=${transfer_type}`,
      apiType: ApiServiceType.ACCOUNT
    });

    return response.data;
  };
  return {
    isFetching,
    getTransferLimit,
    getTransferLimitBalance,
    getMainLimitBalance
  };
};

export default useGetCashInOutLimits;
