import { useDispatch } from 'react-redux';
import LynkModal from '../../../../atoms/LynkModal';
import React, { useEffect, useMemo, useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { hideModal, showModal } from '../../../../../store/actions';

import ConfirmPayment from '../../../../molecules/ConfirmPayment';
import useRequestBatch from '../../../../../hooks/batchPayments/useGetBatchPaymentProcess';
import { useGetProfileState } from '../../../../../store/profile/selector';
import saveAs from 'file-saver';
import useGetBatchPaymentDetail from '../../../../../hooks/batchPayments/useGetBatchPaymentDetail';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useGetBatchTransferLimit from '../../../../../hooks/merchant_portal/useGetBatchTransferLimit';

const ReviewPaymentModal: React.FC = () => {
  const dispatch = useDispatch();
  const { getBatchById } = useRequestBatch();
  const [currentBatchPaymentID, setCurrentBatchPayment] = useState<string>('');
  const [loadAttemptMade, setLoadAttemptMade] = useState(false);
  // const { data } = useModalState();
  const [errorLoading, setErrorLoading] = useState(false);
  const { fetching, getBatchPaymentDetailsById } = useGetBatchPaymentDetail();
  const [limitedExceeded, setLimitedExceeded] = useState(true);
  const [transferBalance, settransferBalance] = useState(0);

  const [data, setData] = useState({
    status: '',
    id: -2,
    name: '',
    payee_count: 0,
    total: 0,
    lynk_fee_gct: 0,
    subtotal: 0,
    lynk_fee: 0,
    completed_at: '',
    created_at: '',
    created_by: '',
    merchant_id: '',
    account_id: '',
    account_type: 0,
    reviewed_at: '',
    reviewed_by: ''
  });

  const { id } = useParams();

  useEffect(() => {
    loadData();
  }, [id]);

  const { getBatchTransferLimitBalance } = useGetBatchTransferLimit();

  const getLimitBalance = async () => {
    const balance = await getBatchTransferLimitBalance('lynk');
    console.log(balance);

    settransferBalance(balance);
  };

  useEffect(() => {
    getLimitBalance();
  }, []);

  const loadData = async () => {
    const result = /^\d+$/.test(currentBatchPaymentID);

    try {
      const loadedData = await getBatchPaymentDetailsById(
        Number.parseInt(id || '')
      );

      setData(loadedData);
      setErrorLoading(false);
    } catch (err) {
      // setCurrentBatchPayment('');
      setErrorLoading(true);
    } finally {
      setLoadAttemptMade(true);
    }

    //if attempt fails show default
  };

  const downloadRecipients = async () => {
    const batch = await getBatchById(data, false);
    if (batch) {
      const fileName = `batch_${data.name}.csv`;

      const csvContent = Buffer.from(batch, 'base64');
      const blob = new Blob([csvContent]);
      await Promise.resolve(saveAs(blob, fileName));
    }
  };
  const closeModal = () => {
    dispatch(hideModal());
    navigate('../');
  };
  const rejectAction = () => {
    dispatch(hideModal());
    navigate('../');
    dispatch(showModal({ modalKey: 'reject-batch-payments', data: data }));
  };

  const approveBatch = async () => {
    dispatch(hideModal());
    navigate('../');
    dispatch(showModal({ modalKey: 'choose-source-funds', data: data }));
  };

  const profileState = useGetProfileState();
  const [processConfirm, setProcessConfirm] = useState(false);

  const showConfirmProps = useMemo(() => {
    const showConfirm = profileState.role.toLowerCase()
      ? {
          confirmVal: processConfirm,
          changeConfirmCheck: () => {
            setProcessConfirm(!processConfirm);
          }
        }
      : undefined;

    return {
      lynkAcctDetail: {
        lynkAcctCount: data?.payee_count,
        downloadText: 'Review batch upload',
        onDownloadTrigger: async () => {
          await downloadRecipients();
        }
      },
      subTotal: data?.subtotal,
      processingFee: data?.lynk_fee,
      gctProcessingFee: data?.lynk_fee_gct,
      total: data?.total,
      showConfirm,
      limitedExceeded: limitedExceeded,
      setLimitedExceeded: setLimitedExceeded
      // transferBalance: transferBalance
    };
  }, [profileState, processConfirm, data]);

  const doNotrenderBatchPaymentDetail = useMemo(() => {
    const result = /^\d+$/.test(id || '');
    return !result && loadAttemptMade;
  }, [id, loadAttemptMade]);

  const navigate = useNavigate();
  if (doNotrenderBatchPaymentDetail || (loadAttemptMade && errorLoading)) {
    navigate('../');
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'failure',
          subtitle:
            'There was an error loading the batch transaction. Please try again',
          title: 'Failure loading the batch transaction details'
        }
      })
    );
  }

  return (
    <>
      <LynkModal
        title={'Confirm Payment'}
        subTitle="Please review this payment and choose an action"
        loading={false}
        show={true}
        subTitleClass={'notDisplayMobile'}
        modalBody={
          <>
            <ConfirmPayment
              limitExceeded={limitedExceeded}
              transferBalance={transferBalance}
              {...showConfirmProps}
              isFetching={fetching}
            />
          </>
        }
        mainAction={
          fetching
            ? undefined
            : {
                loader: {
                  text: 'Upload File',
                  icon: <AutorenewIcon />
                },
                disabled: fetching || !processConfirm,
                testID: 'uploadBatchPayment',
                onClickAsyncFn: approveBatch,
                text: 'Approve and continue'
              }
        }
        closeAction={
          fetching
            ? undefined
            : {
                text: 'Reject',
                showButton: true,
                onClickFn: rejectAction,
                testID: 'uploadBatchCancelButton'
              }
        }
        secondaryAction={
          fetching
            ? undefined
            : {
                text: 'Resume Later',
                showButton: true,
                onClickFn: closeModal,
                testID: 'uploadBatchSecondaryButton'
              }
        }
      />
    </>
  );
};

export default ReviewPaymentModal;
