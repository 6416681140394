import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import HistoryIcon from '@mui/icons-material/History';
import React from 'react';
import { showModal } from '../../../store/modals/modal';
import { useDispatch } from 'react-redux';

const ViewLastExportsWidget: React.FC = () => {
  const dispatch = useDispatch();

  const triggerCallback = () => {
    dispatch(showModal({ modalKey: 'view-last-exports' }));
  };

  return (
    <div className="viewLastExports">
      <DashboardIconWidgetBase
        testID="viewLastExports"
        disabled={false}
        icon={<HistoryIcon className="icon" />}
        label={'View Last Exports'}
        callback={triggerCallback}
        id={'viewLastExportsWidget'}
      />
    </div>
  );
};

export default ViewLastExportsWidget;
