import './index.scss';

import React, { useRef } from 'react';
import { clearScope } from '../../../store/status';
import {
  useGetQRCodeDetail,
  useIsLoadingProfileDetail
} from '../../../store/organization/selector';

import { useGetProfileState } from '../../../store/profile/selector';

import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import { getToast } from './downloadToast';
import { useDispatch } from 'react-redux';
import useDownloadCompAsImage from '../../../hooks/utils/useDownloadCompAsImage';
import QRCodeDownload from '../../organisms/QRCodeDownload';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import { MerchantQRSectionProps } from '../../../types/models/MerchantCodeType';
import { useIsLoadingAccountDetail } from '../../../store/account/selector';
import { showModal } from '../../../store/modals/modal';
import { useGetWalletManager } from '../../../store/walletManagement/selector';

const DownloadMerchantQRWidget: React.FC<MerchantQRSectionProps> = ({
  classes = ''
}) => {
  const fetching = useIsLoadingProfileDetail();
  const qrDetails = useGetQRCodeDetail();
  const profile = useGetProfileState();

  const isAccountDetailsLoading = useIsLoadingAccountDetail();

  const { isFetching, downloadElementAsImage } = useDownloadCompAsImage();

  const ref: any = useRef();
  const dispatch = useDispatch();

  const walletManager = useGetWalletManager();

  const renderResult = (errorType: 'success' | 'error') => {
    const toast = getToast(errorType);

    if (toast) {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'success',
            subtitle:
              'QR code for Lynk generated successfully. Please check you machine for the file.',
            title: 'Lynk QR code generated'
          }
        })
      );
    }
  };

  const downloadQRSnapshot = async () => {
    let status: 'success' | 'error' = 'success';
    dispatch(clearScope('general'));
    try {
      // dispatch(clearScope('general'));
      await downloadElementAsImage(
        `${qrDetails.name || 'merchant'}__qrCode`,
        ref
      );
    } catch (error) {
      status = 'error';
    } finally {
      renderResult(status);
    }
  };

  const generatedValue = isFetching ? 'Generating...' : 'Download QR Code';

  return (
    <React.Fragment>
      <section className={`downloadQRWidget ${classes || ''}`}>
        <DashboardIconWidgetBase
          testID="downloadQRWidgetBase"
          icon={
            isFetching ? (
              <ButtonAsyncLoader showLoaderText={false} />
            ) : (
              <QrCode2OutlinedIcon className="qrIcon default" />
            )
          }
          label={!fetching ? generatedValue : ''}
          callback={downloadQRSnapshot}
          disabled={false}
          id={'downloadQrWidget'}
        />
        <div className="downloadQRefSection">
          <div ref={ref} className="downloadQRefSection__qrCodeSection">
            <QRCodeDownload
              name={
                profile.role === 'Cashier' || profile.role === 'STAFF'
                  ? profile.displayName
                  : qrDetails.name
              }
              merchantName={qrDetails.name}
              merchantLynkID={qrDetails.username}
              qrCode={qrDetails.qr_image}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default DownloadMerchantQRWidget;
