/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElementRangeType } from '../../../types/models/elementRangeType';
import { SorterProps } from '../../../types/models/utils/table-models';
import { ScreenState } from '../../../store/screenSize';
import { createContext } from 'react';
import { TransactionStateKey } from './transactionState';

export * from './useConfigTable';
export * from './useTableFormatter';

export interface TableFooterProps {
  totalPageCount: number;
  totalItems: number;
  currentPage: number;
  elementRange: ElementRangeType;
  pagingHandler: (targetPage: number) => void;
}

interface DataDisplay {
  sortMap: SorterProps[];
  transactions: JSX.Element[];
  columnHeaders: any;
  showMobileView: boolean;
  fetchingData: {
    fetching: boolean;
    fetchingText: string | JSX.Element;
  };
  emptyDataMessage: string | JSX.Element;
  breakpoint: ScreenState;
  paginationDetails: TableFooterProps;
}

export const DataDisplayContext = createContext<DataDisplay>({
  sortMap: [],
  showMobileView: true,
  fetchingData: {
    fetching: false,
    fetchingText: ''
  },
  emptyDataMessage: 'No data loaded',
  breakpoint: {
    breakpoint: 'smartphone',
    windowWidth: 0,
    windowHeight: 0
  },
  columnHeaders: [],
  transactions: [],
  paginationDetails: {
    totalPageCount: 0,
    totalItems: 0,
    currentPage: 0,
    elementRange: {
      initial: 0,
      final: 0
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pagingHandler: (_targetPage: number) => console.log('Not implemented')
  }
});

export interface DataDisplayComponentProps {
  columnFilter?: (val: any[]) => any[];
  endpointGenerator?: (endpoint: string) => string;
  sorter: SorterProps[];
  dataKey: TransactionStateKey;
  header?: (isActive?: boolean) => JSX.Element;
  noDataMessage: string | JSX.Element;
  fetchingData: {
    fetchingText: string | JSX.Element;
  };
  pageLimit: number;
  dataGenerator: (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => JSX.Element[];
  alertScope?: string;
  loadMobileView?: boolean;
}
