import LynkModal from '../../../../atoms/LynkModal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, refreshTable } from '../../../../../store/actions';
import { useModalState } from '../../../../../store/modals/modal/selector';
import useDeleteStaff from '../../../../../hooks/staff/useDeleteStaff';
import useStatusHook from '../../../../../utils/useStatusHook';
import { TransactionStateKeyEnum } from '../../../../../hooks/utils/tableConfig/transactionState';

const DeleteUserModal: React.FC = () => {
  const modalDetails = useModalState();

  const dispatch = useDispatch();
  const { isDeleting, deleteStaffDetails } = useDeleteStaff();
  const { showActionStatus } = useStatusHook();

  const deleteUser = async () => {
    const result = {
      success: true,
      message: 'This user was deleted successfully!'
    };
    try {
      await deleteStaffDetails(modalDetails.data.id);
      dispatch(refreshTable(TransactionStateKeyEnum.STAFF));
    } catch (err) {
      result.success = false;
      result.message =
        'Oops! Something went wrong. We were unable to delete this user. Please try again.';
    } finally {
      dispatch(hideModal());
      showActionStatus(
        {
          title: 'Deletion Status',
          message: result.message,
          timeMade: new Date(),
          alertTag: 'userDeletion'
        },
        result.success ? 'success' : 'error',
        'general',
        'deletionStatus'
      );
    }
  };

  return (
    <LynkModal
      modalBody={
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: '#73848E'
            }}
          >
            <div>Are you sure you want to delete this user?</div>
            <div>This action is irreversible</div>
          </div>
        </>
      }
      title={modalDetails.title || ''}
      loading={isDeleting}
      show={true}
      closeAction={{
        testID: 'deleteStaffCancelBtn',
        text: 'Cancel',
        onClickFn: () => dispatch(hideModal())
      }}
      mainAction={{
        loader: {
          text: 'Deleting user',
          icon: <></>
        },
        disabled: isDeleting,
        testID: 'newUserMainActionTestId',
        onClickAsyncFn: deleteUser,
        text: 'Yes, Delete User'
      }}
    />
  );
};

export default DeleteUserModal;
