import { ApiServiceType } from '../../types/enums/apiServiceType';
import { updateProgress } from '../../store/modals/exportAndDownload';
import { useDispatch } from 'react-redux';
import useGetData from '../../hooks/api/useGetData';
import { useAccountState } from '../../store/account/selector';
export type WalletType = 'jam-dex' | 'lynk' | 'corporate';

export interface GenerateReportPreferenceProps {
  startRange: string;
  endRange: string;
  walletType: WalletType;
}

const useGetGenerateReportForExport = (): {
  isFetching: boolean;
  attemptGetNewReport: (
    preferences: GenerateReportPreferenceProps
  ) => Promise<any>;
} => {
  const { isFetching, getData } = useGetData();
  const accountDetail = useAccountState();

  const getWalletDetails = (walletType: WalletType) => {
    return accountDetail.accounts?.find(acct => {
      const map = {
        lynk: 'LYNK',
        'jam-dex': 'CBDC',
        corporate: ''
      };

      return acct.type == map[walletType];
    });
  };
  const attemptGetNewReport = async (
    preferences: GenerateReportPreferenceProps
  ) => {
    try {
      const accountDetail = getWalletDetails(preferences.walletType);
      const url = `/v1/accounts/${accountDetail?.id}/export?from=${preferences.startRange}&to=${preferences.endRange}`;
      const response = await getData({
        path: url,
        apiType: ApiServiceType.ACCOUNT
      });

      return response.data.base64_file;
    } catch (error) {
      console.log(error);
    }
  };

  return { isFetching, attemptGetNewReport };
};

export default useGetGenerateReportForExport;
