import './index.scss';

import React, { useEffect, useRef } from 'react';
import { Toast, ToastBody } from 'reactstrap';

import { Close } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface ToastDisplayProps {
  showDefaultIcon?: boolean;
  classnames?: string;
  toastType: 'success' | 'error' | 'info';
  message: string | JSX.Element;
  icon?: JSX.Element;
  showToast: boolean;
  testID?: string;
  duration?: number;
  closeHandler?: () => void;
  onOutsideClick?: () => void;
}

const ToastDisplay: React.FC<ToastDisplayProps> = ({
  showDefaultIcon,
  classnames,
  toastType,
  message,
  icon,
  showToast,
  testID,
  closeHandler,
  onOutsideClick,
  duration
}) => {
  const ref: any = useRef(null);
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onOutsideClick && onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (showToast && closeHandler && duration) {
      setTimeout(() => {
        closeHandler();
      }, duration);
    }
  }, [showToast]);

  const defaultIconMap = {
    success: <CheckCircleOutlineIcon />,
    error: <ErrorOutlineIcon />
  };

  return (
    <div ref={ref} className="toasterDisplayContainer">
      <Toast
        data-testid={`${testID || 'toastDisplay'}`}
        className={`toastDisplay ${toastType} ${classnames || ''}`}
        isOpen={showToast}
      >
        <ToastBody className="toast-text d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {showDefaultIcon
              ? defaultIconMap[toastType as keyof typeof defaultIconMap]
              : icon}
            <div className="mx-1">{message}</div>
          </div>
          {!!closeHandler && (
            <Close className="close-button" onClick={closeHandler} />
          )}
        </ToastBody>
      </Toast>
    </div>
  );
};

export default ToastDisplay;
