import React from 'react';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import { Permission } from '../../features/permissions';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LinkIcon from '@mui/icons-material/Link';
import { AccountItem } from '@store/account/types';

export interface SidebarItem {
  permissions: Permission[];
  title: string | JSX.Element;
  key: string;
  icon: JSX.Element;
  additionalValidation?: (additionalParams: {
    additionalDetails: any[] | any;
    validator?: (permissions: Permission[]) => boolean;
  }) => boolean;
}

export const sidebarListLynk: SidebarItem[] = [
  {
    title: 'Transfer',
    icon: <LinkIcon className="linkIcon" />,
    key: 'transfer',
    additionalValidation: (additionalParams: {
      additionalDetails: any[] | any;
      validator?: (permissions: Permission[]) => boolean;
    }): boolean => {
      if (!additionalParams || !additionalParams.additionalDetails)
        return false;

      const cBDCAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'CBDC'
        ) || [];
      const bankAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'BANK_ACCOUNT'
        ) || [];

      const beneficiaryAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'BENEFICIARY'
        ) || [];

      const hasCBDC = cBDCAccounts.length > 0;
      const hasBankAccount = bankAccounts.length > 0;
      const hasBeneficiary = beneficiaryAccounts.length > 0;

      const bankPermissions = [
        { resource: 'cashins', actions: new Set(['create']) }
      ];

      const cbdcPermissions = [
        { resource: 'cbdc-cashins', actions: new Set(['create']) },
        { resource: 'cbdc-cashout', actions: new Set(['create']) }
      ];

      const beneficaryPermission = [
        { resource: 'cashouts', actions: new Set(['create']) }
      ];

      return (
        (hasBankAccount &&
          (additionalParams.validator
            ? additionalParams.validator(bankPermissions)
            : true)) ||
        (hasCBDC &&
          (additionalParams.validator
            ? additionalParams.validator(cbdcPermissions)
            : true)) ||
        (hasBeneficiary &&
          (additionalParams.validator
            ? additionalParams.validator(beneficaryPermission)
            : true))
      );
    },
    permissions: [
      { resource: 'cashouts', actions: new Set(['create']) },
      { resource: 'cashins', actions: new Set(['create']) },
      { resource: 'cbdc-cashins', actions: new Set(['create']) },
      { resource: 'cbdc-cashout', actions: new Set(['create']) }
    ]
  },
  {
    title: 'Download QR Code',
    icon: <QrCode2OutlinedIcon className="linkIcon qrIconAtom default" />,
    permissions: [{ resource: 'profile', actions: new Set(['read']) }],
    key: 'downloadQR'
  },
  {
    title: 'Export Tables',
    icon: <PostAddIcon className="linkIcon" />,
    permissions: [{ resource: 'reports-detailed', actions: new Set(['read']) }],
    key: 'exportTables'
  },
  {
    title: 'Create Payments',
    icon: <LinkIcon className="linkIcon" />,
    permissions: [{ resource: 'batch-payments', actions: new Set(['create']) }],
    key: 'createPaymentRequest'
  },
  {
    title: 'Send Payment Request',
    icon: <LinkIcon className="linkIcon" />,
    permissions: [{ resource: 'orders', actions: new Set(['create']) }],
    key: 'paymentRequest'
  }
];

export const sidebarListJamDex: SidebarItem[] = [
  {
    title: 'Transfer',
    icon: <LinkIcon className="linkIcon" />,
    key: 'transfer',
    additionalValidation: (additionalParams: {
      additionalDetails: any[] | any;
      validator?: (permissions: Permission[]) => boolean;
    }): boolean => {
      if (!additionalParams || !additionalParams.additionalDetails)
        return false;

      const cBDCAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'CBDC'
        ) || [];
      const bankAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'BANK_ACCOUNT'
        ) || [];

      const beneficiaryAccounts =
        (additionalParams.additionalDetails as AccountItem[])?.filter(
          detail => detail.type === 'BENEFICIARY'
        ) || [];

      const hasCBDC = cBDCAccounts.length > 0;
      const hasBankAccount = bankAccounts.length > 0;
      const hasBeneficiary = beneficiaryAccounts.length > 0;

      const bankPermissions = [
        { resource: 'cashins', actions: new Set(['create']) }
      ];

      const cbdcPermissions = [
        { resource: 'cbdc-cashins', actions: new Set(['create']) },
        { resource: 'cbdc-cashout', actions: new Set(['create']) }
      ];

      const beneficaryPermission = [
        { resource: 'cashouts', actions: new Set(['create']) }
      ];

      return (
        (hasBankAccount &&
          (additionalParams.validator
            ? additionalParams.validator(bankPermissions)
            : true)) ||
        (hasCBDC &&
          (additionalParams.validator
            ? additionalParams.validator(cbdcPermissions)
            : true)) ||
        (hasBeneficiary &&
          (additionalParams.validator
            ? additionalParams.validator(beneficaryPermission)
            : true))
      );
    },
    permissions: [
      { resource: 'cashouts', actions: new Set(['create']) },
      { resource: 'cashins', actions: new Set(['create']) },
      { resource: 'cbdc-cashins', actions: new Set(['create']) },
      { resource: 'cbdc-cashout', actions: new Set(['create']) }
    ]
  },
  {
    title: 'Download QR Code',
    icon: <QrCode2OutlinedIcon className="linkIcon qrIconAtom default" />,
    permissions: [{ resource: 'profile', actions: new Set(['read']) }],
    key: 'downloadQRJAMDEX'
  },
  {
    title: 'Export Tables',
    icon: <PostAddIcon className="linkIcon" />,
    permissions: [{ resource: 'reports-detailed', actions: new Set(['read']) }],
    key: 'exportTables'
  }
];
