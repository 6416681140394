import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import React from 'react';
import { showModal } from '../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import { ReactComponent as BatchPaymentsIcon } from '../../../assets/svg/batch-payments.svg';

const CreatePaymentsWidget: React.FC = () => {
  const dispatch = useDispatch();

  const triggerCallback = () => {
    dispatch(showModal({ modalKey: 'create-payments' }));
  };

  return (
    <div className="viewPayments">
      <DashboardIconWidgetBase
        testID="viewBatchPayments"
        disabled={false}
        icon={<BatchPaymentsIcon className="icon" />}
        label={'Create Payments'}
        callback={triggerCallback}
        id={'viewBatchPaymentsWidget'}
      />
    </div>
  );
};

export default CreatePaymentsWidget;
