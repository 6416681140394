import { useState } from 'react';

const useStringFormat = (): {
  handleTextLength: (text: string, maxlength: number) => string;
} => {
  const handleTextLength = (text: string, maxlength: number) => {
    const newText =
      !!text && text.length > maxlength
        ? `${text.slice(0, maxlength)}...`
        : text;
    return newText;
  };

  return {
    handleTextLength
  };
};

export default useStringFormat;
