import { ApiServiceType } from '../../types/enums/apiServiceType';
import { BatchPaymentFormState } from '../../store/batchPaymentProcess';
import useGetData from '../api/useGetData';
import { useGetProfileState } from '../../store/profile/selector';
import { BatchDetailType } from '../../types/models/BatchPaymentsType';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../store/actions';
import saveAs from 'file-saver';
import { GenericBatchUploadException } from '../../exceptions/UploadBatchDocument.exception';

const useGetBatchPaymentProcess = (): {
  fetching: boolean;
  // uploadBatchPaymentFile: (batchPayment: any) => Promise<any>;
  validateUploadedBatchFile: (id: number) => Promise<any>;
  getBatchById: (
    transaction: BatchDetailType,
    withErrors: boolean
  ) => Promise<any>;
  receiptBatch: (transaction: BatchDetailType, name: string) => Promise<any>;
  // closeBatchModal: () => void;
  abortController: AbortController;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();
  const profile = useGetProfileState();
  const dispatch = useDispatch();

  const converBase64 = async (file: any) => {
    return new Promise(resolve => {
      let baseURL = '';
      // Make new FileReader
      const reader: any = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result.split(',')[1];
        resolve(baseURL);
      };
    });
  };

  const validateUploadedBatchFile = async (id: number) => {
    //https://dev-krakend.core-nautilus.net/bff-mcp-pay/batch-payments/1
    try {
      const response = await getData({
        path: `batch-payments/${id}`,
        apiType: ApiServiceType.PAYMENT
      });
      return response;
    } catch (err: any) {
      throw new GenericBatchUploadException();
    }
  };

  const getBatch = async (values: BatchDetailType, withErrors: boolean) => {
    const response = await getData({
      path: `batch-payments/${values.id}/csv?include_errors=${
        withErrors ? 'true' : 'false'
      }`,
      apiType: ApiServiceType.PAYMENT
    });

    return response;
  };
  const getBatchById = async (
    transactionSelected: BatchDetailType,
    withErrors: boolean
  ) => {
    try {
      const batch: any = await getBatch(transactionSelected, withErrors);

      return batch;
    } catch (error) {
      console.error(error);
      throw new GenericBatchUploadException();
    }
  };
  const receiptBatch = async (
    transactionSelected: BatchDetailType,
    name: string
  ) => {
    let includeErrors = true;
    if (transactionSelected.status === 'COMPLETED') {
      includeErrors = false;
    }
    const batch = await getBatchById(transactionSelected, includeErrors);
    if (batch) {
      const fileName = name || 'receipt.csv';

      const csvContent = Buffer.from(batch, 'base64');
      const blob = new Blob([csvContent]);
      await Promise.resolve(saveAs(blob, fileName));
    }
  };
  return {
    fetching: isFetching,
    getBatchById,
    receiptBatch,
    validateUploadedBatchFile,
    abortController
  };
};
export default useGetBatchPaymentProcess;
