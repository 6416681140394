import React, { useEffect, useState } from 'react';
import './index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NotificationsCardProps } from '../../../types/models/tableType';
import moment from 'moment';
import useStringFormat from '../../../hooks/utils/useStringFormat';
import { useGetScreenState } from '../../../store/screenSize/selector';

const NotificationItem: React.FC<NotificationsCardProps> = ({
  title,
  mainTitle,
  subTitle,
  data,
  moreInfoCallback
}) => {
  const { handleTextLength } = useStringFormat();
  const { breakpoint } = useGetScreenState();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(breakpoint === 'smartphone' ? true : false);
  }, [breakpoint]);

  return (
    <>
      <div
        className={`notificationsCardContainer ${!data && 'unread'}`}
        onClick={moreInfoCallback}
      >
        <div className={`${!data && 'unread'}`}>
          <div className="cardTitleContainer">
            <div className="title">
              {isMobile ? handleTextLength(String(title), 25) : title}
            </div>
            <div className="moreDetailsNotification">
              {moment(mainTitle).fromNow()}
            </div>
          </div>
          <div className={'cardDataContainer}'}>
            <div>
              <div className="transTimestamp">
                {isMobile ? handleTextLength(subTitle, 60) : subTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationItem;
