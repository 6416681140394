export const getRoleIDByDescription = (
  role: string,
  options: any[]
): string => {
  const foundRoles = options.filter(roleOpt => roleOpt.name === role);

  if (!foundRoles || foundRoles.length === 0) return '';

  return foundRoles[0].id;
};

export const getRoleDescriptionByID = (
  role: string,
  options: any[]
): string => {
  const foundRoles = options.filter(roleOpt => roleOpt.id === role);

  if (!foundRoles || foundRoles.length === 0) return '';

  return foundRoles[0].name;
};

export const convertStatusToYN = (active: boolean): 'yes' | 'no' => {
  return active ? 'yes' : 'no';
};
