import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { RadioButtonProps } from '../../../types/models/formType';

export const RadioButtonCustom: React.FC<RadioButtonProps> = ({
  value,
  title,
  description,
  classNames,
  isActive,
  testId
}) => {
  return (
    <FormControlLabel
      data-testid={testId}
      className={`${isActive && 'selected'} ${classNames}`}
      value={value}
      defaultChecked
      control={<Radio />}
      label={
        <div className="label">
          <label className="title">{title}</label>
          <label className="description">{description}</label>
        </div>
      }
    />
  );
};

export default RadioButtonCustom;
