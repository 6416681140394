import LynkModal from '../../../../atoms/LynkModal';
import FormattedSelector from '../../../../molecules/FormattedSelector';
import useGetAccountDetails from '../../../../../hooks/account/useGetAccountDetails';
import {
  showModal,
  hideModal,
  setPreviousModal,
  showPreviousModal
} from '../../../../../store/actions';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAccountState } from '../../../../../store/account/selector';
import {
  BankAccountDetails,
  WalletDetailBase
} from '../../../../../store/account/types';
import { useModalState } from '../../../../../store/modals/modal/selector';
import { moneyFormated } from '../../../../../utils/currency';
import useGetBatchPaymentProcess from '../../../../../hooks/batchPayments/useGetBatchPaymentProcess';
import { isNumber } from 'lodash';
import useGetWalletBalance from '../../../../../hooks/merchant_portal/useGetWalletBalance';
import useRequestBatch from '../../../../../hooks/batchPayments/usePostBatchPaymentProcess';
import useGetSinglePaymentProcess from '../../../../../hooks/singlePayments/useGetSinglePaymentProcess';
import { usePermissions } from '../../../../../features/permissions';

let processing = false;
const ChooseFundSourceModal: React.FC = () => {
  const dispatch = useDispatch();
  const modalDetails = useModalState();
  const accountDetails = useAccountState();
  const { getWalletBalance } = useGetWalletBalance();

  const { data, isSPayment } = useModalState();
  const { fetching, validateUploadedBatchFile, receiptBatch } =
    useGetBatchPaymentProcess();
  const { startBatch, batchPaymentFetching } = useRequestBatch();
  const { validateSinglePayment } = useGetSinglePaymentProcess();

  const { fetch, getAccountDetail } = useGetAccountDetails();
  const [isFailure, setIsFailure] = useState(false);
  const [errorString, setErrorString] = useState('');
  const { hasPermissions } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [payeesUnpaid, setPayeesUnpaid] = useState(0);

  const successModal = (with_errors: number | null) => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'success',
          subtitle: !with_errors ? (
            <>
              <div style={{ textAlign: 'center' }}>
                <b>We need some time.</b>
                <br />
                <p style={{ marginTop: '10px' }}>
                  {data.payee_count} payment
                  {data.payee_count > 1 ? 's are ' : ' is '} being processed,
                  worth ${moneyFormated(data.total)}.
                </p>
                <p style={{ marginTop: '10px' }}>
                  We will notify you once the transactions are processed.
                </p>
              </div>
            </>
          ) : (
            <>
              <div style={{ textAlign: 'center' }}>
                <p>
                  We were able to cash in but there were some failures. The
                  remaining amount of [{with_errors}] is now in your Lynk
                  wallet.{' '}
                </p>
                <br />
              </div>
            </>
          ),
          title: with_errors ? 'Payment Sent' : 'Payment Processing'
        }
      })
    );
  };
  const transferSentModal = () => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'success',
          subtitle: (
            <>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginTop: '10px', marginBottom: '-5px' }}>
                  Payment sent successfully.
                </p>
              </div>
            </>
          ),
          title: 'Payment Sent'
        }
      })
    );
  };
  const showError = (statusFailed: boolean) => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'batch-failure',
          title: 'Something went wrong!',
          subtitle: (
            <>
              {statusFailed ? (
                <div style={{ textAlign: 'center', color: 'red' }}>
                  There were errors found with some Lynk accounts. Please check
                  the{' '}
                  <b
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={downloadReceipt}
                  >
                    error log
                  </b>
                  , fix errors and try again.
                </div>
              ) : (
                <div style={{ textAlign: 'center', color: 'red' }}>
                  Sorry, we’re experiencing technical problems.
                  <p style={{ marginTop: '10px' }}>Please try again later.</p>
                </div>
              )}
            </>
          )
        }
      })
    );
  };
  const showUnavailableError = () => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'batch-failure',
          title: 'Something went wrong!',
          subtitle: (
            <>
              <div style={{ textAlign: 'center' }}>
                Cash in is unavailable at this time.
                <p>Please try to do your payment again later</p>
              </div>
            </>
          )
        }
      })
    );
  };
  const verifyPayees = async (array: Array<any>) => {
    let count = 0;

    await array.forEach(element => {
      if (element.status !== 'PAID') count += 1;
    });

    return count;
  };
  const downloadReceipt = async () => {
    await receiptBatch(data, 'batch_payments_error_log.csv');
  };
  const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

  const sendPayment = async () => {
    processing = true;
    try {
      const response = await startBatch(data, selectedAccount);
      setIsFailure(false);
      let currentStatus = '';
      let payees = [];
      let tries = 0;
      const batch = { account_type: '', cash_in_id: null };
      while (
        currentStatus !== 'COMPLETED' &&
        currentStatus !== 'COMPLETED_WITH_ERRORS' &&
        currentStatus !== 'FAILED' &&
        tries < 15
      ) {
        setLoading(true);
        await timer(2000);
        const validationResponse = isSPayment
          ? await validateSinglePayment(data.id)
          : await validateUploadedBatchFile(data.id);
        // const validationResponse = await validateUploadedBatchFile(data.id);
        setLoading(false);
        payees = validationResponse.payees;
        currentStatus = validationResponse.status;
        batch.cash_in_id = validationResponse.cash_in_id;
        batch.account_type = validationResponse.account_type;
        tries += 1;
      }
      if (currentStatus === 'COMPLETED') {
        transferSentModal();
      }
      if (currentStatus === 'COMPLETED_WITH_ERRORS') {
        const payeesUnpaid = await verifyPayees(payees);
        successModal(payeesUnpaid);
      }
      if (currentStatus === 'FAILED') {
        if (
          batch.account_type === 'BANK_ACCOUNT' &&
          !isNumber(batch.cash_in_id)
        ) {
          // setIsFailure(true);
          // setErrorString(
          //   'You don\'t have enough money in your account to do this transaction. Please make sure there is sufficient money in your account and try again.'
          // );
          showUnavailableError();
        } else showError(true);
      }

      if (currentStatus === 'PROCESSING') {
        successModal(null);
      }
    } catch (error) {
      // handle different statuses
      showError(false);
      console.log(error);
    }
  };

  const verifyLynkBalance = async () => {
    setIsFailure(true);
    setLoading(true);
    setErrorString('');
    const balance = await getWalletBalance('lynk');
    if (balance < data.total) {
      setErrorString(
        'You don\'t have enough money in your account to do this transaction. Please make sure there is sufficient money in your account and try again.'
      );
    } else {
      setIsFailure(false);
    }
    setLoading(false);
  };
  const [selectedAccount, setSelectedAccount] = useState('');
  useEffect(() => {
    if (!selectedAccount) return;

    setIsFailure(false);

    if (selectedAccount.includes('lynk')) {
      verifyLynkBalance();
    } else loadAccountDetail();
  }, [selectedAccount]);
  const selectedValue = async (val: any) => {
    const value = String(val.selectedValue);
    setSelectedAccount(value);
  };
  const loadAccountDetail = async () => {
    try {
      await getAccountDetail(Number.parseInt(selectedAccount));
    } catch (err) {
      setIsFailure(true);
      setErrorString(
        'We are having some technical issues recovering your Lynk wallet information. Please, try again later.'
      );
    }
  };

  const fromList = useMemo(() => {
    const generatedFromList: any[] = [];

    const getLynkAcct = accountDetails?.accounts?.find(acct => {
      return acct.type === 'LYNK';
    });
    generatedFromList.push({
      icon: 'lynk-only',
      label: 'Lynk Wallet',
      key: 'LYNK',
      itemKey: 'lynk',
      value: `lynk:${(getLynkAcct?.details as WalletDetailBase).account_id}`,
      currency: (getLynkAcct?.details as WalletDetailBase).currency
    });

    if (
      hasPermissions([{ resource: 'cashins', actions: new Set(['create']) }])
    ) {
      const bankAccounts =
        accountDetails?.accounts?.filter(acct => {
          return acct.type === 'BANK_ACCOUNT';
        }) || [];

      if (bankAccounts.length > 0) {
        bankAccounts.forEach(acct => {
          generatedFromList.push({
            icon: 'ncb-logo', //show based on bank type
            label: `Account ${
              (acct.details as BankAccountDetails).masked_bank_account
            }`,
            key: acct.type,
            itemKey: 'bank_account',
            value: `bank_acct:${(acct.details as BankAccountDetails).id}`,
            currency: 'JMD'
          });
        });
      }
    }

    return generatedFromList;
  }, []);
  const cancelAction = () => {
    dispatch(setPreviousModal({ ...modalDetails }));
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'confirm',
          subtitle:
            'Please confirm that you want to cancel this payment. This process cannot be undone.',
          title: 'Are you sure?',
          mainAction: {
            disabled: false,
            testID: 'transaction-status-main',
            onClickFn: () => {
              // dispatch(clearFormDetails());
              dispatch(hideModal());
            },
            text: 'Yes'
          },
          closeAction: {
            disabled: false,
            testID: 'transaction-status-main',
            onClickFn: () =>
              dispatch(
                showModal({
                  modalKey: modalDetails.modalKey || '',
                  data: modalDetails.data
                })
              ),
            text: 'Nevermind'
          }
        }
      })
    );
  };
  return (
    <LynkModal
      modalBody={
        <>
          <FormattedSelector
            disabled={batchPaymentFetching || loading}
            label={'From'}
            activeItem={selectedAccount}
            options={fromList}
            onSelect={val => {
              selectedValue(val);
              // if (!isTouched) setTouched(true);
            }}
            loading={batchPaymentFetching || fetching || loading || fetch}
            defaultSelectedItem={{
              label: 'Select an account',
              value: '',
              key: ''
            }}
          />
          {isFailure ? (
            <div style={{ color: 'red' }}>{errorString}</div>
          ) : undefined}
        </>
      }
      title={'Choose source of funds'}
      subTitle="Select the account you want to fund the transfer from"
      loading={false}
      show={true}
      closeAction={{
        testID: 'deleteStaffCancelBtn',
        text: 'Cancel',
        onClickFn: cancelAction
      }}
      mainAction={{
        loader: {
          text: 'Deleting user',
          icon: <></>
        },
        disabled:
          !selectedAccount ||
          fetch ||
          isFailure ||
          fetching ||
          loading ||
          batchPaymentFetching,
        testID: 'newUserMainActionTestId',
        onClickAsyncFn: sendPayment,
        text: `${'Send Payment'}`
      }}
    />
  );
};

export default ChooseFundSourceModal;
