import React, { useEffect, useState } from 'react';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import { FormGroup, Label } from 'reactstrap';
import './index.scss';
import { CheckboxGroupItemProps } from '../../../types/models/formType';

const CheckboxGroupItem: React.FC<CheckboxGroupItemProps> = ({
  isActive,
  label,
  onClick,
  classnames = ''
}) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  const getCheckboxIcon = (isActive: boolean) => {
    return isActive ? (
      <CheckIcon className="batch-Checkbox--active" />
    ) : (
      <CheckBoxOutlineBlankIcon className="batch-Checkbox--not-active" />
    );
  };

  return (
    <FormGroup
      check
      data-testid="checkGroupItem"
      className={`checkGroup-item ${classnames}`}
      onClick={() => onClick(!isChecked)}
    >
      <div className={`batch-Checkbox ${isActive ? 'active' : 'inactive'}`}>
        {getCheckboxIcon(isActive)}
      </div>
      <Label check for="exampleCheck">
        {label}
      </Label>
    </FormGroup>
  );
};

export default CheckboxGroupItem;
