import { TableFormatterProps } from '../../../types/models/tableType';
import React from 'react';
import { SorterProps } from '../../../types/models/utils/table-models';
import TableHeaderIcon from '../TableHeaderIcon';

const TableFormatterComponent: React.FC<TableFormatterProps> = ({
  columnHeaders,
  sorter
}) => {
  const getColumnSort = (
    column: string,
    sorter: SorterProps[]
  ): SorterProps | undefined =>
    sorter?.filter(sorterVal => sorterVal.column.includes(column))[0];

  return columnHeaders.length > 0 ? (
    <>
      {columnHeaders?.map((col: any) => {
        const columnSort = getColumnSort(col.field, sorter);

        return (
          <th key={col.field} className={col.field}>
            <div className="tableColumn">
              {col.label}
              {!!columnSort && (
                <TableHeaderIcon
                  iconType={columnSort.icon}
                  iconCallback={columnSort.sorter}
                />
              )}
            </div>
          </th>
        );
      })}
    </>
  ) : (
    <></>
  );
};

export default TableFormatterComponent;
