import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './index.scss';
import { VisibleEyeToggleProps } from '../../../types/models/DashboardWidgetType';

const VisibleEyeToggle: React.FC<VisibleEyeToggleProps> = ({
  classes,
  onClickFn,
  makeVisible
}) => {
  return (
    <button
      style={{ background: 'none', border: 'none' }}
      data-testid="toggleVisibleBtn"
      onClick={onClickFn}
      type="button"
      className={`${classes ?? ' eyeToggle'}`}
    >
      {makeVisible ? (
        <Visibility data-testid="visible" className="eyeToggle__visible" />
      ) : (
        <VisibilityOff data-testid="visibleOff" className="eyeToggle__hidden" />
      )}
    </button>
  );
};

export default VisibleEyeToggle;
