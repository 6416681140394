import { Permission } from '../../features/permissions';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import React from 'react';

export interface ProfileMenuItem {
  key: string;
  display: string;
  url?: string;

  icon: JSX.Element;
  active: boolean;
  permissions?: Permission[];
}

export const profileItems: ProfileMenuItem[] = [
  {
    key: 'profile',
    display: 'My Profile',
    url: '/profile',
    icon: <SupervisedUserCircleOutlinedIcon />,
    active: false,
    permissions: [{ resource: '*', actions: new Set(['*']) }]
  },
  {
    key: 'staff',
    display: 'Staff',
    url: './staff',
    icon: <SupervisedUserCircleOutlinedIcon />,
    active: true,
    permissions: [{ resource: 'staff', actions: new Set(['read']) }]
  },
  {
    key: 'customer-support',
    display: 'Customer Support',
    url: '/support',
    icon: <ContactSupportOutlinedIcon />,
    active: false,
    permissions: [{ resource: '*', actions: new Set(['*']) }]
  },
  {
    key: 'logout',
    display: 'Logout',
    icon: <LogoutIcon />,
    active: true
  }
];
