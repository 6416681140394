interface Env {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  WIDGET_VIEW_OVERRIDE: string;
  CASHIER_ROLE_ID: string;
  ADMIN_ROLE_ID: string;
  MANAGER_ROLE_ID: string;
}

const Env: Env = {
  ADMIN_ROLE_ID: process.env.REACT_APP_ROLE_ADMIN || '',
  MANAGER_ROLE_ID: process.env.REACT_APP_ROLE_MANAGER || '',
  CASHIER_ROLE_ID: process.env.REACT_APP_ROLE_CASHIER || '',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  WIDGET_VIEW_OVERRIDE: process.env.REACT_APP_WIDGET_OVERRIDE || ''
};

export default Env;
