import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

import React from 'react';
import { ToastQRConfig } from '../../../types/models/MerchantCodeType';

const toastConfigs: ToastQRConfig[] = [
  {
    status: 'success',
    icon: <CheckCircleOutline />,
    message: 'Merchant QR image generated successfully.'
  },
  {
    status: 'error',
    icon: <ErrorOutline />,
    message: 'Issue found during download, please try again.'
  }
];

export const getToast = (status: string) => {
  return toastConfigs.find(toast => toast.status === status);
};
