import { useAccountState } from '../../store/account/selector';
import { ApiServiceType } from '../../types/enums/apiServiceType';
import useGetData from '../api/useGetData';

export type WalletType = 'jam-dex' | 'lynk' | 'corporate';

const useGetBatchTransferLimit = (): {
  fetching: boolean;
  getBatchTransferLimit(walletType: WalletType): Promise<number>;
  getBatchTransferLimitBalance(walletType: WalletType): Promise<number>;
  period: number;
  transfer_type: string;
} => {
  const { isFetching, getData } = useGetData();
  const accountDetail = useAccountState();
  const period = 2;
  const transfer_type = 'MBATCH';

  const getWalletDetails = (walletType: WalletType) => {
    return accountDetail.accounts?.find(acct => {
      const map = {
        lynk: 'LYNK',
        'jam-dex': 'CBDC',
        corporate: ''
      };

      return acct.type == map[walletType];
    });
  };

  const getBatchTransferLimitBalance = async (): Promise<number> => {
    const accountDetail = getWalletDetails('lynk');
    try {
      const response = await getData({
        path: `/v1/accounts/${accountDetail?.id}/limits?period=${period}&transfer_type=${transfer_type}`,
        apiType: ApiServiceType.ACCOUNT
      });
      return response.data?.remaining;
    } catch (error) {
      return -1;
    }
  };

  const getBatchTransferLimit = async (): Promise<number> => {
    const accountDetail = getWalletDetails('lynk');
    const response = await getData({
      path: `/v1/accounts/${accountDetail?.id}/limits?period=${period}&transfer_type=${transfer_type}`,
      apiType: ApiServiceType.ACCOUNT
    });

    return response.data?.limit;
  };

  return {
    fetching: isFetching,
    getBatchTransferLimitBalance,
    getBatchTransferLimit,
    period,
    transfer_type
  };
};

export default useGetBatchTransferLimit;
