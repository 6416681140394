import {
  ColumHeaderInfo,
  CustomerColumHeaderInfo,
  PaymentDetailColumHeaderInfo,
  RefundColumHeaderInfo,
  StaffColumHeaderInfo
} from '../tableTransactions';

import { IconKey } from '../../../utils/iconMap';

export type SortType = 'asc' | 'desc';

export enum SortingAscDesc {
  DESC = 'desc',
  ASC = 'asc'
}

export type ColumnType =
  | StaffColumHeaderInfo[]
  | ColumHeaderInfo[]
  | PaymentDetailColumHeaderInfo[]
  | CustomerColumHeaderInfo[]
  | RefundColumHeaderInfo[];

export interface SorterProps {
  column: string[];
  icon: IconKey;
  sorter: () => void;
}

export type ParamsType = {
  offset: number;
  limit: number;
  sort: SortType;
  from_utc?: string;
  to_utc?: string;
};
