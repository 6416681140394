import React from 'react';
import './index.scss';

import MerchantTitle from '../../atoms/MerchantTitle';
import { JamDexQRCodeDownloadProps } from '../../../types/models/MerchantCodeType';
import { getIconMapper } from '../../../utils/iconMap';
import CBDCQRIcon from '../../atoms/CBDCQRIcon';

const CBDCQRCodeDownload: React.FC<JamDexQRCodeDownloadProps> = ({
  merchantName,
  merchantLynkID,
  qrCode
}) => {
  return (
    <div className="cbdcQRCodeDownloadContainer">
      <header>
        <div>
          <div className="lynkLogo">{getIconMapper('merchant-icon')}</div>
          <div className="merchantDetailGroup">
            <MerchantTitle
              merchantName={merchantName}
              loading={false}
              username={`Lynk ID: ${merchantLynkID}`}
              name={''}
            />
          </div>
        </div>
      </header>
      <section className="qrSection">
        <div>
          <CBDCQRIcon loading={false} alias={merchantName} qrCodeURL={qrCode} />
        </div>
      </section>
      <section className="footerDetails">
        <div>
          <div className="scanToPay">{getIconMapper('scan-to-pay-black')}</div>
          <div className="lynkLogo">{getIconMapper('jamdex-info')}</div>
        </div>
        <div className="downloadGrp">
          <div style={{ width: 80 }}>{getIconMapper('powered-by')}</div>
          <div>{getIconMapper('lynk-dark')}</div>
        </div>
      </section>
    </div>
  );
};

export default CBDCQRCodeDownload;
