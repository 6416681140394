export class TransferException extends Error {
  private code: string;
  errorMessage: string = '';
  cancelAction: boolean;

  constructor(msg: string, code: string, cancelAction = false) {
    super(msg);
    this.code = code;
    this.cancelAction = cancelAction;
  }

  public isCancelable(): boolean {
    return this.cancelAction;
  }
}

export class BalanceNotEnoughException extends TransferException {
  errorMessage =
    'You don\'t have enough money in your balance, please update the amount.';
  constructor(msg: string, code: string) {
    super(msg, code);
    Object.setPrototypeOf(this, BalanceNotEnoughException.prototype);
  }
}

export class TransferLimitExceedException extends TransferException {
  errorMessage = 'This action would exceeded your daily limit for this action.';
  constructor(msg: string, code: string) {
    super(msg, code);
    if (msg) this.errorMessage = msg;
    Object.setPrototypeOf(this, TransferLimitExceedException.prototype);
  }
}

export class GenericTransferException extends TransferException {
  errorMessage =
    'Oops! Something went wrong. Your transfer couldn’t be initiated, please try again.';
  constructor(msg: string, code: string) {
    super(msg, code, true);
    Object.setPrototypeOf(this, GenericTransferException.prototype);
  }
}
