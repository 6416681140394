const layoutTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const layoutWidthTypes = {
  FLUID: 'fluid',
  BOXED: 'boxed',
  SCROLLABLE: 'scrollable'
};

const topBarThemeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
  COLORED: 'colored'
};

const leftSidebarTypes = {
  DEFAULT: 'default',
  COMPACT: 'compact',
  ICON: 'icon'
};

const leftSideBarThemeTypes = {
  LIGHT: 'light',
  COLORED: 'colored',
  DARK: 'dark'
};

export {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes
};
