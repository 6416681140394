/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux';
import { put, all, fork, takeEvery } from 'redux-saga/effects';
import {
  addAlertMetadata,
  appStatusSlice,
  clearScope,
  popAlertMetadata
} from '.';

function* pushAlert({ payload }: AnyAction) {
  try {
    const alertPayload = {
      key: payload.key,
      alertType: payload.alertType,
      alertDetails: payload.payload,
      scope: payload.scope
    };
    yield put(addAlertMetadata(alertPayload)); //call(setCurrentBreakpoint,payload);
  } catch (error) {
    console.log(error);
  }
}

function* popAlert({ payload }: AnyAction) {
  try {
    yield put(popAlertMetadata(payload)); //call(setCurrentBreakpoint,payload);
  } catch (error) {
    console.log(error);
  }
}

function* popScope({ payload }: AnyAction) {
  try {
    yield put(clearScope(payload)); //call(setCurrentBreakpoint,payload);
  } catch (error) {
    console.log(error);
  }
}

export function* pushAlertAction() {
  yield takeEvery(appStatusSlice.actions.pushAlert.toString(), pushAlert);
}

export function* popAlertAction() {
  yield takeEvery(appStatusSlice.actions.popAlert.toString(), popAlert);
}

export function* popScopeAction() {
  yield takeEvery(appStatusSlice.actions.popAlert.toString(), popScope);
}

function* AppStatusDetailsSaga() {
  yield all([fork(pushAlertAction), fork(popAlertAction)]);
}

export default AppStatusDetailsSaga;
