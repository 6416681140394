import { ToastMapper } from '../../../types/models/toastType';
import { CheckCircleOutline, Close, ErrorOutline } from '@mui/icons-material';

import React from 'react';

export const toasterResponses: ToastMapper = {
  qrcode_copied: {
    icon: <CheckCircleOutline />,
    message: 'Your QR Code link was copied',
    type: 'success'
  },
  maxTransactionExport_issue: {
    icon: <ErrorOutline />,
    message: (
      <>
        You can only export up to <span className="bold">100 entries</span>.
        Apply filters to reduce the number of entries.
      </>
    ),
    type: 'info'
  },
  refund_return_to_sender: {
    type: 'info',
    icon: <ErrorOutline />,
    message:
      'The recipient was not able to get the funds and the monies has been returned to the sender'
  },
  refund_request_issued_successfully: {
    type: 'success',
    icon: <CheckCircleOutline />,
    message: 'You’ve successfully issued your refund.'
  },
  refund_error: {
    type: 'error',
    icon: <ErrorOutline />,
    message: (
      <>
        Oops! Something went wrong.
        <span className="bold">Want to try again?</span>
      </>
    )
  }
};
