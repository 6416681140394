import './index.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ReactComponent as NotificationBell } from '../../../assets/svg/notification-bell.svg';
import { ReactComponent as Settings } from '../../../assets/svg/settings.svg';
import useGetNotifications from '../../../hooks/notifications/useGetNotifications';
import useUpdateNotification from '../../../hooks/notifications/useUpdateNotification';

const Notifications = () => {
  const settingsSpace = {
    marginLeft: '8px'
  };

  const { getNotifications, getUnreadNotifCount } = useGetNotifications();
  const { updateNotification } = useUpdateNotification();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [read, setRead] = useState('');
  const [notifications, setNotifications] = useState([]);

  const getNotifs = async () => {
    const notifs = await getNotifications();
    setNotifications(notifs.data.result);
  };

  const getUnreadCount = async () => {
    const unread = await getUnreadNotifCount();
    setUnreadCount(unread);
  };

  const markAsRead = async (id: any) => {
    try {
      const response = await updateNotification(id);
      console.log(response);
      setRead(id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getUnreadCount();
    }, 120000);

    return () => clearInterval(interval);
  }, [unreadCount]);

  useEffect(() => {
    getNotifs();
    getUnreadCount();
    if (read) markAsRead(read);
  }, [unreadCount, read]);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <div className="notifications">
        <div className={dropdownOpen ? 'notifications__backdrop' : ''}></div>
        <DropdownToggle className="notifications__btn">
          <NotificationBell />
          {unreadCount > 0 ? (
            <div className="notifications__indicator"> +{unreadCount} </div>
          ) : undefined}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="notifications__menu">
            <div className="notifications__header">
              <p className="notifications__title"> Notifications</p>
              <Link
                to="./notifications/settings"
                className="notifications__settings"
                onClick={() => setDropdownOpen(false)}
              >
                Settings
                <Settings style={settingsSpace} />
              </Link>
            </div>
            {notifications?.map(function (menuItem) {
              return (
                <div
                  key={menuItem['id']}
                  className={
                    menuItem['read']
                      ? 'notifications__dropdown--item'
                      : 'notifications__dropdown--unread'
                  }
                  onClick={() => markAsRead(menuItem['id'])}
                >
                  <div
                    className={`notifications__dropdown--inner ${
                      !menuItem['read'] && 'active'
                    }`}
                  >
                    <div className="notifications__dropdown--headline">
                      <div className="notifications__dropdown--headline-title">
                        {menuItem['title']}
                      </div>
                      <div className="notifications__dropdown--headline-time">
                        {moment(menuItem['created']).fromNow()}
                      </div>
                    </div>
                    <div className="notifications__dropdown--notif-body">
                      {menuItem['body']}
                    </div>
                  </div>
                </div>
              );
            })}
            <Link
              to="./notifications"
              className="notifications__view"
              onClick={() => setDropdownOpen(false)}
            >
              View All
            </Link>
          </div>
        </DropdownMenu>
      </div>
    </Dropdown>
  );
};

export default Notifications;
