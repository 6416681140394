import { ApiServiceType } from '../../types/enums/apiServiceType';
import { STAFF_URL } from '../../helpers/url_helper';
import { NewUserDetail } from './useCreateStaff';
import useGetData from '../../hooks/api/useGetData';

interface EditUserDetail extends NewUserDetail {
  auth_id: string;
}

const useGetStaff = (): {
  fetching: boolean;
  getStaffDetails(id: string): Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();

  const getStaffDetails = async (id: string): Promise<any> => {
    try {
      const response = await getData({
        path: `v3/staff/${id}`,
        apiType: ApiServiceType.PROFILE,
        controller: abortController
      });
      return response.data;
    } catch (error) {
      return {};
    }
  };

  return { fetching: isFetching, getStaffDetails, abortController };
};
export default useGetStaff;
