/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import DoubleInput from '../../molecules/DoubleInput';
import { createDate, validateDate } from '../../../utils/dateFormatter';
import { formatDate } from '../../../utils/dateFormatter';
import { isValidators } from './validators';
import moment from 'moment';
import { useGetScreenState } from '../../../store/screenSize/selector';
import { SecondDateRangePickerProps } from '../../../types/models/datePickerType';

const DateRangePicker: React.FC<SecondDateRangePickerProps> = ({
  maxDateLimit = {
    notExceedFutureDate: false
  },
  openTo,
  autoFlipWhenInverse = false,
  isValidOverall,
  feedback,
  dateRange,
  dateFormat = 'dd/MM/yyyy',
  placeholder = 'DD-MM-YYYY',
  handleChange,
  validators = ['startGreaterThanEnd', 'bothDatesMustNotBeBlank'],
  additionalValidators = []
}) => {
  const [startDStr, setStartDStr] = useState<string | undefined>(undefined);
  const [endDStr, setEndDStr] = useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const [activeCurrentInput, setActiveCurrentInput] = useState<
    'start' | 'end'
  >();

  const { breakpoint, windowWidth } = useGetScreenState();

  useEffect(() => {
    setStartDStr(formatDate(dateRange.start.date, dateFormat.toUpperCase()));
    setEndDStr(formatDate(dateRange.end.date, dateFormat.toUpperCase()));
  }, [dateRange]);

  const setStartDateValue = (dateVal: string) => {
    try {
      const dateValid = validateDate(dateVal, dateFormat);
      if (!dateValid) throw new Error();

      onChange([moment(dateVal, dateFormat).toDate(), null]);
    } catch (err) {
      onChange([null, null]);
    }
  };

  const setEndDateValue = (dateVal: string) => {
    try {
      const date = createDate(dateVal, dateFormat);
      if (!date) throw new Error();

      onChange([dateRange.start.date as Date, date]);
    } catch (err) {
      onChange([dateRange.start.date as Date, null]);
    }
  };

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    let newStart = start || undefined;
    let newEnd = end || undefined;

    const startMoment = moment(start, dateFormat.toUpperCase(), true);
    const endMoment = moment(end, dateFormat.toUpperCase(), true);

    // -- implement a autoflip
    if (
      autoFlipWhenInverse &&
      newStart &&
      newEnd &&
      startMoment.isAfter(endMoment)
    ) {
      const oldStart = newStart;
      newStart = newEnd;
      newEnd = oldStart;
    }

    const isInValidKeys = isValidators(validators, newStart, newEnd);

    if (newStart !== null) setStartDStr(formatDate(newStart, dateFormat));
    if (newEnd !== null) setEndDStr(formatDate(newEnd, dateFormat));

    handleChange(newStart, newEnd, isInValidKeys.length === 0, isInValidKeys);
    if (breakpoint === 'smartphone') setIsOpen(false);
  };

  const maxDate = maxDateLimit.date || new Date();

  return (
    <>
      <div className="datePickerContainer">
        <div
          data-testid="datePickerContainer__inner"
          className={`datePickerContainer__inner datePickerContainer__inner${
            isValidOverall ? '--valid' : '--invalid'
          }`}
        >
          <DatePicker
            wrapperClassName={`mode-${activeCurrentInput}`}
            calendarClassName={`mode-${activeCurrentInput}`}
            popperClassName={`mode-${activeCurrentInput}`}
            className={`${activeCurrentInput}`}
            shouldCloseOnSelect={true}
            maxDate={maxDateLimit.notExceedFutureDate ? maxDate : undefined}
            disabled={false}
            onChange={onChange}
            openToDate={dateRange.end.date || new Date()}
            open={isOpen}
            onClickOutside={() => setIsOpen(false)}
            onInputClick={() => setIsOpen(!isOpen)}
            onCalendarClose={() => setIsOpen(false)}
            startDate={dateRange.start.date}
            endDate={dateRange.end.date}
            selectsRange
            monthsShown={breakpoint === 'smartphone' ? 1 : 2}
            dateFormat={dateFormat}
            placeholderText={placeholder}
            customInput={
              <DoubleInput
                dateFormat={dateFormat.toUpperCase()}
                valid={isValidOverall}
                dateRange={{
                  start: {
                    date: startDStr || '',
                    label: dateRange.start.label
                  },
                  end: {
                    date: endDStr || '',
                    label: dateRange.end.label
                  }
                }}
                togglePicker={() => setIsOpen(!isOpen)}
                clearInput={(state: 'start' | 'end') => {
                  if (state === 'start') setStartDateValue('');
                  if (state === 'end') setEndDateValue('');
                }}
                setStart={setStartDateValue}
                setEnd={setEndDateValue}
                activeInput={setActiveCurrentInput}
              />
            }
          />
        </div>
      </div>
      <>
        {!!feedback && (
          <div
            data-testid="feedbackContainer"
            className={`feedback--${feedback.type} ${feedback.type}`}
          >
            {feedback.message}
          </div>
        )}
      </>
    </>
  );
};

export default DateRangePicker;
