import ActionButton from '../../atoms/ActionButton';
import ButtonTooltip from '../../atoms/ButtonTooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { LaunchOutlined } from '@mui/icons-material';
import BaseColFormatter from '../../atoms/BaseColumnFormatter';
import './index.scss';

import { ColumnBranchProps } from '../../../types/models/tableTransactions';
import BalanceColFormatter from '../../atoms/BalanceColFormatter';
import useFormatBranchAccounts from '../../../hooks/utils/useFormatBranchAccounts';
import useGetWalletBalance from '../../../hooks/merchant_portal/useGetWalletBalance';
import { currencyFormat } from '../../../utils/currency';
import { Progress } from 'reactstrap';
import useGetCashInOutLimits from '../../../hooks/account/useGetCashInOutLimits';
import { useDispatch } from 'react-redux';
import {
  setParentID,
  addBalance,
  addJAMDEXBalance
} from '../../../store/organization';
import { useNavigate } from 'react-router-dom';
interface ButtonColProps {
  row: any;
}
const ButtonColFormatter: React.FC<ButtonColProps> = ({ row }) => {
  const Btn = (
    <a
      onClick={() => {
        window.location.href = `/${row.organization_id.slice(4)}`;
      }}
    >
      <ActionButton
        type="button"
        classnames="launch-button"
        text={''}
        data-testid="launchButton"
        rightIcon={<LaunchOutlined />}
        testId="launchButton"
      />
    </a>
  );

  const showButton = true;

  return <>{showButton ? Btn : <></>}</>;
};

const BranchColumnFormatter: React.FC<ColumnBranchProps> = ({
  column,
  row,
  action,
  accounts
}) => {
  const dispatch = useDispatch();

  const [balance, setBalance] = useState(0);
  const [jamdexBalance, setJamdexBalance] = useState(0);
  const [tierLimit, setTierLimit] = useState({
    remaining: 0,
    limit: 0
  });
  const { getAccountBalance } = useGetWalletBalance();
  const { getMainLimitBalance } = useGetCashInOutLimits();

  const rowLynkAccount = useMemo(() => {
    return row.accounts.find((account: any) => account.type === 'LYNK');
  }, [row]);

  const rowJAMDEXAccount = useMemo(() => {
    return row.accounts.find((account: any) => account.type === 'CBDC');
  }, [row]);

  const getJAMDEXBalance = async () => {
    try {
      const response = await getAccountBalance(rowJAMDEXAccount!.id.toString());
      setJamdexBalance(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getBalance = async () => {
    try {
      const response = await getAccountBalance(rowLynkAccount!.id.toString());
      setBalance(response);

      getJAMDEXBalance();
    } catch (error) {
      setBalance(0);
    }
  };

  const getTransferType = (): string => {
    switch (column.key) {
      case 'Cash In Limit':
        return 'TOP';
      case 'Cash Out Limit':
        return 'ACH_CASHOUT';
      case 'Payments Limit':
        return 'MBATCH';
      default:
        return 'MBATCH';
    }
  };

  const getTierBalance = async () => {
    if (rowLynkAccount) {
      const response = await getMainLimitBalance(
        rowLynkAccount.id.toString(),
        getTransferType()
      );
      setTierLimit(response);
    }
  };
  useEffect(() => {
    if (column.key === 'Lynk Balance') {
      getBalance();
    }
    if (column.key.includes('Limit')) {
      getTierBalance();
    }
  }, [column, row]);

  useEffect(() => {
    dispatch(addBalance(balance));
  }, [balance]);

  useEffect(() => {
    dispatch(addJAMDEXBalance(jamdexBalance));
  }, [jamdexBalance]);

  const calculatePercentage = () => {
    return 100 - Math.trunc((tierLimit.remaining / tierLimit.limit) * 100);
  };
  const progressBarColor = () => {
    if (calculatePercentage() >= 0 && calculatePercentage() <= 49) {
      return 'success';
    } else if (calculatePercentage() >= 50 && calculatePercentage() <= 74) {
      return 'warning';
    } else if (calculatePercentage() >= 75 && calculatePercentage() <= 100) {
      return 'danger';
    }
  };

  let ColumnData = <BaseColFormatter column={column} row={row} />;
  if (column.button) ColumnData = <ButtonColFormatter row={row} />;
  if (column.key === 'Lynk Balance')
    ColumnData = <>{currencyFormat(balance)} JMD</>;
  if (column.key.includes('Limit'))
    ColumnData = (
      <>
        <div className="calculatePorcentage">
          <span>{calculatePercentage()}%</span>
        </div>

        <Progress
          value={calculatePercentage()}
          className="percentage"
          color={progressBarColor()}
        />
      </>
    );
  return <>{ColumnData}</>;
};

export default BranchColumnFormatter;
