import React from 'react';
import './index.scss';

interface CustomSwitchProps {
  activeColour?: string;
  checked: boolean;
  disabled: boolean;
  id?: string;
  handleToggle: (val: 'yes' | 'no') => void;
}

const Switch: React.FC<CustomSwitchProps> = ({
  activeColour = '#06D6A0',
  checked,
  disabled,
  handleToggle,
  id = 'react-switch-new'
}) => {
  return (
    <>
      <input
        checked={checked}
        onChange={event => handleToggle(event.target.checked ? 'yes' : 'no')}
        className={`react-switch-checkbox ${
          disabled && 'react-switch-checkbox--disabled'
        }`}
        id={id}
        type="checkbox"
        disabled={disabled}
        style={{ pointerEvents: disabled ? 'none' : 'all' }}
      />
      <label
        style={{ background: checked ? activeColour : 'transparent' }}
        className={`react-switch-label ${checked && '--checked'} ${
          disabled && '--disabled'
        }`}
        htmlFor={id}
      >
        <span className={'react-switch-button'} />
      </label>
    </>
  );
};

export default Switch;
