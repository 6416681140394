import './index.scss';

import { Button } from 'reactstrap';
import ButtonAsyncLoader from '../ButtonAsyncLoader';
import React from 'react';
import { ActionButtonProps } from '../../../types/models/ButtonsTypes';

const ActionButton: React.FC<ActionButtonProps> = ({
  id = 'actionButton',
  btnType = 'button',
  type,
  text,
  block = false,
  onPress,
  onPressAsync,
  classnames,
  disabled = false,
  loading = false,
  testId = 'actionButton',
  leftIcon,
  rightIcon,
  showLoadingText = true,
  loadingText,
  loaderIcon
}) => {
  const btnPress = async () => {
    if (onPressAsync) await onPressAsync();
    else if (onPress) onPress();
  };

  return (
    <Button
      id={id}
      type={btnType}
      disabled={disabled || loading}
      className={`${classnames || ''} ${loading ? 'loader' : ''}`}
      data-testid={testId}
      onClick={btnPress}
      color={type}
      block={block}
    >
      {loading && (
        <ButtonAsyncLoader
          showLoaderText={showLoadingText}
          loadingText={loadingText}
          loadingIcon={loaderIcon}
        />
      )}
      {!loading && (
        <div data-testid="btnText" className="d-flex btnText">
          {!!leftIcon && <div>{leftIcon}</div>}
          {!!text && (
            <div
              style={{ position: 'relative' }}
              className={`btnText__text${leftIcon ? ' leftIcon' : ''}${
                rightIcon ? ' rightIcon' : ''
              }`}
            >
              {text}
            </div>
          )}
          {!!rightIcon && <div>{rightIcon}</div>}
        </div>
      )}
    </Button>
  );
};

export default ActionButton;
