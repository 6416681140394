import { getIconMapper } from '../../../utils/iconMap';
import React from 'react';
import './index.scss';

const TableEmptyStatusView: React.FC = () => {
  return (
    <>
      <div className="tableEmptyStatusView">
        <div
          className="tableEmptyStatusView__header"
          data-test-id="tableEmptyStatusView__header"
          id="statusHeader"
        >
          We&apos;re coming up empty
        </div>
        <div
          className="tableEmptyStatusView_subheader"
          data-test-id="tableEmptyStatusView_subheader"
          id="statusHeader"
        >
          Sorry, we have not found anything to return at this point.
        </div>
        <div></div>
        {getIconMapper('issue-status', 'tableEmptyStatusView__icon')}
      </div>
    </>
  );
};

export default TableEmptyStatusView;
