import { WalletType } from '../../hooks/merchant_portal/useGetWalletBalance';
import { createSlice } from '@reduxjs/toolkit';

export interface WalletState {
  currentWallet: WalletType;
}

const initialState: WalletState = {
  currentWallet: 'lynk'
};

export const walletManagementSlice = createSlice({
  name: 'walletManagement',
  initialState,
  reducers: {
    updateCurrentWallet: (state, action) => {
      state.currentWallet = action.payload;
    }
  }
});

export const { updateCurrentWallet } = walletManagementSlice.actions;

export default walletManagementSlice.reducer;
