import { useDispatch } from 'react-redux';
import LynkModal from '../../../../atoms/LynkModal';
import React, { useState } from 'react';
import { useModalState } from '../../../../../store/modals/modal/selector';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { hideModal, showModal } from '../../../../../store/actions';
import TextInput from '../../../../atoms/TextInput';

import usePostBatchPaymentProcess from '../../../../../hooks/batchPayments/usePostBatchPaymentProcess';
import usePostSinglePaymentProcess from '../../../../../hooks/singlePayments/usePostSinglePaymentProcess';

const RejectPayment: React.FC = () => {
  const dispatch = useDispatch();
  const { requestRejectBatchPayment, batchPaymentFetching } =
    usePostBatchPaymentProcess();
  const { requestRejectSinglePayment, isSinglePaymentFetching } =
    usePostSinglePaymentProcess();
  const { data, isSPayment } = useModalState();
  const [rejectionReason, setRejectionReason] = useState('');

  const cancelAction = () => {
    dispatch(hideModal());
  };
  const successModal = () => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: 'success',
          subtitle: (
            <>
              <p style={{ marginBottom: '-15px' }}>Your payment was rejected</p>
            </>
          ),
          title: 'Payment Rejected'
        }
      })
    );
  };
  const rejectBatch = async () => {
    const response = await requestRejectBatchPayment(data, rejectionReason);
    if (response) {
      successModal();
    } else {
      console.log('error');
    }
  };

  const rejectSinglePayment = async () => {
    const response = await requestRejectSinglePayment(data, rejectionReason);
    if (response) {
      successModal();
    } else {
      console.log('error');
    }
  };

  return (
    <LynkModal
      title={`Reject batch ID ${data ? data.id : ''}`}
      subTitle="Please provide a reason for rejecting the payment"
      loading={false}
      show={true}
      modalBody={
        <>
          <TextInput
            id="note"
            type="textarea"
            label="Reason for batch rejection (optional)"
            value={rejectionReason}
            style={{ height: 116 }}
            onChange={event => setRejectionReason(event.target.value)}
            invalid={rejectionReason.length > 45}
            classNames="paymentRequestForm__noteInput"
            placeholder="Leave a note stating why you are rejecting the payment."
            feedback={`${rejectionReason.length}/45 characters`}
          />
        </>
      }
      mainAction={{
        loader: {
          text: 'Loading',
          icon: <AutorenewIcon />
        },
        disabled:
          rejectionReason.length > 45 ||
          batchPaymentFetching ||
          isSinglePaymentFetching,
        testID: 'uploadBatchPayment',
        onClickAsyncFn: isSPayment ? rejectSinglePayment : rejectBatch,
        text: 'Reject'
      }}
      closeAction={{
        text: 'Cancel',
        showButton: true,
        onClickFn: cancelAction,
        testID: 'uploadBatchCancelButton'
      }}
      //TODO: add base button as prop
    />
  );
};

export default RejectPayment;
