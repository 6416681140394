import './index.scss';

import { DateOrEmpty } from '../../../types/models/dateTypes';
import React, { useState } from 'react';

import ActionButton from '../../atoms/ActionButton';
import DateRangePicker from '../../organisms/DateRangePicker';
import FilterListOutlined from '@mui/icons-material/FilterListOutlined';
import FilterListingItem from '../../atoms/FilterListingItem';
import LynkModal from '../../atoms/LynkModal';
import SectionView from '../../atoms/SectionView';
import { formatDate } from '../../../utils/dateFormatter';
import useDateFilterButton from '../../../hooks/utils/useDateFilterButton';
import { FilterButtonProps } from '../../../types/models/filtersType';

const FilterButton: React.FC<FilterButtonProps> = ({
  isActive,
  testId = 'filterButton',
  testIdRange = 'filterRange',
  filterRange,
  setFilterRange
}) => {
  const [isValid, setValid] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { date, endDate, handleChange } = useDateFilterButton();

  const togglePopUp = () => {
    setShowFilterModal(!showFilterModal);
  };

  const handler = (
    start: Date | undefined,
    end: Date | undefined,
    status: boolean
  ) => {
    handleChange(start, end);
    setValid(status);
    // setErrors(errorList);
  };

  function handleChangeFilter(
    startDate: Date | undefined,
    endDate: Date | undefined
  ) {
    if (setFilterRange) {
      setFilterRange({
        startRange: startDate,
        endRange: endDate
      });
    }
  }

  const dateFormatter = (date: Date) => {
    return formatDate(date, 'DD/MM/YYYY');
  };

  const filterDateString = (start: Date, end: Date) => {
    return `Date: "${dateFormatter(start)} to ${dateFormatter(end)}"`;
  };

  return (
    <>
      <div className="d-md-flex align-items-center filterOptionGroup">
        <ActionButton
          disabled={!isActive}
          classnames="filterOptionGroup__item--btn"
          onPress={togglePopUp}
          type="primary"
          text={'Filter by'}
          testId={testId}
          rightIcon={<FilterListOutlined />}
        />
        {filterRange && filterRange.startRange && filterRange.endRange && (
          <div className="filterListing">
            <FilterListingItem
              testId={testIdRange}
              onClickFn={() => handleChangeFilter(undefined, undefined)}
              filterListContent={filterDateString(
                filterRange.startRange,
                filterRange.endRange
              )}
            />
          </div>
        )}
      </div>
      <LynkModal
        testID="filterModal"
        modalBody={
          <SectionView
            title={'Date range'}
            body={
              <div className="toggleSection-content">
                <DateRangePicker
                  autoFlipWhenInverse
                  dateFormat="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                  isValidOverall={isValid}
                  dateRange={{
                    start: {
                      date: date,
                      label: 'Start Date'
                    },
                    end: {
                      date: endDate as Date,
                      label: 'End Date'
                    }
                  }}
                  handleChange={handler}
                  validators={[
                    'bothDatesMustNotBeBlank',
                    'startGreaterThanEnd'
                  ]}
                />
              </div>
            }
          />
        }
        title="Filters"
        show={showFilterModal}
        closeAction={{
          testID: 'filterModalCancelBtn',
          onClickFn: togglePopUp,
          text: 'Close'
        }}
        mainAction={{
          testID: 'filterModalActionBtn',
          onClickFn: () => {
            handleChangeFilter(date, endDate);
            togglePopUp();
          },
          disabled: !isValid,
          text: 'Apply'
        }}
        loading={false}
      />
    </>
  );
};

export default FilterButton;
