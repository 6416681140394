import './index.scss';
import React from 'react';
import { ReactComponent as ComputerIcon } from '../../../assets/svg/Computer.svg';

const AccessDenied = () => {
  return (
    <div className="accessDenied--wrapper">
      <div className="accessDenied--container">
        <ComputerIcon className="computer-icon" />
        <div className="accessDenied--headerText"> Access Denied </div>
        <div className="accessDenied--text">
          {' '}
          You don’t have permissions to view this page.{' '}
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
