/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, fork } from 'redux-saga/effects';

import LayoutSaga from './layout/saga';
import ScreenDetailsSaga from './screenSize/saga';
import AppStatusDetailsSaga from './status/saga';

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(ScreenDetailsSaga),
    fork(AppStatusDetailsSaga)
  ]);
}
