/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  StaffColumHeaderInfo,
  StaffDetailType
} from '../../../types/models/tableTransactions';
import ActionButton from '../../atoms/ActionButton';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import BaseColFormatter from '../../atoms/BaseColumnFormatter';
import TableCard from '../../atoms/TableCard';
import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import AddIcon from '@mui/icons-material/Add';
import { showModal } from '../../../store/actions';
import useDownloadCompAsImage from '../../../hooks/utils/useDownloadCompAsImage';
import QRCodeDownload from '../../organisms/QRCodeDownload';
import { useGetQRCodeDetail } from '../../../store/organization/selector';
import { getToast } from '../../molecules/DownloadMerchantQRWidget/downloadToast';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditDeleteGroup from '../../atoms/EditDeleteGroup';
import { useDispatch } from 'react-redux';
import useGetMerchantDetails from '../../../hooks/merchant/useGetMerchantDetails';
import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import { useGetScreenState } from '../../../store/screenSize/selector';
import { useGetProfileState } from '../../../store/profile/selector';
import { usePermissions } from '../../../features/permissions';
import { useLoadStaffRoleCache } from '../../../store/data/selector';

import { getRoleDescriptionByID } from '../../organisms/modals/main/NewEditUserModal/models';
import { RoleId, Role } from '../../../types/enums/roleType';
import { useLocation } from 'react-router-dom';

const StaffTable: React.FC = () => {
  const dispatch = useDispatch();

  const [currentStaff, setCurrentStaff] = useState('');
  const [currentStaffID, setCurrentStaffID] = useState('');
  const [currentStaffQR, setCurrentStaffQR] = useState('');

  const qrDetails = useGetQRCodeDetail();
  const { getMerchantStaffQR } = useGetMerchantDetails();

  const ref: any = useRef();
  const { downloadElementAsImage } = useDownloadCompAsImage();
  const { breakpoint } = useGetScreenState();

  const { role, merchant_id, authID } = useGetProfileState();
  const [pageLimit, setPageLimit] = useState(25);
  const { hasPermissions } = usePermissions();

  const staffRoleCache = useLoadStaffRoleCache();

  const location = useLocation();

  const isCorpStaffPage = useMemo(
    () => location.pathname.split('/')[1].includes('staff'),
    [location.pathname]
  );

  useEffect(() => {
    setPageLimit(breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpoint]);

  const addUser = () => {
    dispatch(
      showModal({
        modalKey: 'register-user',
        title: 'Register New User'
      })
    );
  };

  const editUser = (user: StaffDetailType) => {
    dispatch(
      showModal({
        modalKey: 'register-user',
        title: 'Edit User',
        data: user
      })
    );
  };

  const deleteUser = (user: StaffDetailType) => {
    dispatch(
      showModal({
        modalKey: 'delete-staff',
        title: 'Please confirm',
        data: user
      })
    );
  };

  const downloadStaffQRCode = async (user: StaffDetailType) => {
    let qr = qrDetails.qr_image;
    let currDisplay = qrDetails.name;
    if (user.role?.toLowerCase() === 'cashier') {
      const response = await getMerchantStaffQR(
        user.auth_id,
        user.display_name
      );
      qr = `data:image/png;base64,${response.data}`;
      if (user.show_qr_display_name) currDisplay = user.display_name;
    }

    setCurrentStaffID(user.auth_id);
    setCurrentStaff(currDisplay);
    setCurrentStaffQR(qr);
  };

  useEffect(() => {
    if (currentStaff !== '' && currentStaffQR !== '' && currentStaffID !== '') {
      downloadQRSnapshot();
    }
  }, [currentStaff, currentStaffQR, currentStaffID]);

  const staffIsNotSuperAdmin = (staffId: number) => {
    return staffId !== RoleId.SUPER_ADMIN;
  };

  const userIsSuperAdmin = () => role === Role.SUPER_ADMIN;

  const StaffColFormatterFn = (row: any, column: StaffColumHeaderInfo) => {
    let ColumnData = <BaseColFormatter column={column} row={row} />;

    if (
      column.field === 'action' &&
      column.button &&
      staffIsNotSuperAdmin(row.merchants[0].role_id)
    ) {
      ColumnData = (
        <ActionButton
          loading={
            currentStaffID === row.auth_id && !!currentStaff && !!currentStaffQR
          }
          loadingText="Downloading User QR Code"
          type={'outline-secondary'}
          text={'Download QR Code'}
          rightIcon={<DownloadOutlinedIcon />}
          onPressAsync={() => downloadStaffQRCode(row)}
        />
      );
    } else if (column.field === 'action' && column.label === 'Action') {
      ColumnData = (
        <EditDeleteGroup
          showEdit={
            hasPermissions([
              {
                resource: 'staff',
                actions: new Set(['update'])
              }
            ]) &&
            (isCorpStaffPage ||
              (staffIsNotSuperAdmin(row.merchants[0].role_id) &&
                !isCorpStaffPage))
          }
          miniView={true}
          showDelete={
            authID !== row.auth_id &&
            hasPermissions([
              {
                resource: 'staff',
                actions: new Set(['delete'])
              }
            ]) &&
            (isCorpStaffPage ||
              (staffIsNotSuperAdmin(row.merchants[0].role_id) &&
                !isCorpStaffPage))
          }
          editCallback={() => editUser(row)}
          deleteCallback={() => deleteUser(row)}
        />
      );
    }
    return ColumnData;
  };

  //Gets the role value from the merchants array if the merchant_id matches the merchant_id on profile
  const getStaffRole = (row: any) => {
    const currentMerchant = row.merchants.filter((merchant: any) => {
      return merchant.merchant_id === merchant_id;
    });
    return currentMerchant[0].role_id.toString();
  };

  const getStaffStatus = (row: any) => {
    const currentMerchant = row.merchants.filter((merchant: any) => {
      return merchant.merchant_id === merchant_id;
    });
    return currentMerchant[0].active;
  };

  const ColumnStructure = (
    transactions: any[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    const formattedTrans = transactions.map((row: StaffDetailType) => {
      return {
        ...row,
        role: getRoleDescriptionByID(getStaffRole(row), staffRoleCache),
        active: getStaffStatus(row)
      };
    });

    return formattedTrans.map((row: any) => (
      <tr key={`${row.display_name}__${row.email}`}>
        {columnHeaders.map(col => (
          <td
            key={`${row.display_name}-${col.field}`}
            className={`${col.label}`}
          >
            {StaffColFormatterFn(row, col)}
          </td>
        ))}
      </tr>
    ));
  };

  const moreInfoCallback = (data: any) => {
    const dataMap = {
      details: [
        {
          label: 'Name',
          value: data.display_name
        },
        {
          label: 'ROLE',
          value: getRoleDescriptionByID(getStaffRole(data), staffRoleCache)
        },
        {
          label: 'Status',
          value: getStaffStatus(data) ? 'Active' : 'Inactive'
        }
      ],
      footer: (
        <>
          <div style={{ marginBottom: 5 }}>
            <EditDeleteGroup
              showEdit={
                hasPermissions([
                  {
                    resource: 'staff',
                    actions: new Set(['update'])
                  }
                ]) &&
                (staffIsNotSuperAdmin(data.merchants[0].role_id) ||
                  userIsSuperAdmin())
              }
              miniView={false}
              showDelete={
                authID !== data.auth_id &&
                hasPermissions([
                  {
                    resource: 'staff',
                    actions: new Set(['delete'])
                  }
                ]) &&
                (staffIsNotSuperAdmin(data.merchants[0].role_id) ||
                  userIsSuperAdmin())
              }
              editCallback={() => editUser(data)}
              deleteCallback={() => deleteUser(data)}
            />
          </div>
          {staffIsNotSuperAdmin(data.merchants[0].role_id) && (
            <ActionButton
              block
              type={'outline-secondary'}
              text={'Download QR Code'}
              rightIcon={<DownloadOutlinedIcon />}
              onPressAsync={() => downloadStaffQRCode(data)}
            />
          )}
        </>
      )
    };

    dispatch(
      showModal({
        modalKey: 'more-trans-details',
        data: dataMap,
        title: 'User'
      })
    );
  };

  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: StaffDetailType) => (
      <TableCard
        key={row.display_name}
        title={'Staff'}
        mainTitle={row.display_name || ''}
        subTitle={getRoleDescriptionByID(getStaffRole(row), staffRoleCache)}
        data={
          <>
            Status:&nbsp;
            <span style={{ color: 'red' }}>
              {getStaffStatus(row) ? 'Active' : 'Inactive'}
            </span>
          </>
        }
        moreInfoCallback={() => moreInfoCallback(row)}
      />
    ));
  };

  const generateTableHeader = () => {
    const doesHavePermission = hasPermissions([
      {
        resource: 'staff',
        actions: new Set(['create'])
      }
    ]);

    return doesHavePermission ? (
      <div>
        <ActionButton
          type={'primary'}
          text={'Add User'}
          leftIcon={<AddIcon />}
          onPress={addUser}
        />
      </div>
    ) : (
      <></>
    );
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint === 'smartphone'
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const dataDisplayProps = useMemo(
    () => ({
      sorter: [],
      pageLimit,
      header: generateTableHeader
    }),
    [pageLimit]
  );

  const downloadQRSnapshot = async () => {
    let status: 'success' | 'error' = 'success';
    try {
      await downloadElementAsImage(
        `${qrDetails.name || 'merchant'}__qrCode`,
        ref
      );
    } catch (error) {
      status = 'error';
    } finally {
      renderResult(status);
      setCurrentStaff('');
      setCurrentStaffQR('');
      setCurrentStaffID('');
    }
  };

  const renderResult = (errorType: 'success' | 'error') => {
    const toast = getToast(errorType);

    if (toast) {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: toast.status,
            title: 'Staff QR code generated',
            buttonTitle: 'Return to staff table'
          }
        })
      );
    }
  };

  return (
    <>
      <DataDisplayContainer
        {...dataDisplayProps}
        dataKey={TransactionStateKeyEnum.STAFF}
        dataGenerator={generateDisplay}
        noDataMessage={'No staff is loaded'}
        fetchingData={{
          fetchingText: (
            <ButtonAsyncLoader
              loadingText="Loading Staff Details.. Please wait. "
              showLoaderText={true}
            />
          )
        }}
      />
      <div className="downloadQRefSection">
        <div ref={ref} className="downloadQRefSection__qrCodeSection">
          <QRCodeDownload
            name={currentStaff}
            merchantName={qrDetails.name}
            merchantLynkID={qrDetails.username}
            qrCode={currentStaffQR}
          />
        </div>
      </div>
    </>
  );
};

export default StaffTable;
