import React, { useEffect, useState } from 'react';

import { usePermissions } from '../../../features/permissions';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';
import { MenuTab } from '../../../types/models/tabsType';
import SinglePageTemplate from '../../templates/SinglePageTemplate';
import { useDispatch } from 'react-redux';
import { updateCurrentWallet } from '../../../store/walletManagement';
import { useGetWalletManager } from '../../../store/walletManagement/selector';

const JAMDEXPageDashboard: React.FC = () => {
  const { userPermissions: permissions } = usePermissions();
  const [tabs, setTabs] = useState<MenuTab[]>([]);
  const [widgets, setWidgets] = useState<JSX.Element[]>([]);

  const { getValidWidgets, getValidTabComponents } = useDashboardSetup();

  const dispatch = useDispatch();
  const walletManager = useGetWalletManager();

  useEffect(() => {
    dispatch(updateCurrentWallet('jam-dex'));
  }, []);

  useEffect(() => {
    setTabs(getValidTabComponents());
    setWidgets(getValidWidgets());
  }, [permissions, walletManager.currentWallet]);

  return (
    <div className="singlePageDashboard">
      <SinglePageTemplate
        componentsToRender={widgets}
        menu={tabs}
        // selectedTab={
        //   cashoutAttemptMade &&
        //   (isCashoutAttemptSuccessful || cashoutAttemptError !== null)
        //     ? 'transfers'
        //     : undefined
        // }
      />
    </div>
  );
};

export default JAMDEXPageDashboard;
