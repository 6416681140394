import './index.scss';

import CheckboxGroup from '../CheckboxGroup';
import {
  ExportPreferenceForm,
  exportDateRangeRegex,
  updatePreferences
} from '../../../store/actions';
import React, { useEffect, useState } from 'react';
import { checkboxExportItems, getAllAttribute } from './hooks/checkboxItems';
import {
  dateMonthDifferenceByOne,
  formatDate
} from '../../../utils/dateFormatter';

import SectionView from '../../atoms/SectionView';
import { errorMap } from './errorMap';
import { useDispatch } from 'react-redux';
import { useExportReportMetadata } from '../../../store/modals/exportAndDownload/selector';
import { useValidation } from './hooks/useValidation';
import moment from 'moment';
import DateRangePicker from '../../organisms/DateRangePicker';
import { CheckboxGroupItem } from '../../../types/models/formType';
import { SuccessError } from '../../../types/models/datePickerType';
import { useGetWalletManager } from '../../../store/walletManagement/selector';

const ExportPreferenceWizardPage: React.FC = () => {
  const [exportListOptions, setExportListOptions] =
    useState<CheckboxGroupItem[]>(checkboxExportItems);
  const [isDateValid, setDateValid] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [isDatePickerTouched, setIsDatePickerTouched] = useState(false);
  const [tableExportListValid, setTableExportListValid] =
    useState<boolean>(true);

  const dispatch = useDispatch();
  const { updateCheckboxItems } = useValidation();
  const dateFormatRegex = 'dd/MM/yyyy';

  const exportState = useExportReportMetadata();
  const walletManager = useGetWalletManager();

  useEffect(() => {
    const newItems = checkboxExportItems.map(item => {
      return {
        ...item,
        isActive: exportState.preferences.types.includes(item.type)
      } as CheckboxGroupItem;
    });

    setExportListOptions(newItems);

    setStartDate(moment(exportState.preferences.dateRange.start).toDate());
    setEndDate(moment(exportState.preferences.dateRange.end).toDate());
  }, []);

  useEffect(() => {
    setDisabled(
      exportState.exportStatus.inProgress === true &&
        exportState.exportStatus.stage === 'generate'
    );
  }, [exportState]);

  useEffect(() => {
    updatePreferencesFn({ isFormValid: tableExportListValid && isDateValid });
  }, [tableExportListValid, isDateValid]);

  useEffect(() => {
    const activeList = exportListOptions.filter(listOpt => listOpt.isActive);
    const activeTypes = getAllAttribute(activeList, 'type') as string[];
    setTableExportListValid(activeList.length >= 1);

    updatePreferencesFn({
      preferences: {
        types: activeTypes,
        dateRange: {
          start: formatDate(startDate, exportDateRangeRegex),
          end: formatDate(endDate, exportDateRangeRegex)
        }
      }
    });
  }, [exportListOptions, startDate, endDate]);

  useEffect(() => {
    setDateValid(errors.length === 0);
  }, [errors]);

  // check the date Difference
  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    const isDifferentByOne = dateMonthDifferenceByOne(
      startDate as Date,
      endDate as Date
    );

    const currErrors = [...errors];

    if (!isDifferentByOne) {
      currErrors.push('invalidRangeOverOneMonth');
      setErrors(currErrors);
    } else {
      const newErrors = currErrors.filter(
        val => val != 'invalidRangeOverOneMonth'
      );
      setErrors(newErrors);
    }
  }, [startDate, endDate]);

  const handleDateChange = (
    date1: Date | undefined,
    date2: Date | undefined,
    status: boolean,
    errorList: string[]
  ) => {
    if (!isDatePickerTouched) setIsDatePickerTouched(true);
    setStartDate(date1);
    setEndDate(date2);

    setDateValid(status);

    setErrors(errorList);
  };

  const getFeedback = () => {
    if (errors.length === 0) return undefined;

    return {
      type: 'error' as SuccessError,
      message: errors.includes('invalidRangeOverOneMonth')
        ? errorMap['invalidRangeOverOneMonth']
        : ''
    };
  };

  const updatePreferencesFn = (preference: Partial<ExportPreferenceForm>) => {
    dispatch(updatePreferences(preference));
  };

  return (
    <React.Fragment>
      <div className="exportPreferencesSection">
        {disabled && <div className="exportPreferencesSection--disabled"></div>}
        <div className="exportPreferencesSection-subTitle">
          {walletManager.currentWallet === 'lynk'
            ? 'Please select the tables you want to export and the date range.'
            : 'Please select the date range you want to export.'}
        </div>
        <SectionView
          title={'Selected date range:'}
          body={
            <div className="sectionView-body">
              <div className="sectionView-bodyText">
                The date range allowed is up to 1 month.
              </div>
              <DateRangePicker
                openTo={
                  !isDatePickerTouched
                    ? moment(startDate).subtract(1, 'months').toDate()
                    : moment(endDate).subtract(1, 'months').toDate()
                }
                // isDatePickerTouched ? moment(startDate).subtract(1,'months').toDate() : moment(endDate).subtract(1,'months').toDate()
                maxDateLimit={{ notExceedFutureDate: true }}
                placeholder="DD/MM/YYYY"
                feedback={getFeedback()}
                isValidOverall={isDateValid}
                dateFormat={dateFormatRegex}
                dateRange={{
                  start: {
                    date: startDate as Date,
                    label: 'Start Date'
                  },
                  end: {
                    date: endDate as Date,
                    label: 'End Date'
                  }
                }}
                handleChange={handleDateChange}
              />
            </div>
          }
        />
        {walletManager.currentWallet === 'lynk' && (
          <SectionView
            title={
              <div
                className={`checkboxList-title ${
                  !tableExportListValid && 'invalid'
                }`}
              >
                Tables to be exported
                {!tableExportListValid && (
                  <span className="checkboxList-message--invalid">
                    Please select a table to export
                  </span>
                )}
              </div>
            }
            body={
              <>
                <div
                  className={`sectionView-body ${
                    !tableExportListValid && 'invalid'
                  }`}
                >
                  <CheckboxGroup
                    checkboxGroup={exportListOptions}
                    upgradeGroupState={key =>
                      setExportListOptions(
                        updateCheckboxItems(key, exportListOptions)
                      )
                    }
                  />
                </div>
              </>
            }
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ExportPreferenceWizardPage;
