import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import React from 'react';
import { showModal } from '../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import { ReactComponent as SinglePaymentsIcon } from '../../../assets/svg/single-payments.svg';

interface WidgetProps {
  disableClick?: boolean;
  isActive?: boolean;
}

const SinglePaymentsWidget: React.FC<WidgetProps> = ({
  disableClick,
  isActive
}) => {
  const dispatch = useDispatch();

  const triggerCallback = () => {
    if (!disableClick) {
      dispatch(showModal({ modalKey: 'single-payment' }));
    }
  };

  return (
    <div className="viewPayments">
      <DashboardIconWidgetBase
        testID="viewBatchPayments"
        disabled={false}
        icon={<SinglePaymentsIcon className="icon" />}
        label={'Create Single Payment'}
        callback={triggerCallback}
        id={'viewBatchPaymentsWidget'}
        classes={isActive ? 'activeWidget' : ''}
      />
    </div>
  );
};

export default SinglePaymentsWidget;
