/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import { FilterRangeObject } from '../../../types/models/dateTypes';
import TableCard from '../../atoms/TableCard';
import { amountFormated } from '../../../utils/currency';
import { getTimeStamp } from '../../../utils/dateFormatter';
import {
  SorterProps,
  SortingAscDesc
} from '../../../types/models/utils/table-models';
import { useGetScreenState } from '../../../store/screenSize/selector';
import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/modals/modal';
import { PaymentDetailType } from '../../../types/models/tableTransactions';
import './index.scss';
import TransactionColumnFormatter from '../../molecules/TransactionColumnFormatter';
import useConfigTable from '../../../hooks/utils/tableConfig/useConfigTable';
import { useAccountState } from '../../../store/account/selector';
import { WalletDetailBase } from '../../../store/account/types';

const Payments: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const transactionTableState = useGetTransactionTableState();

  const { dispatchMetadataUpdate } = useGetPaginationInformation();
  const breakpointDetails = useGetScreenState();
  const [pageLimit, setPageLimit] = useState(25);
  const { getTransactionDetails } = useConfigTable();

  const [paymentTooltip, setPaymentTooltip] = useState<any>({});

  useEffect(() => {
    setPageLimit(breakpointDetails.breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpointDetails]);

  useEffect(() => {
    const tooltip = getTransactionDetails(TransactionStateKeyEnum.PAYMENT);
    setPaymentTooltip(tooltip);
  }, []);

  const dispatch = useDispatch();
  const filterRangeHandler = (range: FilterRangeObject) => {
    dispatchMetadataUpdate(
      {
        tableFilter: {
          dateRange: range
        }
      },
      TransactionStateKeyEnum.PAYMENT
    );
  };

  const ColumnStructure = (
    transactions: PaymentDetailType[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: any) => (
      <tr key={row.id}>
        {columnHeaders.map((col: any) => (
          <td key={`${row.id}-${col.field}`} className={`${col.label}`}>
            <TransactionColumnFormatter
              column={col}
              row={row}
              stateOptions={paymentTooltip}
              tooltipIdentifier={`tooltip-${col.field}-${row.id}`}
            />
          </td>
        ))}
      </tr>
    ));
  };

  const moreInfoCallback = (data: PaymentDetailType) => {
    const dataMap = {
      details: [
        {
          label: 'From',
          value: data.from_id
        },
        {
          label: 'STATUS',
          value: data.status,
          tooltip: {
            value: data.status,
            id: data.id,
            feedback: paymentTooltip[`${data.status}`]
          }
        }
      ]
    };

    dispatch(
      showModal({
        modalKey: 'more-trans-details',
        data: dataMap,
        title: 'Payment'
      })
    );
  };

  const accountDetails = useAccountState();
  const endpointGenerator = (endpoint: string) => {
    const getJAMDEXAcct = accountDetails?.accounts?.find(acct => {
      return acct.type === 'CBDC';
    });

    return endpoint.replace(
      '{accountID}',
      `${(getJAMDEXAcct?.details as WalletDetailBase).account_id}`
    );
  };

  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: PaymentDetailType) => (
      <TableCard
        key={`${row.id}`}
        title={row.status || ''}
        mainTitle={row.from_id}
        subTitle={getTimeStamp(row.date)}
        data={`${amountFormated(row.amount)}`}
        moreInfoCallback={() => moreInfoCallback(row)}
      />
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint === 'smartphone'
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.PAYMENT].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;

    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.PAYMENT
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.PAYMENT]]
  );

  return (
    <DataDisplayContainer
      {...dataDisplayProps}
      endpointGenerator={endpointGenerator}
      dataKey={TransactionStateKeyEnum.PAYMENT}
      dataGenerator={generateDisplay}
      noDataMessage={'No transactions yet'}
      fetchingData={{
        fetchingText: (
          <ButtonAsyncLoader
            loadingText="Loading Data... Please wait. "
            showLoaderText={true}
          />
        )
      }}
    />
  );
};

export default Payments;
