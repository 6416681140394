import React, { useEffect, useMemo, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import './index.scss';
import { useNavigate, useBlocker } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from 'src/features/breadcrumbs';
import {
  useGetProfileDetail,
  useIsLoadingProfileDetail
} from '../../../../store/organization/selector';
import {
  NotificationSettingsPreferenceKey,
  PreferenceKey,
  PreferenceTransactionsKey,
  getPreferenceMapper,
  getTransactionPreferenceMapper
} from 'src/utils/notificationSettings';
import useGetUpdatePreferences from 'src/hooks/notifications/useGetUpdatePreferences';
import Skeleton from 'react-loading-skeleton';
import SettingsItem from 'src/components/molecules/SettingsItem';
import ActionButton from 'src/components/atoms/ActionButton';
import LynkModal from 'src/components/atoms/LynkModal';
import CurrencyTextInput from 'src/components/atoms/CurrencyTextInput';
import ToastDisplay from 'src/components/atoms/ToastDisplay';
import { usePermissions } from 'src/features/permissions';
import { setLowBalanceThreshold } from 'src/store/organization';

interface NotificationSetting {
  name: string;
  group: string;
  enabled: boolean;
  code: NotificationSettingsPreferenceKey;
}

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organization_id, low_balance_threshold, low_balance_enabled } =
    useGetProfileDetail();

  const profileLoading = useIsLoadingProfileDetail();

  const { hasPermissions } = usePermissions();

  const {
    fetching,
    getPreferences,
    updatePreference,
    updateLowBalanceThreshold
  } = useGetUpdatePreferences();
  const [settings, setSettings] = useState<NotificationSetting[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [changedSettingsCodes, setChangedSettingsCodes] = useState<string[]>(
    []
  );
  const [thresholdAmount, setThresholdAmount] = useState(
    low_balance_threshold.toString()
  );

  useEffect(() => {
    setThresholdAmount(low_balance_threshold.toString());
  }, [low_balance_threshold]);

  const getSettings = async () => {
    const response = await getPreferences();
    setSettings(response.data.notification_preferences);
  };

  const navigationBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      (preferencesChanged || balanceThresholdChanged) &&
      nextLocation.pathname !== currentLocation.pathname
  );

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    const baseURl = `/${organization_id.split('_')[1]}`;
    dispatch(
      setBreadcrumbs([
        {
          name: 'Dashboard',
          path: `${baseURl}/lynk-wallet/orders`
        },
        { name: 'Notifications', path: `${baseURl}/notifications/all` },
        { name: 'Settings', path: '' }
      ])
    );
  }, []);

  const handleToggleSetting = (code: string) => {
    if (changedSettingsCodes.includes(code)) {
      const newSettings = changedSettingsCodes.filter(
        setting => setting !== code
      );
      setChangedSettingsCodes(newSettings);
    } else {
      setChangedSettingsCodes([...changedSettingsCodes, code]);
    }

    setSettings(prevState => {
      const newSettings = prevState.map(setting => {
        if (setting.code === code) {
          return {
            ...setting,
            enabled: !setting.enabled
          };
        }
        return setting;
      });
      return newSettings;
    });
  };

  const preferencesChanged = useMemo(() => {
    return changedSettingsCodes.length > 0;
  }, [changedSettingsCodes]);

  const balanceThresholdChanged = useMemo(() => {
    return thresholdAmount !== low_balance_threshold.toString();
  }, [thresholdAmount, low_balance_threshold]);

  const handleSaveSettings = async () => {
    const promises: Promise<any>[] = [];
    setIsSaving(true);
    if (preferencesChanged) {
      const changedSettingsData = settings.filter(setting =>
        changedSettingsCodes.includes(setting.code)
      );
      promises.push(updatePreference(changedSettingsData));
    }

    if (balanceThresholdChanged) {
      promises.push(updateLowBalanceThreshold(thresholdAmount));
    }

    try {
      await Promise.all(promises);
      setChangedSettingsCodes([]);
      dispatch(setLowBalanceThreshold(parseInt(thresholdAmount)));
      getSettings();
    } catch (error) {
      console.dir(error);
      setShowToast(true);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="pageTitle">
        <KeyboardArrowLeftIcon
          style={{
            cursor: 'pointer',
            marginBottom: '3px',
            marginLeft: '-6px',
            marginRight: '5px'
          }}
          onClick={() => navigate('./../')}
        />
        Notifications
      </div>
      <div className="settingsPage">
        <header>
          <div className="notificationHeader">
            <div>
              <h2 className="notificationTitle">Notifications Settings</h2>
              <p className="notificationSubtitle">
                Select when you’ll be notified when the following changes occur
                on your account. Send me an email when:
              </p>
            </div>

            <ActionButton
              type="primary"
              text="Save"
              loading={isSaving}
              classnames="saveButton"
              disabled={!preferencesChanged && !balanceThresholdChanged}
              onPress={handleSaveSettings}
            />
          </div>
        </header>

        <div className="notificationsBody">
          <div>
            <div className="preferenceTitle">Balance Threshold</div>
            {profileLoading ? ( // change to fetching preference
              <>
                <Skeleton height={40} width={'90%'} />
              </>
            ) : (
              <SettingsItem
                title={'When your wallet balance goes below your set threshold'}
                active={low_balance_enabled}
                code={'0'}
                disabled={true}
                handleChangeCallback={() => {}}
                classes="uniqueElement"
              >
                <div className="balanceThresholdAmount">
                  <span className="instruction">
                    Enter balance threshold amount
                  </span>
                  <div className="currencyFieldReset">
                    <CurrencyTextInput
                      label=""
                      inputTextLabel="J$"
                      onChange={value => {
                        setThresholdAmount(value);
                      }}
                      disabled={
                        !hasPermissions([
                          { actions: new Set(['update']), resource: 'profile' }
                        ])
                      }
                      amount={thresholdAmount}
                      placeholder="0"
                      decimalScale={0}
                    />
                  </div>
                </div>
              </SettingsItem>
            )}
          </div>

          <div>
            <div className="preferenceTitle">Transactions</div>
            {fetching && (
              <>
                <Skeleton height={40} width={'90%'} />
              </>
            )}
            {settings &&
              !fetching &&
              settings
                .filter(setting => setting.code === 'PAYMENT_RECEIVED')
                .map(item => (
                  <SettingsItem
                    key={item.code}
                    title={getTransactionPreferenceMapper(
                      item.code as PreferenceTransactionsKey
                    )}
                    active={item.enabled}
                    code={item.code}
                    handleChangeCallback={() => handleToggleSetting(item.code)}
                    classes="uniqueElement"
                  />
                ))}
          </div>
          <div>
            <div className="preferenceTitle">Payments</div>
            {fetching && (
              <>
                <Skeleton height={40} width={'90%'} />
                <Skeleton height={40} width={'40%'} />
                <Skeleton height={40} width={'90%'} />
                <Skeleton height={40} width={'70%'} />
                <Skeleton height={40} width={'80%'} />
              </>
            )}

            {settings &&
              !fetching &&
              settings
                .sort((x, y) => {
                  if (x.name < y.name) return -1;
                  if (x.name > y.name) return 1;
                  return 0;
                })
                .map(
                  item =>
                    getPreferenceMapper(item.code as PreferenceKey) && (
                      <SettingsItem
                        key={item.code}
                        title={getPreferenceMapper(item.code as PreferenceKey)}
                        active={item.enabled}
                        code={item.code}
                        handleChangeCallback={() =>
                          handleToggleSetting(item.code)
                        }
                      />
                    )
                )}
          </div>
        </div>

        <p className="notificationSubtitle">
          If you choose to disable all notifications, please note that you will
          still receive emails regarding Security updates and warnings related
          to your account. These notifications are essential to keep your
          account secure and ensure you stay informed about any potential
          threats or issues.
        </p>

        {navigationBlocker.state === 'blocked' && (
          <LynkModal
            title="You have unsaved changes"
            subTitle={
              <>
                You are navigating away from the notifications settings page.
                <br /> If you do not save, you will lose all your changes.
              </>
            }
            modalBody={<></>}
            show
            mainAction={{
              text: 'Keep Editing',
              onClickFn: navigationBlocker.reset,
              disabled: false,
              testID: ''
            }}
            closeAction={{
              text: 'Discard Changes',
              onClickFn: navigationBlocker.proceed,
              disabled: false,
              testID: ''
            }}
          />
        )}
        <ToastDisplay
          showDefaultIcon
          showToast={showToast}
          toastType="error"
          duration={3000}
          closeHandler={() => setShowToast(false)}
          message={'There was an error, please contact support'}
        />
      </div>
    </>
  );
};

export default Settings;
