import { ApiServiceType } from '../../types/enums/apiServiceType';
import usePutData from '../../hooks/api/usePutData';
import useGetData from '../../hooks/api/useGetData';
export interface Preference {
  enabled: boolean;
  code: string;
}
const useGetUpdatePreferences = (): {
  fetching: boolean;
  getPreferences(): Promise<any>;
  updatePreference(body: Preference[]): Promise<any>;
  updateLowBalanceThreshold(amount: string): Promise<any>;
  abortController: AbortController;
} => {
  const { putData } = usePutData();
  const { isFetching, getData } = useGetData();

  const abortController = new AbortController();

  const getPreferences = async (): Promise<any> => {
    const response = await getData({
      path: 'notifications-preferences',
      apiType: ApiServiceType.PROFILE
    });

    return response;
  };

  const updatePreference = async (body: Preference[]): Promise<any> => {
    const response = await putData({
      path: 'notifications-preferences',
      apiType: ApiServiceType.PROFILE,
      data: body
    });

    return response;
  };

  const updateLowBalanceThreshold = async (amount: string): Promise<any> => {
    const response = await putData({
      path: '',
      apiType: ApiServiceType.PROFILE,
      data: { low_balance_threshold: parseInt(amount) }
    });

    return response;
  };

  return {
    fetching: isFetching,
    getPreferences,
    updatePreference,
    updateLowBalanceThreshold,
    abortController
  };
};
export default useGetUpdatePreferences;
