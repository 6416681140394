import './index.scss';

import { TabContent } from 'reactstrap';
import React, { useState } from 'react';
import DesktopTabHeader from '../../atoms/DesktopTabHeader';
import { MenuTab, RoutingTabProps } from '../../../types/models/tabsType';
import { Outlet, useNavigate } from 'react-router-dom';

const RoutingTabs: React.FC<RoutingTabProps> = ({
  menuTabs,
  className,
  actionElement,
  action
}) => {
  const [activeTab, setActiveTab] = useState('');

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <DesktopTabHeader
        menuTabs={menuTabs}
        selectedTab={activeTab}
        className={className}
        selectTab={(tab: MenuTab) => navigate(`./${tab.path || ''}`)}
        actionElement={actionElement}
      />
      <TabContent>
        <Outlet />
      </TabContent>
    </React.Fragment>
  );
};

export default RoutingTabs;
