/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'reactstrap';
import './index.scss';
import useDownloadCompAsImage from '../../../hooks/utils/useDownloadCompAsImage';
import { showModal } from '../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import QRCodeDownload from '../../organisms/QRCodeDownload';
import { useGetQRCodeDetail } from '../../../store/organization/selector';
import { useGetProfileState } from '../../../store/profile/selector';
import { clearScope } from '../../../store/status';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';
import { updateCurrentWallet } from '../../../store/walletManagement';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { getIconMapper } from '../../../utils/iconMap';
import { WalletType } from '@hooks/merchant_portal/useGetWalletBalance';
import { useAccountState } from '../../../store/account/selector';
import { getToast } from '../DownloadMerchantJamDexQRWidget/downloadToast';
import JAMDEXNavbar from '../JAMDEXNavbar';
import { useNavigate } from 'react-router-dom';

interface Props {
  menuOpen: boolean;
  closeMenu: () => void;
}

const Navbar = (props: Props) => {
  const ref: any = useRef();
  const cbdcref: any = useRef();
  const { isFetching, downloadElementAsImage } = useDownloadCompAsImage();
  const qrDetails = useGetQRCodeDetail();
  const profile = useGetProfileState();
  const dispatch = useDispatch();
  const walletManager = useGetWalletManager();
  const accounts = useAccountState();
  const navigate = useNavigate();

  const transferModal = () => {
    props.closeMenu();
    dispatch(showModal({ modalKey: 'transfer' }));
  };

  const exportTable = () => {
    props.closeMenu();
    dispatch(showModal({ modalKey: 'exporting-table' }));
  };
  const createPayment = () => {
    props.closeMenu();
    dispatch(showModal({ modalKey: 'create-payments' }));
  };
  const sendPaymentRequest = () => {
    props.closeMenu();
    dispatch(showModal({ modalKey: 'payment-request' }));
  };

  const renderResult = (errorType: 'success' | 'error') => {
    const toast = getToast(errorType);
    if (toast) {
      showModal({
        modalKey: 'transaction-status',
        data: {
          status: toast.status,
          title: toast.message
        }
      });
    }
  };

  const downloadQRSnapshot = async () => {
    let status: 'success' | 'error' = 'success';
    dispatch(clearScope('general'));
    try {
      dispatch(clearScope('general'));
      await downloadElementAsImage(
        `${qrDetails.name || 'merchant'}__qrCode`,
        ref
      );
    } catch (error) {
      status = 'error';
    } finally {
      renderResult(status);
    }
  };

  const dispatchMap = {
    downloadQR: downloadQRSnapshot,
    exportTables: exportTable,
    paymentRequest: sendPaymentRequest,
    transfer: transferModal,
    createPaymentRequest: createPayment
  };

  const { getValidSidebarComponents, getValidProfileListing } =
    useDashboardSetup();

  const [sidebarItemsLynk, setSidebarItemsLynk] = useState<any[]>([]);
  const [sidebarMyAccount, setSidebarMyaccount] = useState<any[]>([]);

  const showJAMDEXContent = useMemo(() => {
    const cbdcAccountDetails = accounts.accounts?.find(
      (account: any) => account.type === 'CBDC'
    );
    return !!cbdcAccountDetails;
  }, [accounts]);

  useEffect(() => {
    const sidebarLynk = getValidSidebarComponents();
    const listing = getValidProfileListing();

    setSidebarMyaccount(listing);
    setSidebarItemsLynk(
      sidebarLynk.map(item => {
        const fn = dispatchMap[item.key as keyof typeof dispatchMap];
        return {
          ...item,
          onClickFn: fn
        };
      })
    );
  }, []);
  const changeWallet = (nextWallet: WalletType) => {
    navigate(nextWallet === 'lynk' ? 'lynk-wallet' : 'jamdex-wallet');
    dispatch(updateCurrentWallet(nextWallet));
    props.closeMenu();
  };
  const accountAction = (route: string) => {
    props.closeMenu();

    if (route) {
      navigate(route);
    } else {
      dispatch(
        showModal({
          modalKey: 'logout-user'
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div
          className={`topnav topnav${props.menuOpen ? '--open' : '--closed'}`}
        >
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.menuOpen}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <div className="menuContainer">
                {walletManager.currentWallet !== 'corporate' && (
                  <>
                    <div className="quickAccess"> My wallets</div>

                    <div className="walletToggleLeft">
                      <div
                        onClick={() => changeWallet('lynk')}
                        className={`walletOption ${
                          walletManager.currentWallet === 'lynk'
                            ? 'active'
                            : 'inactive'
                        }`}
                      >
                        {getIconMapper('lynk-only-white')} Lynk Wallet
                      </div>
                      {showJAMDEXContent ? (
                        <div
                          onClick={() => changeWallet('jam-dex')}
                          className={`walletOption ${
                            walletManager.currentWallet === 'jam-dex'
                              ? 'active'
                              : 'inactive'
                          }`}
                        >
                          {getIconMapper('jam-dex-white')} JAM-DEX Wallet
                        </div>
                      ) : undefined}
                    </div>

                    <div className="quickAccess">Lynk - Quick Access</div>
                    <ul className="navbar-nav">
                      {sidebarItemsLynk.map(sideItem => {
                        return (
                          <li
                            key={sideItem.key}
                            className="nav-item-sidebar"
                            onClick={sideItem.onClickFn}
                          >
                            <div className="nav-link">
                              <span>{sideItem.title}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {showJAMDEXContent ? (
                      <JAMDEXNavbar
                        exportTable={exportTable}
                        transferModal={transferModal}
                      />
                    ) : undefined}
                  </>
                )}
                <div className="quickAccess">My Account</div>
                <ul className="navbar-nav">
                  {sidebarMyAccount.map(menuItem => (
                    <li
                      key={menuItem.key}
                      className="nav-item-sidebar"
                      onClick={() => accountAction(menuItem.url)}
                    >
                      <div className="nav-link">
                        <span>{menuItem.display}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Collapse>
          </nav>
        </div>
      </div>
      <div className="downloadQRefSection">
        <div ref={ref} className="downloadQRefSection__qrCodeSection">
          <QRCodeDownload
            name={
              profile.role === 'Cashier' || profile.role === 'STAFF'
                ? profile.displayName
                : qrDetails.name
            }
            merchantName={qrDetails.name}
            merchantLynkID={qrDetails.username}
            qrCode={qrDetails.qr_image}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;

// Navbar.propTypes = {
//   leftMenu: PropTypes.any,
//   location: PropTypes.any,
//   menuOpen: PropTypes.any,
//   t: PropTypes.any
// };

// const mapStatetoProps = (state: any) => {
//   const { leftMenu } = state.Layout;
//   return { leftMenu };
// };

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(Navbar))
// );
