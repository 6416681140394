import StaffTable from '../../templates/StaffTable';
import React, { useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetProfile from '../../../hooks/utils/useGetProfile';
import { useAppDispatch } from '../../../store/hooks';
import { setBreadcrumbs } from '../../../features/breadcrumbs';
import { useGetProfileDetail } from '../../../store/organization/selector';

const StaffPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isAdmin } = useGetProfile();
  const { organization_id, merchant } = useGetProfileDetail();

  const isCorpStaffPage = location.pathname.split('/')[1].includes('staff');

  useEffect(() => {
    dispatch(
      setBreadcrumbs(
        isCorpStaffPage
          ? [
              {
                name: merchant,
                path: '/'
              },
              {
                name: 'Staff',
                path: ''
              }
            ]
          : [
              {
                name: 'Dashboard',
                path: `/${organization_id.split('_')[1]}/lynk-wallet/orders`
              },
              { name: 'Staff', path: '' }
            ]
      )
    );
  }, []);

  return (
    <>
      <div className="pageTitleContainer">
        {!isAdmin ? (
          <KeyboardArrowLeftIcon
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('./../lynk-wallet')}
          />
        ) : (
          <KeyboardArrowLeftIcon
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('./..')}
          />
        )}
        Staff
      </div>
      <div
        style={{
          margin: '0px 35px',
          padding: 25,
          backgroundColor: 'white'
        }}
      >
        <StaffTable />
      </div>
    </>
  );
};

export default StaffPage;
