import { CheckboxGroupItem } from '../../../../types/models/formType';
import useGetReportsForDownload from '../../../../hooks/exportAndDownload/useGetReportsForDownload';

export const useValidation = (): {
  refreshTypes: (
    checkboxExportItems: CheckboxGroupItem[],
    dateRange: { start: Date; end: Date }
  ) => Promise<CheckboxGroupItem[]>;
  updateCheckboxItems: (
    key: string,
    exportListOptions: CheckboxGroupItem[]
  ) => CheckboxGroupItem[];
} => {
  const { loadAvailiableReportsForDownload } = useGetReportsForDownload();

  const refreshTypes = async (
    checkboxExportItems: CheckboxGroupItem[],
    dateRange: { start: Date; end: Date }
  ) => {
    const oldItems = [...checkboxExportItems];
    const availiableTypes: string[] = await loadAvailiableReportsForDownload(
      dateRange
    );
    oldItems.forEach(value => availiableTypes.includes(value.type));

    return oldItems;
  };

  const updateCheckboxItems = (
    key: string,
    exportListOptions: CheckboxGroupItem[]
  ): CheckboxGroupItem[] => {
    const oldList = [...exportListOptions];
    const keyIndex = oldList.findIndex(obj => obj.key === key);

    const currentStatus = oldList[keyIndex].isActive;
    oldList[keyIndex].isActive = !currentStatus;

    return oldList;
  };

  return { refreshTypes, updateCheckboxItems };
};
