import DashboardIconWidgetBase from '../../atoms/DashboardIconWidgetBase';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/actions';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const TransferWidget: React.FC = () => {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(showModal({ modalKey: 'transfer' }));
  };
  return (
    <>
      <DashboardIconWidgetBase
        testID="paymentRequestBtn"
        disabled={false}
        icon={<CompareArrowsIcon className="icon" />}
        label={'Transfer'}
        callback={openModal}
        id={'transferWidget'}
      />
    </>
  );
};

export default TransferWidget;
