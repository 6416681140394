import NotificationsTemplate from '../../templates/Notifications';
import React, { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { MenuTab } from '../../../types/models/tabsType';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../features/breadcrumbs';
import { useGetProfileDetail } from '../../../store/organization/selector';

const Notifications: React.FC = () => {
  const [tabs, setTabs] = useState<MenuTab[]>([]);
  const { getNotificationsTabComponents } = useDashboardSetup();
  const { organization_id } = useGetProfileDetail();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTabs(getNotificationsTabComponents());
    dispatch(
      setBreadcrumbs([
        {
          name: 'Dashboard',
          path: `/${organization_id.split('_')[1]}/lynk-wallet/orders`
        },
        { name: 'Notifications', path: '' }
      ])
    );
  }, []);

  return (
    <>
      <div className="pageTitle">
        <KeyboardArrowLeftIcon
          style={{
            cursor: 'pointer',
            marginBottom: '3px',
            marginRight: '5px'
          }}
          onClick={() => navigate('./../lynk-wallet')}
        />
        Notifications
        <div className="settingButton" onClick={() => navigate('./settings')}>
          <span>Settings</span>
          <SettingsOutlinedIcon style={{ marginBottom: '3px' }} />
        </div>
      </div>
      <div className="notificationPage">
        <NotificationsTemplate menu={tabs} />
      </div>
    </>
  );
};

export default Notifications;
