import { Permission } from './types/Permission';

export const generatePermissionList = (permissionList: string[]) => {
  const permissionMap = new Map<string, Set<string>>();

  permissionList.forEach(listItem => {
    const listGroup = listItem.split(':');

    if (!permissionMap.has(listGroup[1])) {
      permissionMap.set(listGroup[1], new Set());
    }

    const actionList = permissionMap.get(listGroup[1]) || new Set();
    actionList.add(listGroup[0]);
    permissionMap.set(listGroup[1], actionList);
  });

  return permissionMap;
};

export const checkIfPermissionsInclude = (
  userPermissionsMap: Map<string, Set<string>>,
  requiredPermissions: Permission[]
) => {
  const profileResources = new Set(userPermissionsMap.keys());

  const widgetResources = requiredPermissions.map(
    permission => permission.resource
  );

  const doesProfileHaveResources = widgetResources.some(
    widgetResource =>
      profileResources.has(widgetResource) || widgetResource === '*'
  );

  if (!doesProfileHaveResources) return false;

  return requiredPermissions.some(permission => {
    if (permission.resource === '*' && permission.actions.has('*')) return true;

    const actions = userPermissionsMap.get(permission.resource);
    if (!actions) return false;

    return Array.from(permission.actions).some(permItem =>
      actions.has(permItem)
    );
  });
};
