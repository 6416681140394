import { ApiServiceType } from '../../types/enums/apiServiceType';
import { BatchPaymentFormState } from '../../store/batchPaymentProcess';
import usePostData from '../api/usePostData';
import { useGetProfileState } from '../../store/profile/selector';
import { BatchDetailType } from '../../types/models/BatchPaymentsType';
import { parseErrorFromAxiosCall } from '../../services/api';
import useFileHandler from '../../components/molecules/SubmitBatchPaymentForm/useFileHandler';
import {
  FileInvalidStructureException,
  GenericBatchUploadException
} from '../../exceptions/UploadBatchDocument.exception';

const usePostBatchPaymentProcess = (): {
  batchPaymentFetching: boolean;
  uploadBatchPaymentFile: (batchPayment: any) => Promise<any>;
  requestApprovalBatchPayment: (batchPayment: any) => Promise<any>;
  startBatch: (transaction: BatchDetailType, account: string) => Promise<any>;
  requestRejectBatchPayment: (
    transaction: BatchDetailType,
    account: string
  ) => Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, postData } = usePostData();
  const abortController = new AbortController();
  const profile = useGetProfileState();

  const { convertBase64 } = useFileHandler();

  const uploadBatchPaymentFile = async (
    batchPayment: BatchPaymentFormState
  ) => {
    try {
      const response = await postData({
        path: 'batch-payments',
        apiType: ApiServiceType.PAYMENT,
        data: {
          merchant_id: profile.merchant_id,
          name: batchPayment.batchName,
          csv: await convertBase64(batchPayment.batchFile)
        }
      });
      return response;
    } catch (err: any) {
      if (err.response.status === 500) {
        throw new GenericBatchUploadException();
      } else {
        throw new GenericBatchUploadException();
      }
    }
  };
  const requestApprovalBatchPayment = async (batchPayment: BatchDetailType) => {
    try {
      const response = await postData({
        path: `batch-payments/${batchPayment.id}/request`,
        apiType: ApiServiceType.PAYMENT
      });
      return response;
    } catch (err: any) {
      if (err.response.status === 500) {
        throw new GenericBatchUploadException();
      } else {
        throw new GenericBatchUploadException();
      }
    }
  };
  const customerTransaction = async (
    values: BatchDetailType,
    account: string
  ) => {
    const funds = account.split(':');
    const formattedAccount = funds[0].toUpperCase();
    const response = await postData({
      path: `batch-payments/${values.id}/approve`,
      apiType: ApiServiceType.PAYMENT,
      data: {
        account_type:
          formattedAccount === 'BANK_ACCT' ? 'BANK_ACCOUNT' : formattedAccount,
        account_id: funds[1]
      }
    });

    return response;
  };

  const startBatch = async (
    transactionSelected: BatchDetailType,
    account: string
  ) => {
    try {
      const transaction: any = await customerTransaction(
        transactionSelected,
        account
      );
      return {
        transaction
      };
    } catch (error) {
      throw parseErrorFromAxiosCall(error);
    }
  };
  const rejectTransaction = async (values: BatchDetailType, reason: string) => {
    const response = await postData({
      path: `batch-payments/${values.id}/reject`,
      apiType: ApiServiceType.PAYMENT,
      data: {
        reason: reason
      }
    });

    return response;
  };
  const requestRejectBatchPayment = async (
    transactionSelected: BatchDetailType,
    reason: string
  ) => {
    try {
      const transaction: any = await rejectTransaction(
        transactionSelected,
        reason
      );
      return {
        status: 200,
        details: 'Ok'
      };
    } catch (error) {
      throw parseErrorFromAxiosCall(error);
    }
  };
  return {
    batchPaymentFetching: isFetching,
    uploadBatchPaymentFile,
    requestApprovalBatchPayment,
    startBatch,
    requestRejectBatchPayment,
    abortController
  };
};
export default usePostBatchPaymentProcess;
