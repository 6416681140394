/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAxiosCall } from '../../services/api';
import { useState } from 'react';
import { ApiConfig } from '../../types/models/api/apiConfig';
import { getHeaders } from './getHeaders';
import { useGetProfileState } from '../../store/profile/selector';

interface Response {
  status: number;
  data: any;
  success: boolean;
}

const useDeleteData = (): {
  isFetching: boolean;
  deleteData: (config: ApiConfig) => Promise<any>;
} => {
  const [isFetching, setIsFetching] = useState(false);
  const { accessToken } = useGetProfileState();

  const deleteData = async ({
    path,
    config,
    headers,
    params,
    apiType,
    useAuth = true,
    token = '',
    controller
  }: ApiConfig): Promise<any> => {
    const API_TYPE = apiType;

    try {
      setIsFetching(true);
      const authHeaders = getHeaders(useAuth, accessToken);

      const AXIOS = getAxiosCall(API_TYPE);

      const response = await AXIOS.delete<Response>(path, {
        headers: {
          ...authHeaders,
          ...headers
        },
        signal: controller?.signal
      });

      return response.data;
    } catch (err) {
      if (controller?.signal.aborted) {
        console.log('The user aborted the request');
      }

      throw err;
    } finally {
      setIsFetching(false);
    }
  };

  return { isFetching, deleteData };
};

export default useDeleteData;
