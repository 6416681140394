import './index.scss';

import { Label } from 'reactstrap';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getIconMapper } from '../../../utils/iconMap';

interface Props {
  fileSizeInMB: number;
  type: string[];
  id: string;
  feedback: string | JSX.Element;
  label: string;
  testID: string;
  readOnly: boolean;
  disabled?: boolean;
  valid: boolean;
  className?: string;
  labelDescription?: string;
  onUploadFile: (file: File | null, isValidFileSize: boolean) => void;
  clearFile: () => void;
  uploadFileName?: string;
  downloadReceipt: () => void;
}

const MiniFileUploadInput: React.FC<Props> = ({
  fileSizeInMB,
  onUploadFile,
  clearFile,
  type,
  testID,
  className = '',
  labelDescription,
  id,
  feedback,
  label,
  readOnly = false,
  disabled = false,
  valid,
  uploadFileName,
  downloadReceipt
}) => {
  const [isActive, setIsActive] = useState(false);

  const [isTouched, setIsTouched] = useState(false);
  const [fileName, setFileName] = useState('');

  const [currentFeedback, setCurrentFeedback] = useState(<></>);

  useEffect(() => {
    setCurrentFeedback(<>{feedback}</>);
    if (feedback === 'error log') {
      setCurrentFeedback(
        <>
          There were errors found with some Lynk accounts. Please check the{' '}
          <strong
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={downloadReceipt}
          >
            error logs
          </strong>
          , fix errors and try again.
        </>
      );
    }
  }, [feedback]);

  const totalFileTypesAccepted = useMemo(() => {
    return type.join(',');
  }, [type]);

  useEffect(() => {
    setFileName(uploadFileName || '');

    if (uploadFileName) setIsTouched(true);
  }, [uploadFileName]);

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const confirmFileSize = (file: File | null) => {
    if (!file) return false;

    const isValidFileSize = file.size > 0 && file.size <= fileSizeInMB * 1024;
    return isValidFileSize;
  };
  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = '';
  };
  return (
    <div
      data-testid={`${testID}__container`}
      className={`text-input ${className}`}
    >
      <Label
        data-testid={`${testID}__label`}
        className={`text-input__label ${isActive && valid && 'active'} ${
          (readOnly || disabled) && 'disabled'
        } ${isTouched && !valid ? 'is-invalid' : ''}`}
        for={id}
      >
        {label}
      </Label>
      <div className="text-input__description">{labelDescription}</div>
      <div
        className={`miniFileUploadContainer ${isActive && valid && 'active'} ${
          readOnly && 'disabled'
        } ${isTouched && !valid && 'is-invalid'}`}
        style={{ position: 'relative', height: 45 }}
      >
        <input
          type="file"
          id="miniUpload-btn"
          hidden
          accept={totalFileTypesAccepted}
          ref={inputFileRef}
          onChange={event => {
            if (!isTouched) setIsTouched(true);
            const currentFile = event.target.files
              ? event.target.files[0]
              : null;
            if (currentFile)
              onUploadFile(currentFile, confirmFileSize(currentFile));
          }}
          onFocus={() => !readOnly && setIsActive(true)}
          onBlur={() => !readOnly && setIsActive(false)}
          onClick={event => handleClick(event)}
        />

        <label
          className="fileLabel"
          htmlFor="miniUpload-btn"
          style={{
            width: '100%',
            marginBottom: 0,
            fontWeight: 400,
            color: `${!fileName ? '#8A99A1' : '#153243'}`
          }}
        >
          {fileName || 'Select a file'}
        </label>
        <div
          className={`uploadContainer ${isTouched && !valid && 'error'}`}
          onClick={() => clearFile()}
        >
          {getIconMapper(
            !isTouched || valid ? 'miniUploadIcon' : 'close',
            `uploadIcon ${isTouched && !valid && 'error'}`
          )}
        </div>
      </div>

      <div
        data-testid={`${testID}__feedback`}
        className={`text-input__feedback ${!valid && 'is-invalid'}`}
      >
        {currentFeedback}
      </div>
    </div>
  );
};

export default MiniFileUploadInput;
