import { MerchantDetails } from '../../types/models/merchantDetails';
import { OrganizationState } from '.';
import { RootState } from '..';
import { useSelector } from 'react-redux';

export const useGetOrganizationState = (): OrganizationState =>
  useSelector((state: RootState) => state.Organization);

export const useIsLoadingProfileDetail = (): boolean =>
  useSelector((state: RootState) => state.Organization.loading);

export const useGetProfileDetail = (): MerchantDetails =>
  useSelector((state: RootState) => state.Organization.profile);
export const useGetProfileParentID = (): string =>
  useSelector((state: RootState) => state.Organization.parent_id);
export const useGetOverallBalance = (): number =>
  useSelector((state: RootState) => state.Organization.balance);
export const useGetOverallJAMDEXBalance = (): number =>
  useSelector((state: RootState) => state.Organization.balanceJAMDEX);
export const useGetQRCodeDetail = (): any =>
  useSelector((state: RootState) => {
    //Todo: change to use the previous profile detail
    const profileDetails = { ...state.Organization.profile };

    return {
      qr_code: profileDetails.qr_code,
      qr_image: profileDetails.qr_image,
      username: profileDetails.username,
      name: profileDetails.merchant
    };
  });
