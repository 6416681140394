import './index.scss';

import { MDBCol, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';

import React, { useContext } from 'react';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Col, Row } from 'reactstrap';
import { DataDisplayContext } from '../../../hooks/utils/tableConfig';

const DataDisplayPagination: React.FC = () => {
  const { paginationDetails } = useContext(DataDisplayContext);

  const firstPageHandler = () => {
    if (paginationDetails.currentPage !== 1) paginationDetails.pagingHandler(1);
  };

  const lastPageHandler = () => {
    if (paginationDetails.currentPage !== paginationDetails.totalPageCount)
      paginationDetails.pagingHandler(paginationDetails.totalPageCount);
  };

  const previousHandler = () => {
    if (paginationDetails.currentPage > 1)
      paginationDetails.pagingHandler(paginationDetails.currentPage - 1);
  };

  const nextHandler = () => {
    if (paginationDetails.currentPage < paginationDetails.totalPageCount)
      paginationDetails.pagingHandler(paginationDetails.currentPage + 1);
  };

  return (
    <Row className="table-footer">
      <Col xs={12} md={6} className="order-sm-2 order-md-1 pagerDetails">
        <div className="table-footer__text">
          <span>Showing</span>
          <span data-testid="elementRangeInitial">
            {paginationDetails.elementRange.initial}
          </span>
          -
          <span data-testid="elementRangeFinal">
            {paginationDetails.elementRange.final}
          </span>
          of&nbsp;
          <span data-testid="itemCount">{paginationDetails.totalItems}</span>
          entries
        </div>
      </Col>
      <Col xs={12} md={6} className="order-sm-1 order-md-2 pagerItem">
        <MDBRow>
          <MDBCol>
            <MDBPagination className="table-footer__pagination">
              <MDBPageNav
                className={`firstPageBtn${
                  paginationDetails.currentPage === 1
                    ? ' disabled'
                    : ' not-disabled'
                }`}
                aria-label="First Page"
                data-testid="firstPageBtn"
                onClick={firstPageHandler}
              >
                <span aria-hidden="true">
                  <SkipPreviousIcon />
                </span>
              </MDBPageNav>
              <MDBPageNav
                className={`${
                  paginationDetails.currentPage === 1
                    ? 'disabled'
                    : 'not-disabled'
                }`}
                aria-label="Previous page"
                data-testid="prevPageBtn"
                onClick={previousHandler}
              >
                <span aria-hidden="true">
                  <ChevronLeftIcon />
                </span>
              </MDBPageNav>
              <MDBPageNav
                className={`${
                  paginationDetails.currentPage ===
                  paginationDetails.totalPageCount
                    ? 'disabled'
                    : 'not-disabled'
                }`}
                aria-label="Next Page"
                data-testid="nextPageBtn"
                onClick={nextHandler}
              >
                <span aria-hidden="true">
                  <ChevronRightIcon />
                </span>
              </MDBPageNav>
              <MDBPageNav
                className={`lastPageBtn${
                  paginationDetails.currentPage ===
                  paginationDetails.totalPageCount
                    ? ' disabled'
                    : ' not-disabled'
                }`}
                aria-label="Last Page"
                data-testid="lastPageBtn"
                onClick={lastPageHandler}
              >
                <span aria-hidden="true">
                  <SkipNextIcon />
                </span>
              </MDBPageNav>
            </MDBPagination>
          </MDBCol>
        </MDBRow>
      </Col>
    </Row>
  );
};

export default DataDisplayPagination;
