import { TableStateAction, defaultFilter, initialState } from './innerState';

import { TransactionStateKey } from '../../../hooks/utils/tableConfig/transactionState';
import { createSlice } from '@reduxjs/toolkit';

export const tableStateSlice = createSlice({
  name: 'tableState',
  initialState,
  reducers: {
    refreshTable: (state, action) => {
      state.refreshTable = action.payload;
    },
    clearRefresh: state => {
      state.refreshTable = undefined;
    },
    getTableMetadata: (
      state,
      action: { type: string; payload: TableStateAction }
    ) => {
      return state;
    },
    updateTableMetadata: (
      state,
      action: { type: string; payload: TableStateAction }
    ) => {
      const { key, filter } = action.payload;

      state[key] = { ...state[key], ...filter };
    },
    resetTableMetadata: (
      state,
      action: { type: string; payload: { key: TransactionStateKey } }
    ) => {
      state.refreshTable = undefined;
      state[action.payload.key] = {
        ...state[action.payload.key],
        ...defaultFilter
      };
    }
  }
});

export const {
  refreshTable,
  clearRefresh,
  getTableMetadata,
  updateTableMetadata,
  resetTableMetadata
} = tableStateSlice.actions;

export default tableStateSlice.reducer;
