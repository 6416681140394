import React, { useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import LynkModal from '../../../../atoms/LynkModal';
import { hideModal, showModal } from '../../../../../store/modals/modal';
import BatchPaymentsWidget from '../../../../molecules/BatchPaymentsWidget';
import SinglePaymentsWidget from '../../../../molecules/SinglePaymentWidget';

const CreatePaymentsModal: React.FC = () => {
  const dispatch = useDispatch();

  const [createSinglePayment, setCreateSinglePayment] = useState(false);
  const [createBatchPayment, setCreateBatchPayment] = useState(false);

  const onCloseHandler = () => {
    dispatch(hideModal());
  };

  const selectPaymentTypeAction = () => {
    createBatchPayment
      ? dispatch(showModal({ modalKey: 'batch-payments' }))
      : dispatch(showModal({ modalKey: 'single-payment' }));
  };

  const selectBP = () => {
    setCreateBatchPayment(!createBatchPayment);
    setCreateSinglePayment(false);
  };

  const selectSP = () => {
    setCreateSinglePayment(!createSinglePayment);
    setCreateBatchPayment(false);
  };

  return (
    <LynkModal
      title={'Create a Payment'}
      subTitle={'Please choose the type of payment you want to create'}
      loading={false}
      show={true}
      closeAction={{
        //TODO: Add test
        testID: 'logoutModalButtonCancel',
        onClickFn: onCloseHandler,
        text: 'Cancel',
        showButton: true
      }}
      mainAction={{
        testID: 'logoutModalButtonCancel',
        onClickFn: selectPaymentTypeAction,
        text: 'Next',
        disabled: !createBatchPayment && !createSinglePayment
      }}
      modalBody={
        <div className="createPayments__container">
          <div onClick={selectBP} className={'createPayments__bpWidget'}>
            <BatchPaymentsWidget
              disableClick={true}
              isActive={createBatchPayment}
            />
          </div>

          <div onClick={selectSP}>
            <SinglePaymentsWidget
              disableClick={true}
              isActive={createSinglePayment}
            />
          </div>
        </div>
      }
    />
  );
};

export default CreatePaymentsModal;
