import React, { useEffect, useState } from 'react';

import CorporateTemplate from '../../templates/CorporateTemplate';
import { useGetProfileState } from '../../../store/profile/selector';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';

import { MenuTab } from '../../../types/models/tabsType';
import { useDispatch } from 'react-redux';
import { updateCurrentWallet } from '../../../store/walletManagement';
import { useNavigate } from 'react-router-dom';
import { useGetProfileDetail } from '../../../store/organization/selector';
import useGetProfile from '../../../hooks/utils/useGetProfile';
import { usePermissions } from '../../../features/permissions';
import { restartBalance } from '../../../store/organization';
import { setBreadcrumbs } from '../../../features/breadcrumbs';

const CorporatePage: React.FC = () => {
  const { id } = useGetProfileState();
  const { userPermissions: permissions } = usePermissions();
  const [tabs, setTabs] = useState<MenuTab[]>([]);
  const [widgets, setWidgets] = useState<JSX.Element[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const profile = useGetProfileState();
  const profile = useGetProfileDetail();
  const { isAdmin } = useGetProfile();

  const { getValidCorporateWidgets, getValidTabComponents } =
    useDashboardSetup();
  const redirectTo = async () => {
    const admin = await isAdmin(id);
    if (admin) {
      navigate('/');
    } else {
      navigate(`./${profile.organization_id.replace('org_', '')}`);
    }
  };
  useEffect(() => {
    if (id) {
      redirectTo();
    } else {
      navigate(`./${profile.organization_id.replace('org_', '')}`);
    }
    dispatch(restartBalance());
    dispatch(updateCurrentWallet('corporate'));
  }, [profile.organization_id, id]);

  useEffect(() => {
    setTabs(getValidTabComponents());
    setWidgets(getValidCorporateWidgets());
  }, [permissions]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          name: profile.merchant,
          path: ''
        }
      ])
    );
  }, [profile.merchant]);

  return (
    <div className="singlePageDashboard">
      {profile.organization_id &&
        profile.organization_id != '' &&
        permissions.size !== 0 && (
          <CorporateTemplate componentsToRender={widgets} menu={tabs} />
        )}
    </div>
  );
};

export default CorporatePage;
