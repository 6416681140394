import { AlertMetadata, AlertMetadataState } from '../../store/state';

import { createSlice } from '@reduxjs/toolkit';

const initialState: AlertMetadataState = {
  alerts: []
};

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    pushAlert: (state, action) => {
      return state;
    },
    popAlert: (state, action) => {
      return state;
    },
    popScope: (state, action) => {
      return state;
    },
    addAlertMetadata: (
      state,
      action: { payload: AlertMetadata; type: string }
    ) => {
      const filteredList = state.alerts.filter(
        status => status.scope !== action.payload.scope
      );

      return {
        ...state,
        alerts: [...filteredList, action.payload]
      };
    },
    popAlertMetadata: (state, action: { payload: string; type: string }) => {
      const filteredList = state.alerts.filter(
        status => status.key !== action.payload
      );

      return {
        ...state,
        alerts: [...filteredList]
      };
    },
    clearScope: (state, action: { payload: string; type: string }) => {
      const filteredList = state.alerts.filter(
        status => status.scope !== action.payload
      );

      return {
        ...state,
        alerts: [...filteredList]
      };
    }
  }
});

export const {
  pushAlert,
  popAlert,
  popScope,
  addAlertMetadata,
  popAlertMetadata,
  clearScope
} = appStatusSlice.actions;

export default appStatusSlice.reducer;
