import BalanceValue from '../../molecules/BalanceValue';
import React from 'react';
import './index.scss';

interface Props {
  loadBalance: () => void;
  loading?: boolean;
  widgetIcon: JSX.Element;
  widgetTitle: string;
  balanceDetails: {
    amount: number;
    currency: string;
  };
}

const WalletWidget: React.FC<Props> = ({
  loadBalance,
  widgetIcon,
  widgetTitle,
  balanceDetails,
  loading = false
}) => {
  return (
    <div className="lynkBalanceWidget">
      <div className="lynkBalanceWidget__titleContent">
        <div className="lynkBalanceWidget__titleContent--titleLeftContent">
          <div className="lynkBalanceWidget__titleContent--titleLeftContent_icon">
            {widgetIcon}
          </div>
          <div className="lynkBalanceWidget__titleContent--titleLeftContent_text">
            {widgetTitle}
          </div>
        </div>
      </div>
      <div className="lynkBalanceWidget__balanceContent">
        <BalanceValue
          currency={balanceDetails.currency}
          amount={balanceDetails.amount}
          loading={loading}
          loadBalance={loadBalance}
        />
      </div>
    </div>
  );
};

export default WalletWidget;
