import React, { useEffect, useState } from 'react';

import WalletWidget from '../WalletWidget';
import { getIconMapper } from '../../../utils/iconMap';
import useGetWalletBalance from '../../../hooks/merchant_portal/useGetWalletBalance';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { useGetOverallBalance } from '../../../store/organization/selector';

const LynkBalanceWidget: React.FC = () => {
  const { fetching, getWalletBalance, abortController } = useGetWalletBalance();
  const walletManager = useGetWalletManager();

  const [balance, setBalance] = useState(0);
  const overallBalance = useGetOverallBalance();
  useEffect(() => {
    return () => abortController.abort();
  }, []);

  const loadBalance = async () => {
    try {
      const balance = await getWalletBalance('lynk');
      setBalance(
        walletManager.currentWallet === 'corporate'
          ? balance + overallBalance
          : balance
      );
    } catch (error) {
      setBalance(
        walletManager.currentWallet === 'corporate' ? overallBalance : 0
      );
    }
  };

  return (
    <WalletWidget
      loading={fetching}
      widgetIcon={getIconMapper('lynk-only-white')}
      widgetTitle={`${
        walletManager.currentWallet === 'corporate' ? 'Overall' : 'Lynk'
      } balance`}
      balanceDetails={{
        amount: balance,
        currency: 'JMD'
      }}
      loadBalance={loadBalance}
    />
  );
};

export default LynkBalanceWidget;
