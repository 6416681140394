import './index.scss';

import React, { useEffect, useMemo, useState } from 'react';
import Tabs from '../../molecules/Tabs';

import LynkBalanceWidget from '../../organisms/LynkBalanceWidget';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import {
  RefreshDetails,
  SinglePageTemplateProps
} from '../../../types/models/singlePage.Type';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import JAMDEXWallet from '../../organisms/JAMDEXWallet';
import RoutingTabs from '../../molecules/RoutingTabs';
import AccountsLimitWidget from '../../organisms/AccountsLimitWidget';

const SinglePageTemplate: React.FC<SinglePageTemplateProps> = ({
  componentsToRender,
  menu,
  selectedTab
}) => {
  const [validComponents, setValidComponents] = useState<JSX.Element[]>([]);
  const walletManager = useGetWalletManager();

  useEffect(() => {
    setValidComponents(componentsToRender);
  }, [componentsToRender]);

  // const stateVal = useGetTransactionTableState();
  // const shouldRefreshTableDetails = useMemo(() => {
  //   const refreshDetails: RefreshDetails = {
  //     selectedTab: selectedTab || undefined,
  //     refreshContent: false
  //   };
  //   if (stateVal.refreshTable) {
  //     refreshDetails.selectedTab = stateVal.refreshTable || selectedTab;
  //     refreshDetails.refreshContent = true;
  //   }

  //   return refreshDetails;
  // }, [stateVal]);

  const mobileWidget = () => {
    const widget =
      walletManager.currentWallet === 'lynk' ? (
        <>
          <LynkBalanceWidget />
          <AccountsLimitWidget />
        </>
      ) : (
        <JAMDEXWallet />
      );
    return widget;
  };
  return (
    <div className="singlePageDashboard">
      <div className="dashboardCarousel">{validComponents}</div>
      <div className="dashboardCarousel--mini">{mobileWidget()}</div>
      <RoutingTabs menuTabs={menu} />
    </div>
  );
};

export default SinglePageTemplate;
