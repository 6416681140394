import { SelectorInputProps } from '../../../types/models/formType';
import React, { useEffect, useState } from 'react';
import { Input, InputProps, Label } from 'reactstrap';
import './index.scss';

const SelectorInput: React.FC<SelectorInputProps> = ({
  label,
  labelDescription,
  testID = 'selector',
  readOnly = false,
  className,
  feedback,
  options,
  defaultLabelOption,
  required,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState<
    string | string[] | undefined
  >(undefined);

  useEffect(() => {
    setCurrentFeedback(feedback);
  }, [feedback]);

  const generateFeeback = () => {
    if (Array.isArray(feedback) && feedback.length > 0) {
      return (currentFeedback as string[]).map((result, index) => (
        <div key={index}>{result}</div>
      ));
    }

    return <>{currentFeedback}</>;
  };

  return (
    <div
      data-testid={`${testID}__container`}
      className={`text-input ${className ? className : ''}`}
    >
      <Label
        style={{ display: 'flex' }}
        data-testid={`${testID}__label`}
        className={`text-input__label ${isActive && 'active'} ${
          (readOnly || props.disabled) && 'disabled'
        } ${props.invalid && 'is-invalid'}`}
        for={props?.id}
      >
        {label}
        {required && (
          <div data-testid="requiredLabel" className="requiredTag">
            (required)
          </div>
        )}
      </Label>
      <div className="text-input__description">{labelDescription}</div>
      <div
        data-testid={`${testID}__holder`}
        className={`textInput_holder ${className} ${
          isActive && !props.invalid && 'active'
        } ${readOnly && 'disabled'} ${props.invalid && 'is-invalid'}`}
      >
        <Input
          type="select"
          data-testid={testID}
          onFocus={() => !readOnly && setIsActive(true)}
          onBlur={() => !readOnly && setIsActive(false)}
          {...props}
        >
          {!!defaultLabelOption && (
            <option
              defaultChecked
              data-testid={`${testID}__defaultOption`}
              value={defaultLabelOption.value || ''}
            >
              {defaultLabelOption.label}
            </option>
          )}
          {options.map((option, index) => (
            <option key={index} value={option.value || option.label}>
              {option.label}
            </option>
          ))}
        </Input>
      </div>
      {!!currentFeedback && (
        <div
          data-testid={`${testID}__feedback`}
          className={`text-input__feedback ${props.invalid && 'is-invalid'}`}
        >
          {generateFeeback()}
        </div>
      )}
    </div>
  );
};

export default SelectorInput;
