import { BaseColumnProps } from '../../../types/models/tableType';
import React from 'react';

const BaseColFormatter: React.FC<BaseColumnProps> = ({ column, row }) => {
  return (
    <>
      {column?.format
        ? column.format(row[column.field] || '')
        : row[column.field]}
    </>
  );
};

export default BaseColFormatter;
