import './index.scss';

import { ReactComponent as LynkLogoOnly } from '../../../assets/svg/dark/LogoLink.svg';
import React from 'react';
import { ButtonAsyncLoaderProps } from '../../../types/models/ButtonsTypes';

const ButtonAsyncLoader: React.FC<ButtonAsyncLoaderProps> = ({
  showLoaderText,
  loadingText = 'Please wait',
  loadingIcon = <LynkLogoOnly />
}) => {
  return (
    <div data-testid="buttonAsyncLoader" className="buttonAsyncLoader">
      <div data-testid="loader" className="loader">
        {showLoaderText && (
          <div data-testid="buttonAsyncLoader-text">{loadingText}</div>
        )}
        <div className="status">{loadingIcon}</div>
      </div>
    </div>
  );
};

export default ButtonAsyncLoader;
