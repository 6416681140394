import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { Permission, checkIfPermissionsInclude } from '..';

type PermissionsContextData = {
  userPermissions: Map<string, Set<string>>;
  hasPermissions: (permissions: Permission[]) => boolean;
  setUserPermissions: (permissions: Map<string, Set<string>>) => void;
};

const PermissionsContext = createContext<PermissionsContextData>({
  userPermissions: new Map<string, Set<string>>(),
  hasPermissions: () => false,
  setUserPermissions: () => {}
});

export const PermissionsProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const [userPermissions, setUserPermissions] = useState<
    Map<string, Set<string>>
  >(new Map<string, Set<string>>());

  const hasPermissions = (permissions: Permission[]): boolean => {
    return checkIfPermissionsInclude(userPermissions, permissions);
  };

  return (
    <PermissionsContext.Provider
      value={{ userPermissions, hasPermissions, setUserPermissions }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePermissions() {
  const context = useContext(PermissionsContext);

  if (!context)
    throw new Error('useContext must be used within a PermissionsProvider');

  return context;
}
