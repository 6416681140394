import { getIconMapper, IconKey } from '../../../utils/iconMap';
import React, { useMemo, useState } from 'react';
import './index.scss';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Skeleton from 'react-loading-skeleton';

export interface SelectorItemProp {
  icon?: IconKey;
  label: string;
  value: string;
  key: string;
}

interface Props {
  label: string;
  activeItem?: string;
  options: SelectorItemProp[];
  loading: boolean;
  onSelect: (value: { selectedValue: string | number; key: string }) => void;
  disabled: boolean;
  defaultSelectedItem: SelectorItemProp;
}

interface SelectorItemPropBody extends SelectorItemProp {
  loading: boolean;
}

const FormattedSelectorItem: React.FC<SelectorItemPropBody> = ({
  icon,
  label,
  value,
  loading
}) => {
  let Icon = <></>;

  if (icon) {
    Icon = getIconMapper(icon);
  }

  return (
    <>
      {loading ? (
        <div className="selectorLoader">
          <Skeleton
            className="iconLoading"
            width={35}
            height={35}
            baseColor="#D9D9D9"
          />
          <div className="detailsLoadingGroup">
            <Skeleton width={165} height={10} baseColor="#D9D9D9" />
            <Skeleton width={121} height={10} baseColor="#D9D9D9" />
          </div>
        </div>
      ) : (
        <div className="selectorBaseInner">
          {icon && <div className="selectorBaseInner--icon">{Icon}</div>}
          <div className="selectorBaseInner--text">{label}</div>
        </div>
      )}
    </>
  );
};

const FormattedSelector: React.FC<Props> = ({
  label,
  activeItem,
  options,
  onSelect,
  loading,
  disabled,
  defaultSelectedItem
}) => {
  const activeSelectedItem = useMemo(() => {
    if (!activeItem || activeItem === '') {
      if (options.length === 1) {
        onSelect({
          selectedValue: options[0].value,
          key: options[0].key
        });
        return options[0];
      }
      return defaultSelectedItem;
    }

    return (
      options.find(option => option.value == activeItem) || defaultSelectedItem
    );
  }, [activeItem, options]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {loading ? (
        <Skeleton
          width={141}
          height={15}
          baseColor="#D9D9D9"
          className="titleLoading"
        />
      ) : (
        <div className={`selectorTitle ${isOpen ? 'active' : 'inactive'}`}>
          {label}
        </div>
      )}
      <UncontrolledDropdown
        data-testid="formatterSelectorID"
        onToggle={() => setIsOpen(!isOpen)}
        className={`boxSelector ${isOpen ? 'active' : 'inactive'} ${
          disabled || options.length < 1 || loading ? 'disabled' : ''
        }`}
      >
        <DropdownToggle outline caret size="lg" className="selectorBase">
          <FormattedSelectorItem
            loading={loading}
            icon={activeSelectedItem.icon as IconKey}
            label={activeSelectedItem.label}
            value={activeSelectedItem.value}
            key={activeSelectedItem.key}
          />

          {!(options.length < 1 || loading) ? (
            <>
              {isOpen ? (
                <KeyboardArrowUpIcon className="active" />
              ) : (
                <KeyboardArrowDownIcon className="inactive" />
              )}
            </>
          ) : (
            <></>
          )}
        </DropdownToggle>
        {options.length >= 1 && !loading ? (
          <DropdownMenu className="selectorBaseMenu">
            {options.map(option => (
              <>
                <DropdownItem
                  className="selectorBaseMenu--Item"
                  data-testid="formatterOption"
                  onClick={() =>
                    onSelect({
                      selectedValue: option.value,
                      key: option.key
                    })
                  }
                >
                  <FormattedSelectorItem
                    key={option.value}
                    loading={false}
                    icon={option.icon}
                    label={option.label}
                    value={option.value}
                  />
                </DropdownItem>
              </>
            ))}
          </DropdownMenu>
        ) : (
          <></>
        )}
      </UncontrolledDropdown>
    </>
  );
};

export default FormattedSelector;
