/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import './index.scss';

import {
  BatchColumHeaderInfo,
  ColumHeaderBranchesInfo,
  ColumHeaderInfo,
  CustomerColumHeaderInfo,
  LastExportListDetailType,
  NotificationsColumHeaderInfo,
  PaymentDetailColumHeaderInfo,
  RefundColumHeaderInfo,
  StaffColumHeaderInfo
} from '../../../types/models/tableTransactions';

import { amountFormated } from '../../../utils/currency';
import moment from 'moment';
import { HelpOutlineOutlined } from '@mui/icons-material';

export const RefundColumnHeaders: RefundColumHeaderInfo[] = [
  {
    label: 'Order ID',
    field: 'transactionID'
  },
  {
    label: 'Transfer ID',
    field: 'refund_transfer_id'
  },
  {
    label: 'External Transaction ID',
    field: 'external_transaction_id',
    format: (value: string) => (value.toString() !== 'null' ? value : '-')
  },
  {
    label: 'From',
    field: 'from'
  },
  {
    label: 'To',
    field: 'to'
  },
  {
    label: 'Date',
    field: 'date',
    sorter: true,
    format: (value: string) => moment(value).format('DD/MM/YYYY HH:mm a')
  },
  {
    label: 'Total',
    field: 'amount_to_refund',
    format: (value: string) => amountFormated(parseFloat(!value ? '' : value)),
    tooltip: true
  },
  {
    label: '',
    field: 'action',
    button: true,
    permissions: [{ resource: 'refunds', actions: new Set(['create']) }]
  }
];

export const TransferColumnHeaders: ColumHeaderInfo[] = [
  {
    label: 'Transaction ID',
    field: 'transfer_id'
  },
  {
    label: 'From',
    field: 'from'
  },
  {
    label: 'To',
    field: 'to'
  },
  {
    label: 'Date',
    field: 'datetime',
    sorter: true,
    format: (value: string) => {
      return moment(value).format('DD/MM/YYYY HH:mm a');
    }
  },
  {
    label: 'Total',
    field: 'total',
    format: (value: string) => amountFormated(parseFloat(!value ? '' : value))
  },
  {
    label: 'Type',
    field: 'type',
    show: (walletType: string) => walletType === 'lynk'
  },
  {
    label: 'Status',
    field: 'status',
    tooltip: true
  },
  {
    label: '',
    field: 'action',
    button: true
  }
];
const ColumnData = <>?</>;
export const BranchesColumnHeaders: ColumHeaderBranchesInfo[] = [
  {
    label: 'Name',
    key: 'Name',
    field: 'name'
  },
  {
    label: 'Lynk Balance',
    key: 'Lynk Balance',
    field: 'organization_id'
  },
  {
    label: <>Cash In Limit</>,
    key: 'Cash In Limit',
    field: 'organization_id'
  },
  {
    label: <>Cash Out Limit</>,
    key: 'Cash Out Limit',
    field: 'organization_id'
  },
  {
    label: <>Payments Limit</>,
    key: 'Payments Limit',
    field: 'organization_id'
  },
  {
    label: 'View Details',
    key: 'View Details',
    field: 'action',
    button: true
  }
];

export const TransactionColumnHeaders: CustomerColumHeaderInfo[] = [
  {
    label: 'Order ID',
    field: 'm_order_id'
  },
  {
    label: 'Transfer ID',
    field: 'transfer_id'
  },
  {
    label: 'From',
    field: 'lynk_id'
  },
  {
    label: 'Date',
    field: 'datetime',
    format: (value: string) => {
      return moment(value).format('DD/MM/YYYY HH:mm a');
    }
  },
  {
    label: 'Total',
    field: 'amount_to_pay',
    format: (value: string) => amountFormated(Number.parseFloat(value))
  },
  {
    label: 'Commission',
    field: 'fee',
    format: (value: string) => amountFormated(Number.parseFloat(value))
  },
  {
    label: 'GCT',
    field: 'tax',
    format: (value: string) => amountFormated(Number.parseFloat(value))
  },
  {
    label: 'Status',
    field: 'status',
    tooltip: true
  },
  {
    label: '',
    field: 'action',
    button: true
  }
];

export const StaffColumnHeaders: StaffColumHeaderInfo[] = [
  {
    label: 'Name',
    field: 'display_name'
  },
  {
    label: 'Email',
    field: 'email'
  },
  {
    label: 'Role',
    field: 'role'
  },
  {
    label: 'Status',
    field: 'active',
    format: (value: string | boolean) => (value ? 'Active' : 'Inactive')
  },
  {
    label: 'Action',
    field: 'action',
    permissions: [{ resource: 'staff', actions: new Set(['update', 'delete']) }]
  },
  {
    label: '',
    field: 'action',
    button: true
  }
];

export interface ExportReportHeaderInfo {
  label: string;
  field: keyof LastExportListDetailType;
  tooltip?: boolean;
  button?: boolean;
  format?: (value: string) => string;
  buttonCheck?: (value: string) => boolean;
}

export const ExportReportColumnHeaders: ExportReportHeaderInfo[] = [
  {
    label: 'Date',
    field: 'date'
  },
  {
    label: 'Tables',
    field: 'tables'
  },
  {
    label: '',
    field: 'status',
    button: true,
    buttonCheck: (value: string) => value === 'completed'
  }
];

export const PaymentDetailColumnHeaders: PaymentDetailColumHeaderInfo[] = [
  {
    label: 'Transfer ID',
    field: 'id'
  },
  {
    label: 'Date',
    field: 'date',
    format: (value: string) => moment(value).format('DD/MM/YYYY HH:mm a')
  },
  {
    label: 'From',
    field: 'from_id'
  },
  {
    label: 'Total',
    field: 'amount',
    format: (value: string) => amountFormated(Number.parseFloat(value))
  },
  {
    label: 'Status',
    field: 'status',
    tooltip: true
    //format: (value: string) => amountFormated(Number.parseFloat(value))
  }
];
export const NotificationsHeaders: NotificationsColumHeaderInfo[] = [
  {
    label: '',
    field: 'display_name'
  }
];
export const BatchColumnHeaders: BatchColumHeaderInfo[] = [
  {
    label: 'Payment ID',
    field: 'id'
  },
  {
    label: 'Payment Name',
    field: 'name'
  },
  {
    label: 'Date',
    field: 'created_at',
    sorter: true,
    format: (value: string) => moment(value).format('DD/MM/YYYY HH:mm a')
  },
  {
    label: 'Lynk Fee',
    field: 'lynk_fee',
    format: (value: string) => amountFormated(parseFloat(!value ? '' : value))
  },
  {
    label: 'GCT',
    field: 'lynk_fee_gct',
    format: (value: string) => amountFormated(parseFloat(!value ? '' : value))
  },
  {
    label: 'Total',
    field: 'total',
    format: (value: string) => amountFormated(parseFloat(!value ? '' : value))
  },
  {
    label: 'Created by',
    field: 'created_by'
  },
  {
    label: 'Reviewed by',
    field: 'reviewed_by'
  },
  {
    label: 'Status',
    field: 'status'
  },
  {
    label: 'Actions',
    field: 'action',
    button: true
  }
];
