import { FilterRangeObject } from '../../../types/models/dateTypes';
import { SortingAscDesc } from '../../../types/models/utils/table-models';
import { TransactionStateKey } from '../../../hooks/utils/tableConfig/transactionState';
import { defaultFilterRange } from '../../../hooks/utils/tableConfig/defaultProps';

export interface TransacFilter {
  tableFilter: {
    dateRange: FilterRangeObject;
    group?: string;
  };
  currentOffset: number;
  sort: SortingAscDesc;
  scrollPoint?: any;
}

// export interface TransacFilterObj {
//   tableFilter: {
//     dateRange: {
//       startRange: string;
//       endRange: string;
//     };
//   };
//   currentOffset: number;
//   sort: SortingAscDesc;
//   scrollPoint?: any;
// }

export type TransacFilterState = {
  [key in TransactionStateKey]: TransacFilter;
};

export interface TransFilterState extends TransacFilterState {
  refreshTable: TransactionStateKey | undefined;
}

export const defaultFilter: TransacFilter = {
  tableFilter: {
    dateRange: defaultFilterRange,
    group: ''
  },
  currentOffset: 0,
  sort: SortingAscDesc.DESC
};

export const initialState: TransFilterState = {
  refreshTable: undefined,
  transfers: defaultFilter,
  transactions: defaultFilter,
  refund: defaultFilter,
  staff: defaultFilter,
  batch: defaultFilter,
  notifications: defaultFilter,
  branches: defaultFilter,
  payment: {
    tableFilter: {
      dateRange: defaultFilterRange,
      group: ''
    },
    currentOffset: 1,
    sort: SortingAscDesc.DESC
  }
};

export interface TableStateAction {
  key: TransactionStateKey;
  filter: Partial<TransacFilter>;
}
