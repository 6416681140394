import CashierRoleDetails from '../../../../atoms/CashierRoleDetails';
import ManagerRoleDetails from '../../../../atoms/ManagerRoleDetails';
import Env from '../../../../../helpers/Env';
import React from 'react';

const useUserModalHook = (): {
  getRoleIDByDescription: (role: string, options: any[]) => string;
  convertStatusToYN: (active: boolean) => 'yes' | 'no';
  getRoleDescription: (selectedRole: number) => JSX.Element;
} => {
  const getRoleIDByDescription = (role: string, options: any[]): string => {
    const foundRoles = options.filter(roleOpt => roleOpt.name === role);

    if (!foundRoles || foundRoles.length === 0) return '';

    return foundRoles[0].id;
  };

  const convertStatusToYN = (active: boolean): 'yes' | 'no' => {
    return active ? 'yes' : 'no';
  };

  const roleDescription = [
    {
      label: 'Manager/Supervisor',
      value: 2,
      info: <ManagerRoleDetails />
    },
    {
      label: 'Cashier',
      value: 3,
      info: <CashierRoleDetails />
    },
    {
      label: 'Admin',
      value: 1,
      info: <>Admin Details Here</>
    }
  ];

  const getRoleDescription = (selectedRole: number) => {
    const description = roleDescription.find(
      roleOpt => roleOpt.value === selectedRole
    );

    return description?.info || <></>;
  };

  return {
    getRoleIDByDescription,
    convertStatusToYN,
    getRoleDescription
  };
};

export default useUserModalHook;
