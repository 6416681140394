import React, { useEffect, useState } from 'react';
import NotFoundTemplate from '../../templates/NotFoundTemplate';
import './index.scss';
import { usePermissions } from '../../../features/permissions';

const NotFoundPage: React.FC = () => {
  const [pageContent, setPageContent] = useState<string>('');
  const { userPermissions: permissions } = usePermissions();

  const getPageToReturnTo = (): string => {
    let returnToText;

    if (permissions.size !== 0) {
      returnToText = 'Go back to the Dashboard';
    } else {
      returnToText = 'Go back to the Login';
    }
    return returnToText;
  };

  useEffect(() => {
    setPageContent(getPageToReturnTo());
  }, []);

  return (
    <div className="page-content" id={'non-auth'}>
      <div className="notFoundTemplateBase">
        <NotFoundTemplate pageNotFoundReturn={pageContent} />
      </div>
    </div>
  );
};

export default NotFoundPage;
