import LynkModal from '../../../../atoms/LynkModal';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../atoms/TextInput';
import SelectorInput from '../../../../atoms/SelectorInput';
import { useFormik } from 'formik';
import { boolean, number, object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { hideModal, refreshTable } from '../../../../../store/actions';
import './index.scss';
import { useModalState } from '../../../../../store/modals/modal/selector';
import { useGetProfileState } from '../../../../../store/profile/selector';

import { UncontrolledTooltip } from 'reactstrap';
import Switch from '../../../../molecules/Switch';
import CheckboxGroupItem from '../../../../atoms/CheckboxGroupItem';
import useCreateStaff from '../../../../../hooks/staff/useCreateStaff';
import useStatusHook from '../../../../../hooks/utils/useStatusHook';
import useUpdateStaff from '../../../../../hooks/staff/useUpdateStaff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useUserModalHook from './useUserModalHook';
import { TransactionStateKeyEnum } from '../../../../../hooks/utils/tableConfig/transactionState';
import { useLoadStaffRoleCache } from '../../../../../store/data/selector';
import { usePermissions } from '../../../../../features/permissions';
import { useGetWalletManager } from '../../../../../store/walletManagement/selector';
import useGetAccountDetails from '../../../../../hooks/account/useGetAccountDetails';
import { RoleId, Role } from '../../../../../types/enums/roleType';

const NewEditUserModal: React.FC = () => {
  const modalDetails = useModalState();
  const { merchant_id, authID, role } = useGetProfileState();
  const dispatch = useDispatch();

  const loading = false;

  const { createStaffDetails } = useCreateStaff();
  const { updateStaffDetails } = useUpdateStaff();
  const { showActionStatus } = useStatusHook();
  const { hasPermissions } = usePermissions();
  const { convertStatusToYN, getRoleDescription } = useUserModalHook();
  const { getChildrenAccount } = useGetAccountDetails();
  const staffRoleCache = useLoadStaffRoleCache();
  const walletManager = useGetWalletManager();

  const [adminEditing, setAdminEditing] = useState(false);
  const [currentDesc, setCurrentDesc] = useState(<></>);
  const [isNewMode, setMode] = useState(true);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = async () => {
    const resp = await getChildrenAccount();
    setAccounts(resp.children);
  };

  const withoutSuperAdmin = staffRoleCache.filter((role: any) => {
    return String(role.name).toLowerCase() !== 'super administrator';
  });

  const onlySuperAdmin = staffRoleCache.filter((role: any) => {
    return String(role.name).toLowerCase() === 'super administrator';
  });

  const CASHIER_ROLE = staffRoleCache.find(
    role => String(role.name).toLowerCase() === 'cashier'
  );

  const getStaffRole = (row: any) => {
    const currentMerchant = row.merchants.filter((merchant: any) => {
      return merchant.merchant_id === merchant_id;
    });
    return currentMerchant[0].role_id.toString();
  };

  const getStaffStatus = (row: any) => {
    const currentMerchant = row.merchants.filter((merchant: any) => {
      return merchant.merchant_id === merchant_id;
    });
    return currentMerchant[0].active;
  };

  const getStaffMerchantId = (row: any) => {
    const currentMerchant = row.merchants.filter((merchant: any) => {
      return merchant.merchant_id === merchant_id;
    });
    return currentMerchant[0].merchant_id;
  };

  const getStaffQRCode = (row: any) => {
    const currentMerchant = row.merchants.filter((merchant: any) => {
      return merchant.merchant_id === merchant_id;
    });
    return currentMerchant[0].show_qr_display_name;
  };

  const newUserSchema = object({
    show_qr_display_name: boolean().default(false),
    display_name: string().when('show_qr_display_name', {
      is: false,
      then: schema => schema,
      otherwise: string().when('show_qr_display_name', {
        is: true,
        then: string()
          .required('This field is required.')
          .test(
            'invalid-name',
            'Please ensure that a valid display name is entered',
            value => {
              if (!value) return false;
              return value.trim().length > 0;
            }
          )
      })
    }),
    email: string()
      .required('Email is a required field')
      .email('Please input a valid email'),
    role: number().required('User Role is required'),
    status: string().default('yes')
  });

  const formik = useFormik({
    initialValues: {
      display_name: modalDetails.data ? modalDetails.data.display_name : '',
      show_qr_display_name: modalDetails.data
        ? getStaffQRCode(modalDetails.data)
        : false,
      email: modalDetails.data ? modalDetails.data.email : '',
      role: modalDetails.data ? getStaffRole(modalDetails.data) : '',
      status: convertStatusToYN(
        modalDetails.data ? getStaffStatus(modalDetails.data) : false
      )
    },
    validationSchema: newUserSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {
      userUpdateCallback();
    }
  });

  useEffect(() => {
    setCurrentDesc(getRoleDescription(Number(formik.values.role)));
  }, [formik.values.role]);

  const merchantsList = accounts.map((branch: any) => {
    return {
      merchant_id: branch['id'],
      role_id: RoleId.SUPER_ADMIN,
      show_qr_display_name: true,
      active: true
    };
  });

  const currentMerchant = {
    merchant_id: merchant_id,
    role_id: Number(formik.values.role),
    show_qr_display_name: formik.values.show_qr_display_name || false,
    active: true
  };

  const compileMerchantList = () => {
    merchantsList.push(currentMerchant);
    merchantsList.forEach((item, i) => {
      if (item.merchant_id === merchant_id) {
        merchantsList.splice(i, 1);
        merchantsList.unshift(item);
      }
    });
    return merchantsList;
  };

  const submitNewUser = async () => {
    const result = {
      success: true,
      message:
        'The registered user will receive an email to activate the account.'
    };

    try {
      const body = {
        email: formik.values.email,
        display_name: formik.values.display_name,
        merchants:
          Number(formik.values.role) === RoleId.SUPER_ADMIN
            ? compileMerchantList()
            : [currentMerchant]
      };
      await createStaffDetails(body);
      dispatch(refreshTable(TransactionStateKeyEnum.STAFF));
    } catch (err) {
      if (err instanceof Error) {
        result.success = false;
        result.message = err.message;
      }
    } finally {
      dispatch(hideModal());

      showActionStatus(
        {
          title: 'Staff Creation Status',
          message: result.message,
          timeMade: new Date(),
          alertTag: 'userCreation'
        },
        result.success ? 'success' : 'error',
        'general',
        'creationStatus'
      );
    }
  };

  const updateExistingUser = async () => {
    const result = {
      success: true,
      message: 'This user was updated successfully!'
    };
    try {
      await updateStaffDetails(modalDetails.data.id, {
        merchant_id: getStaffMerchantId(modalDetails.data),
        auth_id: modalDetails.data.auth_id || '',
        email: formik.values.email,
        display_name: formik.values.display_name,
        role_id: formik.values.role,
        show_qr_display_name: formik.values.show_qr_display_name || false,
        active: formik.values.status === 'yes'
      });
      dispatch(refreshTable(TransactionStateKeyEnum.STAFF));
    } catch (err) {
      result.success = false;
      result.message =
        'Oops! Something went wrong. We were unable to update this user. Please try again.';
    } finally {
      dispatch(hideModal());
      showActionStatus(
        {
          title: 'Staff Details Edit Status',
          message: result.message,
          timeMade: new Date(),
          alertTag: 'userCreation'
        },
        result.success ? 'success' : 'error',
        'general',
        'editStaffStatus'
      );
    }
  };

  useEffect(() => {
    const hasExistingData = !!modalDetails.data;
    setMode(!hasExistingData);
  }, [modalDetails]);

  const userUpdateCallback = () => {
    if (isNewMode) submitNewUser();
    else updateExistingUser();
  };

  useEffect(() => {
    setAdminEditing(
      hasPermissions([{ resource: 'staff', actions: new Set(['create']) }])
    );
    console.log(
      hasPermissions([{ resource: 'staff', actions: new Set(['create']) }])
    );
  }, []);
  const onSelectRol = (event: any) => {
    formik.setFieldValue('role', event.target.value);
    if (event.target.value !== CASHIER_ROLE.id) {
      formik.setFieldValue('show_qr_display_name', false);
    }
  };

  return (
    <LynkModal
      classes="customModal"
      modalBody={
        <>
          <div style={{ marginBottom: 20 }}>
            <SelectorInput
              label="Role"
              className="selectInput"
              testID="roleTestID"
              invalid={!!formik.errors.role}
              value={formik.values.role}
              onChange={event => onSelectRol(event)}
              disabled={!adminEditing || modalDetails?.data?.auth_id === authID}
              defaultLabelOption={{
                label: 'Select role...',
                value: ''
              }}
              options={
                walletManager.currentWallet !== 'corporate' &&
                role === Role.SUPER_ADMIN &&
                !isNewMode &&
                formik.values.role === RoleId.SUPER_ADMIN.toString()
                  ? onlySuperAdmin.map(roleOpt => ({
                      label: roleOpt.name,
                      value: roleOpt.id
                    }))
                  : walletManager.currentWallet !== 'corporate'
                  ? withoutSuperAdmin.map(roleOpt => ({
                      label: roleOpt.name,
                      value: roleOpt.id
                    }))
                  : onlySuperAdmin.map(roleOpt => ({
                      label: roleOpt.name,
                      value: roleOpt.id
                    }))
              }
            />
            <div style={{ color: 'red' }}>{formik.errors.role}</div>
            {formik.values.role && (
              <div>
                <div
                  id="moreInfoDetail"
                  style={{
                    marginTop: 5,
                    color: '#8A99A1',
                    fontSize: 12,
                    cursor: 'pointer'
                  }}
                >
                  More info about this role{' '}
                  <InfoOutlinedIcon style={{ width: 16 }} />
                </div>
                <UncontrolledTooltip
                  placement="bottom"
                  target="moreInfoDetail"
                  style={{ textAlign: 'left' }}
                >
                  {currentDesc}
                </UncontrolledTooltip>
              </div>
            )}
            {/* <div>{formik.errors.role}</div> */}
          </div>

          <div style={{ marginBottom: 20 }}>
            <TextInput
              label="Display Name"
              testID="displayInput"
              // disabled={!isNewMode}
              disabled={!adminEditing}
              className="displayNameInput"
              value={formik.values.display_name}
              onChange={event => {
                formik.setFieldValue('display_name', event.target.value);
              }}
              // feedback={formik.errors.display_name || undefined}
              invalid={!!formik.errors.display_name}
              type="text"
            />
            <div style={{ color: 'red', marginBottom: 10 }}>
              {formik.errors.display_name}
            </div>
            {formik.values.role === CASHIER_ROLE.id ? (
              <CheckboxGroupItem
                isActive={formik.values.show_qr_display_name}
                label={'Show Display Name in the QR Code'}
                onClick={val =>
                  formik.setFieldValue('show_qr_display_name', val)
                }
              />
            ) : (
              <></>
            )}
          </div>
          <div style={{ marginBottom: 20 }}>
            <TextInput
              label="Email"
              disabled={!isNewMode}
              testID="emailInput"
              className="emailInput"
              onChange={event =>
                formik.setFieldValue('email', event.target.value)
              }
              // feedback={formik.errors.email || ''}
              invalid={!!formik.errors.email}
              value={formik.values.email}
              type="email"
            />
            <div style={{ color: 'red', marginBottom: 10 }}>
              {formik.errors.email}
            </div>
          </div>

          {!isNewMode ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 15,
                opacity: !adminEditing && !isNewMode ? 0.5 : 1,
                pointerEvents: !adminEditing && !isNewMode ? 'none' : 'all'
                // {adminEditing && !isNewMode) && pointer-events: none;}
              }}
            >
              <div className="userStatusDetails">
                <div className="main">
                  User Status
                  <span
                    style={{
                      color: formik.values.status === 'yes' ? '#055AEE' : 'red'
                    }}
                  >
                    ({formik.values.status === 'yes' ? 'Active' : 'Deactivated'}{' '}
                    )
                  </span>
                </div>
                <div className="description">
                  Enable/Disable a user’s access to your dashboard.
                </div>
              </div>
              <div
                style={{
                  paddingRight: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Switch
                  activeColour="#055AEE"
                  checked={formik.values.status === 'yes'}
                  disabled={
                    (!adminEditing && !isNewMode) ||
                    modalDetails?.data?.auth_id === authID
                  }
                  handleToggle={value => formik.setFieldValue('status', value)}
                />
              </div>
            </div>
          ) : undefined}
        </>
      }
      title={modalDetails.title || ''}
      loading={loading}
      show={true}
      showModalClose={{
        onClickFn: () => dispatch(hideModal())
      }}
      mainAction={{
        loader: {
          text: 'Saving new user',
          icon: <></>
        },
        disabled: loading || !formik.isValid,
        testID: 'newUserMainActionTestId',
        onClickFn: () => formik.submitForm(),
        text: isNewMode ? 'Create User' : 'Save'
      }}
    />
  );
};

export default NewEditUserModal;
