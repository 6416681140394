import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { Auth0AccessToken } from 'src/providers/AuthProvider';

export interface ProfileState {
  id: string;
  authID: string;
  orgId?: string;
  role: string;
  displayName?: string;
  accessToken: string;
  merchant_id: string;
  parent_id: string;
}

const initialState: ProfileState = {
  role: '',
  authID: '',
  id: '',
  accessToken: '',
  merchant_id: '',
  parent_id: ''
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setAccessToken: (state, action) => {
      const { payload: accessToken } = action;

      const tokenContents = jwtDecode(accessToken ?? '{}') as Auth0AccessToken;

      state.accessToken = accessToken;
      state.id = tokenContents['https://lynk.us/merchant/user/id'] ?? '';
      state.authID = tokenContents.sub ?? '';
      state.role = tokenContents['https://lynk.us/merchant/role'] ?? '';
      state.displayName =
        tokenContents['https://lynk.us/merchant/display_name'] ?? '';
      state.orgId = tokenContents.org_id;
      state.merchant_id = tokenContents['https://lynk.us/merchant/id'] ?? '';
    }
  }
});

export const { setRole, setDisplayName, setID, setAccessToken } =
  profileSlice.actions;

export default profileSlice.reducer;
