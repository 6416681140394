import React, { PropsWithChildren, useEffect, useState } from 'react';
import './index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckboxGroupItem from '../../atoms/CheckboxGroupItem';
import Switch from '../Switch';

interface SettingsCardProps {
  title: string;
  active: boolean;
  code: string;
  classes?: string;
  disabled?: boolean;
  handleChangeCallback: () => void;
}

const SettingsItem: React.FC<PropsWithChildren<SettingsCardProps>> = ({
  title,
  active,
  code,
  classes,
  disabled = false,
  handleChangeCallback,
  children
}) => {
  return (
    <>
      <div className={`settingsCardContainer ${classes}`}>
        <div className="cardTitleContainer">
          <div className="title">{title}</div>
          <div className="moreDetailsIcon">
            <Switch
              id={code}
              activeColour="#055AEE"
              checked={active}
              disabled={disabled}
              handleToggle={handleChangeCallback}
            />
          </div>
        </div>
        {children && <div className="title">{children}</div>}
      </div>
    </>
  );
};

export default SettingsItem;
