import React, { useEffect, useState } from 'react';
import './index.scss';
import {
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Progress
} from 'reactstrap';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import useGetCashInOutLimits from '../../../hooks/account/useGetCashInOutLimits';
import useGetBatchTransferLimit from '../../../hooks/merchant_portal/useGetBatchTransferLimit';
import { useAccountState } from '../../../store/account/selector';

const AccountsLimitWidget = () => {
  const types = ['Payments', 'Cash - In', 'Cash - Out'];
  const [transferType, setTransferType] = useState(types[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [cashInBalance, setCashInBalance] = useState(0);
  const [cashInLimit, setCashInLimit] = useState(0);
  const [cashOutBalance, setCashOutBalance] = useState(0);
  const [cashOutLimit, setCashOutLimit] = useState(0);
  const [batchTransferBalance, setBatchTransferBalance] = useState(0);
  const [batchTransferLimit, setBatchTransferLimit] = useState(0);
  const accountDetail = useAccountState();

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const changeTransferType = (menuItem: string) => {
    setTransferType(menuItem);
    setDropdownOpen(false);
  };

  const { getTransferLimit, getTransferLimitBalance } = useGetCashInOutLimits();
  const { getBatchTransferLimitBalance, getBatchTransferLimit } =
    useGetBatchTransferLimit();

  const getCashInBalance = async () => {
    const balanceCashIn = await getTransferLimitBalance('TOP');
    setCashInBalance(balanceCashIn);
  };

  const getCashInLimit = async () => {
    const limitCashIn = await getTransferLimit('TOP');
    setCashInLimit(limitCashIn);
  };

  const getCashOutBalance = async () => {
    const balanceCashOut = await getTransferLimitBalance('ACH_CASHOUT');
    setCashOutBalance(balanceCashOut);
  };

  const getCashOutLimit = async () => {
    const limitCashOut = await getTransferLimit('ACH_CASHOUT');
    setCashOutLimit(limitCashOut);
  };

  const getBatchTransferBalance = async () => {
    const balanceBatchTransfer = await getBatchTransferLimitBalance('lynk');
    setBatchTransferBalance(balanceBatchTransfer);
  };

  const getBatchTransLimit = async () => {
    const limitBatchTransfer = await getBatchTransferLimit('lynk');
    setBatchTransferLimit(limitBatchTransfer);
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const calculatePercentage = () => {
    switch (transferType) {
      case 'Cash - In':
        return 100 - Math.trunc((cashInBalance / cashInLimit) * 100);
      case 'Cash - Out':
        return 100 - Math.trunc((cashOutBalance / cashOutLimit) * 100);
      case 'Payments':
        return (
          100 - Math.trunc((batchTransferBalance / batchTransferLimit) * 100)
        );
      default:
        return 0;
    }
  };

  const showTransferUsage = () => {
    switch (transferType) {
      case 'Cash - In':
        return (
          <div className="accountLimitsWidget__progressContainer--usage">
            {currencyFormatter.format(cashInLimit - cashInBalance)}/
            {currencyFormatter.format(cashInLimit)}
          </div>
        );
      case 'Cash - Out':
        return (
          <div className="accountLimitsWidget__progressContainer--usage">
            {currencyFormatter.format(cashOutLimit - cashOutBalance)}/
            {currencyFormatter.format(cashOutLimit)}
          </div>
        );
      case 'Payments':
        return (
          <div className="accountLimitsWidget__progressContainer--usage">
            {currencyFormatter.format(
              batchTransferLimit - batchTransferBalance
            )}
            /{currencyFormatter.format(batchTransferLimit)}
          </div>
        );
      default:
        return 0;
    }
  };

  const progressBarColor = () => {
    if (calculatePercentage() >= 0 && calculatePercentage() <= 49) {
      return 'success';
    } else if (calculatePercentage() >= 50 && calculatePercentage() <= 74) {
      return 'warning';
    } else if (calculatePercentage() >= 75 && calculatePercentage() <= 100) {
      return 'danger';
    }
  };

  useEffect(() => {
    if (accountDetail.accounts && accountDetail.accounts.length > 0) {
      getCashInBalance();
      getCashInLimit();
      getCashOutBalance();
      getCashOutLimit();
      getBatchTransferBalance();
      getBatchTransLimit();
      progressBarColor();
    }
  }, [transferType, accountDetail]);

  const downwardIcon = {
    marginLeft: '10px'
  };

  return (
    <Card className="accountLimitsWidget">
      <CardBody
        style={{ width: '100%' }}
        className="accountLimitsWidget__content"
      >
        <div className="accountLimitsWidget__header">
          <div className="accountLimitsWidget__header--title">
            Account Limits
          </div>

          <div className="accountLimitsWidget__dropdownContainer">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="accountLimitsWidget__dropdown"
            >
              <DropdownToggle
                caret
                className="accountLimitsWidget__dropdown--text"
              >
                {transferType}
                <KeyboardArrowDownOutlinedIcon
                  fontSize="inherit"
                  style={downwardIcon}
                />
              </DropdownToggle>

              <DropdownMenu className="accountLimitsWidget__dropdown--menu">
                {types.map(function (menuItem, i) {
                  return (
                    <div
                      className={`accountLimitsWidget__dropdown--menuItem ${
                        menuItem === transferType ? 'active' : ''
                      }`}
                      key={i}
                      onClick={() => changeTransferType(menuItem)}
                    >
                      {menuItem}
                    </div>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        <div className="accountLimitsWidget__progressContainer">
          <div className="accountLimitsWidget__progressContainer--details">
            {transferType === 'Payments' ? (
              <div className="accountLimitsWidget__progressContainer--frequency">
                {' '}
                Weekly{' '}
              </div>
            ) : (
              <div className="accountLimitsWidget__progressContainer--frequency">
                {' '}
                Daily{' '}
              </div>
            )}
            {showTransferUsage()}
          </div>
          <Progress value={calculatePercentage()} color={progressBarColor()} />
        </div>
      </CardBody>
    </Card>
  );
};

export default AccountsLimitWidget;
