import './index.scss';

import { ErrorOutline, HelpOutlineOutlined } from '@mui/icons-material';

import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import type { Placement } from '@popperjs/core';
import { ReactComponent as AlertIcon } from '../../../assets/svg/alert.svg';

interface ButtonTooltipProps {
  placement?: string;
  tooltipIdentifier: string;
  title: string | JSX.Element;
  feedback: string;
  ellipsis?: boolean;
  classnames?: string;
  withErrors?: boolean;
  withoutIcon?: boolean;
}

const ButtonTooltip: React.FC<ButtonTooltipProps> = ({
  placement = 'top',
  tooltipIdentifier,
  title,
  feedback,
  ellipsis,
  classnames,
  withErrors,
  withoutIcon
}) => {
  return (
    <div
      id={tooltipIdentifier}
      data-testid={tooltipIdentifier}
      className={`detailsContainer ${classnames || ''}`}
    >
      <div className="detailsContainer__text">{title}</div>

      {ellipsis ? (
        '...'
      ) : withErrors ? (
        <AlertIcon className="detailsContainer__icon" />
      ) : withoutIcon ? (
        <></>
      ) : (
        <HelpOutlineOutlined className="detailsContainer__icon" />
      )}

      <UncontrolledTooltip
        innerClassName="detailsContainer__tooltip-content"
        popperClassName="detailsContainer__tooltip"
        className="tooltip buttonTooltip"
        placement={placement as Placement}
        target={tooltipIdentifier}
      >
        {feedback}
      </UncontrolledTooltip>
    </div>
  );
};

export default ButtonTooltip;
