export const getBreakpoint = (): string => {
  // this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');

  return (
    window
      .getComputedStyle(document.body, ':before')
      // eslint-disable-next-line no-useless-escape
      .content.replace(/\"/g, '')
  );
};
