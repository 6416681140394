import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { currencyFormat } from '../../../utils/currency';
import * as Yup from 'yup';
import CurrencyTextInput from '../../atoms/CurrencyTextInput';
import './index.scss';
import TextInput from '../../atoms/TextInput';
import SelectorInput from '../../atoms/SelectorInput';
import { RefundDetailsFormProps } from '../../../types/models/refundType';

const RefundDetailsForm: React.FC<RefundDetailsFormProps> = ({
  amount,
  isFullRefund,
  refundRecipient,
  onApplyClick
}) => {
  const [reasonIsActive, setReasonIsActive] = useState(false);
  const [noteIsActive, setNoteIsActive] = useState(false);

  const formik = useFormik({
    validateOnMount: false,
    initialValues: {
      amount: isFullRefund ? amount : '0',
      reason: '',
      notes: ''
    },
    enableReinitialize: true,
    initialTouched: {
      reason: false,
      amount: false
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required'),
      amount: Yup.number()
        .min(1, 'Amount is required')
        .max(Number(amount), 'Amount is greater than the Total')
    }),
    onSubmit: () => {
      updateForm();
    }
  });

  const updateForm = () => {
    const { amount, ...rest } = formik.values;

    onApplyClick(
      {
        ...rest,
        amount: Number(amount)
      },
      formik.isValid
    );
  };

  useEffect(() => {
    if (formik.dirty) {
      updateForm();
    }
  }, [formik.values]);

  return (
    <>
      <div className="refund-details-form__container">
        <TextInput
          className="refund-details-form__container__input disabled"
          disabled
          readOnly
          label={'Refund Recipient'}
          value={refundRecipient}
        />
      </div>
      <div className="refund-details-form__container">
        <CurrencyTextInput
          nameInput="amount"
          label="Refund Amount"
          amount={currencyFormat(Number(formik.values.amount))}
          onChange={value => {
            formik.setFieldValue('amount', value);
          }}
          onBlurInput={() => formik.setFieldTouched('amount', true)}
          invalid={formik.touched.amount && !!formik.errors.amount}
          feedback={formik.errors.amount}
          disabled={isFullRefund}
        />
      </div>
      <div className="refund-details-form__container">
        <SelectorInput
          className={`refund-details-form__container__input ${
            reasonIsActive && 'active'
          }`}
          data-testid="reason"
          type="select"
          name="reason"
          id="reason"
          onChange={formik.handleChange}
          onFocus={() => setReasonIsActive(true)}
          onBlur={value => {
            formik.handleBlur(value);
            setReasonIsActive(false);
          }}
          value={formik.values.reason}
          invalid={
            formik.touched.reason && !!formik.errors.reason && !reasonIsActive
          }
          feedback={formik.errors.reason}
          options={[
            {
              label: 'Wrong product or service',
              value: 'WRONG'
            },
            {
              label: 'Product or service not needed anymore',
              value: 'NOT_NEEDED'
            },
            {
              label: 'Product was damaged or defective',
              value: 'DAMAGED'
            },
            {
              label: 'Other',
              value: 'OTHER'
            }
          ]}
          defaultLabelOption={{
            label: 'Select a Reason'
          }}
        />
      </div>
      <div className="refund-details-form__container">
        <TextInput
          label="Note (Optional)"
          data-testid="note"
          style={{ height: 106 }}
          className={`refund-details-form__container__label ${
            noteIsActive && 'active'
          }`}
          placeholder="Leave a note about this order"
          value={formik.values.notes}
          type="textarea"
          onChange={event => formik.setFieldValue('notes', event.target.value)}
          onFocus={() => setNoteIsActive(true)}
          onBlur={() => setNoteIsActive(false)}
        />
      </div>
    </>
  );
};

export default RefundDetailsForm;
