export class UploadBatchException extends Error {
  // private code: string;
  errorMessage: string = '';
  cancelAction: boolean;

  constructor(msg: string, code: string, cancelAction = false) {
    super(msg);
    //   this.code = code;
    this.cancelAction = cancelAction;
  }

  public isCancelable(): boolean {
    return this.cancelAction;
  }
}

export class GenericBatchUploadException extends UploadBatchException {
  constructor() {
    super(
      'Oops! Something went wrong. Your transfer couldn’t be initiated, please try again.',
      '',
      true
    );
    Object.setPrototypeOf(this, GenericBatchUploadException.prototype);
  }
}

export class FileInvalidStructureException extends UploadBatchException {
  constructor() {
    //  <div style="font-style:italic;">error log</div>
    super('error log', '', true);
    Object.setPrototypeOf(this, FileInvalidStructureException.prototype);
  }
}
