import { popAlertMetadata } from '../../../store/status';
import { useDispatch } from 'react-redux';

const useHandleDataDisplay = (): {
  closeAlert: (key: string) => void;
  paginationHandler: (
    page: number,
    paginationDetails: {
      limit: number;
      paginationCallback: (offset: number) => void;
    }
  ) => void;
} => {
  const dispatch = useDispatch();

  const closeAlert = (key: string) => {
    dispatch(popAlertMetadata(key));
  };

  const paginationHandler = (
    page: number,
    paginationDetails: {
      limit: number;
      paginationCallback: (offset: number) => void;
    }
  ) => {
    const factorOffset = (page - 1) * paginationDetails.limit;
    paginationDetails.paginationCallback &&
      paginationDetails.paginationCallback(factorOffset);
  };

  return {
    closeAlert,
    paginationHandler
  };
};

export default useHandleDataDisplay;
