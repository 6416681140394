import React from 'react';
import './index.scss';
import { useGetProfileDetail } from '../../../../store/organization/selector';
import { useGetProfileState } from '../../../../store/profile/selector';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useAppSelector } from '../../../../store/hooks';
import { selectBreadcrumbs } from '../../store/selectors';
import { Role } from '../../../../types/enums/roleType';

const Breadcrumbs = () => {
  const merchantDetail = useGetProfileDetail();
  const { role } = useGetProfileState();
  const breadcrumbs = useAppSelector(selectBreadcrumbs);

  return (
    <Breadcrumb listTag="div" className="breadcrumbs-container">
      {role === Role.SUPER_ADMIN && merchantDetail.parent_org_id && (
        <BreadcrumbItem
          href={`${window.location.origin}/?parent_org_id=${merchantDetail.parent_org_id}`}
          tag="a"
        >
          {merchantDetail.parent_name}
        </BreadcrumbItem>
      )}

      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbItem
          key={index}
          to={breadcrumb.path}
          tag={breadcrumb.path ? Link : 'div'}
        >
          {breadcrumb.name}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
