// eslint-disable-next-line @typescript-eslint/no-explicit-any
import './index.scss';

import * as Yup from 'yup';

import ActionButton from '../../atoms/ActionButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CurrencyTextInput from '../../atoms/CurrencyTextInput';
import { Form } from 'reactstrap';
import React from 'react';
import TextInput from '../../atoms/TextInput';
import { currencyFormat } from '../../../utils/currency';
import { useFormik } from 'formik';
import { PaymentRequestFormProps } from '../../../types/models/paymentType';
import ButtonFooter from '../../atoms/ButtonFooter';

const PaymentRequestForm: React.FC<PaymentRequestFormProps> = ({
  onSubmitHandler,
  closeHandler
}) => {
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      amount: 0,
      note: ''
    },
    validationSchema: Yup.object({
      note: Yup.string().max(100),
      amount: Yup.number().min(1, 'The amount needs to be greater than J$ 0.00')
    }),
    onSubmit: async values => {
      await onSubmitHandler({
        amount_to_pay: values.amount,
        description: values.note,
        currency: 'JMD'
      });
    }
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="paymentRequestForm" data-testid="paymentRequestForm">
        <div>
          <CurrencyTextInput
            inputTextLabel="J$"
            amount={currencyFormat(Number(formik.values.amount))}
            label="Amount"
            onChange={value => formik.setFieldValue('amount', Number(value))}
            onBlurInput={() => formik.setFieldTouched('amount', true)}
            onFocusInput={() => formik.setFieldTouched('amount', false)}
            invalid={formik.touched.amount && !!formik.errors.amount}
            feedback={formik.errors.amount}
          />
        </div>

        <TextInput
          id="note"
          type="textarea"
          label="Note (optional)"
          value={formik.values.note}
          onChange={formik.handleChange}
          invalid={formik.values.note.length > 100}
          classNames="paymentRequestForm__noteInput"
          placeholder="Leave a note about this order"
          labelDescription="Your customer will see this note in the Lynk app when they open the payment request."
          feedback={`${formik.values.note.length}/100 characters`}
        />
      </div>
      <ButtonFooter
        cancelBtnProps={{
          title: 'Cancel',
          disabled: formik.isSubmitting,
          onPressFn: closeHandler,
          testId: 'paymentRequestCancelButton'
        }}
        actionBtnProps={{
          title: 'Continue',
          disabled: !formik.touched || !formik.isValid,
          onPressFn: formik.handleSubmit,
          loadingDetails: {
            isLoading: formik.isSubmitting,
            loaderIcon: <AutorenewIcon />
          }
        }}
      />
    </Form>
  );
};

export default PaymentRequestForm;
