import {
  AlertMetadaDetail,
  AlertMetadata,
  AlertScope,
  AlertType
} from '../store/state';
import { popAlert, popScope, pushAlert } from '../store/status';
import { useDispatch } from 'react-redux';

const useStatusHook = (): {
  showActionStatus: (
    payload: Partial<AlertMetadaDetail>,
    type: AlertType,
    scope: AlertScope,
    key: string
  ) => void;
  removeActionStatus: (key?: string, scope?: string) => void;
} => {
  const dispatch = useDispatch();

  const showActionStatus = (
    payload: Partial<AlertMetadaDetail>,
    type: AlertType,
    scope = 'general',
    key: string
  ): void => {
    //TODO: generate key
    dispatch(
      pushAlert({
        key,
        alertType: type,
        scope,
        payload
      })
    );
  };
  const removeActionStatus = (key?: string, scope?: string): void => {
    if (key) dispatch(popAlert(key));
    else if (scope) dispatch(popScope(scope));
  };

  return { showActionStatus, removeActionStatus };
};

export default useStatusHook;
