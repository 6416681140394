import { PaymentChannel } from '../../../types/enums/paymentChannelType';

export const formatChannelData = (channel?: string) => {
  switch (channel) {
    case PaymentChannel.SHOW_TO_PAY:
      return 'Show To Pay';
    case PaymentChannel.PAY_WITH_LYNK:
      return 'Pay With Lynk';
    case PaymentChannel.SCAN_AND_PAY:
      return 'Scan And Pay';
    case PaymentChannel.PAYMENT_REQUEST_LINK:
      return 'Payment Link';
    case PaymentChannel.FIND_AND_PAY:
      return 'Find And Pay';
    default:
      return 'Unknown Channel';
  }
};
