/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import {
  RefundDetailType,
  RefundDetailTypeKey
} from '../../../types/models/refundDetailType';

import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import ButtonTooltip from '../../atoms/ButtonTooltip';
import { RefundColumHeaderInfo } from '../../../types/models/tableTransactions';
import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import TableCard from '../../atoms/TableCard';
import { getTimeStamp } from '../../../utils/dateFormatter';
import { amountFormated } from '../../../utils/currency';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/actions';
import moment from 'moment';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import { useGetScreenState } from '../../../store/screenSize/selector';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import { FilterRangeObject } from '../../../types/models/dateTypes';
import FilterButton from '../../molecules/FilterButton';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import {
  SorterProps,
  SortingAscDesc
} from '../../../types/models/utils/table-models';

const Refund: React.FC = () => {
  const dispatch = useDispatch();
  const { breakpoint } = useGetScreenState();
  const transactionTableState = useGetTransactionTableState();

  const [pageLimit, setPageLimit] = useState(25);
  const { dispatchMetadataUpdate } = useGetPaginationInformation();

  useEffect(() => {
    setPageLimit(breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpoint]);

  const filterRangeHandler = (range: FilterRangeObject) => {
    dispatchMetadataUpdate(
      {
        tableFilter: {
          dateRange: range
        }
      },
      TransactionStateKeyEnum.REFUND
    );
  };

  const generateTableHeader = (isActive = true) => {
    return (
      <div>
        <FilterButton
          filterRange={
            transactionTableState[TransactionStateKeyEnum.REFUND].tableFilter
              .dateRange
          }
          setFilterRange={filterRangeHandler}
          isActive={isActive}
        />
      </div>
    );
  };

  const cellContent = (row: RefundDetailType, col: RefundColumHeaderInfo) => {
    switch (col.field) {
      case 'amount_to_refund':
        if (col.format) {
          // TODO: confirm this condition to show the tooltip only when it is a refund from link;
          return row.source_user_name === '@link' ? (
            <ButtonTooltip
              tooltipIdentifier={`tooltip-${col.field}-${row.refund_transfer_id}`}
              title={col.format(`${row[col?.field]}`)}
              feedback="This value represents the refund of the lynk commission."
            />
          ) : (
            col.format(`${row[col?.field]}`)
          );
        }
        return '';
      case 'date':
        if (!moment(row[col?.field]).isValid()) return '';
        return col.format
          ? col.format(`${row[col?.field as RefundDetailTypeKey]}`)
          : row[col.field as RefundDetailTypeKey];
      default:
        return col.format
          ? col.format(`${row[col?.field as RefundDetailTypeKey]}`)
          : row[col.field as RefundDetailTypeKey];
    }
  };

  const ColumnStructure = (
    transactions: any[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: RefundDetailType, index: number) => (
      <tr key={`${row.refund_transfer_id}_${index}`}>
        {columnHeaders.map((col: any) => (
          <td key={`${row.refund_transfer_id}-${col.field}`}>
            {cellContent(row, col)}
          </td>
        ))}
      </tr>
    ));
  };

  const moreInfoCallback = (data: RefundDetailType) => {
    const dataMap = {
      details: [
        {
          label: 'ID',
          value: data.transactionID
        },
        {
          label: 'Extrnal Transaction ID',
          value: data.external_transaction_id || '-'
        },
        {
          label: 'FROM',
          value: data.from
        },
        {
          label: 'TO',
          value: data.to
        },
        {
          label: 'DATE',
          value: moment(data.date).isValid()
            ? moment(data.date).format('DD/MM/YYYY HH:mm:ss')
            : ''
        },
        {
          label: 'AMOUNT',
          value: amountFormated(data.amount_to_refund)
        }
      ]
    };

    dispatch(
      showModal({
        modalKey: 'more-trans-details',
        data: dataMap,
        title: 'Refund'
      })
    );
  };

  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: RefundDetailType) => (
      <TableCard
        key={row.refund_transfer_id}
        title={row.refund_status || ''}
        mainTitle={row.to}
        subTitle={moment(row.date).isValid() ? getTimeStamp(row.date) : ''}
        data={`${amountFormated(row.amount_to_refund)}`}
        moreInfoCallback={() => moreInfoCallback(row)}
      />
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint === 'smartphone'
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.REFUND].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;

    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.REFUND
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit,
      header: generateTableHeader
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.REFUND]]
  );

  return (
    <DataDisplayContainer
      {...dataDisplayProps}
      dataKey={TransactionStateKeyEnum.REFUND}
      dataGenerator={generateDisplay}
      noDataMessage={'No transactions yet'}
      fetchingData={{
        fetchingText: (
          <ButtonAsyncLoader
            loadingText="Loading Data... Please wait. "
            showLoaderText={true}
          />
        )
      }}
    />
  );
};

export default Refund;
