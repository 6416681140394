/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.scss';

import React, { useEffect, useState } from 'react';

import useAuth from '../../hooks/auth/useAuth';
import Loader from '../../components/molecules/Loader';
import { Outlet } from 'react-router-dom';

interface Props {
  isAuthProtected: boolean;
}

const Authmiddleware: React.FC<Props> = ({
  isAuthProtected
}) => {
  const { isAuthenticated, isLoading, login } = useAuth();

  if (isAuthProtected && isLoading) {
    return <Loader loadingText={'Loading...'} testID="isLoader" />;
  } else if (isAuthProtected && !isAuthenticated) {
    login();
    return <Loader loadingText={'Redirecting...'} testID="isRedirecting" />;
  }

  return (
    <div className="authMiddleware">
      <>
        <Outlet />
      </>
    </div>
  );
};

export default Authmiddleware;
