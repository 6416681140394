import { useDispatch } from 'react-redux';
import LynkModal from '../../../../atoms/LynkModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useModalState } from '../../../../../store/modals/modal/selector';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  hideModal,
  setPreviousModal,
  showModal
} from '../../../../../store/actions';
import './index.scss';
import usePostBatchPaymentProcess from '../../../../../hooks/batchPayments/usePostBatchPaymentProcess';
import SubmitBatchPaymentForm, {
  FormChange
} from '../../../../molecules/SubmitBatchPaymentForm';
import ConfirmPayment from '../../../../molecules/ConfirmPayment';
import { useLoadBatchPayment } from '../../../../../store/batchPaymentProcess/selector';
import {
  clearFormDetails,
  setBatchFormDetails
} from '../../../../../store/batchPaymentProcess';
import saveAs from 'file-saver';
import { useGetProfileState } from '../../../../../store/profile/selector';
import {
  BatchDetailType,
  initBatchDetail
} from '../../../../../types/models/BatchPaymentsType';
import useRequestBatch from '../../../../../hooks/batchPayments/useGetBatchPaymentProcess';
import useGetBatchPaymentProcess from '../../../../../hooks/batchPayments/useGetBatchPaymentProcess';
import { FileInvalidStructureException } from '../../../../../exceptions/UploadBatchDocument.exception';
import useGetBatchTransferLimit from '../../../../../hooks/merchant_portal/useGetBatchTransferLimit';

const BatchPaymentModal: React.FC = () => {
  const dispatch = useDispatch();
  const modalDetails = useModalState();

  const batchPayment = useLoadBatchPayment();
  const [isFormValid, setIsFormValid] = useState(false);
  const [confirmBatchPayment, setConfirmBatchPayment] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [batchUploadError, setBatchUploadError] = useState('');
  const [batchCreated, setBatchCreated] =
    useState<BatchDetailType>(initBatchDetail);
  const [limitedExceeded, setLimitedExceeded] = useState(false);
  const [transferBalance, settransferBalance] = useState(0);
  const { getBatchById } = useRequestBatch();
  const { receiptBatch } = useGetBatchPaymentProcess();
  const downloadReceipt = async () => {
    await receiptBatch(batchCreated, 'batch_payments_error_log.csv');
  };
  const [currentPageTitle, setCurrentPageTitle] = useState({
    title: '',
    subTitle: ''
  });

  const stackedPages = [
    {
      page: 0,
      title: 'Upload batch payment file',
      subTitle: 'Please upload a file to be processed for batch payment',
      dataGenerator: () => {
        console.log('not implemented');
      },
      pageComponent: SubmitBatchPaymentForm
    },
    {
      page: 1,
      title: 'Confirm payment',
      subTitle: 'Please review this payment and choose an action',
      pageComponent: SubmitBatchPaymentForm
    }
  ];

  const profileState = useGetProfileState();
  const {
    uploadBatchPaymentFile,
    batchPaymentFetching,
    requestApprovalBatchPayment
  } = usePostBatchPaymentProcess();
  const { fetching, validateUploadedBatchFile } = useGetBatchPaymentProcess();

  useEffect(() => {
    const role = profileState.role.toLowerCase();
    setConfirmBatchPayment(role !== 'admin');
  }, [profileState]);

  const downloadCSVFile = async (batch: string, name: any) => {
    const fileName = `batch_${name || batchCreated?.name}.csv`;

    const csvContent = Buffer.from(batch, 'base64');
    const blob = new Blob([csvContent]);
    await Promise.resolve(saveAs(blob, fileName));
  };

  const approvalLabelOptions = {
    admin: 'Approve & Continue',
    manager: 'Request approval'
  };

  const showConfirmProps = useMemo(() => {
    const showConfirm = profileState.role.toLowerCase().includes('admin')
      ? {
          confirmVal: confirmBatchPayment,
          changeConfirmCheck: () => {
            setConfirmBatchPayment(!confirmBatchPayment);
          }
        }
      : undefined;

    return {
      lynkAcctDetail: {
        lynkAcctCount: batchCreated?.payee_count || 0,
        downloadText: 'Review batch upload',
        onDownloadTrigger: async () => {
          if (batchCreated) {
            const batch = await getBatchById(batchCreated, false);
            if (batch) {
              downloadCSVFile(batch, null);
            }
          }
        }
      },
      subTotal: batchCreated?.subtotal || 0,
      processingFee: batchCreated?.lynk_fee || 0,
      gctProcessingFee: batchCreated?.lynk_fee_gct || 0,
      total: batchCreated?.total || 0,
      showConfirm,
      limitedExceeded: limitedExceeded,
      setLimitedExceeded: setLimitedExceeded,
      transferBalance: transferBalance
    };
  }, [profileState, confirmBatchPayment, batchCreated]);

  const { getBatchTransferLimitBalance } = useGetBatchTransferLimit();

  const getLimitBalance = async () => {
    const balance = await getBatchTransferLimitBalance('lynk');
    settransferBalance(balance);
  };

  useEffect(() => {
    getLimitBalance();
  }, []);

  const approvalLabel = useMemo(() => {
    const profileRole = profileState.role.toLowerCase();

    return approvalLabelOptions[
      profileRole as keyof typeof approvalLabelOptions
    ];
  }, [profileState]);

  useEffect(() => {
    setCurrentPage(0);
    dispatch(clearFormDetails());
  }, []);

  useEffect(() => {
    setCurrentPageTitle({
      title: stackedPages[currentPage].title,
      subTitle: stackedPages[currentPage].subTitle
    });
  }, [currentPage]);

  const successApproval = () => {
    dispatch(
      showModal({
        modalKey: 'transaction-status',
        data: {
          title: 'Payment Sent for Approval',
          status: 'success',
          subtitle:
            'Your payment was sent for approval. You will be notified when the approval is done'
        }
      })
    );
  };

  const approveBatch = async () => {
    if (profileState.role.toLowerCase().includes('admin')) {
      dispatch(
        showModal({ modalKey: 'choose-source-funds', data: batchCreated })
      );
    } else {
      try {
        await requestApprovalBatchPayment(batchCreated);
        successApproval();
      } catch (err: any) {
        setIsFormValid(false);
        setBatchUploadError(err.message);
      }
    }
  };
  const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

  const createBatch = async () => {
    if (currentPage === stackedPages.length - 1) {
      approveBatch();
    } else {
      try {
        const response = await uploadBatchPaymentFile(batchPayment);

        let currentStatus = '';
        while (
          currentStatus !== 'VALIDATED' &&
          currentStatus !== 'INVALID_PAYEE'
        ) {
          setIsFormValid(false);
          await timer(2000);
          const validationResponse = await validateUploadedBatchFile(
            response.id
          );
          setIsFormValid(true);
          if (validationResponse) {
            setBatchCreated(validationResponse);
            currentStatus = validationResponse.status;
          } else {
            setIsFormValid(false);
            setBatchUploadError(
              'Sorry, we’re experiencing technical problems. Please try again later.'
            );
            currentStatus = 'INVALID_PAYEE';
          }
        }

        if (currentStatus === 'INVALID_PAYEE') {
          throw new FileInvalidStructureException();
        }

        setCurrentPage(currentPage + 1);
      } catch (err: any) {
        setIsFormValid(false);
        setBatchUploadError(err.message);
      }
    }
  };

  const cancelAction = () => {
    if (currentPage > 0) {
      dispatch(setPreviousModal({ ...modalDetails }));
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'confirm',
            subtitle:
              'Please confirm that you want to cancel this payment. This process cannot be undone.',
            title: 'Are you sure?',
            mainAction: {
              disabled: false,
              testID: 'transaction-status-main',
              onClickFn: () => {
                dispatch(clearFormDetails());
                dispatch(hideModal());
              },
              text: 'Yes'
            },
            closeAction: {
              disabled: false,
              testID: 'transaction-status-main',
              onClickFn: () =>
                dispatch(
                  showModal({
                    modalKey: modalDetails.modalKey || '',
                    data: modalDetails.data
                  })
                ),
              text: 'Nevermind'
            }
          }
        })
      );
    } else {
      // dispatch(clearFormDetails());
      dispatch(hideModal());
    }
  };

  return (
    <LynkModal
      title={currentPageTitle.title}
      subTitle={currentPageTitle.subTitle}
      loading={batchPaymentFetching || fetching}
      show={true}
      modalBody={
        <>
          {currentPage === 0 ? (
            <SubmitBatchPaymentForm
              file={batchPayment.batchFile || null}
              batchName={batchPayment.batchName}
              technicalError={batchUploadError}
              onFormChange={(formVal: FormChange, isValid: boolean) => {
                dispatch(setBatchFormDetails(formVal));
                setBatchUploadError('');
                setIsFormValid(isValid);
              }}
              downloadTemplate={downloadCSVFile}
              downloadReceipt={downloadReceipt}
            />
          ) : (
            <ConfirmPayment
              {...showConfirmProps}
              limitExceeded={limitedExceeded}
              setLimitedExceeded={setLimitedExceeded}
              transferBalance={transferBalance}
              isFetching={false}
            />
          )}
        </>
      }
      mainAction={{
        loader: {
          text: currentPage === 0 ? 'Upload' : approvalLabel,
          icon: <AutorenewIcon />
        },
        disabled: currentPage === 0 ? !isFormValid : !confirmBatchPayment,
        testID: 'uploadBatchPayment',
        onClickAsyncFn: createBatch,
        text: currentPage === 0 ? 'Upload' : approvalLabel
      }}
      closeAction={{
        text: 'Cancel',
        showButton: true,
        onClickFn: cancelAction,
        testID: 'uploadBatchCancelButton'
      }}
    />
  );
};

export default BatchPaymentModal;
