import React, { createContext, useEffect, useMemo, useState } from 'react';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

import Env from '../../helpers/Env';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { setAccessToken, setDisplayName } from '../../store/profile';

import { useGetProfileState } from '../../store/profile/selector';

import { useDispatch } from 'react-redux';
import useGetAccountDetails from '../../hooks/account/useGetAccountDetails';
import useGetMerchantDetails from '../../hooks/merchant/useGetMerchantDetails';
import useGetStaff from '../../hooks/staff/useGetStaff';
import qs from 'qs';
import useGetStaffTypeOptions from '../../hooks/data/useGetStaffTypeOptions';
import {
  generatePermissionList,
  usePermissions
} from '../../features/permissions';

export interface Auth0AccessToken extends JwtPayload {
  org_id?: string;
  permissions?: string[];
  'https://lynk.us/merchant/id'?: string;
  'https://lynk.us/merchant/role'?: string;
  'https://lynk.us/merchant/display_name'?: string;
  'https://lynk.us/merchant/user/id'?: string;
}

interface AuthContextValues {
  isLoading: boolean;
  isAuthenticated: boolean;
  hasOrganization: boolean;
  auth0Token?: string;
  login: () => void;
  logout: () => void;
}

const initialContextValues: AuthContextValues = {
  isLoading: true,
  isAuthenticated: false,
  hasOrganization: false,
  auth0Token: undefined,
  login: () => {
    return;
  },
  logout: () => {
    return;
  }
};

const AuthContext = createContext<AuthContextValues>(initialContextValues);

const AuthProvider: React.FC = ({ children }) => {
  const [auth0Client, setAuth0Client] = useState<Auth0Client | undefined>();

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasOrganization, setHasOrganization] = useState(false);

  const { accessToken: reduxAccessToken } = useGetProfileState();

  const { loadFullMerchantDetails } = useGetMerchantDetails();
  const { getAccountListing } = useGetAccountDetails();
  const { loadStaffTypeOptions } = useGetStaffTypeOptions();
  const { getStaffDetails } = useGetStaff();
  const { setUserPermissions } = usePermissions();

  // TODO: Add to redux
  const [auth0Token, setAuth0Token] = useState('');

  const login = () => {
    auth0Client?.loginWithRedirect();
  };

  const logout = async () => {
    return await auth0Client?.logout({
      client_id: Env.AUTH0_CLIENT_ID,
      returnTo: `${window.location.origin}`
    });
  };

  const contextVals: AuthContextValues = useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      hasOrganization,
      auth0Token,
      login,
      logout
    }),
    [isAuthenticated, isLoading, auth0Token, hasOrganization]
  );

  const initAuth0 = async () => {
    // Get Org
    let orgId = undefined;

    const path = window.location.pathname.replace(/^\/|\/$/g, '').split('/');

    if (path[0] && !path[0].includes('wallet')) {
      orgId = `org_${path[0]}`;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const parent_org_id = searchParams.get('parent_org_id');

    if (parent_org_id) {
      orgId = parent_org_id;
    }

    try {
      const client = await createAuth0Client({
        domain: Env.AUTH0_DOMAIN,
        audience: Env.AUTH0_AUDIENCE,
        client_id: Env.AUTH0_CLIENT_ID,
        redirect_uri: window.location.origin,
        organization: orgId,
        advancedOptions: {
          defaultScope: 'offline_access openid profile'
        }
      });

      setAuth0Client(client);
    } catch (error) {
      console.log(error);
      window.location.replace('./../');
    }
  };

  const dispatch = useDispatch();

  const initToken = async (): Promise<string | null> => {
    let token = null;
    // sessionStorage.removeItem('auth0Token');

    await handleRedirect();

    try {
      token = (await auth0Client?.getTokenSilently()) || null;

      if (token) {
        setAuth0Token(token);

        const tokenContents = jwtDecode(token) as Auth0AccessToken;

        dispatch(setAccessToken(token));
        setUserPermissions(
          generatePermissionList(tokenContents['permissions'] ?? [])
        );
      }
    } catch (err) {
      console.log(err);
    }

    return token;
  };

  const handleRedirect = async () => {
    const search = qs.parse(document.location.search, {
      ignoreQueryPrefix: true
    });
    const code = search.code;
    const state = search.state;

    if (code && state) {
      await auth0Client?.handleRedirectCallback();
    }
  };

  const loadMerchantProfile = async () => {
    return loadFullMerchantDetails();
  };

  const loadAccountDetails = async () => {
    return getAccountListing();
  };

  const loadStaffDetails = async (id: string) => {
    const { display_name } = await getStaffDetails(id);
    if (!display_name) return;
    dispatch(setDisplayName(display_name));
  };

  const initUser = async () => {
    const token = await initToken();
    if (token) {
      const { org_id } = jwtDecode(token) as Auth0AccessToken;

      setIsAuthenticated(true);
      setHasOrganization(!!org_id);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initAuth0();
  }, []);

  useEffect(() => {
    if (auth0Client) {
      initUser();
    }
  }, [auth0Client]);

  useEffect(() => {
    if (reduxAccessToken) {
      loadAccountDetails();
      loadMerchantProfile();
      loadStaffTypeOptions();

      const tokenContents = jwtDecode(reduxAccessToken) as Auth0AccessToken;
      const userId = tokenContents['https://lynk.us/merchant/user/id'];
      loadStaffDetails(userId!);
    }
  }, [reduxAccessToken]);

  return (
    <AuthContext.Provider value={contextVals}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext };
