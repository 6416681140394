import './index.scss';

import React, { useEffect, useState } from 'react';
import { amountFormated, currencyFormat } from '../../../utils/currency';

import ActionButton from '../../atoms/ActionButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ToastDisplay from '../../atoms/ToastDisplay';
import { PaymentRequestLinkProps } from '../../../types/models/paymentType';

const PaymentRequestLink: React.FC<PaymentRequestLinkProps> = ({
  orderId,
  amount,
  merchantName,
  url,
  closeHandler
}) => {
  const [showToast, setShowToast] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  const copyText = async () => {
    const copyText = document?.getElementById('copyMessage')?.innerText;
    if (copyText) {
      setIsCopying(true);

      setTimeout(async () => {
        await navigator.clipboard.writeText(copyText);

        setIsCopying(false);
        toggleState();
      }, 2400);
    }
  };

  const toggleState = (): void => {
    setShowToast(!showToast);
  };

  useEffect(() => {
    if (showToast) {
      setTimeout(toggleState, 2000);
    }
  }, [showToast]);

  return (
    <>
      <div className="paymentRequestLink" data-testid="paymentRequestLink">
        <div className="paymentRequestLink__feedback">
          Please, copy and send it to the customer for request a payment
        </div>
        <div className="paymentRequestLink__sectionTitle">
          Payment link & Message
        </div>
        <div id="copyMessage" className="paymentRequestLink__sectionMessage">
          <div
            data-testid="containerRequestIdAmount"
            className="containerRequestIdAmount"
          >
            Here’s a payment request for Order #{orderId}, for
            <div className="containerRequestIdAmount__value">
              {amountFormated(Number(amount))}
            </div>
          </div>
          <div data-testid="containerURL" className="containerURL">
            You can make your payment at:
            <br />
            <a href={url}>{url}</a>
          </div>
          <br />
          <br />
          <span>With Thanks,</span>
          <br />
          <span data-testid="containerMerchantName">{merchantName}</span>
        </div>
      </div>
      <div className="footerPaymentRequestModal">
        {showToast ? (
          <ToastDisplay
            showDefaultIcon
            classnames="textCopyToast"
            showToast={showToast}
            toastType={'success'}
            message={'Payment Request Link Details copied successfully.'}
          />
        ) : (
          <ActionButton
            disabled={isCopying}
            loaderIcon={<AutorenewIcon />}
            loading={isCopying}
            testId="paymentRequestPrimaryButton"
            text="Copy"
            type="primary"
            block={true}
            classnames="primary-button"
            onPress={copyText}
          />
        )}
        <ActionButton
          testId="paymentRequestCancelButton"
          text="Close"
          type="white"
          classnames="footerPaymentRequestModal__cancelButton"
          block={true}
          onPress={closeHandler}
        />
      </div>
    </>
  );
};

export default PaymentRequestLink;
