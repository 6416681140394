import './index.scss';

import PaymentRequestForm from '../../../../molecules/PaymentRequestForm';
import PaymentRequestLink from '../../../../molecules/PaymentRequestLink';
import React from 'react';
import { useGetProfileDetail } from '../../../../../store/organization/selector';
import usePaymentRequest from '../../../../../hooks/merchant_portal/usePaymentRequest';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../store/actions';
import LynkModal from '../../../../atoms/LynkModal';

const PaymentRequestModal: React.FC = () => {
  const { createPaymentRequest, paymentRequest, payRequestStep } =
    usePaymentRequest();
  const merchantDetail = useGetProfileDetail();
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <LynkModal
      classes={'customModal'}
      modalBody={
        <>
          {payRequestStep === 'form' ? (
            <PaymentRequestForm
              closeHandler={closeHandler}
              onSubmitHandler={createPaymentRequest}
            />
          ) : (
            <PaymentRequestLink
              orderId={`${paymentRequest?.m_order_id || ''}`}
              amount={paymentRequest?.amount_to_pay ?? ''}
              merchantName={merchantDetail?.merchant}
              url={`${paymentRequest?.short_url}`}
              closeHandler={closeHandler}
            />
          )}
        </>
      }
      title={
        payRequestStep === 'form'
          ? 'New Payment Request'
          : 'Link Ready To Share'
      }
      subTitle={
        payRequestStep === 'form'
          ? 'Please fill in the following fields to create a new payment request link'
          : ''
      }
      loading={false}
      show={true}
    />
  );
};

export default PaymentRequestModal;
