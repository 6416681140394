import './index.scss';

import { Nav, NavItem, NavLink } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { DesktopTabHeaderProps, MenuTab } from '../../../types/models/tabsType';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useGetScreenState } from '../../../store/screenSize/selector';
import { useLocation } from 'react-router';

const DesktopTabHeader: React.FC<DesktopTabHeaderProps> = ({
  className = '',
  selectedTab,
  menuTabs,
  selectTab,
  actionElement
}) => {
  const breakpointDetails = useGetScreenState();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('');

  const [tabsDiplay, setTabsDiplay] = useState<MenuTab[]>([]);
  const [navIndex, setNavIndex] = useState(1);
  useEffect(() => {
    const path = location.pathname.split('/')[2];
    const activePath = menuTabs.find(tab => tab.path === path);
    const activeKey: string = activePath?.key || '';
    changeTab(activeKey, true);
  }, [menuTabs]);

  useEffect(() => {
    if (breakpointDetails.breakpoint === 'smartphone' && menuTabs.length > 3) {
      const tabs: MenuTab[] = [];
      for (let i = 0; i < 2; i++) {
        tabs.push(menuTabs[i]);
      }
      setNavIndex(1);
      setTabsDiplay([...tabs]);
    } else {
      setTabsDiplay([...menuTabs]);
      setNavIndex(0);
    }
  }, [menuTabs, breakpointDetails]);
  const backward = () => {
    const tabs: MenuTab[] = [];
    for (let i = 0; i < 2; i++) {
      tabs.push(menuTabs[i]);
    }
    setNavIndex(1);
    setTabsDiplay(tabs);
  };
  const forward = () => {
    const tabs: MenuTab[] = [];
    for (let i = 2; i < menuTabs.length; i++) {
      tabs.push(menuTabs[i]);
    }
    setNavIndex(2);
    setTabsDiplay(tabs);
  };

  const changeTab = (tabIndex: string, from: boolean) => {
    const foundTabItem = menuTabs.find(tab => tab.key === tabIndex);
    setActiveTab(tabIndex);

    if (foundTabItem && !from) {
      selectTab(foundTabItem);
    }
  };

  return (
    <React.Fragment>
      <Nav
        data-testid="nav"
        className={`merchantTabs ${className} ${
          tabsDiplay.length === 1 && 'oneTab'
        }`}
        tabs
        fill={
          tabsDiplay.length > 1 &&
          (className !== 'noBackground' ||
            breakpointDetails.breakpoint === 'smartphone')
        }
      >
        {navIndex ? (
          <KeyboardArrowLeftIcon
            style={{
              cursor: 'pointer',
              marginTop: '15px',
              fontSize: '28px',
              width: '23px'
            }}
            onClick={() => backward()}
          />
        ) : null}
        {tabsDiplay.map(menuTab => (
          <NavItem
            key={menuTab.key}
            className={'merchantTabs__item'}
            data-testid={'tabitem'}
          >
            <NavLink
              className={`${activeTab === menuTab.key ? 'active' : ''}`}
              onClick={() => changeTab(menuTab.key, false)}
            >
              {menuTab.title}
            </NavLink>
          </NavItem>
        ))}
        {navIndex ? (
          <KeyboardArrowRightIcon
            style={{
              cursor: 'pointer',
              marginTop: '15px',
              fontSize: '28px',
              width: '23px'
            }}
            onClick={() => forward()}
          />
        ) : null}
      </Nav>
      {navIndex ? (
        <div className="tabs-pills">
          <span
            onClick={() => backward()}
            className={navIndex === 1 ? 'active' : ''}
          >
            {' '}
          </span>
          <span
            onClick={() => forward()}
            className={navIndex === 2 ? 'active' : ''}
          >
            {' '}
          </span>
        </div>
      ) : null}
      {!!actionElement && actionElement}
    </React.Fragment>
  );
};

export default DesktopTabHeader;
