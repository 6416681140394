export function currencyFormat(
  numCurrency?: number,
  trailingZero = true
): string {
  const newValue = numCurrency ? numCurrency : 0.0;
  const formatted =
    newValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? '';

  return trailingZero ? formatted : formatted.replace(/\.00$/, '');
}

export const amountFormated = (
  amount: number,
  addTrailingDecimalZero = true
): string => {
  return `J$${currencyFormat(Math.abs(amount), addTrailingDecimalZero)}`;
};

export const cdbcAmountFormatted = (
  amount: number,
  addTrailingDecimalZero = true
): string => {
  return `${currencyFormat(Math.abs(amount), addTrailingDecimalZero)} JAM-DEX`;
};
export const moneyFormated = (
  amount: number,
  addTrailingDecimalZero = true
): string => {
  return `${currencyFormat(Math.abs(amount), addTrailingDecimalZero)}`;
};
