/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import { FilterRangeObject } from '../../../types/models/dateTypes';
import FilterButton from '../../molecules/FilterButton';
import { BranchesRow } from '../../../types/models/tableTransactions';
import TableCard from '../../atoms/TableCard';
import BranchColumnFormatter from '../../molecules/BranchColumnFormatter';
import { amountFormated } from '../../../utils/currency';
import { getTimeStamp } from '../../../utils/dateFormatter';
import useConfigTable from '../../../hooks/utils/tableConfig/useConfigTable';
import {
  SorterProps,
  SortingAscDesc
} from '../../../types/models/utils/table-models';
import { useGetScreenState } from '../../../store/screenSize/selector';
import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import useRequestRefund from '../../../hooks/merchant_portal/useRequestRefund';
import useOrderUtil from '../Orders/useOrderUtil';
import { useGetProfileState } from '../../../store/profile/selector';
import { restartBalance, addBalance } from '../../../store/organization';

import useGetAccountDetails from '../../../hooks/account/useGetAccountDetails';
import useFormatBranchAccounts from '../../../hooks/utils/useFormatBranchAccounts';
import { useDispatch } from 'react-redux';

const Branches: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const transactionTableState = useGetTransactionTableState();
  const { id } = useGetProfileState();

  const [transTooltip, setTransToolitp] = useState<any>({});
  const { getTransactionDetails } = useConfigTable();
  const { dispatchMetadataUpdate } = useGetPaginationInformation();
  const { getChildrenAccount } = useGetAccountDetails();
  const breakpointDetails = useGetScreenState();
  const [pageLimit, setPageLimit] = useState(25);
  const [accounts, setAccounts] = useState([]);
  const { generateBranchAccounts } = useFormatBranchAccounts();
  const dispatch = useDispatch();
  useEffect(() => {
    getAccounts();
  }, [id]);
  useEffect(() => {
    setPageLimit(breakpointDetails.breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpointDetails]);
  const getAccounts = async () => {
    const resp = await getChildrenAccount();
    setAccounts(resp.children);
  };
  useEffect(() => {
    dispatch(restartBalance());
    setTransToolitp(getTransactionDetails(TransactionStateKeyEnum.BRANCHES));
  }, []);

  const { startRefund } = useRequestRefund();

  const ColumnStructure = (
    transactions: BranchesRow[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: BranchesRow) => (
      <tr key={row.merchant_id}>
        {columnHeaders.map((col: any) => (
          <td
            key={`${row.merchant_id}-${col.field}`}
            className={`${col.label}`}
          >
            <BranchColumnFormatter
              column={col}
              row={row}
              action={startRefund}
              accounts={accounts}
            />
          </td>
        ))}
      </tr>
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return ColumnStructure(
      generateBranchAccounts(accounts, transactions),
      columnHeaders
    );
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.BRANCHES].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;

    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.BRANCHES
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.BRANCHES]]
  );
  const endpointGenerator = (endpoint: string) => {
    return endpoint.replace('{accountID}', id);
  };
  return (
    <DataDisplayContainer
      {...dataDisplayProps}
      // columnFilter={columnFilter}
      endpointGenerator={endpointGenerator}
      dataKey={TransactionStateKeyEnum.BRANCHES}
      dataGenerator={generateDisplay}
      noDataMessage={'No transactions yet'}
      fetchingData={{
        fetchingText: (
          <ButtonAsyncLoader
            loadingText="Loading Data... Please wait. "
            showLoaderText={true}
          />
        )
      }}
    />
  );
};

export default Branches;
