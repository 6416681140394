import './index.scss';
import ActionButton from '../../atoms/ActionButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ButtonTooltip from '../../atoms/ButtonTooltip';
import React from 'react';
import { ReplayOutlined } from '@mui/icons-material';
import BaseColFormatter from '../../atoms/BaseColumnFormatter';
import { usePermissions } from '../../../features/permissions';
import { transactionStateListing } from '../../../hooks/utils/tableConfig/transactionState';

interface ButtonColProps {
  row: any;
  refundFn: (r: any) => void;
  lynkBalanceInsufficient?: boolean;
}

interface ColumnProps {
  readOnlyMode?: boolean;
  column: any;
  row: any;
  refundFn?: (r: any) => void;
  stateOptions: any[];
  tooltipIdentifier: string;
  moreInfoCallback?: () => void;
  disbursementFees?: boolean;
  lynkBalanceInsufficient?: boolean;
  isOrdersTable?: boolean;
}

const INSUFFICIENT_BALANCE_MESSAGE =
  'Your Lynk account balance is too low to complete the transaction';

const ButtonColFormatter: React.FC<ButtonColProps> = ({
  row,
  refundFn,
  lynkBalanceInsufficient
}) => {
  const Btn = (
    <ActionButton
      type="button"
      classnames="refund-button"
      text={'Refund'}
      data-testid="refundButton"
      onPress={() => refundFn(row)}
      rightIcon={<ReplayOutlined />}
      testId="refundButton"
      disabled={lynkBalanceInsufficient}
    />
  );

  const showButton = row.status === 'PAID' || false;

  return <>{showButton ? Btn : <></>}</>;
};

const TransactionColumnFormatter: React.FC<ColumnProps> = ({
  readOnlyMode = false,
  column,
  row,
  refundFn,
  stateOptions,
  tooltipIdentifier,
  moreInfoCallback,
  disbursementFees,
  lynkBalanceInsufficient,
  isOrdersTable
}) => {
  const { hasPermissions } = usePermissions();

  const updateFieldTextFn = (status: string) => {
    if (status === Object.keys(transactionStateListing.transactions)[0]) {
      return 'Awaiting Payment';
    } else {
      return (
        status.toLowerCase().charAt(0).toUpperCase() +
        status.slice(1).toLowerCase()
      );
    }
  };

  let ColumnData = <BaseColFormatter column={column} row={row} />;
  if (column.button && refundFn)
    ColumnData = (
      <div className="orders-action">
        {hasPermissions([
          { resource: 'refunds', actions: new Set(['create']) }
        ]) && (
          <>
            {lynkBalanceInsufficient ? (
              <ButtonTooltip
                tooltipIdentifier={`tooltip-${tooltipIdentifier}`}
                withoutIcon={true}
                feedback={INSUFFICIENT_BALANCE_MESSAGE}
                title={
                  <ButtonColFormatter
                    row={row}
                    refundFn={refundFn}
                    lynkBalanceInsufficient={lynkBalanceInsufficient}
                  />
                }
              />
            ) : (
              <ButtonColFormatter
                row={row}
                refundFn={refundFn}
                lynkBalanceInsufficient={lynkBalanceInsufficient}
              />
            )}
          </>
        )}
        <div className="moreDetailsIcon" onClick={moreInfoCallback}>
          <MoreVertIcon />
        </div>
      </div>
    );
  else if (column.button && disbursementFees) {
    ColumnData = (
      <div className="orders-action">
        <div className="moreDetailsIcon" onClick={moreInfoCallback}>
          <MoreVertIcon />
        </div>
      </div>
    );
  } else if (column.tooltip && isOrdersTable) {
    ColumnData = (
      <ButtonTooltip
        tooltipIdentifier={`tooltip-${tooltipIdentifier}`}
        title={updateFieldTextFn(row[column?.field]) || ''}
        feedback={stateOptions[row[column.field] || '']}
      />
    );
  } else if (column.tooltip) {
    ColumnData = (
      <ButtonTooltip
        tooltipIdentifier={`tooltip-${tooltipIdentifier}`}
        title={row[column?.field] || ''}
        feedback={stateOptions[row[column.field] || '']}
      />
    );
  }

  return <>{ColumnData}</>;
};

export default TransactionColumnFormatter;
