import './index.scss';

import CloseOutlined from '@mui/icons-material/CloseOutlined';
import React from 'react';
import { FilterListingItemProps } from '../../../types/models/filtersType';

const FilterListingItem: React.FC<FilterListingItemProps> = ({
  testId = 'filterButtonRangeItem',
  onClickFn,
  filterListContent
}) => {
  const onCloseClick = () => {
    onClickFn('');
  };

  return (
    <>
      <div className="filterListingItem" data-testid={testId}>
        <div className="filterListingItem-content">{filterListContent}</div>
        <div data-testid={`${testId}Icon`} onClick={onCloseClick}>
          <CloseOutlined className="closeIcon" />
        </div>
      </div>
    </>
  );
};

export default FilterListingItem;
