import './index.scss';

import React from 'react';

import LynkModal from '../../../../atoms/LynkModal';
import useExportTableNav from './hooks/useExportTableNav';
import { useExportReportMetadata } from '../../../../../store/modals/exportAndDownload/selector';
import { useModalState } from '../../../../../store/modals/modal/selector';

const ExportTableModal: React.FC = () => {
  const { executeDispatchMap, currentPageDetails, close, currentPage } =
    useExportTableNav();

  const metadata = useExportReportMetadata();

  const { modalKey } = useModalState();

  const mainBtn = currentPageDetails.mainAction
    ? {
        text: currentPageDetails.mainAction.text,
        testID: 'exportTableActionBtn',
        loader: currentPageDetails?.mainAction?.loader,
        onClickAsyncFn: async () => executeDispatchMap(currentPage),
        disabled: metadata.exportStatus.inProgress || !metadata.isValidForm
      }
    : undefined;

  return (
    <React.Fragment>
      <LynkModal
        loading={metadata.exportStatus.inProgress}
        title={currentPageDetails.title}
        modalBody={currentPageDetails.component}
        show={modalKey === 'exporting-table'}
        mainAction={mainBtn}
        closeAction={{
          testID: 'cancelAction',
          onClickFn: close,
          text: 'Cancel'
        }}
      />
    </React.Fragment>
  );
};

export default ExportTableModal;
