import { CustomerTransactionRow } from '../../../types/models/tableTransactions';
import useRequestRefund from '../../../hooks/merchant_portal/useRequestRefund';
import { usePermissions } from '../../../features/permissions';
import { hideModal, showModal } from '../../../store/actions';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { amountFormated } from '../../../utils/currency';
import { formatChannelData } from '../../../features/orders';

const useOrderUtil = (): {
  moreInfoCallback: (
    data: CustomerTransactionRow,
    stateOptions: any,
    lynkWalletBalance: number
  ) => void;
} => {
  const { startRefund } = useRequestRefund();

  const { hasPermissions } = usePermissions();
  const dispatch = useDispatch();

  const moreInfoCallback = (
    data: CustomerTransactionRow,
    stateOptions: any,
    lynkWalletBalance: number
  ) => {
    const updateStatusText = (status: string) => {
      if (status === Object.keys(stateOptions)[0]) {
        return 'Awaiting Payment';
      } else {
        return (
          status.toLowerCase().charAt(0).toUpperCase() +
          status.slice(1).toLowerCase()
        );
      }
    };

    const dataMap = {
      closeAction:
        data.status === 'PAID' &&
        hasPermissions([{ resource: 'refunds', actions: new Set(['create']) }])
          ? {
              testID: 'nextStep',
              onClickFn: () => {
                dispatch(hideModal());
                startRefund(data);
              },
              text: 'Issue Refund'
            }
          : undefined,
      transactionDetails: data,
      lynkWalletBalance: lynkWalletBalance,
      details: [
        {
          label: 'ORDER ID',
          value: data.m_order_id
        },
        {
          label: 'TRANSFER ID',
          value: data.transfer_id
        },
        {
          label: 'EXTERNAL TRANSACTION ID',
          value:
            data.external_transaction_id !== null
              ? data.external_transaction_id
              : '-'
        },
        {
          label: 'FROM',
          value: data.lynk_id
        },
        {
          label: 'DATE',
          value: moment(data.datetime).format('DD/MM/YYYY HH:mm a')
        },
        {
          label: 'TOTAL',
          value: amountFormated(data.amount_to_pay)
        },
        {
          label: 'COMMISSION',
          value: amountFormated(data.fee || 0)
        },
        {
          label: 'GCT',
          value: amountFormated(data.tax || 0)
        },
        {
          label: 'STATUS',
          value: updateStatusText(data.status),
          tooltip: {
            value: data.status,
            id: data.m_order_id,
            feedback: stateOptions[`${data.status}`]
          }
        },
        {
          label: 'CHANNEL',
          value: formatChannelData(data.channel)
        },
        {
          label: 'BILLED BY',
          value: data.cashier_name
        }
      ]
    };

    dispatch(
      showModal({
        modalKey: 'more-order-details',
        data: dataMap,
        title: 'Transaction'
      })
    );
  };

  return {
    moreInfoCallback
  };
};

export default useOrderUtil;
