import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetProfileDetail } from '../store/organization/selector';
import useGetStaff from '../hooks/staff/useGetStaff';
import useGetMerchantDetails from '../hooks/merchant/useGetMerchantDetails';

export default function NoOrg() {
  console.log('Wo0t!');
  const navigate = useNavigate();
  const { getStaffDetails } = useGetStaff();
  // const {getMerchantDetails} = useGetMerchantDetails()
  const profile = useGetProfileDetail();
  console.log(profile);
  useEffect(() => {
    if (profile.organization_id) {
      navigate(`./${profile.organization_id.replace('org_', '')}`);
    }
    const details = getStaffDetails('608');
    console.log(details);
  }, [profile]);

  return <>Building!</>;
}
