import { useGetProfileState } from '../../store/profile/selector';
import useGetAccountDetails from '../../hooks/account/useGetAccountDetails';

export interface Permission {
  actions: Set<string>;
  resource: string;
}

const useGetProfile = (): {
  isAdmin(accountid: string): Promise<boolean>;
} => {
  const profile = useGetProfileState();

  const { getChildrenAccount } = useGetAccountDetails();

  const isAdmin = async (accountid: string) => {
    const access = profile.role.toLowerCase().includes('super admin')
      ? true
      : false;
    try {
      const accounts = await getChildrenAccount();
      return accounts.children.length === 0
        ? access
        : accounts.children.find(
            (child: any) => child.id !== profile.merchant_id
          )
        ? access
        : false;
    } catch (error) {
      return false;
    }
  };
  return {
    isAdmin
  };
};

export default useGetProfile;
