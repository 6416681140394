import { ApiServiceType } from '../../types/enums/apiServiceType';
import { setStaffRoleCache } from '../../store/data';
import useGetData from '../api/useGetData';
import { useDispatch } from 'react-redux';

const useGetStaffTypeOptions = (): {
  loadStaffTypeOptions: () => Promise<any>;
} => {
  const { isFetching, getData } = useGetData();
  const dispatch = useDispatch();

  const loadStaffTypeOptions = async () => {
    try {
      const response = await getData({
        path: '/v2/roles',
        apiType: ApiServiceType.PROFILE
      });

      dispatch(setStaffRoleCache({ ...response.data }));
    } catch (err: unknown) {
      dispatch(setStaffRoleCache([]));
    }
  };
  return { loadStaffTypeOptions };
};
export default useGetStaffTypeOptions;
