/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import DataDisplayContainer from '../../molecules/DataDisplayContainer';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import { FilterRangeObject } from '../../../types/models/dateTypes';
import FilterButton from '../../molecules/FilterButton';
import { useDispatch } from 'react-redux';
import { TransactionRow } from '../../../types/models/tableTransactions';
import TableCard from '../../atoms/TableCard';
import TransactionColumnFormatter from '../../molecules/TransactionColumnFormatter';
import { hideModal, showModal } from '../../../store/actions';
import { amountFormated } from '../../../utils/currency';
import { getTimeStamp } from '../../../utils/dateFormatter';
import moment from 'moment';
import useConfigTable from '../../../hooks/utils/tableConfig/useConfigTable';
import './index.scss';
import {
  SorterProps,
  SortingAscDesc
} from '../../../types/models/utils/table-models';
import { useGetScreenState } from '../../../store/screenSize/selector';
import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { ReactComponent as AlertIcon } from '../../../assets/svg/alert.svg';
import { TransferTypes } from '../../../types/enums/transferTypes';

const TransferHistory: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  const transactionTableState = useGetTransactionTableState();

  const [transTooltip, setTransTooltip] = useState<any>({});
  const { getTransactionDetails } = useConfigTable();

  const [pageLimit, setPageLimit] = useState(25);
  const { breakpoint } = useGetScreenState();
  const { dispatchMetadataUpdate } = useGetPaginationInformation();
  const walletManager = useGetWalletManager();
  useEffect(() => {
    setTransTooltip(getTransactionDetails(TransactionStateKeyEnum.TRANSFERS));
  }, []);

  useEffect(() => {
    setPageLimit(breakpoint === 'smartphone' ? 10 : 25);
  }, [breakpoint]);

  const columnFilter = (columns: any[]) => {
    const val = columns.filter(col => {
      if (col.field !== 'type') {
        return true;
      }

      return col.show(walletManager.currentWallet);
    });

    return val;
  };

  const endpointGenerator = (endpoint: string) => {
    return `${endpoint}?${
      walletManager.currentWallet === 'jam-dex'
        ? 'jam-dex-only=true'
        : 'jam-dex-only=false'
    }`;
  };

  const filterRangeHandler = (range: FilterRangeObject) => {
    dispatchMetadataUpdate(
      {
        tableFilter: {
          dateRange: range
        }
      },
      TransactionStateKeyEnum.TRANSFERS
    );
  };

  const moreInfoCallback = (data: TransactionRow) => {
    const dataMap = {
      closeAction: {
        testID: 'modalCancelBtn',
        text: 'Close',
        onClickFn: () => dispatch(hideModal())
      },
      details: [
        {
          label: 'Transaction ID',
          value: data.transfer_id
        },
        {
          label: 'TO',
          value: data.to
        },
        {
          label: 'DATE',
          value: moment(data.datetime).format('MMM DD YYYY hh:mm a')
        },
        {
          label: 'Total',
          value: amountFormated(Number.parseFloat(data.total))
        },
        {
          label: 'Commission',
          value: amountFormated(Number.parseFloat(data.commission))
        },
        {
          label: 'GCT',
          value: amountFormated(Number.parseFloat(data.gct))
        },
        {
          label: 'STATUS',
          value: data.status,
          tooltip: {
            value: data.status,
            id: data.transfer_id,
            feedback: transTooltip[`${data.status}`]
          }
        }
      ]
    };

    if (walletManager.currentWallet === 'lynk') {
      dataMap.details.push({
        label: 'TYPE',
        value: data.type
      });
    }

    dispatch(
      showModal({
        modalKey: 'more-trans-details',
        data: dataMap,
        title: 'Transaction'
      })
    );
  };

  const ColumnStructure = (
    transactions: any[],
    columnHeaders: any[]
  ): JSX.Element[] => {
    return transactions.map((row: TransactionRow) => (
      <tr key={row.transfer_id}>
        {columnHeaders.map((col: any) => (
          <td key={`${row.transfer_id}-${col.field}`} className="col-auto">
            <TransactionColumnFormatter
              column={col}
              row={row}
              stateOptions={transTooltip}
              tooltipIdentifier={`tooltip-${col.field}-${row.transfer_id}`}
              moreInfoCallback={() => moreInfoCallback(row)}
              disbursementFees={
                row.type.toUpperCase() === TransferTypes.DISBURSEMENT
              }
            />
          </td>
        ))}
      </tr>
    ));
  };

  const MobileColFormatter = (transactions: any[]): JSX.Element[] => {
    return transactions.map((row: TransactionRow) => (
      <TableCard
        key={row.transfer_id}
        title={row.status || ''}
        mainTitle={row.from}
        subTitle={getTimeStamp(row.datetime || '')}
        data={amountFormated(Number.parseFloat(row.total))}
        moreInfoCallback={() => moreInfoCallback(row)}
      />
    ));
  };

  const generateDisplay = (
    transactions: any[],
    columnHeaders: any[],
    breakpoint: string
  ) => {
    return breakpoint === 'smartphone'
      ? MobileColFormatter(transactions)
      : ColumnStructure(transactions, columnHeaders);
  };

  const generateTableHeader = (isActive = true) => {
    return (
      <div>
        <FilterButton
          filterRange={
            transactionTableState[TransactionStateKeyEnum.TRANSFERS].tableFilter
              .dateRange
          }
          setFilterRange={filterRangeHandler}
          isActive={isActive}
        />
      </div>
    );
  };

  const updateSort = () => {
    const newSortOrder =
      transactionTableState[TransactionStateKeyEnum.TRANSFERS].sort ===
      SortingAscDesc.DESC
        ? SortingAscDesc.ASC
        : SortingAscDesc.DESC;
    dispatchMetadataUpdate(
      { sort: newSortOrder },
      TransactionStateKeyEnum.TRANSFERS
    );
  };

  const sortCol: SorterProps[] = [
    {
      column: ['date', 'datetime'],
      icon: 'sort',
      sorter: updateSort
    }
  ];

  const dataDisplayProps = useMemo(
    () => ({
      sorter: sortCol,
      pageLimit,
      header: generateTableHeader
    }),
    [pageLimit, transactionTableState[TransactionStateKeyEnum.TRANSFERS]]
  );

  return (
    <DataDisplayContainer
      {...dataDisplayProps}
      columnFilter={columnFilter}
      endpointGenerator={endpointGenerator}
      dataKey={TransactionStateKeyEnum.TRANSFERS}
      dataGenerator={generateDisplay}
      noDataMessage={'No transactions yet'}
      fetchingData={{
        fetchingText: (
          <ButtonAsyncLoader
            loadingText="Loading Data... Please wait. "
            showLoaderText={true}
          />
        )
      }}
    />
  );
};

export default TransferHistory;
