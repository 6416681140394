import usePostData from '../api/usePostData';
import useGetData from '../api/useGetData';
import { ApiServiceType } from '../../types/enums/apiServiceType';
import { SinglePaymentDetailType } from '../../types/models/SinglePaymentsType';
import { parseErrorFromAxiosCall } from '../../services/api';
export interface SPPayee {
  lynk_id: string;
  amount: string;
}

const usePostSinglePaymentProcess = (): {
  isSinglePaymentFetching: boolean;
  abortController: AbortController;
  createSinglePayment(data: SPPayee): Promise<any>;
  requestApprovalSinglePayment(
    singlePayment: SinglePaymentDetailType
  ): Promise<any>;
  startSinglePayment: (
    transaction: SinglePaymentDetailType,
    account: string
  ) => Promise<any>;
  requestRejectSinglePayment: (
    transaction: SinglePaymentDetailType,
    account: string
  ) => Promise<any>;
} => {
  const { isFetching, postData } = usePostData();
  const abortController = new AbortController();

  const createSinglePayment = async (data: SPPayee) => {
    const response = await postData({
      path: 'batch-payments/single-payee',
      apiType: ApiServiceType.PAYMENT,
      controller: abortController,
      data
    });
    return response;
  };

  const requestApprovalSinglePayment = async (
    singlePayment: SinglePaymentDetailType
  ) => {
    try {
      const response = await postData({
        path: `batch-payments/${singlePayment.id}/request`,
        apiType: ApiServiceType.PAYMENT
      });
      return response;
    } catch (err: any) {
      //add unhappy path
      console.log(err);
    }
  };

  const customerTransaction = async (
    values: SinglePaymentDetailType,
    account: string
  ) => {
    const funds = account.split(':');
    const formattedAccount = funds[0].toUpperCase();
    const response = await postData({
      path: `batch-payments/${values.id}/approve`,
      apiType: ApiServiceType.PAYMENT,
      data: {
        account_type:
          formattedAccount === 'BANK_ACCT' ? 'BANK_ACCOUNT' : formattedAccount,
        account_id: funds[1]
      }
    });

    return response;
  };

  const startSinglePayment = async (
    transactionSelected: SinglePaymentDetailType,
    account: string
  ) => {
    try {
      const transaction: any = await customerTransaction(
        transactionSelected,
        account
      );
      return {
        transaction
      };
    } catch (error) {
      throw parseErrorFromAxiosCall(error);
    }
  };

  const rejectTransaction = async (
    values: SinglePaymentDetailType,
    reason: string
  ) => {
    const response = await postData({
      path: `batch-payments/${values.id}/reject`,
      apiType: ApiServiceType.PAYMENT,
      data: {
        reason: reason
      }
    });
    return response;
  };

  const requestRejectSinglePayment = async (
    transactionSelected: SinglePaymentDetailType,
    reason: string
  ) => {
    try {
      const transaction: any = await rejectTransaction(
        transactionSelected,
        reason
      );
      return {
        status: 200,
        details: 'Ok'
      };
    } catch (error) {
      throw parseErrorFromAxiosCall(error);
    }
  };

  return {
    isSinglePaymentFetching: isFetching,
    createSinglePayment,
    startSinglePayment,
    requestApprovalSinglePayment,
    requestRejectSinglePayment,
    abortController
  };
};

export default usePostSinglePaymentProcess;
