import React from 'react';
import { menuTabs, menuTabsNotifications } from './tabList';
import { widgets } from './widgetList';
import Env from '../../helpers/Env';
import { usePermissions } from '../../features/permissions';
import { SidebarItem, sidebarListJamDex, sidebarListLynk } from './sidebar';
import { MenuTab } from '../../types/models/tabsType';
import { useGetWalletManager } from '../../store/walletManagement/selector';
import { profileItems, ProfileMenuItem } from './profileOptionList';

const useDashboardSetup = (): {
  getValidCorporateWidgets: () => JSX.Element[];
  getValidWidgets: () => JSX.Element[];
  getValidTabComponents: () => MenuTab[];
  getValidSidebarComponents: () => SidebarItem[];
  getValidSidebarComponentsJamdex: () => SidebarItem[];
  getValidProfileListing: () => ProfileMenuItem[];
  getNotificationsTabComponents: () => MenuTab[];
} => {
  const { hasPermissions } = usePermissions();
  const walletManager = useGetWalletManager();

  const getValidCorporateWidgets = (): JSX.Element[] => {
    const override = Env.WIDGET_VIEW_OVERRIDE.split(',');

    let finalWidgetList = widgets;
    if (override.length > 0) {
      finalWidgetList = widgets.filter(
        widget => !override.includes(widget.key)
      );
    }

    return finalWidgetList
      .filter(
        component =>
          hasPermissions(component.permissions) &&
          component.walletView.includes('corporate')
      )
      .map(comp => <div key={comp.key}>{comp.comp}</div>);
  };
  const getValidWidgets = (): JSX.Element[] => {
    const override = Env.WIDGET_VIEW_OVERRIDE.split(',');

    let finalWidgetList = widgets;
    if (override.length > 0) {
      finalWidgetList = widgets.filter(
        widget => !override.includes(widget.key)
      );
    }

    return finalWidgetList
      .filter(
        component =>
          hasPermissions(component.permissions) &&
          component.walletView.includes(walletManager.currentWallet)
      )
      .map(comp => <div key={comp.key}>{comp.comp}</div>);
  };

  const getValidTabComponents = (): MenuTab[] => {
    return menuTabs
      .filter(
        component =>
          hasPermissions(component.permissions) &&
          component.walletView.includes(walletManager.currentWallet)
      )
      .map(tabItem => {
        const newTabItem: MenuTab = {
          ...tabItem,
          paneComponent: (
            <div className="tabContent" key={tabItem.key}>
              {tabItem.paneComponent}
            </div>
          )
        };

        return newTabItem;
      });
  };
  const getValidProfileListing = (): ProfileMenuItem[] => {
    if (walletManager.currentWallet === 'corporate') {
      return profileItems.filter(profileItem => profileItem.key === 'logout');
    } else {
      return profileItems.filter(
        profileItem =>
          profileItem.active &&
          (!profileItem.permissions || hasPermissions(profileItem.permissions))
      );
    }
  };

  const getValidSidebarComponents = (): SidebarItem[] => {
    return sidebarListLynk.filter(component =>
      hasPermissions(component.permissions)
    );
  };

  const getValidSidebarComponentsJamdex = (): SidebarItem[] => {
    return sidebarListJamDex.filter(component =>
      hasPermissions(component.permissions)
    );
  };
  const getNotificationsTabComponents = (): MenuTab[] => {
    return menuTabsNotifications.map(tabItem => {
      const newTabItem: MenuTab = {
        ...tabItem,
        paneComponent: (
          <div className="tabContent" key={tabItem.key}>
            {tabItem.paneComponent}
          </div>
        )
      };

      return newTabItem;
    });
  };
  return {
    getValidWidgets,
    getValidTabComponents,
    getValidSidebarComponents,
    getValidProfileListing,
    getValidSidebarComponentsJamdex,
    getNotificationsTabComponents,
    getValidCorporateWidgets
  };
};

export default useDashboardSetup;
