export interface Headers {
  'Content-Type': string;
  Authorization: string;
}

export const getHeaders = (
  useAuthentication: boolean,
  token = ''
): Headers | Record<string, never> => {

  if (useAuthentication && !token) {
    throw Error('User not authenticated');
  }

  const headers: Headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };

  return useAuthentication ? headers : {};
};
