import { NotFoundTemplateProps } from '../../../types/models/notFoundType';
import React from 'react';

import './index.scss';
import { ReactComponent as ComputerIcon } from '../../../assets/svg/Computer.svg';
import { ReactComponent as NotFoundIcon } from '../../../assets/svg/notFound.svg';
import ActionButton from '../../atoms/ActionButton';
import { Link } from 'react-router-dom';

const NotFoundTemplate: React.FC<NotFoundTemplateProps> = ({
  pageNotFoundReturn
}) => {
  const goHomeHandler = () => {
    console.log('going back');
  };
  return (
    <div className="container notFoundTemplate">
      <div className="row notFoundTemplate__pageDetails">
        <div className="col-md-4" style={{ display: 'flex' }}>
          <ComputerIcon className="computerIcon" />
        </div>

        <div className="col-md-6 notFoundTemplate__pageDetails--subtext">
          <NotFoundIcon />
          <h3>Looks like you´re lost.</h3>
          <div className="">
            <p>How’d you get here? We’re sorry but that page</p>
            <p>your looking for doesn’t exist.</p>
            <Link to={'./../'} className={'hyperlink'}>
              <ActionButton
                type={'primary'}
                text={pageNotFoundReturn}
                onPress={goHomeHandler}
                classnames="goHomeButton"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundTemplate;
