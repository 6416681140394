import { ApiServiceType } from '../../types/enums/apiServiceType';
import { PaymentRequest } from '../../types/models/paymentRequestType';
import usePostData from '../../hooks/api/usePostData';
import { useState } from 'react';

type PayRequestStep = 'form' | 'link';
type ValuesRequestType = {
  amount_to_pay: number;
  currency?: string;
  description?: string;
};

const usePaymentRequest = (): {
  createPaymentRequest: (values: ValuesRequestType) => Promise<void>;
  paymentRequest?: PaymentRequest;
  requestLink?: string;
  payRequestStep: PayRequestStep;
} => {
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  const [payRequestStep, setPayRequestStep] = useState<PayRequestStep>('form');
  const { postData } = usePostData();
  const [requestLink, setRequestLink] = useState('');

  const customerTransaction = async (values: ValuesRequestType) => {
    const response = await postData({
      path: 'customer-transactions',
      apiType: ApiServiceType.PAYMENT,
      data: values
    });

    return response;
  };

  const createPaymentRequest = async (values: ValuesRequestType) => {
    try {
      const transaction: any = await customerTransaction(values);

      setPaymentRequest(transaction);
      setPayRequestStep('link');
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createPaymentRequest,
    paymentRequest,
    requestLink,
    payRequestStep
  };
};

export default usePaymentRequest;
