import { ApiServiceType } from '../../types/enums/apiServiceType';
import { updateProgress } from '../../store/modals/exportAndDownload';
import { useDispatch } from 'react-redux';
import usePostData from '../../hooks/api/usePostData';

export interface GenerateReportPreferenceProps {
  types: string[];
  dateRange: {
    startRange: string;
    endRange: string;
  };
}

const usePostGenerateReportForExport = (): {
  isFetching: boolean;
  attemptGenerateNewReport: (
    preferences: GenerateReportPreferenceProps
  ) => Promise<any>;
} => {
  const { isFetching, postData } = usePostData();

  const attemptGenerateNewReport = async (
    preferences: GenerateReportPreferenceProps
  ) => {
    const data = {
      start: preferences.dateRange.startRange,
      end: preferences.dateRange.endRange,
      types: preferences.types
    };

    const response = await postData({
      path: 'v2/transfers/exports/csv',
      apiType: ApiServiceType.REPORTING,
      data
    });

    return response.data.result.base64File;
  };

  return { isFetching, attemptGenerateNewReport };
};

export default usePostGenerateReportForExport;
