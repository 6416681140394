import React from 'react';

import NotFoundPage from '../components/pages/NotFoundPage';
import Authmiddleware from '../routes/middleware/Authmiddleware';
import StaffPage from '../components/pages/StaffPage';
import JAMDEXPageDashboard from '../components/pages/JAMDEXPageDashboard';
import SinglePageDashboard from '../components/pages/LynkPageDashboard';

import { Navigate, RouteObject } from 'react-router-dom';

import {
  generateTabNotificationsRouting,
  generateTabRouting
} from '../hooks/permissions/tabList';
import ProtectedRoute from '../providers/AuthProvider/protectedRoute';
import Notifications from '../components/pages/Notifications';
import Settings from '../components/pages/Notifications/Settings';

import HorizontalLayout from '../components/organisms/HorizontalLayout';

import NoOrg from './no-org';
import CorporatePage from '../components/pages/CorporatePage';

const AltLayout = (
  <>
    <NoOrg />
  </>
);
const Layout = (
  <>
    <HorizontalLayout />
  </>
);
const reviewLayout = () => {
  if (location.pathname.split('/')[1].includes('wallet')) {
    return AltLayout;
  }
  return Layout;
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Authmiddleware isAuthProtected={true} />,
    children: [
      {
        element: Layout,
        children: [
          {
            index: true,
            element: <CorporatePage />
          },
          {
            path: 'staff',
            element: <StaffPage />
          }
        ]
      },
      {
        path: '/:org_id',
        element: reviewLayout(),
        children: [
          {
            index: true,
            element: <Navigate to={'lynk-wallet'} />
          },
          {
            path: 'lynk-wallet',
            element: <SinglePageDashboard />,
            children: generateTabRouting(false)
          },
          {
            path: 'jamdex-wallet',
            element: (
              <ProtectedRoute isSignedIn={true}>
                <JAMDEXPageDashboard />
              </ProtectedRoute>
            ),
            children: generateTabRouting(true)
          },
          {
            path: 'staff',
            element: <StaffPage />
          },
          {
            path: 'notifications',
            children: [
              {
                path: '',
                element: <Notifications />,
                children: generateTabNotificationsRouting()
              },
              {
                path: 'settings',
                element: <Settings />
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
];

export default routes;
