import React, { useEffect, useState } from 'react';

import WalletWidget from '../WalletWidget';
import { getIconMapper } from '../../../utils/iconMap';
import useGetWalletBalance from '../../../hooks/merchant_portal/useGetWalletBalance';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { useGetOverallJAMDEXBalance } from '../../../store/organization/selector';

const JAMDEXWallet: React.FC = () => {
  const { fetching, getWalletBalance, abortController } = useGetWalletBalance();
  const [balance, setBalance] = useState(0);
  const walletManager = useGetWalletManager();
  const overallBalance = useGetOverallJAMDEXBalance();
  useEffect(() => {
    return () => abortController.abort();
  }, []);

  // const loadBalance = async () => {
  //   const balance = await getWalletBalance('jam-dex');
  //   setBalance(balance);
  // };
  const loadBalance = async () => {
    try {
      const balance = await getWalletBalance('jam-dex');
      setBalance(
        walletManager.currentWallet === 'corporate'
          ? balance + overallBalance
          : balance
      );
    } catch (error) {
      setBalance(
        walletManager.currentWallet === 'corporate' ? overallBalance : 0
      );
    }
  };
  return (
    <WalletWidget
      loading={fetching}
      widgetIcon={getIconMapper('jam-dex-white')}
      widgetTitle={'JAM-DEX Balance'}
      balanceDetails={{
        amount: balance,
        currency: 'JAM-DEX'
      }}
      loadBalance={loadBalance}
    />
  );
};

export default JAMDEXWallet;
