import React from 'react';
import Refund from '../../components/templates/Refund';
import TransferHistory from '../../components/templates/Transfers';
import Orders from '../../components/templates/Orders';
import { TransactionStateKeyEnum } from '../utils/tableConfig/transactionState';
import { MenuTab } from '../../types/models/tabsType';
import Payments from '../../components/templates/Payments';
import BatchTransfers from '../../components/templates/BatchPayments';
import NotificationsList from '../../components/templates/Notifications/NotificationsList';
import { Navigate, RouteObject } from 'react-router-dom';
import ReviewPaymentModal from '../../components/organisms/modals/main/ReviewPaymentModal';

export const menuTabs: MenuTab[] = [
  {
    title: 'Orders',
    permissions: [
      { resource: 'orders', actions: new Set(['read']) },
      { resource: 'owned-orders', actions: new Set(['read']) },
      { resource: 'refunds', actions: new Set(['create']) }
    ],
    walletView: ['lynk'],
    path: 'orders',
    key: TransactionStateKeyEnum.TRANSACTIONS,
    paneComponent: <Orders />
  },
  {
    title: 'Payments',
    permissions: [
      { resource: 'orders', actions: new Set(['read']) },
      { resource: 'owned-orders', actions: new Set(['read']) },
      { resource: 'refunds', actions: new Set(['create']) }
    ],
    key: TransactionStateKeyEnum.PAYMENT,
    paneComponent: <Payments />,
    walletView: ['jam-dex'],
    path: 'payments'
  },
  {
    title: 'Transfers History',
    permissions: [
      { resource: 'cashouts', actions: new Set(['read']) },
      { resource: 'cashins', actions: new Set(['read']) }
    ],
    key: TransactionStateKeyEnum.TRANSFERS,
    paneComponent: <TransferHistory />,
    walletView: ['lynk', 'jam-dex'],
    path: 'transfers-history'
  },
  {
    title: 'Refunds',
    walletView: ['lynk'],
    permissions: [{ resource: 'refunds', actions: new Set(['read']) }],
    key: TransactionStateKeyEnum.REFUND,
    paneComponent: <Refund />,
    path: 'refunds'
  },
  {
    title: 'Payment History',
    walletView: ['lynk'],
    permissions: [{ resource: 'batch-payments', actions: new Set(['read']) }],
    key: TransactionStateKeyEnum.BATCH,
    paneComponent: <BatchTransfers />,
    path: 'payment-history'
  }
];
export const menuTabsNotifications: MenuTab[] = [
  {
    title: 'All',
    path: 'all',
    walletView: ['lynk'],
    permissions: [{ resource: 'batch-payments', actions: new Set(['read']) }],
    key: TransactionStateKeyEnum.TRANSACTIONS,
    paneComponent: <NotificationsList />
  },
  {
    title: 'Transactions',
    key: TransactionStateKeyEnum.PAYMENT,
    walletView: ['lynk'],
    permissions: [{ resource: 'batch-payments', actions: new Set(['read']) }],
    paneComponent: <NotificationsList />,
    path: 'transaction'
  },
  {
    title: 'Payment Updates',
    key: TransactionStateKeyEnum.TRANSFERS,
    walletView: ['lynk'],
    permissions: [{ resource: 'batch-payments', actions: new Set(['read']) }],
    paneComponent: <NotificationsList />,
    path: 'batch-payment'
  }
];

export const generateTabRouting = (jamdex: boolean): Partial<RouteObject>[] => {
  const routingDetails: Partial<RouteObject>[] = menuTabs.map(tabDets => {
    if (tabDets.key === TransactionStateKeyEnum.BATCH) {
      return {
        path: tabDets.path || '',
        element: tabDets.paneComponent,
        children: [
          {
            index: true
            // element: tabDets.paneComponent
          },
          {
            path: 'review/:id',
            element: <ReviewPaymentModal />
          }
        ]
      };
    }
    return {
      path: tabDets.path || '',
      element: tabDets.paneComponent
    };
  });
  const route = jamdex ? 'payments' : 'orders';
  routingDetails.push({
    index: true,
    element: <Navigate to={route} />
  });

  //  routes.push({
  //     path: '/',
  //     exact: true,
  //     component: () => <Redirect to={'lynk-wallet'} />,
  //     menuItem: false
  //   });

  return routingDetails;
};

export const generateTabNotificationsRouting = (): Partial<RouteObject>[] => {
  const routingDetails: Partial<RouteObject>[] = menuTabsNotifications.map(
    tabDets => {
      return {
        path: tabDets.path || '',
        element: tabDets.paneComponent
      };
    }
  );

  routingDetails.push({
    index: true,
    element: <Navigate to={'all'} />
  });

  return routingDetails;
};
