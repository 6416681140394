import { ApiServiceType } from '../../types/enums/apiServiceType';
import { CustomerTransactionRow } from '../../types/models/tableTransactions';
import { addAlertMetadata } from '../../store/status';
import { toasterResponses } from '../../components/organisms/TransactionTableLayout/toasterResponses';
import { useDispatch } from 'react-redux';
import usePostData from '../../hooks/api/usePostData';
import { useState } from 'react';
import { hideModal, refreshTable, showModal } from '../../store/actions';
import { TransactionStateKeyEnum } from '../../hooks/utils/tableConfig/transactionState';

export type RefundType = 'full-refund' | 'partial-refund';

export type RequestValuesType = {
  amount: number;
  reason: string;
  notes: string;
};

const useRequestRefund = (): {
  startRefund: (transaction: CustomerTransactionRow) => void;
  closeRefundModal: () => void;
  isFullRefund: boolean;
  refundTypeHandler: (refundType: RefundType) => void;
  showRefundDetailsModal: boolean;
  isFetching: boolean;
  submitRefundHandler: (
    values: RequestValuesType,
    transaction: CustomerTransactionRow
  ) => Promise<void>;
} => {
  const [showRefundDetailsModal, setShowRefundDetailsModal] = useState(false);
  const [isFullRefund, setIsFullRefund] = useState(true);
  const { isFetching, postData } = usePostData();

  const dispatch = useDispatch();

  const closeRefundModal = () => {
    dispatch(hideModal());
  };

  const startRefund = (transactionSelected: CustomerTransactionRow) => {
    dispatch(
      showModal({
        modalKey: 'refund',
        title: `Transaction ID #${transactionSelected.m_order_id}`,
        data: {
          transaction: transactionSelected,
          showRefundDetailsForm: false,
          isFullRefund: true
        }
      })
    );
  };

  const refundTypeHandler = (refund: RefundType) => {
    setIsFullRefund(refund === 'full-refund' ? true : false);
    setShowRefundDetailsModal(true);
  };

  const submitRefundHandler = async (
    values: RequestValuesType,
    transaction: CustomerTransactionRow
  ) => {
    const refundType: RefundType = isFullRefund
      ? 'full-refund'
      : 'partial-refund';
    let isSuccessful = true;
    try {
      await postData({
        path: `${transaction?.m_order_id}/${refundType}`,
        apiType: ApiServiceType.PAYMENT,
        data: {
          amount_to_refund: values?.amount,
          transferID: transaction?.transfer_id,
          description: values?.notes,
          motif: values?.reason
        }
      });
      dispatch(refreshTable(TransactionStateKeyEnum.REFUND));
    } catch (error) {
      isSuccessful = false;
    } finally {
      closeRefundModal();
      const toastDetails =
        toasterResponses[
          isSuccessful ? 'refund_request_issued_successfully' : 'refund_error'
        ];

      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: isSuccessful ? 'success' : 'failure',
            title: 'Refund Action Status',
            message: toastDetails.message
          }
        })
      );
    }
  };
  return {
    startRefund,
    closeRefundModal,
    isFullRefund,
    refundTypeHandler,
    showRefundDetailsModal,
    isFetching,
    submitRefundHandler
  };
};

export default useRequestRefund;
