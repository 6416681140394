import './index.scss';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CloseOutlined } from '@mui/icons-material';
import ButtonFooter from '../ButtonFooter';
import React from 'react';
import {
  LynkModalMainAction,
  LynkModalCloseAction
} from '../../../types/models/linkModalType';

export interface LynkModalProps {
  classes?: string;
  modalBody?: JSX.Element;
  title: string;
  subTitle?: string | JSX.Element;
  loading?: boolean;
  testID?: string;
  titleClass?: string;
  subTitleClass?: string;
  footerStacked?: boolean;
  showModalClose?: {
    onClickFn: () => void;
  };
  mainAction?: LynkModalMainAction;

  closeAction?: LynkModalCloseAction;
  disabled?: boolean;
  show: boolean;
  secondaryAction?: LynkModalCloseAction;
}

const LynkModal: React.FC<LynkModalProps> = ({
  titleClass,
  testID,
  classes,
  loading = false,
  modalBody,
  show,
  title,
  subTitle,
  subTitleClass,
  showModalClose,
  mainAction,
  footerStacked = false,
  closeAction,
  secondaryAction
}) => {
  const showFooter = !!closeAction || !!mainAction;

  return (
    <Modal
      className={`lynk-modal ${classes || ''}`}
      isOpen={show}
      centered={true}
      data-testid={`${testID || 'lynkModal'}`}
    >
      {loading ? <div className="loading-overlay"></div> : <></>}
      <div
        className={`headerContainer ${classes || ''}`}
        style={{ position: 'relative' }}
      >
        {!!showModalClose && (
          <div
            onClick={showModalClose.onClickFn}
            style={{
              textAlign: 'right',
              right: 0,
              top: 0,
              position: 'absolute'
            }}
          >
            <CloseOutlined className="closeIcon" />
          </div>
        )}
        <ModalHeader
          style={{ display: 'flex', textAlign: 'center' }}
          className={`ps-0 lynk-modal__title ${titleClass || ''} ${
            showModalClose ? 'showClose' : 'showNotClose'
          }`}
        >
          <div className={'titleDesc'}>{title}</div>
          {subTitle && (
            <div className={`${subTitleClass} subtitleDesc`}>{subTitle}</div>
          )}
        </ModalHeader>
      </div>
      {modalBody ? <ModalBody>{modalBody}</ModalBody> : undefined}
      {showFooter && (
        <ModalFooter
          className={`${footerStacked ? 'stacked' : 'inline'} ${classes || ''}`}
          data-testid="lynkmodal-footer"
        >
          <ButtonFooter
            cancelBtnProps={
              closeAction
                ? {
                    title: closeAction?.text,
                    disabled: false,
                    onPressFn: closeAction?.onClickFn,
                    testId: closeAction?.testID,
                    type: closeAction?.type
                  }
                : undefined
            }
            actionBtnProps={
              mainAction
                ? {
                    title: mainAction.text,
                    disabled: mainAction?.disabled,
                    onPressFn: mainAction?.onClickFn,
                    onPressAsync: mainAction?.onClickAsyncFn,
                    loadingDetails: {
                      isLoading: loading,
                      text: mainAction?.loader?.text,
                      loaderIcon: mainAction?.loader?.icon
                    }
                  }
                : undefined
            }
          />
          {secondaryAction && (
            <div
              className="secondaryAction"
              onClick={secondaryAction.onClickFn}
            >
              {secondaryAction.text}
            </div>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default LynkModal;
