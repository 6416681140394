import './index.scss';
import ProfileMenu, { ProfileMenuLabel } from '../Profile';
import React, { useMemo } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { HorizontalHeaderProps } from '../../../types/models/headerType';
import { getIconMapper } from '../../../utils/iconMap';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { useDispatch } from 'react-redux';
import { updateCurrentWallet } from '../../../store/walletManagement';
import { WalletType } from '@hooks/merchant_portal/useGetWalletBalance';
import { useIsLoadingProfileDetail } from '../../../store/organization/selector';
import { useAccountState } from '../../../store/account/selector';
import { useNavigate } from 'react-router-dom';
import Notifications from '../Notifications';
import { useGetProfileState } from '../../../store/profile/selector';

const HorizontalHeader: React.FC<HorizontalHeaderProps> = ({
  openLeftMenuCallBack
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const walletManager = useGetWalletManager();
  const userDetails = useGetProfileState();
  const fetching = useIsLoadingProfileDetail();
  const accounts = useAccountState();

  const changeWallet = (nextWallet: WalletType) => {
    navigate(nextWallet === 'lynk' ? 'lynk-wallet' : 'jamdex-wallet');
    dispatch(updateCurrentWallet(nextWallet));
  };
  const showJAMDEXContent = useMemo(() => {
    const cbdcAccountDetails = accounts.accounts?.find(
      (account: any) => account.type === 'CBDC'
    );
    return !!cbdcAccountDetails;
  }, [accounts]);

  return (
    <React.Fragment>
      <div className="navbar-header">
        <div>
          <div className="menuToggle" onClick={openLeftMenuCallBack}>
            <MenuIcon />
          </div>
          {walletManager.currentWallet !== 'corporate' && (
            <div
              className="walletToggle d-md-flex"
              data-testid="horizontal-header"
            >
              <div
                onClick={() => changeWallet('lynk')}
                className={`walletOption ${
                  walletManager.currentWallet === 'lynk' ? 'active' : 'inactive'
                }`}
              >
                {getIconMapper('lynk-only-white')} Lynk Wallet
              </div>
              {showJAMDEXContent ? (
                <div
                  onClick={() => changeWallet('jam-dex')}
                  className={`walletOption ${
                    walletManager.currentWallet === 'jam-dex'
                      ? 'active'
                      : 'inactive'
                  }`}
                >
                  {getIconMapper('jam-dex-white')} JAM-DEX Wallet
                </div>
              ) : undefined}
            </div>
          )}
        </div>
        <div className="logoContainer">
          {getIconMapper('lynkbiz-white-bkgnd')}
        </div>
        <div className="d-flex account-menu">
          <ProfileMenu />
          {walletManager.currentWallet !== 'corporate' && <Notifications />}
        </div>
        <div className="account-mobile">
          <ProfileMenuLabel
            displayName={userDetails.displayName}
            role={userDetails.role}
            loading={fetching}
            dropdown={false}
          />
          <Notifications />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HorizontalHeader;

// Header.propTypes = {
//   leftMenu: PropTypes.any,
//   showRightSidebar: PropTypes.any,
//   showRightSidebarAction: PropTypes.func,
//   t: PropTypes.any,
//   toggleLeftmenu: PropTypes.func
// };

// const mapStatetoProps = (state: any) => {
//   const { layoutType, showRightSidebar, leftMenu } = state.Layout;
//   return { layoutType, showRightSidebar, leftMenu };
// };

// export default connect(mapStatetoProps, {})(withTranslation()(Header));
