import { GenericBatchUploadException } from '../../exceptions/UploadBatchDocument.exception';
import { ApiServiceType } from '../../types/enums/apiServiceType';
import useGetData from '../api/useGetData';

const useGetBatchPaymentDetail = (): {
  fetching: boolean;
  getBatchPaymentDetailsById: (id: number) => Promise<any>;
  abortController: AbortController;
} => {
  const { isFetching, getData } = useGetData();
  const abortController = new AbortController();

  const getBatchPaymentDetailsById = async (id: number) => {
    try {
      const batch: any = await getData({
        path: `batch-payments/${id}`,
        apiType: ApiServiceType.PAYMENT
      });

      return batch;
    } catch (error) {
      throw new GenericBatchUploadException();
    }
  };
  return {
    fetching: isFetching,
    getBatchPaymentDetailsById,
    abortController
  };
};
export default useGetBatchPaymentDetail;
