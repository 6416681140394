import React, { useEffect, useState } from 'react';

import SinglePageTemplate from '../../templates/SinglePageTemplate';
import { usePermissions } from '../../../features/permissions';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';
import { updateCurrentWallet } from '../../../store/walletManagement';
import { useGetWalletManager } from '../../../store/walletManagement/selector';
import { useDispatch } from 'react-redux';
import { MenuTab } from '../../../types/models/tabsType';
import { setBreadcrumbs } from '../../../features/breadcrumbs';

const LynkPageDashboard: React.FC = () => {
  const { userPermissions: permissions } = usePermissions();
  const [tabs, setTabs] = useState<MenuTab[]>([]);
  const [widgets, setWidgets] = useState<JSX.Element[]>([]);

  const { getValidWidgets, getValidTabComponents } = useDashboardSetup();

  const dispatch = useDispatch();
  const walletManager = useGetWalletManager();

  useEffect(() => {
    dispatch(updateCurrentWallet('lynk'));
    dispatch(setBreadcrumbs([{ name: 'Dashboard', path: '' }]));
  }, []);

  useEffect(() => {
    setTabs(getValidTabComponents());
    setWidgets(getValidWidgets());
  }, [permissions, walletManager]);

  return (
    <div className="singlePageDashboard">
      <SinglePageTemplate componentsToRender={widgets} menu={tabs} />
    </div>
  );
};

export default LynkPageDashboard;
