import React from 'react';
import { ReactComponent as SortIcon } from '../assets/svg/sortIcon.svg';
import { ReactComponent as LynkLogoOnly } from '../assets/svg/dark/LogoLink.svg';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ReactComponent as LynkBizLogo } from '../assets/svg/lynkbiz.svg';
import { ReactComponent as Lynk } from '../assets/svg/light/lynklogo.svg';
import { ReactComponent as LynkLogoDark } from '../assets/svg/dark/lynklogo.svg';
import CloseIcon from '@mui/icons-material/Close';
//
import { ReactComponent as JAMDexLogo } from '../assets/svg/jam-dex.svg';
import StoreIcon from '@mui/icons-material/Store';
import { ReactComponent as JamDexStoreIcon } from '../assets/svg/jamdex-store-icon.svg';

import { ReactComponent as TransactionSuccessPendingLogo } from '../assets/svg/transaction-success.svg';

import { ReactComponent as TransactionSuccessLogo } from '../assets/svg/transaction-success-pending.svg';
import { ReactComponent as TransactionErrorLogo } from '../assets/svg/transaction-error.svg';

import { ReactComponent as JAMDexWhiteLogo } from '../assets/svg/jamdex-white.svg';
import { ReactComponent as LynkWhiteLogo } from '../assets/svg/lynklogo-without-text.svg';
//
import { ReactComponent as LynkbizWhiteLogo } from '../assets/svg/lynkbiz-whitebg.svg';
import { ReactComponent as ScanToPay } from '../assets/svg/scan2pay.svg';
import { ReactComponent as ScanToPayBlack } from '../assets/svg/scan2payBlack.svg';
import { ReactComponent as JAMDEXInfo } from '../assets/svg/jamdexInfo.svg';
import { ReactComponent as PlayStore } from '../assets/svg/playStore.svg';
import { ReactComponent as PoweredBy } from '../assets/svg/poweredBy.svg';
import { ReactComponent as MerchantIcon } from '../assets/svg/merchantIcon.svg';
import { ReactComponent as MiniUploadIcon } from '../assets/svg/miniUploadIcon.svg';

import { ReactComponent as AreYouSureIcon } from '../assets/svg/are-you-sure.svg';

import { ReactComponent as NCBLogo } from '../assets/svg/ncblogo.svg';
import { ReactComponent as IssueStatus } from '../assets/svg/issueStatus.svg';

const iconMap = {
  sort: SortIcon,
  lynk: Lynk,
  close: CloseIcon,
  'issue-status': IssueStatus,
  'ncb-logo': NCBLogo,
  'lynk-dark': LynkLogoDark,
  lynkbiz: LynkBizLogo,
  'lynkbiz-white-bkgnd': LynkbizWhiteLogo,
  'lynk-only': LynkLogoOnly,
  'lynk-only-white': LynkWhiteLogo,
  'acct-balanace': AccountBalanceIcon,
  'merchant-icon': MerchantIcon,
  'store-icon': StoreIcon,
  'jamdex-store-icon': JamDexStoreIcon,
  'jam-dex': JAMDexLogo,
  'jam-dex-white': JAMDexWhiteLogo,
  'transaction-success-pending': TransactionSuccessPendingLogo,
  'transaction-success': TransactionSuccessLogo,
  'transaction-failure': TransactionErrorLogo,
  'scan-to-pay': ScanToPay,
  'scan-to-pay-black': ScanToPayBlack,
  'google-play-store': PlayStore,
  'jamdex-info': JAMDEXInfo,
  'powered-by': PoweredBy,
  miniUploadIcon: MiniUploadIcon,
  'are-you-sure': AreYouSureIcon
};

export type IconKey = keyof typeof iconMap;

export const getIconMapper = (iconKey: IconKey, classes = ''): JSX.Element => {
  const IconElement = iconMap[iconKey];
  return (
    <IconElement
      data-testid={`${iconKey}Icon`}
      className={`${iconKey}Icon ${classes}`}
    />
  );
};
