import axios, { AxiosError, AxiosInstance } from 'axios';

import { ApiError } from '../../types/models/api/apiError';
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiServiceType } from '../../types/enums/apiServiceType';

/**
 * Creates an `AxiosInstance` that is tied to the base URL provided.
 *
 * @param baseURL the part URL that comes before the paths of an API
 * @returns {AxiosInstance} an instance of `Axios` with the specified base URL
 */
const createAxiosAPI = (baseURL: string): AxiosInstance => {
  const customAPI = axios.create({
    baseURL
  });

  customAPI.interceptors.request.use(
    config => {
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  customAPI.interceptors.response.use(
    response => {
      // get the data from Response
      return response;
    },
    async error => {
      const { config, message } = error;
      // const createdError = new Error(err)
      return Promise.reject(error);
    }

    // customAPI.interceptors.response.use(undefined, (err) => {
    //   const { config, message } = err;
    //   if (!config || !config.retry) {
    //     return Promise.reject(err);
    //   }
    //   // retry while Network timeout or Network Error
    //   if (!(message.includes("timeout") || message.includes("Network Error"))) {
    //     return Promise.reject(err);
    //   }
    //   config.retry -= 1;
    //   const delayRetryRequest = new Promise((resolve) => {
    //     setTimeout(() => {
    //       console.log("retry the request", config.url);
    //       resolve();
    //     }, config.retryDelay || 1000);
    //   });
    //   return delayRetryRequest.then(() => axios(config));
    // })
  );

  return customAPI;
};

const API_ACCOUNT_BFF_BASE = process.env.REACT_APP_API_ACCOUNT_BFF_BASE;
const API_REPORTING_BFF_BASE = process.env.REACT_APP_API_REPORTING_BFF_BASE;
const API_PROFILE_BFF_BASE = process.env.REACT_APP_API_PROFILE_BFF_BASE;
const API_PAYMENT_BFF_BASE = process.env.REACT_APP_API_PAYMENT_BFF_BASE;
const API_CONSUMER_AUTH_BASE = process.env.REACT_APP_API_CONSUMER_AUTH_BASE;
const API_REPORT_EXPORT_AUTH_BASE = process.env.REACT_APP_EXPORT_BFF_BASE;

const API_REPORTING_BFF = createAxiosAPI(API_REPORTING_BFF_BASE || '');
const API_PROFILE_BFF = createAxiosAPI(API_PROFILE_BFF_BASE || '');
const API_PAYMENT_BFF = createAxiosAPI(API_PAYMENT_BFF_BASE || '');
const API_CONSUMER_AUTH = createAxiosAPI(API_CONSUMER_AUTH_BASE || '');
const API_REPORT_EXPORT = createAxiosAPI(API_REPORT_EXPORT_AUTH_BASE || '');
const API_ACCOUNT_BFF = createAxiosAPI(API_ACCOUNT_BFF_BASE || '');
/**
 * Object holding a collection of all of the existing `AxiosInstance`
 * that are available for API calls.
 */
const apiCallOptions = {
  reporting: API_REPORTING_BFF,
  profile: API_PROFILE_BFF,
  payment: API_PAYMENT_BFF,
  consumerAuth: API_CONSUMER_AUTH,
  reportExport: API_REPORT_EXPORT,
  account: API_ACCOUNT_BFF
};

/**
 * Returns an `AxiosInstance` that matches the type specified. Each type
 * returns a different instance that is responsible for different APIs
 *
 * @param {ApiServiceType} apiType the type of *API Service* needed.
 * @returns {AxiosInstance} the instance that matches the type provided
 */
const getAxiosCall = (apiType: ApiServiceType): AxiosInstance => {
  return apiCallOptions[apiType];
};

/**
 * Checks whether or not an error is an `AxiosError`.
 *
 * @param error the object to be checked
 * @returns {boolean} `true` if object is an `AxiosError`, `false` otherwise.
 */
const isAxiosError = (error: any): error is AxiosError => {
  return 'isAxiosError' in error;
};

/**
 * Parses and returns an `ApiError` based on the object given.
 *
 * @param {any} error the error thrown from an `Axios` request.
 * @returns {ApiError} a simplified error object based on the `AxiosError`.
 */
const parseErrorFromAxiosCall = (error: any): ApiError => {
  // Check if the object is an `AxiosError`
  if (isAxiosError(error)) {
    const IS_JSON: boolean =
      error.response?.headers['content-type'] === 'application/json';
    return {
      hasJsonBody: IS_JSON,
      originalError: error,
      responseStatus: error.response?.status || 500,
      responseBody: error.response?.data
    };
  }

  // Return pre-defined error object.
  return {
    hasJsonBody: false,
    originalError: error,
    responseStatus: 500
  };
};

export { getAxiosCall, parseErrorFromAxiosCall, isAxiosError };
