/**
 * The various types of API services that the application
 * can integrate with. Use to distinguish which API service
 * an API call should be made to.
 */
export enum ApiServiceType {
  REPORTING = 'reporting',
  PROFILE = 'profile',
  PAYMENT = 'payment',
  CONSUMER_AUTH = 'consumerAuth',
  REPORTEXPORT = 'reportExport',
  ACCOUNT = 'account'
}
