import React from 'react';
import './index.scss';
import { Label } from 'reactstrap';

interface RecipientProps {
  lynkID: string;
  errorText: string | null;
  onFormChange: (e: React.FormEvent<HTMLInputElement>) => void;
}
const ChooseRecipientForm: React.FC<RecipientProps> = ({
  lynkID,
  errorText,
  onFormChange
}) => {
  return (
    <div className="extraFormPadding">
      <Label className="textbox__description">
        Enter Recipient&apos;s Lynk.iD
      </Label>
      <div className="text">
        <input
          className="textbox__input"
          value={lynkID}
          onChange={onFormChange}
        />
      </div>
      {errorText && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 13,
            color: '#EB5757',
            fontFamily: 'Heebo'
          }}
        >
          <div>{errorText}</div>
        </div>
      )}
    </div>
  );
};

export default ChooseRecipientForm;
