import { ReactComponent as BatchListIcon } from '../../../assets/svg/checklistBatch.svg';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import './index.scss';

import React from 'react';
import ActionButton from '../ActionButton';
interface ButtonColProps {
  row: any;
  actionFn: (r: any) => void;
  text: string;
  review: boolean;
  classname?: string;
}

export const ButtonColFormatter: React.FC<ButtonColProps> = ({
  row,
  text,
  review,
  actionFn,
  classname = ''
}) => {
  const Btn = (
    <ActionButton
      type="button"
      classnames={`batch-button ${classname}`}
      text={text}
      data-testid="batchButton"
      onPress={() => actionFn(row)}
      rightIcon={review ? <BatchListIcon /> : <DownloadOutlinedIcon />}
      testId="batchButton"
    />
  );

  return <>{Btn}</>;
};
