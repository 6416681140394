/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { useDispatch } from 'react-redux';
import ButtonTooltip from '../../../../atoms/ButtonTooltip';
import LynkModal from '../../../../atoms/LynkModal';
import { hideModal } from '../../../../../store/actions';
import { useModalState } from '../../../../../store/modals/modal/selector';
import useScreenSize from '../../../../../hooks/utils/useScreenSize';
import useRequestRefund from '../../../../../hooks/merchant_portal/useRequestRefund';
import { usePermissions } from '../../../../../features/permissions';

const OrderDetailsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { data, title, modalKey } = useModalState();
  const screenSize = useScreenSize();
  const { startRefund } = useRequestRefund();
  const { hasPermissions } = usePermissions();

  const closeAction = () => {
    dispatch(hideModal());
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  const getDataItemValue = (value: Function | string) => {
    if (typeof value === 'function') return value();

    return value;
  };

  return (
    <LynkModal
      titleClass="customModal"
      classes="customModal"
      showModalClose={
        title === 'User' ? { onClickFn: () => closeAction() } : undefined
      }
      modalBody={
        <>
          {!!data && (
            <>
              <div
                className={
                  screenSize.width > 500 ? 'modalBody--desktop' : 'modalBody'
                }
                style={{ minHeight: 300, wordWrap: 'break-word' }}
              >
                {!!data.details &&
                  (data.details as any[]).map((dataItem, index) => (
                    <div
                      key={index}
                      className={
                        screenSize.width > 500
                          ? 'transactionModal--desktop'
                          : ''
                      }
                      style={{ marginBottom: 12 }}
                    >
                      <div style={{ color: '#73848E', marginBottom: 12 }}>
                        {(dataItem.label as string).toUpperCase()}
                      </div>

                      <div style={{ marginLeft: 12 }}>
                        {dataItem.tooltip ? (
                          <ButtonTooltip
                            tooltipIdentifier={`tooltip-${dataItem.tooltip.id}`}
                            title={dataItem.value}
                            feedback={dataItem.tooltip.feedback}
                          />
                        ) : (
                          <>{getDataItemValue(dataItem.value)}</>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              {!!data && data.footer ? (
                <div className="modalFooter">{data.footer}</div>
              ) : null}
            </>
          )}
        </>
      }
      title={`${title as string} Details`}
      loading={false}
      mainAction={
        hasPermissions([{ resource: 'refunds', actions: new Set(['create']) }])
          ? {
              testID: '',
              onClickFn: () => startRefund(data?.transactionDetails),
              text: 'Issue Refund',
              disabled:
                data?.transactionDetails?.status.toLowerCase() !== 'paid' ||
                data?.transactionDetails?.amount_to_pay >
                  data?.lynkWalletBalance
            }
          : undefined
      }
      closeAction={
        !data?.footer
          ? {
              testID: 'transaction-data',
              onClickFn: closeAction,
              text: 'Close'
            }
          : undefined
      }
      show={modalKey === 'more-order-details'}
    />
  );
};

export default OrderDetailsModal;
