/* eslint-disable @typescript-eslint/no-explicit-any */
import { defaultTableFooterProps } from '../../../hooks/utils/tableConfig/defaultProps';
import useGetPaginationInformation, {
  MetadataConfig
} from '../../../hooks/utils/useGetPaginationInformation';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetScreenState } from '../../../store/screenSize/selector';
import { useGetAppStatuses } from '../../../store/status/selector';
import { AlertMetadata } from '../../../store/state';
import useHandleDataDisplay from './useHandleDataDisplay';
import DataDisplayBody from '../../atoms/DataDisplayBody';
import DataDisplayPagination from '../../atoms/DataDisplayPagination';
import './index.scss';
import {
  DataDisplayComponentProps,
  DataDisplayContext
} from '../../../hooks/utils/tableConfig';
import useConfigTable from '../../../hooks/utils/tableConfig/useConfigTable';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import { useDispatch } from 'react-redux';
import { clearRefresh } from '../../../store/actions';
import { LoadTransMeta } from '../../../types/models/cashInOutType';
import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';

const DataDisplayContainer: React.FC<DataDisplayComponentProps> = ({
  columnFilter,
  endpointGenerator,
  sorter,
  dataKey,
  dataGenerator,
  header,
  pageLimit,
  noDataMessage,
  fetchingData,
  alertScope = 'transactions',
  loadMobileView = true
}) => {
  const [currentToaster, setCurrentToaster] = useState<
    AlertMetadata | undefined
  >(undefined);
  const [metadataTransactions, setMetadataTransactions] =
    useState<MetadataConfig>(defaultTableFooterProps);

  const { getColumnHeaders } = useConfigTable();

  const alerts = useGetAppStatuses();
  const screenState = useGetScreenState();
  const { closeAlert } = useHandleDataDisplay();
  const { calculatePagination, dispatchMetadataUpdate } =
    useGetPaginationInformation();

  useEffect(() => {
    const scope =
      screenState.breakpoint === 'smartphone' ? 'general' : alertScope;
    const generalAlerts = alerts.filter(alert => alert.scope === scope);
    if (currentToaster === undefined) {
      setCurrentToaster(generalAlerts[0]);
      return;
    }

    if (generalAlerts.length === 0) setCurrentToaster(undefined);
  }, [alerts, screenState]);

  const { loadTrans, fetching } = useConfigTable();
  const [transactions, setTransactions] = useState([] as any[]);
  const transactionTableState = useGetTransactionTableState();
  const dispatch = useDispatch();
  const stateVal = useGetTransactionTableState();
  const { currentOffset, sort, tableFilter } = transactionTableState[dataKey];

  const loadTransactions = async ({
    offset,
    sort,
    filter,
    group
  }: LoadTransMeta) => {
    setTransactions([]);
    console.log('.............');

    console.log(filter);

    const { results, metadata } = await loadTrans(
      sort,
      pageLimit,
      offset,
      dataKey,
      filter,
      group,
      endpointGenerator
    );

    setMetadataTransactions(calculatePagination(metadata));
    setTransactions(results);
  };

  // useEffect(() => {
  //   if (stateVal.refreshTable) {
  //     loadTransactions({
  //       offset: currentOffset,
  //       sort: sort,
  //       filter: tableFilter.dateRange
  //     });
  //     dispatch(clearRefresh());
  //   }
  // }, [stateVal.refreshTable]);

  useEffect(() => {
    loadTransactions({
      offset: currentOffset,
      sort: sort,
      filter: tableFilter.dateRange,
      group: tableFilter.group
    });
  }, [currentOffset, sort, tableFilter]);

  const paginationHandler = (factorOffset: number) => {
    dispatchMetadataUpdate({ currentOffset: factorOffset }, dataKey);
  };

  const contextValue = useMemo(() => {
    return {
      sortMap: sorter,
      fetchingData: {
        ...fetchingData,
        fetching
      },
      emptyDataMessage: noDataMessage,
      breakpoint: screenState,
      columnHeaders: columnFilter
        ? columnFilter(getColumnHeaders(dataKey))
        : getColumnHeaders(dataKey),
      transactions: dataGenerator(
        transactions,
        columnFilter
          ? columnFilter(getColumnHeaders(dataKey))
          : getColumnHeaders(dataKey),
        screenState.breakpoint
      ),
      showMobileView: loadMobileView,
      paginationDetails: {
        ...metadataTransactions,
        pagingHandler: (offset: number) => {
          const factorOffset = (offset - 1) * pageLimit;
          paginationHandler(factorOffset);
        }
      }
    };
  }, [
    metadataTransactions,
    fetchingData,
    screenState.breakpoint,
    loadMobileView,
    transactions,
    pageLimit
  ]);

  return (
    <DataDisplayContext.Provider value={contextValue}>
      {header ? (
        <div style={{ marginBottom: 12 }}>
          {/* <div></div> */}
          {header(!fetching)}
        </div>
      ) : null}
      <DataDisplayBody
        noHeader={
          dataKey === TransactionStateKeyEnum.NOTIFICATIONS ? true : false
        }
      />
      {transactions.length > 0 ? <DataDisplayPagination /> : null}
    </DataDisplayContext.Provider>
  );
};

export default DataDisplayContainer;
