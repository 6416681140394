import { createSlice } from '@reduxjs/toolkit';
import { DataCacheState } from '../state';

/**
 * This state will act as a sort of cache for data that will need to be loaded but will not need to
 * be refreshed
 */
const initialState: DataCacheState = {
  staffRoleCache: []
};

export const dataCacheSlice = createSlice({
  name: 'dataCache',
  initialState,
  reducers: {
    setStaffRoleCache: (state, action) => {
      state.staffRoleCache = action.payload.roles;
    }
  }
});

export const { setStaffRoleCache } = dataCacheSlice.actions;

export default dataCacheSlice.reducer;
