import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './index.scss';
import { UncontrolledTooltip } from 'reactstrap';
import ActionButton from '../ActionButton';
import { EditDeleteGroupProps } from '../../../types/models/ButtonsTypes';

const EditDeleteGroup: React.FC<EditDeleteGroupProps> = ({
  showEdit = true,
  showDelete = true,
  miniView,
  editCallback,
  deleteCallback
}) => {
  return (
    <div className={`editDeleteGroup ${miniView ? 'mini' : ''}`}>
      {showEdit ? (
        <>
          <ActionButton
            onPress={editCallback}
            type={miniView ? 'link' : 'outline-secondary'}
            block={!miniView}
            btnType={miniView ? 'reset' : 'button'}
            leftIcon={<EditIcon style={{ color: 'blue' }} id="editIcon" />}
            text={miniView ? '' : 'Edit'}
          />
          {miniView ? (
            <UncontrolledTooltip placement="bottom" target="editIcon">
              Edit User
            </UncontrolledTooltip>
          ) : (
            <></>
          )}
        </>
      ) : null}
      {showDelete ? (
        <>
          <ActionButton
            onPress={deleteCallback}
            type={miniView ? 'link' : 'outline-secondary'}
            block={!miniView}
            btnType={miniView ? 'reset' : 'button'}
            leftIcon={
              <DeleteOutlineIcon style={{ color: 'red' }} id="deleteIcon" />
            }
            text={miniView ? '' : 'Delete'}
          />
          {miniView ? (
            <UncontrolledTooltip placement="bottom" target="deleteIcon">
              Delete User
            </UncontrolledTooltip>
          ) : (
            <></>
          )}
        </>
      ) : null}
    </div>
  );
};

export default EditDeleteGroup;
