import { createSlice } from '@reduxjs/toolkit';
import { AccountState } from '../../store/state';
import { AccountDetail, AccountItem } from './types';

const initialState: AccountState = {
  error: null,
  loading: false,
  accounts: [] as AccountItem[],
  selectedAccount: undefined
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loadAccountDetails: state => {
      state.loading = true;
      state.error = null;
    },
    loadAccountDetailsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.accounts = action.payload.accounts;
      // state.selectedAccount = action.payload.beneficiary_accounts[0];
    },
    loadAccountDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.accounts = initialState.accounts;
      state.selectedAccount = undefined;
    },
    selectAccountDetail: (state, action) => {
      if (state.accounts && state.accounts.length > 0)
        state.selectedAccount = state.accounts.find(
          acct => acct.id === action.payload
        );
    }
  }
});

export const {
  loadAccountDetails,
  loadAccountDetailsSuccess,
  loadAccountDetailsFailure,
  selectAccountDetail
} = accountSlice.actions;

export default accountSlice.reducer;
