import React from 'react';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextInput from '../../atoms/TextInput';
import './index.scss';
import { DateRangePickerProps } from '../../../types/models/datePickerType';

const DoubleInput: React.FC<DateRangePickerProps> = ({
  dateFormat,
  valid,
  dateRange,
  togglePicker,
  clearInput,
  setStart,
  setEnd,
  activeInput
}) => {
  // const [isStartFocused, setIsStartFocused] = useState(false);
  // const [isEndFocused, setIsEndFocused] = useState(false);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setStart(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEnd(value);
  };

  return (
    <div>
      <div className="doubleInputContainer">
        <div className="dateGroup">
          <TextInput
            className={'dateInput startText'}
            label={dateRange.start.label}
            testID="startDateInput"
            readOnly={false}
            value={dateRange.start.date}
            placeholder={dateFormat}
            onChange={handleStartDateChange}
            onClick={togglePicker}
            onFocus={() => activeInput('start')}
            rightIcon={
              valid ? (
                <CalendarTodayOutlinedIcon
                  onClick={togglePicker}
                  className="datePickerContainer__icon"
                />
              ) : (
                <div
                  data-testid="startClose"
                  onClick={() => clearInput('start')}
                >
                  <CloseOutlinedIcon className="datePickerContainer__icon" />
                </div>
              )
            }
          />
        </div>
        <div className="dateGroup">
          <TextInput
            className={'dateInput endText'}
            label={dateRange.end.label}
            testID="endDateInput"
            readOnly={false}
            value={dateRange.end.date}
            placeholder={dateFormat}
            onChange={handleEndDateChange}
            onClick={togglePicker}
            onFocus={() => activeInput('end')}
            rightIcon={
              valid ? (
                <CalendarTodayOutlinedIcon
                  onClick={togglePicker}
                  className="datePickerContainer__icon"
                />
              ) : (
                <div data-testid="endClose" onClick={() => clearInput('end')}>
                  <CloseOutlinedIcon className="datePickerContainer__icon" />
                </div>
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DoubleInput;
