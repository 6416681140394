/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.scss';

import React, { useEffect, useMemo, useState } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import LynkModal from '../../../../atoms/LynkModal';
import { hideModal, showModal } from '../../../../../store/modals/modal';
import { useDispatch } from 'react-redux';
import { useModalState } from '../../../../../store/modals/modal/selector';
import CurrencyTextInput from '../../../../atoms/CurrencyTextInput';
import './index.scss';
import FormattedSelector, {
  SelectorItemProp
} from '../../../../molecules/FormattedSelector';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import { useAccountState } from '../../../../../store/account/selector';
import { usePermissions } from '../../../../../features/permissions';
import useTransferHook from '../../../../../hooks/transactions/usePostTransaction';
import {
  AccountItemType,
  BankAccountDetails,
  BeneficiaryDetails,
  WalletDetailBase
} from '../../../../../store/account/types';
import { TransferException } from '../../../../../exceptions/TransferHistory.exceptions';
import { TransactionStateKeyEnum } from '../../../../../hooks/utils/tableConfig/transactionState';
import { refreshTable } from '../../../../../store/transactions/tableState';
import useGetAccountDetails from '../../../../../hooks/account/useGetAccountDetails';
import Skeleton from 'react-loading-skeleton';
import { Alert } from 'reactstrap';
import useGetCashInOutLimits from '../../../../../hooks/account/useGetCashInOutLimits';
import { moneyFormated } from '../../../../../utils/currency';

interface TransferOptions extends SelectorItemProp {
  key: AccountItemType;
  itemKey: string;
  currency: string;
}

const TransferModal: React.FC = () => {
  const dispatch = useDispatch();
  const { fetching, postTransaction } = useTransferHook();
  const { getTransferLimitBalance } = useGetCashInOutLimits();
  const { data, modalKey } = useModalState();
  const [loadAttemptMade, setLoadingAttemptMade] = useState(false);
  const [lastAttempt, setLastAttempt] = useState({
    attemptMade: false,
    amountAttempted: 0
  });
  const [inputError, setInputError] = useState('');

  const onCloseHandler = () => {
    dispatch(hideModal());
  };

  const accountDetails = useAccountState();
  const { hasPermissions } = usePermissions();
  const { isFetching, getAccountListing } = useGetAccountDetails();
  const [loadingStatus, setLoadingStatus] = useState<
    'loading' | 'success' | 'failure'
  >('success');

  useEffect(() => {
    if (!accountDetails.accounts && !isFetching && !loadAttemptMade) {
      setLoadingStatus('loading');
      try {
        getAccountListing();
      } finally {
        setLoadingAttemptMade(true);
      }
    }
  }, []);

  useEffect(() => {
    if (loadAttemptMade) {
      setLoadingStatus(
        accountDetails.accounts && accountDetails.accounts.length > 0
          ? 'success'
          : 'failure'
      );
    }
  }, [loadAttemptMade]);

  useEffect(() => {
    if (loadingStatus === 'failure') {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'failure',
            title: 'Transfer Action',
            subtitle: 'Unable to action a transfer. Please try again later'
          }
        })
      );
    }
  }, [loadingStatus]);

  const transferSchema = object({
    fromAccount: string().required(),
    toAccount: string().required(),
    amount: number().min(0.1)
  });

  const formik = useFormik({
    initialValues: {
      fromAccount: '',
      toAccount: '',
      amount: 0
    },
    validationSchema: transferSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {
      submitTransfer();
    }
  });

  const isCurrencyInputInValid = useMemo(() => {
    if (!lastAttempt.attemptMade && !!inputError) return true;

    return (
      lastAttempt.attemptMade &&
      formik.values.amount === lastAttempt.amountAttempted
    );
  }, [formik.values.amount, lastAttempt]);

  // useEffect(() => {})

  const fromList = useMemo(() => {
    const generatedFromList: TransferOptions[] = [];

    const getLynkAcct = accountDetails?.accounts?.find(acct => {
      return acct.type === 'LYNK';
    });
    generatedFromList.push({
      icon: 'lynk-only',
      label: 'Lynk Wallet',
      key: 'LYNK',
      itemKey: 'lynk',
      value: `lynk:${(getLynkAcct?.details as WalletDetailBase).account_id}`,
      currency: (getLynkAcct?.details as WalletDetailBase).currency
    });

    if (
      hasPermissions([
        { resource: 'cbdc-cashins', actions: new Set(['create']) }
      ])
    ) {
      const getJAMDEXAcct = accountDetails?.accounts?.find(acct => {
        return acct.type === 'CBDC';
      });
      if (getJAMDEXAcct) {
        generatedFromList.push({
          icon: 'jam-dex',
          label: 'Jam-Dex Wallet',
          key: 'CBDC',
          itemKey: 'jam-dex',
          value: `jam-dex:${
            (getJAMDEXAcct?.details as WalletDetailBase).account_id
          }`,
          currency: (getJAMDEXAcct?.details as WalletDetailBase).currency
        });
      }
    }

    if (
      hasPermissions([{ resource: 'cashins', actions: new Set(['create']) }])
    ) {
      const bankAccounts =
        accountDetails?.accounts?.filter(acct => {
          return acct.type === 'BANK_ACCOUNT';
        }) || [];

      if (bankAccounts.length > 0) {
        bankAccounts.forEach(acct => {
          generatedFromList.push({
            icon: 'acct-balanace',
            label: `Account ${
              (acct.details as BankAccountDetails).masked_bank_account
            }`,
            key: acct.type,
            itemKey: 'bank_account',
            value: `bank_acct:${(acct.details as BankAccountDetails).id}`,
            currency: 'JMD'
          });
        });
      }
    }

    return generatedFromList;
  }, []);

  const toList = useMemo(() => {
    const generatedToList: TransferOptions[] = [];

    if (!formik.values.fromAccount) return generatedToList;

    const getLynkAcct = accountDetails?.accounts?.find(acct => {
      return acct.type === 'LYNK';
    });
    generatedToList.push({
      icon: 'lynk-only',
      label: 'Lynk Wallet',
      key: getLynkAcct?.type as AccountItemType,
      itemKey: 'lynk',
      value: `lynk:${(getLynkAcct?.details as WalletDetailBase).account_id}`,
      currency: (getLynkAcct?.details as WalletDetailBase).currency
    });

    const fromDetails = formik.values.fromAccount.split(':');

    if (fromDetails[0] !== 'lynk') return generatedToList;

    if (
      hasPermissions([
        { resource: 'cbdc-cashouts', actions: new Set(['create']) }
      ])
    ) {
      const getJAMDEXAcct = accountDetails?.accounts?.find(acct => {
        return acct.type === 'CBDC';
      });
      if (getJAMDEXAcct) {
        generatedToList.push({
          icon: 'jam-dex',
          label: 'Jam-Dex Wallet',
          key: getJAMDEXAcct?.type as AccountItemType,
          itemKey: 'jam-dex',
          value: `jam-dex:${
            (getJAMDEXAcct?.details as WalletDetailBase).account_id
          }`,
          currency: (getJAMDEXAcct?.details as WalletDetailBase).currency
        });
      }
    }

    if (
      hasPermissions([{ resource: 'cashouts', actions: new Set(['create']) }])
    ) {
      const beneficiariesAccount =
        accountDetails?.accounts?.filter(acct => {
          return acct.type === 'BENEFICIARY';
        }) || [];
      if (beneficiariesAccount.length > 0) {
        beneficiariesAccount.forEach(acct => {
          generatedToList.push({
            icon: 'acct-balanace',
            label: `Account ${
              (acct.details as BeneficiaryDetails).account_number
            }`,
            key: acct.type,
            itemKey: 'beneficiary',
            value: `beneficiary:${(acct.details as BeneficiaryDetails).id}`,
            currency: (acct?.details as WalletDetailBase).currency
          });
        });
      }
    }

    return generatedToList.filter(
      opt => opt.value !== formik.values.fromAccount
    );
  }, [formik.values.fromAccount]);
  const validateLimitTransfer = async (type: string) => {
    const transferType = type === 'CASH-IN' ? 'TOP' : 'ACH_CASHOUT';
    const balance = await getTransferLimitBalance(transferType);

    return balance;
  };
  const submitTransfer = async () => {
    const fromDetails = formik.values.fromAccount.split(':');
    const toDetails = formik.values.toAccount.split(':');

    const transferType = fromDetails[0] === 'lynk' ? 'CASH-OUT' : 'CASH-IN';

    const listMap = {
      lynk: 'LYNK',
      'jam-dex': 'CBDC',
      bank_acct: 'BANK_ACCOUNT',
      beneficiary: 'BENEFICIARY'
    };

    const lynkAccount =
      transferType === 'CASH-OUT' ? fromDetails[1] : toDetails[1];
    const transferAccount =
      transferType === 'CASH-OUT' ? toDetails[1] : fromDetails[1];
    const transferAccountType =
      transferType === 'CASH-OUT'
        ? listMap[toDetails[0] as keyof typeof listMap]
        : listMap[fromDetails[0] as keyof typeof listMap];
    const balance = await validateLimitTransfer(transferType);
    if (
      balance &&
      formik.values.amount > balance &&
      fromDetails[0] !== 'jam-dex' &&
      toDetails[0] !== 'jam-dex'
    ) {
      dispatch(
        showModal({
          modalKey: 'transaction-status',
          data: {
            status: 'failure',
            subtitle: `You currently have ${moneyFormated(
              balance
            )} JMD remaining for this day, and the limit will reset at the end of the day.`,
            title: 'Your Transfer Failed'
          }
        })
      );
    } else {
      try {
        const response = await postTransaction(
          {
            transfer_type: transferType,
            account_type: transferAccountType as AccountItemType,
            account_id: Number.parseInt(transferAccount),
            amount: formik.values.amount
          },
          Number.parseInt(lynkAccount)
        );

        dispatch(
          showModal({
            modalKey: 'transaction-status',
            data: {
              status: 'success-pending'
            }
          })
        );
        dispatch(refreshTable(TransactionStateKeyEnum.TRANSFERS));
      } catch (transferAttemptError: any) {
        const errorValue = transferAttemptError as TransferException;
        setInputError(errorValue.errorMessage);

        setLastAttempt({
          attemptMade: true,
          amountAttempted: formik.values.amount
        });

        if (errorValue.cancelAction) {
          dispatch(
            showModal({
              modalKey: 'transaction-status',
              data: {
                status: 'failure'
              }
            })
          );
        }
      }
    }
  };

  return (
    <LynkModal
      subTitle={'Select the accounts you want to do your transfer between'}
      modalBody={
        <>
          <div className="selectorFormatInput">
            <FormattedSelector
              disabled={false}
              label={'From'}
              activeItem={formik.values.fromAccount}
              options={fromList}
              onSelect={val => {
                formik.setFieldValue('fromAccount', val.selectedValue);
              }}
              loading={isFetching}
              defaultSelectedItem={{
                icon: 'acct-balanace',
                label: 'Select An Account',
                value: '',
                key: ''
              }}
            />
          </div>
          <div className="selectorFormatInput">
            <FormattedSelector
              label={'To'}
              disabled={formik.values.fromAccount === ''}
              activeItem={formik.values.toAccount}
              options={toList}
              onSelect={val => {
                formik.setFieldValue('toAccount', val.selectedValue);
              }}
              loading={isFetching}
              defaultSelectedItem={{
                icon: 'acct-balanace',
                label: 'Select An Account',
                value: '',
                key: ''
              }}
            />
          </div>
          {loadingStatus === 'loading' ? (
            <>
              <Skeleton height={10} width={86} baseColor="#D9D9D9" />
              <div className="skeletonCurrencyInput">
                <Skeleton height={10} width={210} baseColor="#D9D9D9" />
                <Skeleton height={10} width={141} baseColor="#D9D9D9" />
              </div>
            </>
          ) : (
            <CurrencyTextInput
              label="Amount"
              amount={`${formik.values.amount}`}
              onChange={val =>
                formik.setFieldValue('amount', Number.parseFloat(val))
              }
              invalid={!!inputError && isCurrencyInputInValid}
            />
          )}
          {!!inputError && isCurrencyInputInValid ? (
            <Alert
              color="danger"
              style={{
                border: 'none',
                padding: 12,
                color: '#FF4D64',
                backgroundColor: '#FDF2F2'
              }}
            >
              {inputError}
            </Alert>
          ) : undefined}
        </>
      }
      title={'Transfer'}
      classes={'customModal'}
      loading={fetching}
      show={modalKey === 'transfer'}
      closeAction={{
        testID: 'cashInOutModalButtonCancel',
        onClickFn: onCloseHandler,
        text: 'Cancel',
        showButton: true
      }}
      mainAction={{
        loader: {
          text: 'Transfer',
          icon: <AutorenewIcon style={{ color: 'red' }} />
        },
        testID: 'cashInOutModalButtonPrimary',
        onClickAsyncFn: submitTransfer,
        text: 'Transfer', //cashinoutConfig.button,
        disabled: !formik.isValid || fetching || isCurrencyInputInValid
      }}
    />
  );
};

export default TransferModal;
